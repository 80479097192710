import { AutoComplete, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { getCounterpartiesByFilter, getSettlementsByName } from "../../../../redux/actions/appealActions";
import { useDispatch, useSelector } from "react-redux";

import css from "./AppealPaidServiceLocation.module.scss";
import debounce from "lodash/debounce";
import { dictionaryTypeEnum } from "../../../../dictionaries/models/dictionaryTypeEnum";
import { useCallback } from "react";
import useDictionary from "../../../../dictionaries/useDictionaries";

const AppealPaidServiceLocation = () => {
	const dispatch = useDispatch();
	const { Option } = Select;

	const form = Form.useFormInstance();
	const departmentIdWatch = Form.useWatch("departmentId", form);

	const currentUser = useSelector(state => state.auth);
	const userRole = useSelector(state => state.auth.userRole);
	const departments = useDictionary(dictionaryTypeEnum.DEPARTMENTS);
	const branches = useDictionary(dictionaryTypeEnum.BRANCHES);
	const userDepartmentId = JSON.parse(useSelector(state => state.auth.departmentId));

	//For settlement search
	const [settlementNameData, setSettlementNameData] = useState([]);
	const [settlementNameOptions, setSettlementNameOptions] = useState([]);

	//For personal search
	const [selectedBranches, setSelectedBranches] = useState(branches);
	const [personalSearchData, setPersonalSearchData] = useState([]);
	const [personalSearchOptions, setPersonalSearchOptions] = useState([]);
	const [personalSearchRequest, setPersonalSearchRequest] = useState(null); //{}
	const counterpartyPersonalAccountWatch = Form.useWatch("counterpartyPersonalAccount", form);
	const [counterpartyPersonalAccount, setCounterpartyPersonalAccount] = useState(undefined);

	//START of Geting personal data by searches controls (2 inputs)
	const getCounterparties = useCallback(
		async params => {
			if (!params) return;
			let departmentId = form.getFieldValue("departmentId");
			if (!params.counterpartyName && !params.personalAccNumber) {
				setPersonalSearchOptions([]);
				return;
			}

			setPersonalSearchData(
				await getCounterpartiesByFilter(params.counterpartyName, params.personalAccNumber, departmentId)
			);
		},
		[form]
	);

	const onDepartmentChange = () => {
		form.setFieldsValue({
			counterpartyName: null,
			counterpartyPersonalAccount: null,
			address: null,
			branchId: null,
			settlementName: null,
		});

		setPersonalSearchOptions([]);
	};

	const onSettlementNameSearch = async () => {
		let settlementName = form.getFieldValue("settlementName");
		if (!settlementName) return;
		setSettlementNameData(await getSettlementsByName(settlementName));
	};

	const onSettlementNameSelect = value => {
		if (!settlementNameData.length) return;
		let item = settlementNameData.find(x => String(x.id) === value);
		form.setFieldsValue({
			settlementId: item.id,
			settlementName: item.name,
		});
	};

	const onCounterpartyNameSearch = value => {
		if (!personalSearchRequest) {
			setPersonalSearchRequest({ counterpartyName: value, personalAccNumber: null });
		} else {
			setPersonalSearchRequest({ ...personalSearchRequest, counterpartyName: value });
		}
	};

	const onPersonalAccSearch = value => {
		if (!personalSearchRequest) {
			setPersonalSearchRequest({ counterpartyName: null, personalAccNumber: value });
		} else {
			setPersonalSearchRequest({ ...personalSearchRequest, personalAccNumber: value });
		}
	};

	const onPersonalSearchSelect = value => {
		setCounterpartyPersonalAccount(value);
		if (!personalSearchData.length) return;
		let item = personalSearchData.find(x => x.counterpartyPersonalAccount === value);
		form.setFieldsValue({
			counterpartyName: item.counterpartyName,
			counterpartyPersonalAccount: item.counterpartyPersonalAccount,
			address: item.counterpartyAddress,
			counterpartyTypeId: item.counterpartyTypeId,
			departmentId: item.departmentId,
			branchId: item.branchId, //?????
			settlementId: item.settlementId,
			settlementName: item.settlementName,
		});
	};

	//Filtering branches
	useEffect(() => {
		const isAdminOr25 = userRole.includes("Administrator") || Number(userDepartmentId) === 25;

		if (isAdminOr25) {
			if (departmentIdWatch) {
				setSelectedBranches(branches.filter(b => b.departmentId === departmentIdWatch));
			} else {
				setSelectedBranches(branches);
			}
		} else {
			setSelectedBranches(branches.filter(b => b.departmentId === departmentIdWatch));
		}
	}, [branches, departmentIdWatch, userDepartmentId, userRole]);

	//Search by settlement name
	useEffect(() => {
		if (!settlementNameData || !settlementNameData.length) return;
		const options = settlementNameData.map(item => {
			return {
				label: item.name,
				value: String(item.id),
			};
		});

		setSettlementNameOptions(options);
	}, [settlementNameData]);

	// ======== Search by counterparty (2 inputs) ========
	useEffect(() => {
		if (!personalSearchData || !personalSearchData.length) {
			setPersonalSearchOptions([]);
			return;
		}
		const options = personalSearchData.map(item => {
			return {
				label: `${item.counterpartyName} (${item.counterpartyPersonalAccount})`,
				value: item.counterpartyPersonalAccount,
			};
		});

		setPersonalSearchOptions(options);
	}, [personalSearchData]);

	useEffect(() => {
		if (!personalSearchRequest) return;
		getCounterparties(personalSearchRequest);
	}, [personalSearchRequest, getCounterparties]);
	// ========= END of Geting personal data by searches controls (2 inputs) ========

	//Set counterpartyPersonalAccount
	useEffect(() => {
		setCounterpartyPersonalAccount(counterpartyPersonalAccountWatch);
	}, [counterpartyPersonalAccountWatch]);

	useEffect(() => {
		if (!counterpartyPersonalAccount) {
			setPersonalSearchRequest(undefined);
		} else setPersonalSearchRequest({ personalAccNumber: counterpartyPersonalAccount });
	}, [dispatch, counterpartyPersonalAccount]);

	return (
		<div className={css.appeal_location_wrapper}>
			<Row gutter={20}>
				<Col lg={12} md={24}>
					<Form.Item
						labelCol={{ span: 8 }}
						label='Підрозділ:'
						name='departmentId'
						rules={[{ required: false, message: "Будь ласка оберіть підрозділ!" }]}
					>
						<Select
							placeholder='Оберіть підрозділ'
							allowClear={true}
							popupMatchSelectWidth={false}
							onChange={onDepartmentChange}
							disabled={!(userRole[0] === "Administrator" || Number(currentUser.departmentId) === 25)}
						>
							{departments.map(item => (
								<Option key={item.id} value={item.id}>
									{item.name}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>

				<Col lg={12} md={24}>
					<Form.Item
						labelCol={{ span: 8 }}
						label='Відділення'
						name='branchId'
						rules={[{ required: false, message: "Будь ласка, виберіть відділення!" }]}
					>
						<Select
							style={{ width: "100%" }}
							placeholder='Оберіть відділення'
							popupMatchSelectWidth={false}
							//disabled={!departmentIdWatch}
						>
							{selectedBranches?.length &&
								selectedBranches.map(item => (
									<Option key={item.id} value={item.id}>
										{item.name}
									</Option>
								))}
						</Select>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={20}>
				<Col lg={12} md={24}>
					<Form.Item
						labelCol={{ span: 8 }}
						label='Назва заявника:'
						name='counterpartyName'
						rules={[{ required: true, message: "Будь ласка вкажіть назву заявника!" }]}
					>
						<AutoComplete
							popupMatchSelectWidth={450}
							options={personalSearchOptions}
							notFoundContent={<i style={{ color: "orange" }}>по введенним значенням споживачів не знайдено</i>}
							onSelect={onPersonalSearchSelect}
							onSearch={debounce(onCounterpartyNameSearch, 400)}
						>
							<Input.Search disabled={true} />
						</AutoComplete>
					</Form.Item>
				</Col>

				<Col lg={12} md={24}>
					<Form.Item
						labelCol={{ span: 8 }}
						label='Особовий рахунок:'
						name='counterpartyPersonalAccount'
						rules={[{ required: false, message: "Будь ласка, вкажіть особовий рахунок!" }]}
					>
						<AutoComplete
							popupMatchSelectWidth={450}
							options={personalSearchOptions}
							notFoundContent={<i style={{ color: "orange" }}>по введенним значенням споживачів не знайдено</i>}
							onSelect={onPersonalSearchSelect}
							onSearch={debounce(onPersonalAccSearch, 400)}
							onClick={() => getCounterparties(personalSearchRequest)}
						>
							<Input.Search disabled={true} />
						</AutoComplete>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={20}>
				<Col lg={12} md={24}>
					<Form.Item labelCol={{ span: 8 }} style={{ display: "none", position: "absolute" }} name='settlementId'>
						<Input />
					</Form.Item>
					<Form.Item
						labelCol={{ span: 8 }}
						label='Населений пункт:'
						name='settlementName'
						rules={[{ required: false, message: "Будь ласка оберіть населений пункт!" }]}
					>
						<AutoComplete
							options={settlementNameOptions}
							onSelect={onSettlementNameSelect}
							onSearch={debounce(onSettlementNameSearch, 400)}
						>
							<Input.Search disabled={true} />
						</AutoComplete>
					</Form.Item>
				</Col>

				<Col lg={12} md={24}>
					<Form.Item
						labelCol={{ span: 8 }}
						label='Адреса:'
						name='address'
						rules={[{ required: false, message: "Будь ласка вкажіть адресу!" }]}
					>
						<Input disabled={false} />
					</Form.Item>
				</Col>
			</Row>
		</div>
	);
};

export default AppealPaidServiceLocation;
