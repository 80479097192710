import { Button, DatePicker, Spin, Table, Tooltip, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { defaultPageSize, paginationPosition, tableButtonsSize, tableSize } from "../../../assets/js/options";
import { useDispatch, useSelector } from "react-redux";

import AppstoreAddOutlined from "@ant-design/icons/es/icons/AppstoreAddOutlined";
import { ClearOutlined } from "@ant-design/icons";
import CounterpartyBySmartMeterModal from "./CounterpartyBySmartMeterModal";
import EyeOutlined from "@ant-design/icons/es/icons/EyeOutlined";
import FilterFilled from "@ant-design/icons/es/icons/FilterFilled";
import { NavLink } from "react-router-dom";
import css from "./Smart.module.scss";
import { dictionaryTypeEnum } from "../../../dictionaries/models/dictionaryTypeEnum";
import { getCounterpartiesBySmartMerer } from "../../../redux/actions/shutdownActions";
import { getSmartData } from "../../../redux/actions/smartActions";
import { minutesToHours } from "./../../../assets/js/helpers/minutesToHours";
import moment from "moment";
import { scrollAntTableToTop } from "../../../assets/js/helpers/scrollAntTableToTop";
import { setPageBreadcrumb } from "../../../redux/actions/appActions";
import { showTotal } from "../../../assets/js/helpers/showTotal";
import { shutdownTypeEnum } from "../enums/shutdownEnums";
import { smartGetParameters } from "../../../api/parameters";
import { tableFilterStringBuilder } from "../../../assets/js/helpers/tableFilterStringBuilder";
import { tableSortBuilder } from "../../../assets/js/helpers/tableSortBuilder";
import useDictionary from "../../../dictionaries/useDictionaries";

const Smart = ({ text }) => {
	const dispatch = useDispatch();
	const { RangePicker } = DatePicker;
	const rangePickerDateFormat = "YYYY-MM-DD";
	const tableDateFormat = "YYYY-MM-DD HH:mm";

	const departments = useDictionary(dictionaryTypeEnum.DEPARTMENTS);
	const meterTypes = useDictionary(dictionaryTypeEnum.METER_TYPES);
	const smartData = useSelector(state => state.smart?.smartData.data);
	const isLoading = useSelector(state => state.app.isLoading);
	const smartTotalItems = useSelector(state => state.smart?.smartData.count);
	const counterpartiesBySmartMerer = useSelector(state => state.shutdown?.counterpartiesBySmartMerer);

	const [sorteredInfo, setSorteredInfo] = useState(null);
	const [filteredInfo, setFilteredInfo] = useState(null);
	const [paginationInfo, setPaginationInfo] = useState(null);
	const [meterLastAccessDateRagePickerFilter, setMeterLastAccessDateRagePickerFilter] = useState(null);
	const [smartGetParametersLocal, setSmartGetParametersLocal] = useState({ ...smartGetParameters });
	const [openModal, setOpenModal] = useState(false);

	const smartColumns = [
		{
			title: "№ події",
			dataIndex: "id",
			key: "id",
			sorter: true,
			width: 100,
			align: "center",
		},
		{
			title: "Підрозділ",
			dataIndex: "departmentName",
			key: "departmentName",
			sorter: true,
			width: 250,
			align: "center",
			filters:
				departments &&
				departments.map(item => {
					return {
						text: item.name,
						value: item.id,
					};
				}),
			filteredValue: filteredInfo ? filteredInfo.departmentName : null,
		},
		{
			title: "№ вузла",
			dataIndex: "meterId",
			key: "meterId",
			sorter: true,
			width: 130,
			align: "center",
		},
		{
			title: "Тип вузла",
			dataIndex: "meterTypeName",
			key: "meterTypeName",
			sorter: true,
			width: 130,
			align: "center",
			filters:
				meterTypes &&
				meterTypes.map(item => {
					return {
						text: item.name,
						value: item.id,
					};
				}),
			filteredValue: filteredInfo ? filteredInfo.meterTypeName : null,
		},
		{
			title: "Електрична адреса",
			dataIndex: "electricAddress",
			key: "electricAddress",
			width: 450,
			align: "center",
			render: text => (
				<Typography.Paragraph
					style={{ textAlign: "left" }}
					ellipsis={{ rows: 3, expandable: true, symbol: <div style={{ fontSize: "11px" }}>більше</div> }}
				>
					{text}
				</Typography.Paragraph>
			),
		},
		{
			title: "Дата, час останнього успішного опитування",
			dataIndex: "meterLastAccessDate",
			key: "meterLastAccessDate",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "meterLastAccessDate" && sorteredInfo.order : null,
			width: 150,
			align: "center",
			...getMeterLastAccessDateColumnFilterProps("eventDate"),
			filteredValue: null,
		},

		{
			title: "Дата, час відновлення доступу",
			dataIndex: "meterShutdownEndDate",
			key: "meterShutdownEndDate",
			sorter: true,
			width: 150,
			align: "center",
		},
		{
			title: "Наявність відключення",
			dataIndex: "shutdown",
			key: "shutdown",
			width: 150,
			align: "center",
		},
		{
			title: "Тривалість",
			dataIndex: "duration",
			key: "duration",
			render: text => <>{minutesToHours(text)}</>,
			align: "center",
			width: 100,
		},
		{
			title: "Дії",
			dataIndex: "actions",
			key: "actions",
			width: 150, //80
			align: "center",
		},
	];

	function getMeterLastAccessDateColumnFilterProps() {
		return {
			filterDropdown: ({ confirm }) => (
				<RangePicker
					value={meterLastAccessDateRagePickerFilter ? meterLastAccessDateRagePickerFilter : ""}
					onChange={value => {
						setMeterLastAccessDateRagePickerFilter(value);
						confirm();
					}}
				/>
			),
			filterIcon: filtered => (
				<FilterFilled style={{ color: meterLastAccessDateRagePickerFilter ? "#1890ff" : undefined }} />
			),
		};
	}

	const smartTableData = useCallback(() => {
		const onViewModalClick = electricNodeId => {
			setOpenModal(true);
			dispatch(getCounterpartiesBySmartMerer(electricNodeId));
		};

		if (smartData) {
			return smartData.map(x => {
				//console.log(moment.duration(moment(x.meterShutdownEndDate).diff(moment(x.meterLastAccessDate))).asHours);
				return {
					key: x.id,
					id: x.id,
					departmentName: x.departmentName ? x.departmentName : "", //Check needed when departmentName = null/undefined
					meterId: x.meterId,
					meterTypeName: x.meterTypeName,
					electricAddress: x.electricAddress,
					meterShutdownEndDate: x.meterShutdownEndDate
						? moment(x.meterShutdownEndDate).format(tableDateFormat)
						: "",
					meterLastAccessDate: moment(x.meterLastAccessDate).format(tableDateFormat),
					shutdown: x.shutdown,
					duration: x.meterShutdownEndDate
						? moment.duration(moment(x.meterShutdownEndDate).diff(moment(x.meterLastAccessDate))).asMinutes()
						: undefined,
					actions: (
						<>
							<span className={css.table_action_btn}>
								<Tooltip placement='topLeft' title='Докладно' mouseEnterDelay={0.7}>
									<Button size={tableButtonsSize} onClick={() => onViewModalClick(x.electricNodeId)}>
										<EyeOutlined />
									</Button>
								</Tooltip>
							</span>
							{x.meterShutdownEndDate || x.shutdown ? (
								""
							) : (
								<span className={css.table_action_btn}>
									<NavLink
										to={{
											pathname: `/shutdowns/create-shutdown/${shutdownTypeEnum.EMERGENCY}/${x.departmentId}/${x.electricNodeId}`,
										}}
									>
										<Tooltip placement='topLeft' title='Створення відключення' mouseEnterDelay={0.7}>
											<Button size={tableButtonsSize}>
												<AppstoreAddOutlined />
											</Button>
										</Tooltip>
									</NavLink>
								</span>
							)}
						</>
					),
				};
			});
		}
	}, [dispatch, smartData]);

	const onSmartTableChange = (pagination, filters, sorter) => {
		setFilteredInfo(filters);
		setSorteredInfo(sorter);
		setPaginationInfo(pagination);
		scrollAntTableToTop();
	};

	const combineFilter = (filterString, searchString) => {
		if (filterString && searchString) return `(${searchString}),(${filterString})`;
		else if (filterString) return filterString;
		else if (searchString) return searchString;
		else return "";
	};

	const onClearFilters = () => {
		setSorteredInfo(null);
		setFilteredInfo(null);
		scrollAntTableToTop();
		setMeterLastAccessDateRagePickerFilter(null);
		setSmartGetParametersLocal({ ...smartGetParameters });
	};

	useEffect(() => {
		const breadCrumbData = [
			{ name: "Відключення", link: false },
			{ name: text, link: false },
		];
		dispatch(setPageBreadcrumb(breadCrumbData));
	}, [dispatch, text]);

	useEffect(() => {
		dispatch(getSmartData(smartGetParametersLocal));
	}, [dispatch, smartGetParametersLocal]);

	useEffect(() => {
		if (!meterLastAccessDateRagePickerFilter && !filteredInfo && !sorteredInfo && !paginationInfo) return;

		//Filtering
		const tableFilterNamesArr = ["departmentName", "meterTypeName"];
		const fieldNamesInDBArr = ["departmentId", "meterTypeId"];

		let filter = tableFilterStringBuilder(filteredInfo, tableFilterNamesArr, fieldNamesInDBArr);

		//Sorting
		tableSortBuilder(smartGetParametersLocal, paginationInfo, sorteredInfo);

		let searchStr = "";

		const searchObjArray = [
			{
				stringValue: meterLastAccessDateRagePickerFilter
					? `meterLastAccessDate >=${meterLastAccessDateRagePickerFilter[0].format(
							rangePickerDateFormat
					  )}, meterLastAccessDate <=${meterLastAccessDateRagePickerFilter[1].format(
							rangePickerDateFormat
					  )}T23:59:59`
					: undefined,
			},
		];

		searchObjArray.forEach(item => {
			if (item.stringValue) {
				searchStr += item.stringValue + ",";
			}
		});

		searchStr = searchStr.slice(0, -1); //delete last comma in string

		smartGetParametersLocal.filter = combineFilter(filter, searchStr);
		dispatch(getSmartData(smartGetParametersLocal));
	}, [
		dispatch,
		filteredInfo,
		paginationInfo,
		sorteredInfo,
		meterLastAccessDateRagePickerFilter,
		smartGetParametersLocal,
	]);

	return (
		<div className={css.smart_wrapper}>
			<div className={css.clear_filters_btn_wrap}>
				<Button type='default' className={css.filter_button} onClick={onClearFilters}>
					<ClearOutlined />
					Скинути всі фільтри
				</Button>
			</div>
			{
				<Spin spinning={isLoading} tip={"Завантаження"}>
					<Table
						className={css.ant_table_wrapper}
						size={tableSize}
						pagination={{
							position: paginationPosition,
							defaultPageSize: defaultPageSize,
							showSizeChanger: true,
							total: smartTotalItems,
							showTotal: showTotal,
							current: smartGetParametersLocal.page,
						}}
						columns={smartColumns}
						bordered
						showSorterTooltip={false}
						dataSource={smartTableData()}
						onChange={onSmartTableChange}
					/>
				</Spin>
			}

			<CounterpartyBySmartMeterModal
				openModal={openModal}
				setOpenModal={setOpenModal}
				counterpartiesBySmartMerer={counterpartiesBySmartMerer}
			/>
		</div>
	);
};

export default Smart;
