import { Col, Form, Input, Row } from "antd";

import DividerGrid from "../../../common/DividerGrid/DividerGrid";
import React from "react";

export const AppealPaidServiceComment = () => {
	const { TextArea } = Input;

	return (
		<div style={{ marginBottom: "20px" }}>
			<Row>
				<Col>
					<DividerGrid title={"Коментар при закритті"} />
				</Col>
			</Row>
			<Row gutter={20}>
				<Col lg={24} md={24}>
					<Form.Item
						name='comment'
						rules={[
							{ required: true, message: "Будь ласка, введіть коментар!" },
							{ min: 20, message: "Текст має бути більше ніж 20 символів!" },
						]}
					>
						<TextArea
							placeholder='Введіть коментар..'
							autoSize={{ minRows: 2, maxRows: 8 }}
							showCount
							maxLength={2000}
						/>
					</Form.Item>
				</Col>
			</Row>
		</div>
	);
};
