import { Badge } from "antd";

const Indicator = ({ indicationName, count, color }) => {
	return (
		<Badge count={count} offset={[4, 0]}>
			<div
				style={{
					height: "22px",
					marginLeft: "5px",
					padding: "5px 7px",
					display: "inline-flex",
					justifyContent: "center",
					alignItems: "center",
					borderRadius: "10px",
					border: `1px solid ${color}`,
					color: color,
					//background: "#eee",
				}}
			>
				{`${indicationName}`}
			</div>
		</Badge>
	);
};

export default Indicator;
