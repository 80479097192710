import React from 'react'
import css from './StatsInfo.module.scss'

const StatsInfo = ({title, color, dataM, dataS}) => {

    const borderStyle = {
        borderBottom: '6px solid ' + color
    };
    const colorStyle = {
        color: color
    };

    return (
        <div className={css.stats_info} style={borderStyle}>
            <div className={css.info_title} style={colorStyle}>{title}</div>
            <div className={css.info_content}>
                <div className={css.region_wrap}>
                    <div className={css.region_title}>місто</div>
                    <div className={css.region_content}>
                        {dataM}
                    </div>
                </div>
                <div className={css.region_wrap}>
                    <div className={css.region_title}>село</div>
                    <div className={css.region_content}>
                        {dataS}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default StatsInfo