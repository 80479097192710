import {
	SET_APPROVALS,
	SET_CLAIMS,
	SET_CLAIM_BY_ID,
	SET_CLAIM_EXECUTION_LOG,
	SET_NOT_APPROVED_CLAIMS_COUNT,
} from "../types";

const initialState = {
	approvals: [],
	claims: [],
	claimById: undefined,
	claimExecutionLog: [],
	notApprovedClaimsCount: 0,
};

export const claimReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_APPROVALS:
			return { ...state, approvals: action.payload };
		case SET_CLAIMS:
			return { ...state, claims: action.payload };
		case SET_CLAIM_BY_ID:
			return { ...state, claimById: action.payload };
		case SET_CLAIM_EXECUTION_LOG:
			return { ...state, claimExecutionLog: action.payload };
		case SET_NOT_APPROVED_CLAIMS_COUNT:
			return { ...state, notApprovedClaimsCount: action.payload };
		default:
			return state;
	}
};
