import { apiClient } from "./api";
import axios from "axios";

export const reportsAPI = {
	getNkre373Appendix2(startDate, endDate) {
		return apiClient(axios)
			.post(`/Reports/GetNkre373Appendix2`, { startDate, endDate })
			.then(response => response);
	},

	getNkre373Appendix4(startDate, endDate, totalOperatorsTimeInHours) {
		return apiClient(axios)
			.post(`/Reports/GetNkre373Appendix4`, { startDate, endDate, totalOperatorsTimeInHours })
			.then(response => response);
	},

	getNkre373Appendix2Docx(startDate, endDate) {
		return apiClient(axios)
			.post(`/Reports/GetNkre373Appendix2Docx`, { startDate, endDate }, { responseType: "blob" })
			.then(response => response);
	},

	getNkre373Appendix2Pdf(startDate, endDate) {
		return apiClient(axios)
			.post(`/Reports/GetNkre373Appendix2Pdf`, { startDate, endDate }, { responseType: "blob" })
			.then(response => response);
	},

	getNkre373Appendix2Xlsx(startDate, endDate) {
		return apiClient(axios)
			.post(`/Reports/GetNkre373Appendix2Xlsx`, { startDate, endDate }, { responseType: "blob" })
			.then(response => response);
	},

	getNkre373Appendix4Docx(startDate, endDate, totalOperatorsTimeInHours) {
		return apiClient(axios)
			.post(
				`/Reports/GetNkre373Appendix4Docx`,
				{ startDate, endDate, totalOperatorsTimeInHours },
				{ responseType: "blob" }
			)
			.then(response => response);
	},

	getNkre373Appendix4Pdf(startDate, endDate, totalOperatorsTimeInHours) {
		return apiClient(axios)
			.post(
				`/Reports/GetNkre373Appendix4Pdf`,
				{ startDate, endDate, totalOperatorsTimeInHours },
				{ responseType: "blob" }
			)
			.then(response => response);
	},

	getNkre373Appendix4Xlsx(startDate, endDate, totalOperatorsTimeInHours) {
		return apiClient(axios)
			.post(
				`/Reports/GetNkre373Appendix4Xlsx`,
				{ startDate, endDate, totalOperatorsTimeInHours },
				{ responseType: "blob" }
			)
			.then(response => response);
	},

	getShutdownsPerYear(year, shutdownTypeId, shutdownJobTypeId, departmentId, shutdownDriverId, maxRecords) {
		return apiClient(axios)
			.post(`/Reports/GetShutdownsPerYear`, {
				year,
				shutdownTypeId,
				shutdownJobTypeId,
				departmentId,
				shutdownDriverId,
				maxRecords,
			})
			.then(response => response);
	},

	getOverduedAppealComplainsAsync(departmentId, appealSubjectsId, startDate, counterpartyPersonalAccount) {
		return apiClient(axios)
			.post(`/Reports/GetOverduedAppealComplains`, {
				departmentId,
				appealSubjectsId,
				startDate,
				counterpartyPersonalAccount,
			})
			.then(response => response);
	},

	getOverduedAppealComplainsXlsxAsync(departmentId, appealSubjectsId, startDate, counterpartyPersonalAccount) {
		return apiClient(axios)
			.post(
				`/Reports/GetOverduedAppealComplainsXlsx`,
				{ departmentId, appealSubjectsId, startDate, counterpartyPersonalAccount },
				{ responseType: "blob" }
			)
			.then(response => response);
	},

	getOverduedAppealPaidServices(departmentId, appealPaidServicesId, startDate, counterpartyPersonalAccount) {
		return apiClient(axios)
			.post(`/Reports/GetOverduedAppealPaidServices`, {
				departmentId,
				appealPaidServicesId,
				startDate,
				counterpartyPersonalAccount,
			})
			.then(response => response);
	},

	getOverduedAppealPaidServicesXlsx(departmentId, appealPaidServisesId, startDate, counterpartyPersonalAccount) {
		return apiClient(axios)
			.post(
				`/Reports/GetOverduedAppealPaidServicesXlsx`,
				{ departmentId, appealPaidServisesId, startDate, counterpartyPersonalAccount },
				{ responseType: "blob" }
			)
			.then(response => response);
	},

	getOverduedAppealsV2(startDate, endDate) {
		return apiClient(axios)
			.post(`/Reports/GetOverduedAppealsV2`, { startDate, endDate })
			.then(response => response);
	},

	getOverduedAppealsV2Xlsx(params) {
		return apiClient(axios)
			.post(`/Reports/GetOverduedAppealsV2Xlsx`, { ...params }, { responseType: "blob" })
			.then(response => response);
	},

	getActiveShutdownsAsync(departmentId, shutdownTypeId) {
		return apiClient(axios)
			.post(`/Reports/GetActiveShutdowns`, { departmentId, shutdownTypeId })
			.then(response => response);
	},

	getActiveShutdownsXlsxAsync(departmentId, shutdownTypeId) {
		return apiClient(axios)
			.post(`/Reports/GetActiveShutdownsXlsx`, { departmentId, shutdownTypeId }, { responseType: "blob" })
			.then(response => response);
	},

	getHistoricalShutdowns(departmentId, startDate, endDate, counterpartyPersonalAccount) {
		return apiClient(axios)
			.post(`/Reports/GetHistoricalShutdowns`, { departmentId, startDate, endDate, counterpartyPersonalAccount })
			.then(response => response);
	},

	getHistoricalShutdownsDocx(departmentId, startDate, endDate, counterpartyPersonalAccount) {
		return apiClient(axios)
			.post(
				`/Reports/GetHistoricalShutdownsDocx`,
				{ departmentId, startDate, endDate, counterpartyPersonalAccount },
				{ responseType: "blob" }
			)
			.then(response => response);
	},

	getHistoricalShutdownsXlsx(departmentId, startDate, endDate, counterpartyPersonalAccount) {
		return apiClient(axios)
			.post(
				`/Reports/GetHistoricalShutdownsXlsx`,
				{ departmentId, startDate, endDate, counterpartyPersonalAccount },
				{ responseType: "blob" }
			)
			.then(response => response);
	},

	getFormRM3(selectedDate, reasonId) {
		return apiClient(axios)
			.post(`/Reports/GetFormRM3`, { selectedDate, reasonId })
			.then(response => response);
	},

	getFormRM3Xls(selectedDate, reasonId) {
		return apiClient(axios)
			.post(`/Reports/GetFormRM3Xls`, { selectedDate, reasonId }, { responseType: "blob" })
			.then(response => response);
	},

	getGetCounterpartiesByFilter(params) {
		return apiClient(axios)
			.post(`/Reports/GetCounterpartiesByFilter`, { ...params })
			.then(response => response);
	},

	getNkre373Appendix5(startDate, endDate, departmentId, appealSubjectsId) {
		return apiClient(axios)
			.post(`/Reports/GetNkre373Appendix5`, { startDate, endDate, departmentId, appealSubjectsId })
			.then(response => response);
	},

	getNkre373Appendix5Xlsx(startDate, endDate, departmentId, appealSubjectsId) {
		return apiClient(axios)
			.post(
				`/Reports/GetNkre373Appendix5Xlsx`,
				{ startDate, endDate, departmentId, appealSubjectsId },
				{ responseType: "blob" }
			)
			.then(response => response);
	},

	getRemShutdownsEfficiency(params) {
		return apiClient(axios)
			.post(`/Reports/GetRemShutdownsEfficiency`, { ...params })
			.then(response => response);
	},

	getRemShutdownsEfficiencyXlsx(params) {
		return apiClient(axios)
			.post(`/Reports/GetRemShutdownsEfficiencyXlsx`, { ...params }, { responseType: "blob" })
			.then(response => response);
	},

	getSaidiSaifiCalculationsXlsx(departmentId, settlementTypeId, dateBegin, dateEnd) {
		return apiClient(axios)
			.post(
				`/Reports/GetSaidiSaifiCalculationsXlsx`,
				{ departmentId, settlementTypeId, dateBegin, dateEnd },
				{ responseType: "blob" }
			)
			.then(response => response);
	},

	getMaifiCalculationsXlsx(departmentId, settlementTypeId, dateBegin, dateEnd) {
		return apiClient(axios)
			.post(
				`/Reports/GetMaifiCalculationsXlsx`,
				{ departmentId, settlementTypeId, dateBegin, dateEnd },
				{ responseType: "blob" }
			)
			.then(response => response);
	},

	generateStatisticsDocument(departmentId, settlementTypeId, dateBegin, dateEnd) {
		return apiClient(axios)
			.post(
				`/Reports/GenerateStatisticsDocument`,
				{ departmentId, settlementTypeId, dateBegin, dateEnd },
				{ responseType: "blob" }
			)
			.then(response => response);
	},
};
