import React from 'react';
import { Modal, Button } from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {deleteTask} from "../../../redux/actions/taskActions";

const ModalForDeleteTask = ({ open, onCancel, taskId }) => {
    const dispatch = useDispatch();
    const tabelFilter = useSelector(state => state.app.tableFilter);

    const handleOk = () => {
        dispatch(deleteTask(taskId, tabelFilter));
        onCancel();
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <>
            <Modal
                title="Видалення завдання:"
                open={open}
                //onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Ні
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Так
                    </Button>,
                ]}
            >
                <p>Ви дійсно бажаєте видалити дане завдання № "{taskId}"?</p>
            </Modal>
        </>
    );
};

export default ModalForDeleteTask