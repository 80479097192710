export const setStatusColor = statusId => {
	const color = { titleColor: null, lineColor: null, labelColor: null };

	switch (statusId) {
		case 1:
			color.titleColor = "#D9F6FD";
			color.lineColor = "#00ACD7";
			color.labelColor = "#00ACD7";
			return color;
		case 2:
			color.titleColor = "#a0fdb6";
			color.lineColor = "#00a65a";
			color.labelColor = "#00a65a";
			return color;
		case 3:
			color.titleColor = "#F1B24E";
			color.lineColor = "#DA8C10";
			color.labelColor = "#DA8C10";
			return color;
		case 4:
			color.titleColor = "#FAE4E1";
			color.lineColor = "#C64333";
			color.labelColor = "#C64333";
			return color;
		case 5:
			color.titleColor = "#BDBDBD";
			color.lineColor = "#828282";
			color.labelColor = "#828282";
			return color;
		default:
			return color;
	}
};
