import { Col, Form, Radio, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { createStatusTabStyleList } from "../../../../assets/js/helpers/createStatusTabStyleList";
import css from "./AppealPaidServiceStatus.module.scss";

const AppealPaidServiceStatus = ({ isEditMode }) => {
	const form = Form.useFormInstance();
	const appealStatusIdWatch = Form.useWatch("appealStatusId", form);

	const [isStatusChanged, setIsStatusChanged] = useState(false);
	const [selectedStatusId, setSelectedStatusId] = useState(appealStatusIdWatch);

	const defaultStatusTabStylesRef = useRef([
		{ body: `${css.status_item} ${css.item_1}`, text: `${css.radio_status_text}` },
		{ body: `${css.status_item} ${css.item_2}`, text: `${css.radio_status_text}` },
		{ body: `${css.status_item} ${css.item_3}`, text: `${css.radio_status_text}` },
	]);
	const [statusTabStyles, setStatusTabStyles] = useState(defaultStatusTabStylesRef.current);

	useEffect(() => {
		if (selectedStatusId !== appealStatusIdWatch) {
			setIsStatusChanged(false);
			setStatusTabStyles(createStatusTabStyleList(appealStatusIdWatch, defaultStatusTabStylesRef.current, css));
		} else {
			setIsStatusChanged(true);
			setStatusTabStyles(createStatusTabStyleList(selectedStatusId, defaultStatusTabStylesRef.current, css));
		}
	}, [selectedStatusId, appealStatusIdWatch]);

	const onStatusChange = e => {
		setSelectedStatusId(e.target.value);
	};

	return (
		<div className={css.appeal_status_wrapper}>
			<Form.Item name='appealStatusId'>
				<Radio.Group className={css.statuses} onChange={onStatusChange}>
					<Row>
						<Col className={statusTabStyles[0].body}>
							<Radio disabled={isStatusChanged} value={1}>
								<span className={statusTabStyles[0].text}>Нова</span>
							</Radio>
						</Col>
						<Col className={statusTabStyles[1].body}>
							<Radio disabled={!isEditMode || isStatusChanged} value={2}>
								<span className={statusTabStyles[1].text}>В роботі</span>
							</Radio>
						</Col>
						<Col className={statusTabStyles[2].body}>
							<Radio disabled={!isEditMode || isStatusChanged} value={3}>
								<span className={statusTabStyles[2].text}>Закрита</span>
							</Radio>
						</Col>
					</Row>
				</Radio.Group>
			</Form.Item>
		</div>
	);
};

export default AppealPaidServiceStatus;
