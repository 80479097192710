import "moment/locale/uk";

import { Col, Form, Row } from "antd";
import { DatePicker, Input } from "antd";

import React from "react";
import css from "./AppealPaidServiceTime.module.scss";

//import locale from 'antd/es/date-picker/locale/uk_UA';

const AppealPaidServiceTime = () => {
	const datePickerFormat = "YYYY-MM-DD HH:mm";
	const timeFormat = "HH:mm";

	return (
		<div className={css.appeal_time_wrapper}>
			<Row gutter={20}>
				<Col lg={9} md={24}>
					<Form.Item
						labelCol={{ span: 11 }}
						label='Дата реєстрації:'
						name='innerRegistrationDate'
						rules={[{ required: true, message: "Будь ласка оберіть дату!" }]}
					>
						<DatePicker disabled={true} showTime={{ format: timeFormat }} format={datePickerFormat} />
					</Form.Item>
				</Col>
				<Col lg={8} md={24}>
					<Form.Item
						labelCol={{ span: 12 }}
						label='Реєстраційний №:'
						name='innerRegistrationNumber'
						rules={[{ required: false, message: "Будь ласка вкажіть номер" }]}
					>
						<Input disabled={true} />
					</Form.Item>
				</Col>
			</Row>
		</div>
	);
};

export default AppealPaidServiceTime;
