import { Checkbox, Col, Divider, Row, Select, Button } from "antd";
import { GoogleMap, Marker, OverlayView, MarkerClusterer, useJsApiLoader } from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import { getMapDataByShutdownLines, getMapDataByShutdownPs, getMapDataByShutdownForecast } from "../../redux/actions/shutdownActions";
import { useDispatch, useSelector } from "react-redux";

import SyncOutlined from "@ant-design/icons/es/icons/SyncOutlined";
import Preloader from "../common/Preloader/Preloader";
import car from "../../assets/images/map/car.png";
import electricTower from "../../assets/images/map/electric-tower.png";
import css from "./ShutdownMaps.module.scss";
import { dictionaryTypeEnum } from "../../dictionaries/models/dictionaryTypeEnum";
import flashRed from "../../assets/images/map/flash-red.png";
import flashYellow from "../../assets/images/map/flash-yellow.png";
import { getMapDataByAppeals } from "../../redux/actions/appealActions";
import { getMapDataByOvb } from "../../redux/actions/ovbActions";
import man from "../../assets/images/map/man.png";
import flashRedMan from "../../assets/images/map/flash-red-man.png";
import moment from "moment";
import { setPageBreadcrumb } from "../../redux/actions/appActions";
import { shutdownTypeEnum } from "../Shutdowns/enums/shutdownEnums";
import useDictionary from "../../dictionaries/useDictionaries";

const ShutdownMaps = () => {
	const dispatch = useDispatch();
	const dateFormat = "DD.MM.yyyy HH:mm";

	const { Option } = Select;
	const defaultMapOptions = {
		zoom: 8,
		center: {
			lat: 49.419444,
			lng: 26.979444,
		},
		styles: [
			{
				featureType: "poi",
				elementType: "all",
				stylers: [{ visibility: "off" }],
			},
		],
	};

	const departments = useDictionary(dictionaryTypeEnum.DEPARTMENTS);
	const mapDataByAppeals = useSelector(state => state.appeal.mapDataByAppeals);
	const mapDataByShutdownLines = useSelector(state => state.shutdown.mapDataByShutdownLines);
	const mapDataByShutdownPs = useSelector(state => state.shutdown.mapDataByShutdownPs);
	const mapDataByShutdownForecast = useSelector(state => state.shutdown.mapDataByShutdownForecast);
	const mapDataByOvb = useSelector(state => state.ovb.mapDataByOvb);
	const currentUser = useSelector(state => state.auth);

	const [appealMarkerOverlayId, setAppealMarkerOverlayId] = useState(null);
	const [shutdownMarkerOverlayId, setShutdownMarkerOverlayId] = useState(null);
	const [shutdownLineMarkerOverlayId, setShutdownLineMarkerOverlayId] = useState(null);
	const [shutdownForecastMarkerOverlayId, setShutdownForecastMarkerOverlayId] = useState(null);
	const [ovbMarkerOverlayId, setOvbMarkerOverlayId] = useState(null);
	const [mapOptions, setMapOptions] = useState(defaultMapOptions);
	const [selectedDepartmentId, setSelectedDepartmentId] = useState(
		JSON.parse(currentUser.departmentId) === 25 ? null : JSON.parse(currentUser.departmentId)
	);
	const [mapDataByShutdownLinesGrouped, setMapDataByShutdownLinesGrouped] = useState([]);
	const [mapDataByShutdownForecastToOvbLines, setMapDataByShutdownForecastToOvbLines] = useState([]);
	const [iconBaseProps, setIconBaseProps] = useState({});

	const checkedOptions = [
		{
			label: "Звернення (Т3.1 + Т3.2)",
			value: "Appeals",
		},
		{
			label: "Відключення",
			value: "Shutdowns",
		},
		{
			label: "ОВБ",
			value: "Ovbs",
		},
		{
			label: "Прогнозовані відключення",
			value: "ShutdownsForecast",
		},
	];

	const defaultCheckedList = ["Appeals", "Shutdowns", "Ovbs", "ShutdownsForecast"];
	const [checkedList, setCheckedList] = useState(defaultCheckedList);

	const { isLoaded, loadError } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: "AIzaSyBPGkuUku9nlesfQiEvXAHbKYsSvM8ZA5A",
		libraries: ['geometry', 'routes']
	});

	const [map, setMap] = React.useState(null);

	const calcPathBetweenShutdownForecastAndOvb = useCallback(async () => {
		const ovbArray = mapDataByOvb.map(x => ({
			latitude: x.latitude,
			longitude: x.longitude
		}));

		const directionsService = new window.google.maps.DirectionsService();

		for (let i = 0; i < mapDataByShutdownForecast.length; i++) {
			let nearest;

			const shutdownForecastPoint = new window.google.maps.LatLng(mapDataByShutdownForecast[i].latitude, mapDataByShutdownForecast[i].longitude);
			for (let j = 0; j < mapDataByOvb.length; j++) {
				if (ovbArray[j].isUsed)
					continue;

				const ovbPoint = new window.google.maps.LatLng(ovbArray[j].latitude, ovbArray[j].longitude);
				const currentDistance = window.google.maps.geometry.spherical.computeDistanceBetween(shutdownForecastPoint, ovbPoint);

				if (!nearest) {
					nearest = { distance: currentDistance, point: ovbArray[j] }
					continue;
				}

				if (nearest.distance > currentDistance) {
					nearest.point.isUsed = 0;
					ovbArray[j].isUsed = 1;
					nearest = { distance: currentDistance, point: ovbArray[j] }
				}
			}

			if (checkedList.includes("ShutdownsForecast") && checkedList.includes("Ovbs")) {
				directionsService.route({
					origin: new window.google.maps.LatLng(nearest.point.latitude, nearest.point.longitude),
					destination: new window.google.maps.LatLng(mapDataByShutdownForecast[i].latitude, mapDataByShutdownForecast[i].longitude),
					travelMode: window.google.maps.TravelMode.DRIVING
				}, (response, status) => {
					if (status === window.google.maps.DirectionsStatus.OK) {
						const polyline = new window.google.maps.Polyline({
							path: [],
							strokeColor: '#0000FF',
							strokeWeight: 3
						});
						const bounds = new window.google.maps.LatLngBounds();
						const legs = response.routes[0].legs;

						for (let i = 0; i < legs.length; i++) {
							const steps = legs[i].steps;
							for (let j = 0; j < steps.length; j++) {
								const nextSegment = steps[j].path;
								for (let k = 0; k < nextSegment.length; k++) {
									polyline.getPath().push(nextSegment[k]);
									bounds.extend(nextSegment[k]);
								}
							}
						}

						polyline.setMap(map);
						setMapDataByShutdownForecastToOvbLines(prev => ([...prev, polyline]))
						return;
					}

					console.log('Directions request failed due to ' + status);
				});
			}
		};
	}, [mapDataByShutdownForecast, mapDataByOvb, map, checkedList]);

	useEffect(() => {
		const breadCrumbData = [
			{ name: "Відключення", link: false },
			{ name: "Мапи", link: false },
		];
		dispatch(setPageBreadcrumb(breadCrumbData));
	}, [dispatch]);

	useEffect(() => {
		if (!departments || !departments.length) return;

		if (selectedDepartmentId) {
			const department = departments.find(x => x.id === selectedDepartmentId);
			setMapOptions({
				...mapOptions,
				zoom: department.mapZoom,
				center: { lat: department.mapLatitude, lng: department.mapLongitude },
			});
		} else {
			setMapOptions({ ...mapOptions, zoom: defaultMapOptions.zoom, center: defaultMapOptions.center });
		}

		dispatch(getMapDataByAppeals(selectedDepartmentId));
		dispatch(getMapDataByShutdownLines(selectedDepartmentId));
		dispatch(getMapDataByShutdownPs(selectedDepartmentId));
		dispatch(getMapDataByShutdownForecast(selectedDepartmentId));
		dispatch(getMapDataByOvb(selectedDepartmentId));
	}, [dispatch, selectedDepartmentId, departments]);

	useEffect(() => {
		if (!mapDataByShutdownLines || !mapDataByShutdownLines.length) {
			setMapDataByShutdownLinesGrouped([]);
			return;
		}

		const mapDataByShutdownLinesGroupBy = mapDataByShutdownLines.reduce((r, c) => {
			const electricNodeChildId = c.electricNodeChildId;
			let { opId, latitude, longitude, ...other } = c;
			r[electricNodeChildId] = r[electricNodeChildId] || { lines: [], ...other };
			r[electricNodeChildId].lines.push({ opId, latitude, longitude });
			return r;
		}, {});

		const result = [];
		for (const electricNodeChildId in mapDataByShutdownLinesGroupBy) {
			const pillars = mapDataByShutdownLinesGroupBy[electricNodeChildId].lines.map(item => {
				return {
					position: new window.google.maps.LatLng({ lat: item.latitude, lng: item.longitude }),
					id: item.opId
				}
			});

			result.push({ electricNodeChildId, pillars });
		}

		setMapDataByShutdownLinesGrouped(result);
	}, [mapDataByShutdownLines]);

	useEffect(() => {
		if (!mapDataByShutdownForecast || !mapDataByShutdownForecast.length || !mapDataByOvb || !mapDataByOvb.length) {
			setMapDataByShutdownForecastToOvbLines([]);
			return;
		}
		calcPathBetweenShutdownForecastAndOvb()
	}, [mapDataByShutdownForecast, mapDataByOvb, calcPathBetweenShutdownForecastAndOvb, checkedList]);

	useEffect(() => {
		if ((!checkedList.includes("ShutdownsForecast") || !checkedList.includes("Ovbs")) && mapDataByShutdownForecastToOvbLines?.length) {
			mapDataByShutdownForecastToOvbLines.forEach(element => {
				element.setMap(null);
				element = null;
			});

			setMapDataByShutdownForecastToOvbLines([]);
		}
	}, [checkedList, mapDataByShutdownForecastToOvbLines]);

	useEffect(() => {
		if (isLoaded) {
			setIconBaseProps({
				size: new window.google.maps.Size(32, 32),
				origin: new window.google.maps.Point(0, 0),
				anchor: new window.google.maps.Point(16, 16)
			});
		}
	}, [isLoaded]);	

	const onMapLoad = React.useCallback(function callback(map) {
		setMap(map);
	}, []);

	const onMapUnmount = React.useCallback(function callback(map) {
		setMap(null);
	}, []);

	const onMapClick = React.useCallback(function callback(e) {
		setAppealMarkerOverlayId(null);
		setShutdownMarkerOverlayId(null);
		setShutdownForecastMarkerOverlayId(null);
		setShutdownLineMarkerOverlayId(null);
		setOvbMarkerOverlayId(null);
	}, []);

	const centerOverlayView = (width, height) => {
		return {
			x: 5,
			y: -10,
		};
	};

	const onDepartmentSelect = value => {
		setSelectedDepartmentId(value);
	};

	const onDepartmentClear = () => {
		setSelectedDepartmentId(null);
	};

	const onUpdateData = () => {
		if (checkedList.includes("Appeals")) {
			dispatch(getMapDataByAppeals(selectedDepartmentId));
		}

		if (checkedList.includes("Shutdowns")) {
			dispatch(getMapDataByShutdownLines(selectedDepartmentId));
			dispatch(getMapDataByShutdownPs(selectedDepartmentId));
		}

		if (checkedList.includes("ShutdownsForecast")) {
			dispatch(getMapDataByShutdownForecast(selectedDepartmentId));
		}

		if (checkedList.includes("Ovbs")) {
			dispatch(getMapDataByOvb(selectedDepartmentId));
		}
	};

	const renderAppealOverlayView = () => {
		if (!mapDataByAppeals) return null;

		const mapData = mapDataByAppeals.find(x => x.id === appealMarkerOverlayId);
		if (!mapData) return null;

		return (
			<OverlayView
				position={{ lat: mapData.latitude, lng: mapData.longitude }}
				mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
				getPixelPositionOffset={centerOverlayView}
			>
				<div className={css.contentStyles}>
					<Row gutter={[8, 8]}>
						<Col span={24}>
							<strong>Ім'я заявника: </strong>
							{mapData.counterpartyName}
						</Col>
						<Col span={24}>
							<strong>Телефон: </strong>
							{mapData.phone}
						</Col>
						<Col span={24}>
							<strong>Короткий зміст: </strong>
							{mapData.description}
						</Col>
						<Col span={24}>
							<strong>Електрична адреса: </strong>
							{mapData.electricAddress}
						</Col>
					</Row>
				</div>
			</OverlayView>
		);
	};

	const renderOvbOverlayView = () => {
		if (!mapDataByOvb) return null;

		const mapData = mapDataByOvb.find(x => x.id === ovbMarkerOverlayId);
		if (!mapData) return null;

		return (
			<OverlayView
				position={{ lat: mapData.latitude, lng: mapData.longitude }}
				mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
				getPixelPositionOffset={centerOverlayView}
			>
				<div className={css.contentStyles}>
					<Row gutter={[8, 8]}>
						<Col span={24}>
							<strong>Група: </strong>
							{mapData.group}
						</Col>
						<Col span={24}>
							<strong>Назва автомобіля: </strong>
							{mapData.name}
						</Col>
						<Col span={24}>
							<strong>Час останньої події: </strong>
							{mapData.lastDateTime}
						</Col>
					</Row>
				</div>
			</OverlayView>
		);
	};

	const renderShutdownOverlayView = () => {
		if (!mapDataByShutdownPs) return null;

		const mapData = mapDataByShutdownPs.find(x => x.id === shutdownMarkerOverlayId);
		if (!mapData) return null;

		return (
			<OverlayView
				position={{ lat: mapData.latitude, lng: mapData.longitude }}
				mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
				getPixelPositionOffset={centerOverlayView}
			>
				<div className={css.contentStyles}>
					<Row gutter={[8, 5]}>
						<Col span={24}>
							<strong>Відключення №: </strong>
							{mapData.shutdownId}
						</Col>
						<Col span={24}>
							<strong>Тип відключення: </strong>
							{mapData.shutdownTypeName}
						</Col>
						<Col span={24}>
							<strong>Електрична адреса відключення: </strong>
							{mapData.shutdownElectricAddress}
						</Col>
						<Divider className={css.divider} />
						<Col span={24}>
							<strong>Початок відключення: </strong>
							{moment(mapData.startDate).format(dateFormat)}
						</Col>
						<Col span={24}>
							<strong>Завершення відключення: </strong>
							{moment(mapData.endDate).format(dateFormat)}
						</Col>
						<Divider className={css.divider} />
						<Col span={24}>
							<strong>Кількість побутових споживачів: </strong>
							{mapData.residentialCounterpartiesCount ?? "відсутні"}
						</Col>
						<Col span={24}>
							<strong>Кількість непобутових споживачів: </strong>
							{mapData.commercialCounterpartiesCount ?? "відсутні"}
						</Col>
						<Divider className={css.divider} />
						<Col span={24}>
							<strong>Назва вузла: </strong>
							{mapData.psName}
						</Col>
						<Col span={24}>
							<strong>Електричні адреси вибраного вузла: </strong>
						</Col>
						<Col span={24}>
							<div className={css.divStyle}>
								<table className={css.tableStyle}>
									<tbody>
										{mapData.psElectricAddresses &&
											mapData.psElectricAddresses.split("\\n").map((i, key) => {
												return (
													<tr key={key}>
														<td className={css.tdStyle}>{key + 1}</td>
														<td className={css.tdStyle}>{i}</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>
						</Col>
					</Row>
				</div>
			</OverlayView>
		);
	};

	const renderShutdownForecastOverlayView = () => {
		if (!mapDataByShutdownForecast) return null;

		const mapData = mapDataByShutdownForecast.find(x => x.electricNodeId === shutdownForecastMarkerOverlayId);
		if (!mapData) return null;

		return (
			<OverlayView
				position={{ lat: mapData.latitude, lng: mapData.longitude }}
				mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
				getPixelPositionOffset={centerOverlayView}
			>
				<div className={css.contentStyles}>
					<Row gutter={[8, 5]}>
						<Col span={24}>
							<strong>Електрична адреса відключення: </strong>
							{mapData.electricAddress}
						</Col>
						<Divider className={css.divider} />
						<Col span={24}>
							<strong>Відділення: </strong>
							{mapData.branchName}
						</Col>
						<Col span={24}>
							<strong>Прогнозована дата завершення: </strong>
							{moment(mapData.dateEndForecast).format(dateFormat)}
						</Col>
						<Col span={24}>
							<strong>Кількість звернень по даній електричній адресі: </strong>
							{mapData.counterpartiesCount}
						</Col>
						<Col span={24}>
							<strong>Потреба виїзду більше однієї бригади в даному відділені: </strong>
							{mapData.isMoreOvbRequired ? "так" : "ні"}
						</Col>
						<Divider className={css.divider} />						
						<Col span={24}>
							<strong>Існує SCADA подія по даній електричній адресі: </strong>
							{mapData.isScadaEventExist ? "так" : "ні"}
						</Col>	
						<Col span={24}>
							<strong>Існує SMART подія по даній електричній адресі: </strong>
							{mapData.isSmartEventExist ? "так" : "ні"}
						</Col>												
					</Row>
				</div>
			</OverlayView>
		);
	};

	const renderShutdownLineOverlayView = () => {
		if (!mapDataByShutdownLines) return null;

		const mapData = mapDataByShutdownLines.find(x => x.electricNodeChildId === Number(shutdownLineMarkerOverlayId.electricNodeChildId) && x.opId === shutdownLineMarkerOverlayId.pillarId);
		if (!mapData) return null;

		//console.log(mapData);
		return (
			<OverlayView
				position={{ lat: mapData.latitude, lng: mapData.longitude }}
				mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
				getPixelPositionOffset={centerOverlayView}
			>
				<div className={css.contentStyles}>
					<Row gutter={[8, 5]}>
						<Col span={24}>
							<strong>Відключення №: </strong>
							{mapData.shutdownId}
						</Col>
						<Col span={24}>
							<strong>Тип відключення: </strong>
							{mapData.shutdownTypeName}
						</Col>
						<Col span={24}>
							<strong>Електрична адреса відключення: </strong>
							{mapData.shutdownElectricAddress}
						</Col>
						<Divider className={css.divider} />
						<Col span={24}>
							<strong>Початок відключення: </strong>
							{moment(mapData.startDate).format(dateFormat)}
						</Col>
						<Col span={24}>
							<strong>Завершення відключення: </strong>
							{moment(mapData.endDate).format(dateFormat)}
						</Col>
						<Divider className={css.divider} />
						<Col span={24}>
							<strong>Кількість побутових споживачів: </strong>
							{mapData.residentialCounterpartiesCount ?? "відсутні"}
						</Col>
						<Col span={24}>
							<strong>Кількість непобутових споживачів: </strong>
							{mapData.commercialCounterpartiesCount ?? "відсутні"}
						</Col>
					</Row>
				</div>
			</OverlayView>
		);
	};

	const renderGoogleMap = () => {
		return (
			<div className={css.map_container}>
				<GoogleMap
					id='googleMap'
					mapContainerClassName={css.container}
					onLoad={onMapLoad}
					onUnmount={onMapUnmount}
					onClick={onMapClick}
					options={mapOptions}
				>
					{checkedList.includes("Appeals") &&
						mapDataByAppeals?.map(item => (
							<Marker
								key={"Appeals" + item.id}
								icon={{
									...iconBaseProps,
									url: man,
								}}
								position={{ lat: item.latitude, lng: item.longitude }}
								onClick={() => {
									setAppealMarkerOverlayId(item.id);
									setShutdownMarkerOverlayId(null);
									setOvbMarkerOverlayId(null);
									setShutdownLineMarkerOverlayId(null);
									setShutdownForecastMarkerOverlayId(null);
								}}
							/>
						))}
					{checkedList.includes("Shutdowns") &&
						mapDataByShutdownPs?.map(item => (
							<Marker
								key={"PS" + item.id}
								position={{ lat: item.latitude, lng: item.longitude }}
								icon={{
									...iconBaseProps,
									url: item.shutdownTypeId === shutdownTypeEnum.EMERGENCY ? flashRed : flashYellow,
								}}
								onClick={() => {
									setShutdownMarkerOverlayId(item.id);
									setAppealMarkerOverlayId(null);
									setOvbMarkerOverlayId(null);
									setShutdownLineMarkerOverlayId(null);
									setShutdownForecastMarkerOverlayId(null);
								}}
							/>
						))}
					{checkedList.includes("Shutdowns") &&
						mapDataByShutdownLinesGrouped?.map(line => {
							return <MarkerClusterer
								key={"ShutdownLines" + line.electricNodeChildId}
								minimumClusterSize={5}
							>
								{(clusterer) =>
									line.pillars.map(pillar => (
										<Marker
											key={"ShutdownLines" + line.electricNodeChildId.toString() + pillar.id.toString()}
											position={pillar.position}
											icon={{
												...iconBaseProps,
												url: electricTower,
											}}
											onClick={() => {
												setShutdownLineMarkerOverlayId({ electricNodeChildId: line.electricNodeChildId, pillarId: pillar.id });
												setShutdownMarkerOverlayId(null);
												setAppealMarkerOverlayId(null);
												setOvbMarkerOverlayId(null);
												setShutdownForecastMarkerOverlayId(null);
											}}
											clusterer={clusterer}
										/>
									))
								}
							</MarkerClusterer>
						})}
					{checkedList.includes("Ovbs") &&
						mapDataByOvb?.map(item => (
							<Marker
								key={item.id}
								position={{ lat: item.latitude, lng: item.longitude }}
								icon={{
									...iconBaseProps,
									url: car,
								}}
								onClick={() => {
									setOvbMarkerOverlayId(item.id);
									setAppealMarkerOverlayId(null);
									setShutdownMarkerOverlayId(null);
									setShutdownLineMarkerOverlayId(null);
									setShutdownForecastMarkerOverlayId(null);
								}}
							/>
						))}
					{checkedList.includes("ShutdownsForecast") &&
						mapDataByShutdownForecast?.map(item => (
							<Marker
								key={"ShutdownForecast" + item.electricNodeId}
								position={{ lat: item.latitude, lng: item.longitude }}
								icon={{
									...iconBaseProps,
									url: flashRedMan,
								}}
								onClick={() => {
									setShutdownForecastMarkerOverlayId(item.electricNodeId);
									setAppealMarkerOverlayId(null);
									setShutdownMarkerOverlayId(null);
									setShutdownLineMarkerOverlayId(null);
									setOvbMarkerOverlayId(null);
								}}
							/>
						))}
					{checkedList.includes("Appeals") && appealMarkerOverlayId ? renderAppealOverlayView() : null}
					{checkedList.includes("Shutdowns") && shutdownMarkerOverlayId ? renderShutdownOverlayView() : null}
					{checkedList.includes("Shutdowns") && shutdownLineMarkerOverlayId
						? renderShutdownLineOverlayView()
						: null}
					{checkedList.includes("Ovbs") && ovbMarkerOverlayId ? renderOvbOverlayView() : null}
					{checkedList.includes("ShutdownsForecast") && shutdownForecastMarkerOverlayId ? renderShutdownForecastOverlayView() : null}
				</GoogleMap>
			</div>
		);
	};

	return (
		<div className={css.wrap}>
			<Row gutter={[32, 16]} align={"middle"}>
				<Col span={18}>
					<Row gutter={[32, 16]} align={"middle"}>
						<Col>
							<Select
								className={css.department_select}
								placeholder='(Всі)'
								popupMatchSelectWidth={false}
								allowClear={true}
								onSelect={onDepartmentSelect}
								onClear={onDepartmentClear}
								value={selectedDepartmentId}
								disabled={
									!(currentUser.userRole.includes("Administrator") || Number(currentUser.departmentId) === 25)
								}
							>
								{departments.map(item => (
									<Option key={item.id} value={item.id}>
										{item.name}
									</Option>
								))}
							</Select>
						</Col>
						<Col>
							<Checkbox.Group options={checkedOptions} value={checkedList} onChange={list => setCheckedList(list)} />
						</Col>
					</Row>
				</Col>
				<Col span={6} className={css.clear_filters_btn_wrap}>
					<Button type='default' onClick={onUpdateData}>
						<SyncOutlined />
						Оновити дані
					</Button>
				</Col>
			</Row>
			{loadError ? (
				<div>Виникла помилка: мапа не може завантажитись. Нам дуже шкода.</div>
			) : isLoaded ? (
				renderGoogleMap()
			) : (
				<Preloader />
			)}
		</div>
	);
};

export default ShutdownMaps;
