import { AppstoreAddOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import {
	Button,
	Card,
	Col,
	DatePicker,
	Divider,
	Drawer,
	Input,
	Popover,
	Row,
	Table,
	Tag,
	Tooltip,
	Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { defaultPageSize, paginationPosition, tableButtonsSize, tableSize } from "../../assets/js/options";
import { getAppeals, updateBulk } from "../../redux/actions/appealActions";
import { useDispatch, useSelector } from "react-redux";

import ClearOutlined from "@ant-design/icons/es/icons/ClearOutlined";
import FilterFilled from "@ant-design/icons/es/icons/FilterFilled";
import GroupActionsModal from "./GroupActionsModal/GroupActionsModal";
import ModalForDeleteAppeal from "./ModalForDeleteAppeal/ModalForDeleteAppeal";
import { NavLink } from "react-router-dom";
import ViewAppealDrawer from "./ViewAppealDrawer/ViewAppealDrawer";
import { appealGetParameters } from "../../api/parameters";
import { appealStatusEnum } from "./enums/appealEnums";
import css from "./Appeals.module.scss";
import { dictionaryTypeEnum } from "../../dictionaries/models/dictionaryTypeEnum";
import { scrollAntTableToTop } from "../../assets/js/helpers/scrollAntTableToTop";
import { setPageBreadcrumb } from "../../redux/actions/appActions";
import { showTotal } from "../../assets/js/helpers/showTotal";
import { tableFilterStringBuilder } from "../../assets/js/helpers/tableFilterStringBuilder";
import { tableSortBuilder } from "../../assets/js/helpers/tableSortBuilder";
import { taskInitiatorsEnum } from "../Tasks/enums/taskEnums";
import { useCallback } from "react";
import useDebounce from "../../assets/js/hooks/useDebounce";
import useDebouncedEffect from "../../assets/js/hooks/useDebouncedEffect";
import useDictionary from "../../dictionaries/useDictionaries";
import useIsMount from "../../assets/js/hooks/useIsMount";
import { useSearchParams } from "react-router-dom/dist";

const Appeals = () => {
	const [queryParams] = useSearchParams();
	const dateFormat = "YYYY-MM-DD HH:mm";
	const dispatch = useDispatch();
	const isMount = useIsMount();

	const departmentEntryPoints = useDictionary(dictionaryTypeEnum.DEPARTMENT_ENTRY_POINTS);
	const departmentsState = useDictionary(dictionaryTypeEnum.DEPARTMENTS);
	const counterpartyTypes = useDictionary(dictionaryTypeEnum.COUNTERPARTY_TYPES);
	const appeals = useSelector(state => state.appeal.appeals.data);
	const appealTotalItems = useSelector(state => state.appeal.appeals.count);
	const currentUserRole = useSelector(state => state.auth.userRole);
	const userDepartmentId = JSON.parse(useSelector(state => state.auth.departmentId));
	const groupActionErrors = useSelector(state => state.app.apiLastError);

	//Is Admin or Management Department
	const currentUser = useSelector(state => state.auth);
	const isAdminOr25 = currentUser.userRole.includes("Administrator") || Number(currentUser.departmentId) === 25;

	//Enable-Disable controls
	const [createAppealButton, setDisableCreateAppealButton] = useState(false);
	const [editButton, setDisableEditButton] = useState(false);
	const [departments, setDepartments] = useState([]);

	//Local states
	//Modals
	const [viewModalVisible, setViewModalVisible] = useState(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const [selectedAppealId, setSelectedAppealId] = useState(null);

	//For table
	//For table filters selectors
	// const appealClassifiers = useDictionary(dictionaryTypeEnum.APPEAL_CLASSIFIERS).filter(x => x.id !== appealClassifiersEnum.PAID_SERVICE);
	const appealStatuses = useDictionary(dictionaryTypeEnum.APPEAL_STATUSES);
	const appealSubjects = useDictionary(dictionaryTypeEnum.APPEAL_SUBJECTS);
	const branches = useDictionary(dictionaryTypeEnum.BRANCHES);

	//For sorters & filters
	const [sorteredInfo, setSorteredInfo] = useState(null);
	const [filteredInfo, setFilteredInfo] = useState(null);
	const [filterString, setFilterString] = useState(
		`appealStatusId=${appealStatusEnum.NEW}|appealStatusId=${appealStatusEnum.IN_PROCESS}`
	);
	const [searchString, setSearchString] = useState("");
	const [appealGetParametersLocal, setAppealGetParametersLocal] = useState({
		...appealGetParameters,
		filter: filterString,
	});

	//For selected table rows
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [closeButtonDisabled, setCloseButtonDisabled] = useState(false);
	const [inProcessButtonDisabled, setInProcessButtonDisabled] = useState(false);
	const [groupActionModalVisible, setGroupActionModalVisible] = useState(false);
	const [groupActionType, setGroupActionType] = useState("");
	const [errorDrawerOpen, setErrorDrawerOpen] = useState(false);
	const [groupAppealSubjectId, setGroupAppealSubjectId] = useState(undefined);

	const [counterpartyPersonalAccountSearch, setCounterpartyPersonalAccountSearch] = useState("");
	const [counterpartyNameSearch, setCounterpartyNameSearch] = useState("");
	const [phoneSearch, setPhoneSearch] = useState("");

	const debouncedCounterpartyPersonalAccountSearch = useDebounce(counterpartyPersonalAccountSearch, 400);
	const debouncedCounterpartyNameSearch = useDebounce(counterpartyNameSearch, 400);
	const debouncedPhoneSearch = useDebounce(phoneSearch, 400);

	const [innerRegistrationDate, setInnerRegistrationDate] = useState("");

	const groupActionsContent = (
		<div className={css.group_operations_content}>
			{selectedRows.length > 1 ? (
				<div style={{ width: "100%", textAlign: "center" }}>
					<Button
						className={css.group_actions_button}
						disabled={inProcessButtonDisabled}
						type='default'
						onClick={() => {
							setGroupActionModalVisible(true);
							setGroupActionType("inProcess");
						}}
					>
						В роботі
					</Button>
					<Button
						className={css.group_actions_button}
						disabled={closeButtonDisabled}
						type='default'
						onClick={() => {
							setGroupActionModalVisible(true);
							setGroupActionType("close");
						}}
					>
						Закриття
					</Button>
				</div>
			) : (
				<div style={{ color: "red" }}>Не обрано жодного відключення, або кількість обраних недостатня!</div>
			)}
		</div>
	);

	//For table filter rangepicker
	const { RangePicker } = DatePicker;
	const getColumnFilterProps = () => {
		return {
			filterDropdown: ({ confirm }) => (
				<RangePicker
					value={innerRegistrationDate ? innerRegistrationDate : ""}
					onChange={value => {
						setInnerRegistrationDate(value);
						confirm();
					}}
				/>
			),
			filterIcon: () => <FilterFilled style={{ color: innerRegistrationDate ? "#1890ff" : undefined }} />,
		};
	};

	const columns = [
		{
			title: "Внутр. № реєстр-ра",
			dataIndex: "innerRegistrationNumber",
			key: "innerRegistrationNumber",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "innerRegistrationNumber" && sorteredInfo.order : null,
			filteredValue: null,
			width: 110,
		},
		{
			title: "Статус",
			dataIndex: "appealStatusName",
			key: "appealStatusName",
			align: "center",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "appealStatusName" && sorteredInfo.order : null,
			filters: appealStatuses?.map(item => {
				return {
					text: item.name,
					value: item.id,
				};
			}),
			filteredValue:
				queryParams.get("cameFrom") === "bell"
					? filteredInfo
						? filteredInfo.appealStatusName || ["1"]
						: ["1"]
					: filteredInfo
					? filteredInfo.appealStatusName || ["1", "2"]
					: ["1", "2"],
			width: 100,
		},
		{
			title: (
				<Input
					value={counterpartyPersonalAccountSearch}
					placeholder='О/Р'
					style={{ fontSize: "12px" }}
					onChange={e => {
						onTableColumnSearch(e.target.value, "counterpartyPersonalAccount");
					}}
				/>
			),
			dataIndex: "counterpartyPersonalAccount",
			key: "counterpartyPersonalAccount",
			filters: counterpartyTypes
				?.filter(x => x.id)
				?.map(x => {
					return {
						text: x.name,
						value: x.id,
					};
				}),
			filteredValue: filteredInfo ? filteredInfo.counterpartyPersonalAccount || null : null,
			width: 140,
		},
		{
			title: "Населений пункт",
			dataIndex: "settlementName",
			key: "settlementName",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "settlementName" && sorteredInfo.order : null,
			filteredValue: null,
			width: 135,
		},
		{
			title: "Дата реєстр",
			dataIndex: "innerRegistrationDate",
			key: "innerRegistrationDate",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "innerRegistrationDate" && sorteredInfo.order : null,
			filteredValue: null,
			width: 100,
			...getColumnFilterProps("innerRegistrationDate"),
		},
		{
			title: "Точка вх.",
			dataIndex: "departmentEntryPointName",
			key: "departmentEntryPointName",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "departmentEntryPointName" && sorteredInfo.order : null,
			width: 130,
			ellipsis: true,
			filters: departmentEntryPoints?.map(item => {
				return {
					text: item.name,
					value: item.id,
				};
			}),
			filteredValue: filteredInfo ? filteredInfo.departmentEntryPointName || null : null,
		},
		{
			title: "Підрозділ",
			dataIndex: "departmentName",
			key: "departmentName",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "departmentName" && sorteredInfo.order : null,
			width: 130,
			ellipsis: true,
			filters: departments?.map(item => {
				return {
					text: item.name,
					value: item.id,
				};
			}),
			filteredValue: filteredInfo ? filteredInfo.departmentName || null : null,
		},
		{
			title: "Відділення",
			dataIndex: "branchName",
			key: "branchName",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "branchName" && sorteredInfo.order : null,
			width: 130,
			ellipsis: true,
			filters: branches
				?.filter(b => (isAdminOr25 ? b : b.departmentId === userDepartmentId))
				.map(item => {
					return {
						text: item.name,
						value: item.id,
					};
				}),
			filteredValue: filteredInfo ? filteredInfo.branchName || null : null,
		},
		{
			title: "Електрична адреса",
			dataIndex: "electricAddress",
			key: "electricAddress",
			width: 200,
			colSpan: 2,
			onCell: () => ({
				colSpan: 2,
			}),
		},
		{
			title: "",
			dataIndex: "electricAddressExpander",
			key: "electricAddressExpander",
			colSpan: 0,
			onCell: () => ({
				colSpan: 0,
			}),
		},
		{
			title: (
				<Input
					value={counterpartyNameSearch}
					placeholder='Заявник'
					style={{ fontSize: "12px" }}
					onChange={e => {
						onTableColumnSearch(e.target.value, "counterpartyName");
					}}
				/>
			),
			dataIndex: "counterpartyName",
			key: "counterpartyName",
			filteredValue: null,
			width: 150,
		},
		{
			title: (
				<Input
					value={phoneSearch}
					placeholder='Телефон'
					style={{ fontSize: "12px" }}
					onChange={e => {
						onTableColumnSearch(e.target.value, "phone");
					}}
				/>
			),
			dataIndex: "phone",
			key: "phone",
			filteredValue: null,
			width: 110,
		},
		{
			title: "Тема",
			dataIndex: "appealSubjectName",
			key: "appealSubjectName",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "appealSubjectName" && sorteredInfo.order : null,
			width: 90,
			filters:
				appealSubjects &&
				appealSubjects.map(item => {
					return {
						text: item.title,
						value: item.id,
					};
				}),
			filteredValue: filteredInfo ? filteredInfo.appealSubjectName || null : null,
		},
		{
			title: "Короткий зміст",
			dataIndex: "description",
			key: "description",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "description" && sorteredInfo.order : null,
			filteredValue: null,
			width: 170,
			colSpan: 2,
			onCell: () => ({
				colSpan: 2,
			}),
			render: text => (
				<Typography.Paragraph
					ellipsis={{ rows: 4, expandable: true, symbol: <div style={{ fontSize: "11px" }}>більше</div> }}
				>
					{text}
				</Typography.Paragraph>
			),
		},
		{
			title: "",
			dataIndex: "descriptionExpander",
			key: "descriptionExpander",
			colSpan: 0,
			onCell: () => ({
				colSpan: 0,
			}),
		},
		{
			title: "Термін",
			dataIndex: "appealSubjectTimeValue",
			key: "appealSubjectTimeValue",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "appealSubjectTimeValue" && sorteredInfo.order : null,
			filteredValue: null,
			width: 80,
		},
		{
			title: "Дії",
			dataIndex: "actions",
			key: "actions",
			align: "left",
			filteredValue: null,
			width: 100,
		},
	];

	//Filtered columns for show for different roles
	const filteredColumns = isAdminOr25
		? columns.filter(
				column =>
					column.dataIndex !== "settlementName" &&
					column.dataIndex !== "electricAddress" &&
					column.dataIndex !== "electricAddressExpander"
		  )
		: columns.filter(
				column =>
					column.dataIndex !== "innerRegistrationNumber" &&
					column.dataIndex !== "departmentName" &&
					column.dataIndex !== "departmentEntryPointName"
		  );

	const onAppealTableChange = (pagination, filters, sorter) => {
		setFilteredInfo(filters);
		let appealGetParametersChanged = { ...appealGetParametersLocal };
		//Sorting
		tableSortBuilder(appealGetParametersChanged, pagination, sorter);
		setSorteredInfo(sorter);

		//Filtering
		const tableFilterNamesArr = [
			"departmentEntryPointName",
			"appealStatusName",
			"appealSubjectName",
			"departmentName",
			"branchName",
			"innerRegistrationDate",
			"counterpartyPersonalAccount",
		];
		const fieldNamesInDBArr = [
			"departmentEntryPointId",
			"appealStatusId",
			"appealSubjectId",
			"departmentId",
			"branchId",
			"innerRegistrationDate",
			"counterpartyTypeId",
		];
		const filterStringLocal = tableFilterStringBuilder(filters, tableFilterNamesArr, fieldNamesInDBArr);
		setFilterString(filterStringLocal);

		appealGetParametersChanged.filter = combineFilter(filterStringLocal, searchString);
		setAppealGetParametersLocal(appealGetParametersChanged);
		scrollAntTableToTop();
	};

	const combineFilter = (filterString, searchString) => {
		if (filterString && searchString) return `(${searchString}), (${filterString})`;
		else if (filterString) return `(${filterString})`;
		else if (searchString) return `(${searchString})`;
		else return `(appealStatusId=${appealStatusEnum.NEW}|appealStatusId=${appealStatusEnum.IN_PROCESS})`;
	};

	const onClearFilters = useCallback(() => {
		setSorteredInfo(null);
		setFilteredInfo(null);
		setCounterpartyPersonalAccountSearch("");
		setCounterpartyNameSearch("");
		setPhoneSearch("");
		setInnerRegistrationDate("");
		setSearchString("");
		setFilterString(`appealStatusId=${appealStatusEnum.NEW}|appealStatusId=${appealStatusEnum.IN_PROCESS}`);
		scrollAntTableToTop();

		setAppealGetParametersLocal({
			...appealGetParameters,
			filter: `appealStatusId=${appealStatusEnum.NEW}|appealStatusId=${appealStatusEnum.IN_PROCESS}`,
		});
	}, []);

	const getAppealsDataForList = () => {
		let moment = require("moment");
		if (appeals) {
			return appeals.map(appeal => {
				return {
					key: appeal.id,
					id: appeal.id,
					appealStatusName: (
						<NavLink
							onClick={e => {
								if (editButton || appeal.appealStatusId === appealStatusEnum.CLOSED) e.preventDefault();
							}}
							to={{
								pathname: `/appeals/edit-appeal/${appeal.id}`,
							}}
						>
							<Tag
								style={{ minWidth: 80, textAlign: "center" }}
								color={setStatusTagColor(appeal.appealStatusId)}
							>
								{appeal.appealStatusName}
							</Tag>
						</NavLink>
					),
					appealSubjectId: appeal.appealSubjectId,
					innerRegistrationNumber: appeal.innerRegistrationNumber,
					settlementName: appeal.settlementName,
					counterpartyPersonalAccount: appeal.counterpartyPersonalAccount,
					innerRegistrationDate: moment(appeal.innerRegistrationDate).format(dateFormat),
					departmentEntryPointName: appeal.departmentEntryPointName,
					electricAddress: appeal.electricAddress,
					departmentName: appeal.departmentName,
					branchName: appeal.branchName,
					counterpartyName: appeal.counterpartyName,
					phone: appeal.phone,
					appealSubjectName: appeal.appealSubjectName,
					description: appeal.description,
					appealSubjectTimeValue:
						appeal.appealSubjectTimeValue.toString() +
						(appeal.appealSubjectIsEachDayWorkDay ? " роб. днів" : " днів"),
					actions: (
						<Row gutter={[10, 10]}>
							<Col>
								<Tooltip placement='topLeft' title='Перегляд скарги' mouseEnterDelay={0.7}>
									<Button size={tableButtonsSize} onClick={() => onViewAppeal(appeal.id)}>
										<EyeOutlined />
									</Button>
								</Tooltip>
							</Col>
							<Col>
								{appeal.appealStatusId !== 3 && (
									<NavLink
										to={{
											pathname: "/tasks/create-task",
											search: `appealId=${appeal.id}&taskInitiatorId=${taskInitiatorsEnum.APPEAL}`,
										}}
									>
										<Tooltip placement='topLeft' title='Додати завдання' mouseEnterDelay={0.7}>
											<Button size={tableButtonsSize}>
												<AppstoreAddOutlined />
											</Button>
										</Tooltip>
									</NavLink>
								)}
							</Col>
							<Col>
								{!(editButton || appeal.appealStatusId === appealStatusEnum.CLOSED) && (
									<NavLink
										to={{
											pathname: `/appeals/edit-appeal/${appeal.id}`,
										}}
									>
										<Tooltip placement='topLeft' title='Редагування скарги' mouseEnterDelay={0.7}>
											<Button size={tableButtonsSize}>
												<EditOutlined />
											</Button>
										</Tooltip>
									</NavLink>
								)}
							</Col>
							<Col>
								{currentUserRole.includes("Administrator") && (
									<Tooltip placement='topLeft' title='Видалення скарги' mouseEnterDelay={0.7}>
										<Button size={tableButtonsSize} danger onClick={() => onDeleteAppeal(appeal.id)}>
											<DeleteOutlined />
										</Button>
									</Tooltip>
								)}
							</Col>
						</Row>
					),
				};
			});
		}
	};

	// rowSelection object indicates the need for row selection
	const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
		setSelectedRowKeys(newSelectedRowKeys);
		setSelectedRows(newSelectedRows);

		if (
			newSelectedRows?.length &&
			newSelectedRows.every((val, i, arr) => val.appealSubjectId === arr[0].appealSubjectId)
		) {
			setGroupAppealSubjectId(newSelectedRows[0].appealSubjectId);
		} else {
			setGroupAppealSubjectId(undefined);
		}

		//Appeal status check
		const selectedAppeals = appeals.filter(x => x.id === newSelectedRowKeys.find(y => y === x.id));
		let selectedAppealStatuses = selectedAppeals.map(x => x.appealStatusId);
		selectedAppealStatuses = [...new Set(selectedAppealStatuses)];
		if (selectedAppealStatuses.length > 1 || selectedAppealStatuses.includes(appealStatusEnum.CLOSED)) {
			//console.log('All actions blocked');
			setInProcessButtonDisabled(true);
			setCloseButtonDisabled(true);
		} else if (selectedAppealStatuses.length === 1 && selectedAppealStatuses[0] === appealStatusEnum.NEW) {
			//console.log('Activate only "Confirm" button');
			setInProcessButtonDisabled(false);
			setCloseButtonDisabled(true);
		} else if (selectedAppealStatuses.length === 1 && selectedAppealStatuses[0] === appealStatusEnum.IN_PROCESS) {
			//console.log('Activate "Close" button');
			setInProcessButtonDisabled(true);
			setCloseButtonDisabled(false);
		}
	};

	const tableRowSelection = {
		selectedRowKeys,
		selectedRows,
		onChange: onSelectChange,
	};

	const onGroupModalSave = modalValues => {
		if (!modalValues) return;

		let appealsForGroupChange = [];
		selectedRowKeys.forEach(x => {
			let appl = appeals.find(item => item.id === x);
			appealsForGroupChange.push({ ...appl });
		});

		//To "in process" status
		if (groupActionType === "inProcess") {
			appealsForGroupChange.forEach(x => {
				x.appealStatusId = appealStatusEnum.IN_PROCESS;
				x.comment = modalValues.comment;
			});
		}

		//To "close" status
		if (groupActionType === "close") {
			appealsForGroupChange.forEach(x => {
				x.appealStatusId = appealStatusEnum.CLOSED;
				x.comment = modalValues.comment;
			});
		}

		dispatch(updateBulk(appealsForGroupChange));
		onGroupModalCancel();
	};

	const onGroupModalCancel = () => {
		setGroupActionModalVisible(false);
		setSelectedRowKeys([]);
		setSelectedRows([]);
		setGroupActionType("");
	};

	//Set statuses color
	const setStatusTagColor = status => {
		let color = "default";
		if (status === 1) color = "red";
		if (status === 2) color = "blue";
		if (status === 3) color = "default";
		return color;
	};

	//On View Appeal
	const onViewAppeal = id => {
		setSelectedAppealId(id);
		setViewModalVisible(true);
	};

	//On Delete Appeal
	const onDeleteAppeal = id => {
		setSelectedAppealId(id);
		setDeleteModalVisible(true);
	};

	//EFFECTS
	//Breadcrumbs, first requests
	useEffect(() => {
		const breadCrumbData = [{ name: "Скарги, пропозиції", link: false }];

		dispatch(setPageBreadcrumb(breadCrumbData));
	}, [dispatch]);

	//Change filter by came from (menu/bell)
	useEffect(() => {
		if (queryParams.get("cameFrom") === "bell") {
			setAppealGetParametersLocal({
				...appealGetParameters,
				filter: `isRead = false, appealStatusId=${appealStatusEnum.NEW}`,
			});
		} else {
			onClearFilters();
		}
	}, [queryParams, onClearFilters]);

	//For department selector when without department
	useEffect(() => {
		setDepartments([].concat(departmentsState, [{ id: null, name: "Відсутній" }]));
	}, [departmentsState]);

	//Get appeals
	useDebouncedEffect(
		() => {
			dispatch(getAppeals(appealGetParametersLocal));
		},
		[appealGetParametersLocal],
		700
	);

	//Role access control
	useEffect(() => {
		if (
			currentUserRole.includes("Administrator") ||
			currentUserRole.includes("DepartmentUser") ||
			currentUserRole.includes("DepartmentDispatcher")
		) {
			setDisableCreateAppealButton(false);
			setDisableEditButton(false);
		} else {
			setDisableCreateAppealButton(true);
			setDisableEditButton(true);
		}
	}, [dispatch, currentUserRole]);

	//Search in inputs header table with delay
	const onTableColumnSearch = (value, column) => {
		if (column === "counterpartyPersonalAccount") {
			setCounterpartyPersonalAccountSearch(value);
		} else if (column === "counterpartyName") {
			setCounterpartyNameSearch(value);
		} else if (column === "phone") {
			setPhoneSearch(value);
		}
	};

	//Full search string creating
	useDebouncedEffect(
		() => {
			if (isMount) return;
			let searchStr = "";

			const searchObjArray = [
				{
					stringValue: debouncedCounterpartyNameSearch
						? `counterpartyName =*${debouncedCounterpartyNameSearch}`
						: "",
				},
				{
					stringValue: debouncedCounterpartyPersonalAccountSearch
						? debouncedCounterpartyPersonalAccountSearch === " "
							? `counterpartyPersonalAccount = null`
							: `counterpartyPersonalAccount =*${debouncedCounterpartyPersonalAccountSearch}`
						: "",
				},
				{
					stringValue: debouncedPhoneSearch ? `phone =*${debouncedPhoneSearch}` : "",
				},
				{
					stringValue: innerRegistrationDate
						? `innerRegistrationDate >=${innerRegistrationDate[0].format(
								"YYYY-MM-DD"
						  )},  innerRegistrationDate <=${innerRegistrationDate[1].format("YYYY-MM-DD")}T23:59:59`
						: "",
				},
			];

			searchObjArray.forEach(item => {
				if (item.stringValue) {
					searchStr += "" + item.stringValue + ",";
				}
			});

			searchStr = searchStr.slice(0, -1); //delete last comma in string
			setSearchString(searchStr);

			setAppealGetParametersLocal({
				...appealGetParametersLocal,
				filter: combineFilter(filterString, searchStr),
				page: 1,
			});
		},
		[
			dispatch,
			debouncedCounterpartyPersonalAccountSearch,
			debouncedCounterpartyNameSearch,
			debouncedPhoneSearch,
			innerRegistrationDate,
		],
		100
	);

	return (
		<>
			<Row className={css.appeals}>
				<Col span={12}>
					<NavLink
						to={{
							pathname: `/appeals/create-appeal`,
						}}
					>
						<Button hidden={createAppealButton} className={css.site_button} type='primary'>
							<PlusOutlined />
							Створити скаргу
						</Button>
					</NavLink>

					<>
						<Popover content={groupActionsContent} title='Групові операції:'>
							{selectedRows.length >= 2 && (
								<Button className={css.site_button} type='primary'>
									Групові операції ({selectedRows.length})
								</Button>
							)}
						</Popover>
						{groupActionErrors.length && (
							<Button
								style={{ margin: "0 0 15px 0" }}
								type='primary'
								danger
								disabled={!groupActionErrors.length}
								onClick={() => setErrorDrawerOpen(true)}
							>
								{`Помилки (${groupActionErrors.length})`}
							</Button>
						)}
					</>
				</Col>
				<Col span={12} className={css.clear_filters_btn_wrap}>
					<Button type='default' className={css.filter_button} onClick={onClearFilters}>
						<ClearOutlined />
						Скинути всі фільтри
					</Button>
				</Col>
				<Card bodyStyle={{ padding: "0px" }} bordered={true} style={{ width: "100%" }}>
					<Table
						onChange={onAppealTableChange}
						className={css.ant_table_wrapper}
						size={tableSize}
						rowSelection={{ ...tableRowSelection }}
						pagination={{
							position: paginationPosition,
							defaultPageSize: defaultPageSize,
							showSizeChanger: true,
							total: appealTotalItems,
							current: appealGetParametersLocal.page,
							showTotal: showTotal,
						}}
						showSorterTooltip={false}
						//scroll={appealsTableScroll}
						columns={filteredColumns}
						dataSource={getAppealsDataForList()}
						//rowClassName={(record, index) => onOverdueAppealCheck(record, index)}
						rowClassName={(record, index) => (index % 2 === 0 ? css.table_row_light : css.table_row_dark)}
					/>
				</Card>
				<ViewAppealDrawer
					visible={viewModalVisible}
					onClose={() => setViewModalVisible(false)}
					appealId={selectedAppealId}
				/>
				<ModalForDeleteAppeal
					open={deleteModalVisible}
					onCancel={() => setDeleteModalVisible(false)}
					appealId={selectedAppealId}
				/>
				<GroupActionsModal
					showModal={groupActionModalVisible}
					onCancel={onGroupModalCancel}
					groupActionType={groupActionType}
					selectedRowsCount={selectedRows.length}
					onSave={onGroupModalSave}
					groupAppealSubjectId={groupAppealSubjectId}
				/>
				<Drawer
					title={`Помилки при групових операціях (${groupActionErrors.length})`}
					headerStyle={{ backgroundColor: "orange", borderRadius: "0px" }}
					drawerStyle={{ border: `2px solid orange`, backgroundColor: "#FFEDBD" }}
					placement='left'
					onClose={() => setErrorDrawerOpen(false)}
					open={errorDrawerOpen}
				>
					{groupActionErrors.length
						? groupActionErrors.map((e, i) => (
								<>
									<p key={e.id}>{`${i + 1}) Cкаргa/пропозиція. №${e.id}, помилка: "${e.message}."`}</p>
									<Divider />
								</>
						  ))
						: ""}
				</Drawer>
			</Row>
		</>
	);
};

export default Appeals;
