import { SET_SCADA_EVENTS } from "../types";

const initialState = {
	scadaEvents: {},
};

export const scadaEventsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SCADA_EVENTS:
			return { ...state, scadaEvents: action.payload };

		default:
			return state;
	}
};
