import {
	SET_APPEAL_CLASSIFIERS,
	SET_APPEAL_COMMUNICATION_TYPES,
	SET_APPEAL_OBTAINING_METHODS,
	SET_APPEAL_PAID_SERVICE_TYPES,
	SET_APPEAL_STATUSES,
	SET_APPEAL_SUBJECTS,
	SET_APPEAL_SUBJECT_GROUPS,
	SET_BRANCHES,
	SET_CLAIM_APPROVAL_DEFAULTS,
	SET_CLAIM_APPROVAL_LEVELS,
	SET_CLAIM_EMERGENCY_READINESS,
	SET_CLAIM_REPAIR_TYPES,
	SET_CLAIM_STATUSES,
	SET_CLAIM_TYPES,
	SET_COUNTERPARTY_TYPES,
	SET_DEPARTMENTS_FOR_HIERARCHY,
	SET_DEPARTMENT_DATA,
	SET_HOLIDAY_TYPES,
	SET_METER_TYPES,
	SET_NOTIFICATION_STATUSES,
	SET_NOTIFICATION_TYPES,
	SET_RECIPIENT_GROUPS,
	SET_RECIPIENT_TYPES_DATA,
	SET_SCADAS,
	SET_SCADA_SUBSTANTIONS,
	SET_SCADA_SUBSTANTION_VOLTAGES,
	SET_SCADA_SYGNAL_STATUSES,
	SET_SETTLEMENT_TYPES,
	SET_SHUTDOWN_DRIVERS,
	SET_SHUTDOWN_JOB_TYPE_REASONS,
	SET_SHUTDOWN_STATUSES,
	SET_SHUTDOWN_TYPES,
	SET_STATUSES_MATRIX_TYPES,
	SET_TASK_INITIATORS,
	SET_TASK_STATUSES,
	SET_USER_DEPARTMENT_ENTRY_POINTS,
	SET_USER_ROLES,
} from "../types";
import { dictionaryApiEnum, dictionaryTypeEnum } from "../../dictionaries/models/dictionaryTypeEnum";
import { hideLoader, setApiLastError, showLoader } from "./appActions";

import { dictionariAPI } from "../../api/dictionaryEndpoints";
import { thunkErrorHandler } from "./actionHandlers";

export const getDictionary = dictionaryType => async dispatch => {
	try {
		dispatch(showLoader());
		const response = await dictionariAPI[getApiType(dictionaryType)]();
		if (response.status === 200) {
			dispatch(setApiData(dictionaryType, response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	} finally {
		dispatch(hideLoader());
	}
};

//Dictionary Actions (setters)
export const setShutdownStatuses = shutdownStatuses => {
	return {
		type: SET_SHUTDOWN_STATUSES,
		payload: shutdownStatuses,
	};
};

export const setDepartmentData = department => {
	return {
		type: SET_DEPARTMENT_DATA,
		payload: department,
	};
};

export const setShutdownTypes = shutdownTypes => {
	return {
		type: SET_SHUTDOWN_TYPES,
		payload: shutdownTypes,
	};
};

export const setShutdownDrivers = shutdownDrivers => {
	return {
		type: SET_SHUTDOWN_DRIVERS,
		payload: shutdownDrivers,
	};
};

export const setNotificationStatuses = notificationStatuses => {
	return {
		type: SET_NOTIFICATION_STATUSES,
		payload: notificationStatuses,
	};
};

export const setNotificationTypes = notificationTypes => {
	return {
		type: SET_NOTIFICATION_TYPES,
		payload: notificationTypes,
	};
};

export const setSettlementTypes = types => {
	return {
		type: SET_SETTLEMENT_TYPES,
		payload: types,
	};
};

export const setUserDepartmentEntryPoints = userDepartmentEntryPoints => {
	return {
		type: SET_USER_DEPARTMENT_ENTRY_POINTS,
		payload: userDepartmentEntryPoints,
	};
};

export const setRecipientTypes = recipientTypes => {
	return {
		type: SET_RECIPIENT_TYPES_DATA,
		payload: recipientTypes,
	};
};

export const setAppealClassifiers = appealClassifiers => {
	return {
		type: SET_APPEAL_CLASSIFIERS,
		payload: appealClassifiers,
	};
};

export const setCounterpartyTypes = counterpartyTypes => {
	return {
		type: SET_COUNTERPARTY_TYPES,
		payload: counterpartyTypes,
	};
};

export const setAppealStatuses = appealStatuses => {
	return {
		type: SET_APPEAL_STATUSES,
		payload: appealStatuses,
	};
};

export const setTaskStatuses = taskStatuses => {
	return {
		type: SET_TASK_STATUSES,
		payload: taskStatuses,
	};
};

export const setTaskInitiators = taskInitiators => {
	return {
		type: SET_TASK_INITIATORS,
		payload: taskInitiators,
	};
};

export const setAppealSubjects = appealSubjects => {
	return {
		type: SET_APPEAL_SUBJECTS,
		payload: appealSubjects,
	};
};

export const setAppealCommunicationTypes = appealCommunicationTypes => {
	return {
		type: SET_APPEAL_COMMUNICATION_TYPES,
		payload: appealCommunicationTypes,
	};
};

export const setAppealObtainingMethods = appealObtainingMethods => {
	return {
		type: SET_APPEAL_OBTAINING_METHODS,
		payload: appealObtainingMethods,
	};
};

//For Statuses matrix
export const setStatusesMatrixTypes = statusesMatrixTypes => {
	return {
		type: SET_STATUSES_MATRIX_TYPES,
		payload: statusesMatrixTypes,
	};
};

//For holidays
export const setHolidayTypes = holidayTypes => {
	return {
		type: SET_HOLIDAY_TYPES,
		payload: holidayTypes,
	};
};

//For department hierarchy
export const setDepartmentsForHierarchy = departmentsForHierarchy => {
	return {
		type: SET_DEPARTMENTS_FOR_HIERARCHY,
		payload: departmentsForHierarchy,
	};
};

//Appeal subject groups
export const setAppealSubjectGroups = appealSubjectGroups => {
	return {
		type: SET_APPEAL_SUBJECT_GROUPS,
		payload: appealSubjectGroups,
	};
};

export const setClaimEmergencyReadiness = claimEmergencyReadiness => {
	return {
		type: SET_CLAIM_EMERGENCY_READINESS,
		payload: claimEmergencyReadiness,
	};
};

export const setClaimTypes = claimTypes => {
	return {
		type: SET_CLAIM_TYPES,
		payload: claimTypes,
	};
};

export const setClaimRepairTypes = claimRepairTypes => {
	return {
		type: SET_CLAIM_REPAIR_TYPES,
		payload: claimRepairTypes,
	};
};

export const setClaimApprovalDefaults = claimApprovalDefaults => {
	return {
		type: SET_CLAIM_APPROVAL_DEFAULTS,
		payload: claimApprovalDefaults,
	};
};

export const setClaimStatuses = claimStatuses => {
	return {
		type: SET_CLAIM_STATUSES,
		payload: claimStatuses,
	};
};

export const setUserRoles = roles => {
	return {
		type: SET_USER_ROLES,
		payload: roles,
	};
};

export const setRecipientGroups = groups => {
	return {
		type: SET_RECIPIENT_GROUPS,
		payload: groups,
	};
};

export const setClaimApprovalLevels = levels => {
	return {
		type: SET_CLAIM_APPROVAL_LEVELS,
		payload: levels,
	};
};

export const setShutdownJobTypeReasons = reasons => {
	return {
		type: SET_SHUTDOWN_JOB_TYPE_REASONS,
		payload: reasons,
	};
};

export const setScadas = scadas => {
	return {
		type: SET_SCADAS,
		payload: scadas,
	};
};

export const setScadaSubstantions = scadaSubstantions => {
	return {
		type: SET_SCADA_SUBSTANTIONS,
		payload: scadaSubstantions,
	};
};

export const setScadaSubstantionVoltages = scadaSubstantionVoltages => {
	return {
		type: SET_SCADA_SUBSTANTION_VOLTAGES,
		payload: scadaSubstantionVoltages,
	};
};

export const setScadaSygnalStatuses = scadaSygnalStatuses => {
	return {
		type: SET_SCADA_SYGNAL_STATUSES,
		payload: scadaSygnalStatuses,
	};
};

export const setAppealPaidServiceTypes = appealPaidServiceTypes => {
	return {
		type: SET_APPEAL_PAID_SERVICE_TYPES,
		payload: appealPaidServiceTypes,
	};
};

export const setBranches = branches => {
	return {
		type: SET_BRANCHES,
		payload: branches,
	};
};

export const setMeterTypes = meterTypes => {
	return {
		type: SET_METER_TYPES,
		payload: meterTypes,
	};
};

//-----------------------------------------------

//Set API data
const setApiData = (dictionaryType, apiData) => {
	switch (dictionaryType) {
		case dictionaryTypeEnum.SHUTDOWN_STATUSES:
			return setShutdownStatuses(apiData);

		// case dictionaryTypeEnum.SHUTDOWN_JOB_TYPES:
		// 	return setShutdownJobTypesData(apiData);

		case dictionaryTypeEnum.DEPARTMENTS:
			return setDepartmentData(apiData);

		case dictionaryTypeEnum.SHUTDOWN_TYPES:
			return setShutdownTypes(apiData);

		case dictionaryTypeEnum.SHUTDOWN_DRIVERS:
			return setShutdownDrivers(apiData);

		case dictionaryTypeEnum.NOTIFICATION_STATUSES:
			return setNotificationStatuses(apiData);

		case dictionaryTypeEnum.NOTIFICATION_TYPES:
			return setNotificationTypes(apiData);

		case dictionaryTypeEnum.SETTLEMENT_TYPES:
			return setSettlementTypes(apiData);

		case dictionaryTypeEnum.DEPARTMENT_ENTRY_POINTS:
			return setUserDepartmentEntryPoints(apiData);

		case dictionaryTypeEnum.RECIPIENT_TYPES:
			return setRecipientTypes(apiData);

		case dictionaryTypeEnum.APPEAL_CLASSIFIERS:
			return setAppealClassifiers(apiData);

		case dictionaryTypeEnum.COUNTERPARTY_TYPES:
			return setCounterpartyTypes(apiData);

		case dictionaryTypeEnum.APPEAL_STATUSES:
			return setAppealStatuses(apiData);

		case dictionaryTypeEnum.TASK_STATUSES:
			return setTaskStatuses(apiData);

		case dictionaryTypeEnum.TASK_INITIATORS:
			return setTaskInitiators(apiData);

		case dictionaryTypeEnum.APPEAL_SUBJECTS:
			return setAppealSubjects(apiData);

		case dictionaryTypeEnum.APPEAL_COMMUNICATION_TYPES:
			return setAppealCommunicationTypes(apiData);

		case dictionaryTypeEnum.APPEAL_OBTAINING_METHODS:
			return setAppealObtainingMethods(apiData);

		case dictionaryTypeEnum.STATUSES_MATRIX_TYPES:
			return setStatusesMatrixTypes(apiData);

		case dictionaryTypeEnum.HOLIDAY_TYPES:
			return setHolidayTypes(apiData);

		case dictionaryTypeEnum.DEPARTMENTS_FOR_HIERARCHY:
			return setDepartmentsForHierarchy(apiData);

		case dictionaryTypeEnum.APPEAL_SUBJECT_GROUPS:
			return setAppealSubjectGroups(apiData);

		case dictionaryTypeEnum.CLAIM_EMERGENCY_READINESS:
			return setClaimEmergencyReadiness(apiData);

		case dictionaryTypeEnum.CLAIM_TYPES:
			return setClaimTypes(apiData);

		case dictionaryTypeEnum.CLAIM_REPAIR_TYPES:
			return setClaimRepairTypes(apiData);

		case dictionaryTypeEnum.CLAIM_APPROVAL_DEFAULTS:
			return setClaimApprovalDefaults(apiData);

		case dictionaryTypeEnum.CLAIM_STATUSES:
			return setClaimStatuses(apiData);

		case dictionaryTypeEnum.USER_ROLES:
			return setUserRoles(apiData);

		case dictionaryTypeEnum.RECIPIENT_GROUPS:
			return setRecipientGroups(apiData);

		case dictionaryTypeEnum.CLAIM_APPROVAL_LEVELS:
			return setClaimApprovalLevels(apiData);

		case dictionaryTypeEnum.SHUTDOWN_JOB_TYPE_REASONS:
			return setShutdownJobTypeReasons(apiData);

		case dictionaryTypeEnum.SCADAS:
			return setScadas(apiData);

		case dictionaryTypeEnum.SCADA_SUBSTANTIONS:
			return setScadaSubstantions(apiData);

		case dictionaryTypeEnum.SCADA_SUBSTANTION_VOLTAGES:
			return setScadaSubstantionVoltages(apiData);

		case dictionaryTypeEnum.SCADA_SYGNAL_STATUSES:
			return setScadaSygnalStatuses(apiData);

		case dictionaryTypeEnum.APPEAL_PAID_SERVICE_TYPES:
			return setAppealPaidServiceTypes(apiData);

		case dictionaryTypeEnum.BRANCHES:
			return setBranches(apiData);

		case dictionaryTypeEnum.METER_TYPES:
			return setMeterTypes(apiData);

		default:
			break;
	}
};

//------------------------------------
//Get API type
const getApiType = dictionaryType => {
	let type;
	switch (dictionaryType) {
		case dictionaryTypeEnum.SHUTDOWN_STATUSES:
			type = dictionaryApiEnum.GET_SHUTDOWN_STATUSES;
			break;

		case dictionaryTypeEnum.DEPARTMENTS:
			type = dictionaryApiEnum.GET_DEPARTMENTS;
			break;

		case dictionaryTypeEnum.SHUTDOWN_TYPES:
			type = dictionaryApiEnum.GET_SHUTDOWN_TYPES;
			break;

		case dictionaryTypeEnum.SHUTDOWN_DRIVERS:
			type = dictionaryApiEnum.GET_SHUTDOWN_DRIVERS;
			break;

		case dictionaryTypeEnum.NOTIFICATION_STATUSES:
			type = dictionaryApiEnum.GET_NOTIFICATION_STATUSES;
			break;

		case dictionaryTypeEnum.NOTIFICATION_TYPES:
			type = dictionaryApiEnum.GET_NOTIFICATION_TYPES;
			break;

		case dictionaryTypeEnum.SETTLEMENT_TYPES:
			type = dictionaryApiEnum.GET_SETTLEMENT_TYPES;
			break;

		case dictionaryTypeEnum.DEPARTMENT_ENTRY_POINTS:
			type = dictionaryApiEnum.GET_DEPARTMENT_ENTRY_POINTS;
			break;

		case dictionaryTypeEnum.RECIPIENT_TYPES:
			type = dictionaryApiEnum.GET_RECIPIENT_TYPES;
			break;

		case dictionaryTypeEnum.APPEAL_CLASSIFIERS:
			type = dictionaryApiEnum.GET_APPEAL_CLASSIFIERS;
			break;

		case dictionaryTypeEnum.COUNTERPARTY_TYPES:
			type = dictionaryApiEnum.GET_COUNTERPARTY_TYPES;
			break;

		case dictionaryTypeEnum.APPEAL_STATUSES:
			type = dictionaryApiEnum.GET_APPEAL_STATUSES;
			break;

		case dictionaryTypeEnum.TASK_STATUSES:
			type = dictionaryApiEnum.GET_TASK_STATUSES;
			break;

		case dictionaryTypeEnum.TASK_INITIATORS:
			type = dictionaryApiEnum.GET_TASK_INITIATORS;
			break;

		case dictionaryTypeEnum.APPEAL_SUBJECTS:
			type = dictionaryApiEnum.GET_APPEAL_SUBJECTS;
			break;

		case dictionaryTypeEnum.APPEAL_COMMUNICATION_TYPES:
			type = dictionaryApiEnum.GET_APPEAL_COMMUNICATION_TYPES;
			break;

		case dictionaryTypeEnum.APPEAL_OBTAINING_METHODS:
			type = dictionaryApiEnum.GET_APPEAL_OBTAINING_METHODS;
			break;

		case dictionaryTypeEnum.STATUSES_MATRIX_TYPES:
			type = dictionaryApiEnum.GET_STATUSES_MATRIX_TYPES;
			break;

		case dictionaryTypeEnum.HOLIDAY_TYPES:
			type = dictionaryApiEnum.GET_HOLIDAY_TYPES;
			break;

		case dictionaryTypeEnum.DEPARTMENTS_FOR_HIERARCHY:
			type = dictionaryApiEnum.GET_DEPARTMENTS_FOR_HIERARCHY;
			break;

		case dictionaryTypeEnum.APPEAL_SUBJECT_GROUPS:
			type = dictionaryApiEnum.GET_APPEAL_SUBJECT_GROUPS;
			break;

		case dictionaryTypeEnum.CLAIM_EMERGENCY_READINESS:
			type = dictionaryApiEnum.GET_CLAIM_EMERGENCY_READINESS;
			break;

		case dictionaryTypeEnum.CLAIM_TYPES:
			type = dictionaryApiEnum.GET_CLAIM_TYPES;
			break;

		case dictionaryTypeEnum.CLAIM_REPAIR_TYPES:
			type = dictionaryApiEnum.GET_CLAIM_REPAIR_TYPES;
			break;

		case dictionaryTypeEnum.CLAIM_APPROVAL_DEFAULTS:
			type = dictionaryApiEnum.GET_CLAIM_APPROVAL_DEFAULTS;
			break;

		case dictionaryTypeEnum.CLAIM_STATUSES:
			type = dictionaryApiEnum.GET_CLAIM_STATUSES;
			break;

		case dictionaryTypeEnum.USER_ROLES:
			type = dictionaryApiEnum.GET_USER_ROLES;
			break;

		case dictionaryTypeEnum.RECIPIENT_GROUPS:
			type = dictionaryApiEnum.GET_RECIPIENT_GROUPS;
			break;

		case dictionaryTypeEnum.CLAIM_APPROVAL_LEVELS:
			type = dictionaryApiEnum.GET_CLAIM_APPROVAL_LEVELS;
			break;

		case dictionaryTypeEnum.SHUTDOWN_JOB_TYPE_REASONS:
			type = dictionaryApiEnum.GET_SHUTDOWN_JOB_TYPE_REASONS;
			break;

		case dictionaryTypeEnum.SCADAS:
			type = dictionaryApiEnum.GET_SCADAS;
			break;

		case dictionaryTypeEnum.SCADA_SUBSTANTIONS:
			type = dictionaryApiEnum.GET_SCADA_SUBSTANTIONS;
			break;

		case dictionaryTypeEnum.SCADA_SUBSTANTION_VOLTAGES:
			type = dictionaryApiEnum.GET_SCADA_SUBSTANTION_VOLTAGES;
			break;

		case dictionaryTypeEnum.SCADA_SYGNAL_STATUSES:
			type = dictionaryApiEnum.GET_SCADA_SYGNAL_STATUSES;
			break;

		case dictionaryTypeEnum.APPEAL_PAID_SERVICE_TYPES:
			type = dictionaryApiEnum.GET_APPEAL_PAID_SERVICE_TYPES;
			break;

		case dictionaryTypeEnum.BRANCHES:
			type = dictionaryApiEnum.GET_BRANCHES;
			break;

		case dictionaryTypeEnum.METER_TYPES:
			type = dictionaryApiEnum.GET_METER_TYPES;
			break;

		default:
			break;
	}

	return type;
};
