import { apiClient } from "./api";
import axios from "axios";

//Admin endpoints
export const adminAPI = {
	//Users
	getUsers(usersGetParameters) {
		return apiClient(axios)
			.post("/User/GetAll", { ...usersGetParameters })
			.then(response => response);
	},
	getUser(id) {
		return apiClient(axios)
			.get(`/User/${id}`)
			.then(response => response);
	},
	addUser(addEditUserParams) {
		return apiClient(axios)
			.post("/User", { ...addEditUserParams })
			.then(response => response);
	},
	editUser(addEditUserParams) {
		return apiClient(axios)
			.put(`/User/${addEditUserParams.id}`, { ...addEditUserParams })
			.then(response => response);
	},
	deleteUser(id) {
		return apiClient(axios)
			.delete(`/User/${id}`)
			.then(response => response);
	},

	//SL ShutdownJobTypes
	getShutdownJobTypes(shutdownJobTypesGetParameters) {
		return apiClient(axios)
			.post("/ShutdownJobType/GetAll", { ...shutdownJobTypesGetParameters })
			.then(response => response);
	},
	addShutdownJobType(addEditShutdownJobTypesParams) {
		return apiClient(axios)
			.post("/ShutdownJobType", { ...addEditShutdownJobTypesParams })
			.then(response => response);
	},
	getShutdownJobType(id) {
		return apiClient(axios)
			.get(`/ShutdownJobType/${id}`)
			.then(response => response);
	},
	editShutdownJobType(addEditShutdownJobTypesParams) {
		return apiClient(axios)
			.put(`/ShutdownJobType/${addEditShutdownJobTypesParams.id}`, { ...addEditShutdownJobTypesParams })
			.then(response => response);
	},
	deleteShutdownJobType(id) {
		return apiClient(axios)
			.delete(`/ShutdownJobType/${id}`)
			.then(response => response);
	},
	//SL Recipients
	getRecipients(recipientsGetParameters) {
		return apiClient(axios)
			.post("/Recipient/GetAll", { ...recipientsGetParameters })
			.then(response => response);
	},
	addRecipient(addEditRecipientsParams) {
		return apiClient(axios)
			.post("/Recipient", { ...addEditRecipientsParams })
			.then(response => response);
	},
	getRecipient(id) {
		return apiClient(axios)
			.get(`/Recipient/${id}`)
			.then(response => response);
	},
	editRecipient(addEditRecipientsParams) {
		return apiClient(axios)
			.put(`/Recipient/${addEditRecipientsParams.id}`, { ...addEditRecipientsParams })
			.then(response => response);
	},
	deleteRecipient(id) {
		return apiClient(axios)
			.delete(`/Recipient/${id}`)
			.then(response => response);
	},
};
