//Table & pagination
export const paginationPosition = ["bottomRight"];
export const defaultPageSize = 20;
export const tableSize = "small";
export const tableScroll = { x: "100%", y: "calc(100vh - 400px)" };
export const tableScrollOneRowHeader = { x: "100%", y: "calc(100vh - 340px)" };
export const appealsTableScroll = { x: "100%", y: "calc(100vh - 330px)" };
export const shutdownsTableScroll = { x: "100%", y: "calc(100vh - 310px)" };
export const tasksTableScroll = { x: "100%", y: "calc(100vh - 330px)" };
export const claimApprovalsTableScroll = { x: "100%", y: "312px" };
export const tableButtonsSize = "small";
