import { Button, DatePicker, Spin, Table, Tooltip, Typography } from "antd";
import { defaultPageSize, paginationPosition, tableButtonsSize, tableSize } from "../../../assets/js/options";
import { useDispatch, useSelector } from "react-redux";

import AppstoreAddOutlined from "@ant-design/icons/es/icons/AppstoreAddOutlined";
import { ClearOutlined } from "@ant-design/icons";
import FilterFilled from "@ant-design/icons/es/icons/FilterFilled";
import { NavLink } from "react-router-dom";
import React from "react";
import css from "./Scada.module.scss";
import { dictionaryTypeEnum } from "../../../dictionaries/models/dictionaryTypeEnum";
import { getScadaEvents } from "../../../redux/actions/scadaEventsActions";
import moment from "moment";
import { scadaEventsGetParameters } from "../../../api/parameters";
import { scrollAntTableToTop } from "../../../assets/js/helpers/scrollAntTableToTop";
import { setPageBreadcrumb } from "../../../redux/actions/appActions";
import { showTotal } from "../../../assets/js/helpers/showTotal";
import { shutdownTypeEnum } from "../enums/shutdownEnums";
import { tableFilterStringBuilder } from "../../../assets/js/helpers/tableFilterStringBuilder";
import { tableSortBuilder } from "../../../assets/js/helpers/tableSortBuilder";
import { useCallback } from "react";
import useDictionary from "../../../dictionaries/useDictionaries";
import { useEffect } from "react";
import { useState } from "react";

const Scada = ({ text }) => {
	const dispatch = useDispatch();
	const { RangePicker } = DatePicker;
	const tableDateFormat = "YYYY-MM-DD HH:mm";

	const scadasEvents = useSelector(state => state.scada?.scadaEvents.data);
	const departments = useDictionary(dictionaryTypeEnum.DEPARTMENTS);
	const scadas = useDictionary(dictionaryTypeEnum.SCADAS);
	const scadaSubstantions = useDictionary(dictionaryTypeEnum.SCADA_SUBSTANTIONS);
	const scadaSubstantionVoltages = useDictionary(dictionaryTypeEnum.SCADA_SUBSTANTION_VOLTAGES);
	const scadaSygnalStatuses = useDictionary(dictionaryTypeEnum.SCADA_SYGNAL_STATUSES);
	const isLoading = useSelector(state => state.app.isLoading);
	const scadaEventsTotalItems = useSelector(state => state.scada.scadaEvents.count);

	const [sorteredInfo, setSorteredInfo] = useState(null);
	const [filteredInfo, setFilteredInfo] = useState(null);
	const [paginationInfo, setPaginationInfo] = useState(null);
	const [eventDateRagePickerFilter, setEventDateRagePickerFilter] = useState(null);
	const [scadaGetParamsLocal, setScadaGetParamsLocal] = useState({ ...scadaEventsGetParameters });

	const scadaTableColumns = [
		{
			title: "№ події",
			dataIndex: "id",
			key: "id",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "id" && sorteredInfo.order : null,
			width: 80,
			align: "center",
			filteredValue: null,
		},
		{
			title: "Дата події",
			dataIndex: "eventDate",
			key: "eventDate",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "eventDate" && sorteredInfo.order : null,
			width: 150,
			align: "center",
			...getEventDateColumnFilterProps("eventDate"),
			filteredValue: null,
		},
		{
			title: "Тип системи",
			dataIndex: "scadaName",
			key: "scadaName",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "scadaName" && sorteredInfo.order : null,
			align: "center",
			filters:
				scadas &&
				scadas.map(item => {
					return {
						text: item.name,
						value: item.id,
					};
				}),
			filteredValue: filteredInfo ? filteredInfo.scadaName : null,
		},
		{
			title: "Підрозділ",
			dataIndex: "departmentName",
			key: "departmentName",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "departmentName" && sorteredInfo.order : null,
			width: 250,
			align: "center",
			filters:
				departments &&
				departments.map(item => {
					return {
						text: item.name,
						value: item.id,
					};
				}),
			filteredValue: filteredInfo ? filteredInfo.departmentName : null,
		},
		{
			title: "Електрична адреса",
			dataIndex: "electricAddress",
			key: "electricAddress",
			align: "center",
			filteredValue: null,
			render: text => (
				<Typography.Paragraph
					style={{ textAlign: "left" }}
					ellipsis={{ rows: 3, expandable: true, symbol: <div style={{ fontSize: "11px" }}>більше</div> }}
				>
					{text}
				</Typography.Paragraph>
			),
		},
		{
			title: "Назва підстанції",
			dataIndex: "scadaSubstantionName",
			key: "scadaSubstantionName",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "scadaSubstantionName" && sorteredInfo.order : null,
			width: 150,
			align: "center",
			filters:
				scadaSubstantions &&
				scadaSubstantions.map(item => {
					return {
						text: item.name,
						value: item.id,
					};
				}),
			filteredValue: filteredInfo ? filteredInfo.scadaSubstantionName : null,
		},
		{
			title: "Напруга підстанції",
			dataIndex: "scadaSubstantionVoltageName",
			key: "scadaSubstantionVoltageName",
			sorter: true,
			sortOrder: sorteredInfo
				? sorteredInfo.columnKey === "scadaSubstantionVoltageName" && sorteredInfo.order
				: null,
			width: 80,
			align: "center",
			filters:
				scadaSubstantionVoltages &&
				scadaSubstantionVoltages.map(item => {
					return {
						text: item.name,
						value: item.id,
					};
				}),
			filteredValue: filteredInfo ? filteredInfo.scadaSubstantionVoltageName : null,
		},
		{
			title: "Обладнання підстанції",
			dataIndex: "scadaEquipmentName",
			key: "scadaEquipmentName",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "scadaEquipmentName" && sorteredInfo.order : null,
			width: 80,
			align: "center",
			filteredValue: null,
		},
		{
			title: "Статус обладнання",
			dataIndex: "scadaSygnalStatusName",
			key: "scadaSygnalStatusName",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "scadaSygnalStatusName" && sorteredInfo.order : null,
			width: 150,
			align: "center",
			filters:
				scadaSygnalStatuses &&
				scadaSygnalStatuses.map(item => {
					return {
						text: item.name,
						value: item.id,
					};
				}),
			filteredValue: filteredInfo ? filteredInfo.scadaSygnalStatusName : null,
		},
		{
			title: "Дії",
			dataIndex: "actions",
			key: "actions",
			width: 80,
			align: "center",
			filteredValue: null,
		},
	];

	function getEventDateColumnFilterProps() {
		return {
			filterDropdown: ({ confirm }) => (
				<RangePicker
					value={eventDateRagePickerFilter ? eventDateRagePickerFilter : ""}
					onChange={value => {
						setEventDateRagePickerFilter(value);
						confirm();
					}}
				/>
			),
			filterIcon: filtered => <FilterFilled style={{ color: eventDateRagePickerFilter ? "#1890ff" : undefined }} />,
		};
	}

	const scadaTableData = useCallback(() => {
		return scadasEvents?.map(x => {
			return {
				key: x.id,
				id: x.id,
				scadaEventId: x.scadaEventId,
				eventDate: moment(x.eventDate).format(tableDateFormat),
				scadaId: x.scadaId,
				scadaName: x.scadaName,
				departmentId: x.departmentId,
				departmentName: x.departmentName ? x.departmentName : "", //Check need when departmentName = null/undefined
				electricNodeId: x.electricNodeId,
				electricAddress: x.electricAddress,
				scadaSubstantionId: x.scadaSubstantionId,
				scadaSubstantionName: x.scadaSubstantionName,
				scadaEquipmentId: x.scadaEquipmentId,
				scadaEquipmentName: x.scadaEquipmentName,
				scadaSubstantionVoltageId: x.scadaSubstantionVoltageId,
				scadaSubstantionVoltageName: x.scadaSubstantionVoltageName,
				scadaSygnalStatusId: x.scadaSygnalStatusId,
				scadaSygnalStatusName: x.scadaSygnalStatusName,

				actions:
					x.scadaSygnalStatusId === "74d6425d-a421-4566-a1e8-9ef0fc21bcff" ? (
						<span className={css.table_action_btn}>
							<NavLink
								to={{
									pathname: `/shutdowns/create-shutdown/${shutdownTypeEnum.EMERGENCY}/${x.departmentId}/${x.electricNodeId}`,
								}}
							>
								<Tooltip placement='topLeft' title='Створення відключення' mouseEnterDelay={0.7}>
									<Button size={tableButtonsSize}>
										<AppstoreAddOutlined />
									</Button>
								</Tooltip>
							</NavLink>
						</span>
					) : (
						""
					),
			};
		});
	}, [scadasEvents]);

	const onScadaTableChange = (pagination, filters, sorter) => {
		setFilteredInfo(filters);
		setSorteredInfo(sorter);
		setPaginationInfo(pagination);
		scrollAntTableToTop();
	};

	const combineFilter = (filterString, searchString) => {
		if (filterString && searchString) return `(${searchString}),(${filterString})`;
		else if (filterString) return filterString;
		else if (searchString) return searchString;
		else return "";
	};

	const onClearFilters = () => {
		setSorteredInfo(null);
		setFilteredInfo(null);
		scrollAntTableToTop();
		setEventDateRagePickerFilter(null);
		setScadaGetParamsLocal({ ...scadaEventsGetParameters });
	};

	const onScadaSygnalStatusCheck = (record, index) => {
		//Red row when status "off by scada"
		let offByScadaClassName = "";
		if (record?.scadaSygnalStatusId === "74d6425d-a421-4566-a1e8-9ef0fc21bcff") {
			offByScadaClassName = `${css.attention_row}`;
		}

		//For dark and light table rows
		if (index % 2 === 0) {
			return `${css.table_row_light} ${offByScadaClassName}`;
		} else {
			return `${css.table_row_dark} ${offByScadaClassName}`;
		}
	};

	useEffect(() => {
		const breadCrumbData = [
			{ name: "Відключення", link: false },
			{ name: text, link: false },
		];
		dispatch(setPageBreadcrumb(breadCrumbData));
	}, [dispatch, text]);

	useEffect(() => {
		dispatch(getScadaEvents(scadaGetParamsLocal));
	}, [dispatch, scadaGetParamsLocal]);

	useEffect(() => {
		if (!eventDateRagePickerFilter && !filteredInfo && !sorteredInfo && !paginationInfo) return;

		//Filtering
		const tableFilterNamesArr = [
			"departmentName",
			"scadaSubstantionName",
			"scadaName",
			"scadaSubstantionVoltageName",
			"scadaSygnalStatusName",
		];
		const fieldNamesInDBArr = [
			"departmentId",
			"scadaSubstantionId",
			"scadaId",
			"scadaSubstantionVoltageId",
			"scadaSygnalStatusId",
		];

		let filter = tableFilterStringBuilder(filteredInfo, tableFilterNamesArr, fieldNamesInDBArr);

		//Sorting
		tableSortBuilder(scadaGetParamsLocal, paginationInfo, sorteredInfo);

		let searchStr = "";

		const searchObjArray = [
			{
				stringValue: eventDateRagePickerFilter
					? `eventDate >=${eventDateRagePickerFilter[0].format(
						"YYYY-MM-DD"
					)}, eventDate <=${eventDateRagePickerFilter[1].format("YYYY-MM-DD")}T23:59:59`
					: undefined,
			},
		];

		searchObjArray.forEach(item => {
			if (item.stringValue) {
				searchStr += item.stringValue + ",";
			}
		});

		searchStr = searchStr.slice(0, -1); //delete last comma in string

		scadaGetParamsLocal.filter = combineFilter(filter, searchStr);
		dispatch(getScadaEvents(scadaGetParamsLocal));
	}, [dispatch, filteredInfo, paginationInfo, scadaGetParamsLocal, sorteredInfo, eventDateRagePickerFilter]);

	return (
		<div className={css.scada_container}>
			<div className={css.clear_filters_btn_wrap}>
				<Button type='default' className={css.filter_button} onClick={onClearFilters}>
					<ClearOutlined />
					Скинути всі фільтри
				</Button>
			</div>

			{
				<Spin spinning={isLoading} tip={"Завантаження"}>
					<Table
						className={css.ant_table_wrapper}
						size={tableSize}
						pagination={{
							position: paginationPosition,
							defaultPageSize: defaultPageSize,
							showSizeChanger: true,
							total: scadaEventsTotalItems,
							showTotal: showTotal,
							current: scadaGetParamsLocal.page,
						}}
						columns={scadaTableColumns}
						bordered
						showSorterTooltip={false}
						dataSource={scadaTableData()}
						onChange={onScadaTableChange}
						rowClassName={(record, index) => onScadaSygnalStatusCheck(record, index)}
					/>
				</Spin>
			}
		</div>
	);
};

export default Scada;
