import { apiClient } from "./api";
import axios from "axios";

//Dictionary endpoints
export const dictionariAPI = {
	getUserRoles() {
		return apiClient(axios)
			.get("/Dictionary/GetUserRoles")
			.then(response => response);
	},
	getDepartmentEntryPoints() {
		return apiClient(axios)
			.get("/Dictionary/GetDepartmentEntryPoints")
			.then(response => response);
	},
	getDepartments() {
		return apiClient(axios)
			.get("/Dictionary/GetDepartment")
			.then(response => response);
	},
	//Dictionary With Params
	getElectricNodes(departmentId = null) {
		if (departmentId) {
			departmentId = "?departmentId=" + departmentId;
		} else {
			departmentId = "";
		}
		return apiClient(axios)
			.get(`/Dictionary/GetElectricNodes${departmentId}`)
			.then(response => response);
	},
	getSettlementTypes() {
		return apiClient(axios)
			.get("/Dictionary/GetSettlementTypes")
			.then(response => response);
	},
	getRecipientTypes() {
		return apiClient(axios)
			.get("/Dictionary/GetRecipientTypes")
			.then(response => response);
	},
	getShutdownTypes() {
		return apiClient(axios)
			.get("/Dictionary/GetShutdownTypes")
			.then(response => response);
	},
	getShutdownDrivers() {
		return apiClient(axios)
			.get("/Dictionary/GetShutdownDrivers")
			.then(response => response);
	},
	//Dictionary With Params
	getShutdownStatuses() {
		return apiClient(axios)
			.get(`/Dictionary/GetShutdownStatuses`)
			.then(response => response);
	},
	getNotificationStatuses() {
		return apiClient(axios)
			.get("/Dictionary/GetNotificationStatuses")
			.then(response => response);
	},
	getNotificationTypes() {
		return apiClient(axios)
			.get("/Dictionary/GetNotificationTypes")
			.then(response => response);
	},
	getAppealClassifiers() {
		return apiClient(axios)
			.get("/Dictionary/GetAppealClassifiers")
			.then(response => response);
	},
	getCounterpartyTypes() {
		return apiClient(axios)
			.get("/Dictionary/GetCounterpartyTypes")
			.then(response => response);
	},

	getAppealStatuses() {
		return apiClient(axios)
			.get("/Dictionary/GetAppealStatuses")
			.then(response => response);
	},

	getTaskStatuses() {
		return apiClient(axios)
			.get("/Dictionary/GetTaskStatuses")
			.then(response => response);
	},

	getTaskInitiators() {
		return apiClient(axios)
			.get("/Dictionary/GetTaskInitiators")
			.then(response => response);
	},

	getAppealSubjects() {
		return apiClient(axios)
			.get("/Dictionary/GetAppealSubjects")
			.then(response => response);
	},

	getAppealCommunicationTypes() {
		return apiClient(axios)
			.get("/Dictionary/GetAppealCommunicationTypes")
			.then(response => response);
	},

	getAppealObtainingMethods() {
		return apiClient(axios)
			.get("/Dictionary/GetAppealObtainingMethods")
			.then(response => response);
	},

	//Dictionary With Params
	getAppealSubjectCloseComments(appealSubjectId) {
		return apiClient(axios)
			.get(`/Dictionary/GetAppealSubjectCloseComments/${appealSubjectId}`)
			.then(response => response);
	},

	//Deprecated
	//Dictionary With Params
	getSettlements(departmentId) {
		return apiClient(axios)
			.get(`/Dictionary/GetSettlements/${departmentId}`)
			.then(response => response);
	},

	//Dictionary With Params
	getAppealSubjectTimes(appealSubjectId) {
		return apiClient(axios)
			.get(`/Dictionary/GetAppealSubjectTimes/${appealSubjectId}`)
			.then(response => response);
	},

	getAppealPaidServiceTimes(appealPaidServiceId) {
		return apiClient(axios)
			.post(`/Dictionary/GetAppealPaidServiceTimes`, { appealPaidServiceId })
			.then(response => response);
	},

	getStatusesMatrixTypes() {
		return apiClient(axios)
			.get("/Dictionary/GetStatusesMatrixTypes")
			.then(response => response);
	},

	getHolidayTypes() {
		return apiClient(axios)
			.get("/Dictionary/GetHolidayTypes")
			.then(response => response);
	},
	getDepartmentsForHierarchy() {
		return apiClient(axios)
			.get("/Dictionary/GetDepartmentsForHierarchy")
			.then(response => response);
	},
	getAppealSubjectGroups() {
		return apiClient(axios)
			.get("/Dictionary/GetAppealSubjectGroups")
			.then(response => response);
	},
	//Dictionary With Params
	getCounterpartiesByFilter(counterpartyName, counterpartyPersonalAccount, departmentId) {
		return apiClient(axios)
			.post("/Dictionary/GetCounterpartiesByFilter", { counterpartyName, counterpartyPersonalAccount, departmentId })
			.then(response => response);
	},
	//Dictionary With Params
	getSettlementsByName(query) {
		return apiClient(axios)
			.get(`/Dictionary/GetSettlementsByName/${query}`)
			.then(response => response);
	},

	getClaimEmergencyReadiness() {
		return apiClient(axios)
			.get(`/Dictionary/GetClaimEmergencyReadiness`)
			.then(response => response);
	},

	getClaimTypes() {
		return apiClient(axios)
			.get(`/Dictionary/GetClaimTypes`)
			.then(response => response);
	},

	getClaimRepairTypes() {
		return apiClient(axios)
			.get(`/Dictionary/GetClaimRepairTypes`)
			.then(response => response);
	},

	getClaimApprovalDefaults() {
		return apiClient(axios)
			.get(`/Dictionary/GetClaimApprovalDefaults`)
			.then(response => response);
	},

	getClaimStatuses() {
		return apiClient(axios)
			.get(`/Dictionary/GetClaimStatuses`)
			.then(response => response);
	},

	getRecipientGroups() {
		return apiClient(axios)
			.get(`/Dictionary/GetRecipientGroups`)
			.then(response => response);
	},

	getClaimApprovalLevels() {
		return apiClient(axios)
			.get(`/Dictionary/GetClaimApprovalLevels`)
			.then(response => response);
	},

	getShutdownJobTypeReasons() {
		return apiClient(axios)
			.get(`/Dictionary/GetShutdownJobTypeReasons`)
			.then(response => response);
	},

	getScadas() {
		return apiClient(axios)
			.get(`/Dictionary/GetScadas`)
			.then(response => response);
	},

	getScadaSubstantions() {
		return apiClient(axios)
			.get(`/Dictionary/GetScadaSubstantions`)
			.then(response => response);
	},

	getScadaSubstantionVoltages() {
		return apiClient(axios)
			.get(`/Dictionary/GetScadaSubstantionVoltages`)
			.then(response => response);
	},

	getScadaSygnalStatuses() {
		return apiClient(axios)
			.get(`/Dictionary/GetScadaSygnalStatuses`)
			.then(response => response);
	},

	getAppealPaidServiceTypes() {
		return apiClient(axios)
			.get(`/Dictionary/GetAppealPaidServices`)
			.then(response => response);
	},

	getBranches() {
		return apiClient(axios)
			.get(`/Dictionary/GetBranches`)
			.then(response => response);
	},

	getMeterTypes() {
		return apiClient(axios)
			.get(`/Dictionary/GetMeterTypes`)
			.then(response => response);
	},
};
