import {SET_HOLIDAYS} from "../types";

const initialState = {
    holidays: null,
};

export const holidaysReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_HOLIDAYS:
            return {...state, holidays: action.payload};
        default:
            return state
    }
};