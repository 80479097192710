import {
	SET_DEPARTMENT_HIERARCHY_USERS_SUBTREE,
	SET_DRAWER_TASKS_DATA,
	SET_TASKS_DATA,
	SET_TASK_APPEAL,
	SET_TASK_BY_ID,
	SET_TASK_EXECUTION_LOG,
} from "../types";
import { setApiLastError, setSuccess } from "./appActions";

import { appealAPI } from "../../api/appealEndpoints";
import { appealGetParameters } from "../../api/parameters";
import { departmentHierarchyAPI } from "../../api/departmentHierarchyEndpoints";
import { taskAPI } from "../../api/taskEndpoints";
import { thunkErrorHandler } from "./actionHandlers";

export const setDepartmentHierarchyUsersSubtree = departmentHierarchyUsersSubtree => {
	return {
		type: SET_DEPARTMENT_HIERARCHY_USERS_SUBTREE,
		payload: departmentHierarchyUsersSubtree,
	};
};

export const getDepartmentHierarchyUsersSubtree = (departmentId, taskId) => async dispatch => {
	try {
		const response = await departmentHierarchyAPI.getDepartmentHierarchyUsersSubtree(departmentId, taskId);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setDepartmentHierarchyUsersSubtree(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getTasks = tasksGetParameters => async dispatch => {
	try {
		const response = await taskAPI.getTasks(tasksGetParameters);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setTasksData(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
export const setTasksData = tasks => {
	return {
		type: SET_TASKS_DATA,
		payload: tasks,
	};
};

export const getDrawerTasks = tasksGetParameters => async dispatch => {
	try {
		const response = await taskAPI.getTasks(tasksGetParameters);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setDrawerTasksData(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
export const setDrawerTasksData = drawerTasks => {
	return {
		type: SET_DRAWER_TASKS_DATA,
		payload: drawerTasks,
	};
};

export const getTaskById = taskId => async dispatch => {
	try {
		const response = await taskAPI.getTaskById(taskId);
		if (response.status >= 200 && response.status < 300) {
			const result = response.data.data?.shift(0);
			dispatch(setTaskById(result));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
export const setTaskById = task => {
	return {
		type: SET_TASK_BY_ID,
		payload: task,
	};
};

export const addTask = tasksPostParameters => async dispatch => {
	try {
		const response = await taskAPI.addTask(tasksPostParameters);
		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));
		if (response.status === 201) {
			dispatch(setSuccess("Завдання успішно створено!"));
			dispatch(setSuccess(null));
			dispatch(setApiLastError({ isErrorExists: false, errorObject: null }));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const editTask = (taskId, tasksPostParameters) => async dispatch => {
	try {
		const response = await taskAPI.editTask(taskId, tasksPostParameters);
		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));
		if (response.status === 202) {
			dispatch(setSuccess("Завдання успішно відредаговано!"));
			dispatch(setSuccess(null));
			dispatch(setApiLastError({ isErrorExists: false, errorObject: null }));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const deleteTask = (taskId, tableFilter) => async dispatch => {
	try {
		const response = await taskAPI.deleteTask(taskId);
		if (response.status === 204) {
			appealGetParameters.filter = tableFilter;
			dispatch(getTasks(appealGetParameters));
			dispatch(setSuccess("Завдання успішно видалено!"));
			dispatch(setSuccess(null));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getTaskExecutionLog = taskId => async dispatch => {
	try {
		const response = await taskAPI.getTaskExecutionLog(taskId);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setTasksExecutionLog(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
export const setTasksExecutionLog = taskExecutionLog => {
	return {
		type: SET_TASK_EXECUTION_LOG,
		payload: taskExecutionLog,
	};
};

export const getTaskAppeal = appealId => async dispatch => {
	try {
		const response = await appealAPI.getAppealById(appealId);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setTaskAppeal(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const setTaskAppeal = taskAppeal => {
	return {
		type: SET_TASK_APPEAL,
		payload: taskAppeal,
	};
};
