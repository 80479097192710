import { SET_SMART_DATA } from "../types";

const initialState = {
	smartData: {},
};

export const smartReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SMART_DATA:
			return { ...state, smartData: action.payload };

		default:
			return state;
	}
};
