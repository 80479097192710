import { apiClient } from "./api";
import axios from "axios";

//Shutdown endpoints
export const shutdownAPI = {
	getShutdowns(shutdownsGetParameters) {
		return apiClient(axios)
			.post(`/Shutdown/GetAll`, { ...shutdownsGetParameters })
			.then(response => response);
	},
	getShutdownById(shutdownId) {
		return apiClient(axios)
			.post(`/Shutdown/GetAll`, { filter: `id=${shutdownId}` })
			.then(response => response);
	},
	addShutdown(shutdownsPostParameters) {
		return apiClient(axios)
			.post("/Shutdown", { ...shutdownsPostParameters })
			.then(response => response);
	},
	getShutdown(id) {
		return apiClient(axios)
			.get(`/Shutdown/${id}`)
			.then(response => response);
	},
	editShutdown(shutdownsPostParameters) {
		return apiClient(axios)
			.put(`/Shutdown/${shutdownsPostParameters.id}`, { ...shutdownsPostParameters })
			.then(response => response);
	},
	deleteShutdown(id) {
		return apiClient(axios)
			.delete(`/Shutdown/${id}`)
			.then(response => response);
	},
	getResidentialCounterpartiesCount(id) {
		return apiClient(axios)
			.get(`/Shutdown/GetResidentialCounterpartiesCount/${id}`)
			.then(response => response);
	},
	getCommercialCounterpartiesCount(id) {
		return apiClient(axios)
			.get(`/Shutdown/GetCommercialCounterpartiesCount/${id}`)
			.then(response => response);
	},
	getCommercialCounterpartiesNames(id) {
		return apiClient(axios)
			.get(`/Shutdown/GetCommercialCounterpartiesNames/${id}`)
			.then(response => response);
	},
	getElectricAddressByCounterpartyPersonalAccount(counterpartyPersonalAccount) {
		return apiClient(axios)
			.get(`/Shutdown/GetElectricAddressByCounterpartyPersonalAccount/${counterpartyPersonalAccount}`)
			.then(response => response);
	},
	updateBulk(shutdowns) {
		return apiClient(axios)
			.put(`/Shutdown/UpdateBulk`, shutdowns)
			.then(response => response);
	},
	getMapDataByShutdownLines(departmentId) {
		return apiClient(axios)
			.get(`/Shutdown/GetMapDataByShutdownLines/${departmentId ? departmentId : ""}`)
			.then(response => response);
	},
	getMapDataByShutdownPs(departmentId) {
		return apiClient(axios)
			.get(`/Shutdown/GetMapDataByShutdownPs/${departmentId ? departmentId : ""}`)
			.then(response => response);
	},
	getMapDataByShutdownForecast(departmentId) {
		return apiClient(axios)
			.get(`/Shutdown/GetMapDataByShutdownForecast/${departmentId ? departmentId : ""}`)
			.then(response => response);
	},
	getCounterpartiesBySmartMerer(electricNodeId) {
		return apiClient(axios)
			.get(`/Shutdown/GetCounterpartiesBySmartMerer/${electricNodeId}`)
			.then(response => response);
	},
};
