import { Col, Form, Input, Row, Select } from "antd";
import { DollarOutlined, PartitionOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import DividerGrid from "../../../common/DividerGrid/DividerGrid";
import Flatpickr from "react-flatpickr";
import PriceInput from "../../../../assets/shared/components/PriceInput";
import TreeSelect from "antd/es/tree-select";
import { Ukrainian } from "flatpickr/dist/l10n/uk";
import css from "./AppealPaidServiceText.module.scss";
import { dictionariAPI } from "../../../../api/dictionaryEndpoints";
import { dictionaryTypeEnum } from "../../../../dictionaries/models/dictionaryTypeEnum";
import flatpickr from "flatpickr";
import useDictionary from "../../../../dictionaries/useDictionaries";

const AppealPaidServiceText = () => {
	const { Option } = Select;
	const { TextArea } = Input;
	const { SHOW_PARENT } = TreeSelect;
	flatpickr.localize(Ukrainian);

	const treeData = useDictionary(dictionaryTypeEnum.APPEAL_PAID_SERVICE_TYPES);

	const [appealPaidServiceTimes, setAppealPaidServiceTimes] = useState([]);
	const [appealPaidServicesTypesTree, setAppealPaidServicesTypesTree] = useState(undefined);

	const form = Form.useFormInstance();
	const appealPaidServiceIdWatch = Form.useWatch("appealPaidServiceId", form);

	//Paid service's types tree converting
	useEffect(() => {
		if (!treeData) {
			setAppealPaidServicesTypesTree([]);
			return;
		}

		const data = [...treeData];

		data.forEach(el => {
			el.value = el.id;
			el.icon = el.selectable ? (
				<DollarOutlined style={{ color: "orange" }} />
			) : (
				<PartitionOutlined style={{ color: "red" }} />
			);
		});

		setAppealPaidServicesTypesTree(data);
	}, [treeData]);

	const onPaidServiceChange = value => {
		form.setFieldValue("appealPaidServiceTimeId", undefined);
		form.setFieldValue("appealPaidServicePrice", appealPaidServicesTypesTree.find(x => x.id === value)?.price);
	};

	useEffect(() => {
		if (!appealPaidServiceIdWatch) {
			setAppealPaidServiceTimes([]);
		} else {
			dictionariAPI.getAppealPaidServiceTimes(appealPaidServiceIdWatch).then(response => {
				if (response.data.length === 1) form.setFieldValue("appealPaidServiceTimeId", response.data[0].id);
				setAppealPaidServiceTimes(response.data);
			});
		}
	}, [appealPaidServiceIdWatch, form]);

	return (
		<div className={css.appeal_text_wrapper}>
			<DividerGrid title={"Послуга"} />

			<Row gutter={20}>
				<Col lg={24} md={24}>
					<Form.Item
						label='Назва'
						name='appealPaidServiceId'
						rules={[{ required: true, message: "Будь ласка виберіть послугу!" }]}
					>
						<TreeSelect
							required={true}
							treeData={appealPaidServicesTypesTree}
							placeholder='Оберіть послугу'
							treeDataSimpleMode={{
								id: "id",
								pId: "parentId",
								rootPId: "/",
								key: "id",
							}}
							treeNodeFilterProp='title'
							treeNodeLabelProp='title'
							showCheckedStrategy={SHOW_PARENT}
							showSearch={true}
							listHeight={500}
							popupMatchSelectWidth={600}
							treeDefaultExpandAll={false}
							treeIcon={true}
							allowClear
							onChange={value => onPaidServiceChange(value)}
						/>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={20}>
				<Col lg={24} md={24}>
					<Form.Item
						name='description'
						rules={[
							{ required: true, message: "Будь ласка введіть повідомлення!" },
							{ min: 20, message: "Повідомлення має бути більше ніж 20 символів!" },
						]}
					>
						<TextArea
							placeholder='короткий зміст..'
							autoSize={{ minRows: 6, maxRows: 8 }}
							showCount
							maxLength={2000}
						/>
					</Form.Item>
				</Col>
			</Row>

			<Row>
				<Col lg={8} md={24}>
					<Form.Item
						name='appealPaidServiceTimeId'
						label='Термін розгляду:'
						rules={[{ required: appealPaidServiceTimes.length, message: "Будь ласка  вкажіть термін!" }]}
					>
						<Select disabled={!appealPaidServiceTimes.length} popupMatchSelectWidth={false}>
							{appealPaidServiceTimes?.map(pst => (
								<Option key={pst.id} value={pst.id}>
									{pst.name}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>

				<Col lg={8} md={24}>
					<PriceInput formFieldName={"appealPaidServicePrice"} isRequired={true} />
				</Col>

				<Col lg={8} md={24}>
					<Form.Item
						labelCol={{ span: 10 }}
						label='Дата оплати:'
						name='datePaidServiceWasPaid'
						rules={[{ required: false, message: "Будь ласка, вкажіть дату!" }]}
					>
						<Flatpickr
							className={css.flat_picker_input}
							options={{
								dateFormat: "Y-m-d H:i",
								enableTime: true,
								time_24hr: true,
							}}
						/>
					</Form.Item>
				</Col>
			</Row>
		</div>
	);
};

export default AppealPaidServiceText;
