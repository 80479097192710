import axios from "axios";
import { apiClient } from "./api";

//Ovb endpoints
export const ovbAPI = {
	getMapDataByOvb(departmentId) {
		return apiClient(axios)
			.get(`/Ovb/GetMapData/${departmentId ? departmentId : ""}`)
			.then(response => response);
	},
};
