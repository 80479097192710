import {
	SET_CHOSEN_RECIPIENT,
	SET_CHOSEN_ROLES,
	SET_CHOSEN_SHUTDOWN_JOB_TYPE,
	SET_CHOSEN_USER,
	SET_DEPARTMENT_HIERARCHY_FREE_USERS,
	SET_DEPARTMENT_HIERARCHY_USERS_TREE,
	SET_RECIPIENTS_DATA,
	SET_SHUTDOWN_JOB_TYPES_DATA,
	SET_USERS_DATA,
} from "../types";
import { hideLoader, setApiLastError, setSuccess, showLoader } from "./appActions";
import { recipientsGetParameters, shutdownJobTypesGetParameters, usersGetParameters } from "../../api/parameters";

import { adminAPI } from "../../api/adminEndpoints";
import { departmentHierarchyAPI } from "../../api/departmentHierarchyEndpoints";
import { thunkErrorHandler } from "./actionHandlers";

//For ADMIN component
//Users
export const setUsersData = users => {
	return {
		type: SET_USERS_DATA,
		payload: users,
	};
};

export const setChosenUser = user => {
	return {
		type: SET_CHOSEN_USER,
		payload: user,
	};
};

export const setChosenRoles = roles => {
	return {
		type: SET_CHOSEN_ROLES,
		payload: roles,
	};
};

export const getUsers = usersGetParameters => async dispatch => {
	try {
		const response = await adminAPI.getUsers(usersGetParameters);
		dispatch(showLoader());
		if (response.status >= 200 && response.status < 300) {
			dispatch(setUsersData(response.data));
		}
		dispatch(hideLoader());
	} catch (error) {
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getUser = id => async dispatch => {
	try {
		const response = await adminAPI.getUser(id);
		dispatch(showLoader());
		if (response.status >= 200 && response.status < 300) {
			dispatch(setChosenUser(response.data));
		}
		dispatch(hideLoader());
	} catch (error) {
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const addUser = addEditUserParams => async dispatch => {
	try {
		const response = await adminAPI.addUser(addEditUserParams);
		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));
		if (response.status === 201) {
			dispatch(getUsers(usersGetParameters));
			dispatch(setSuccess("Користувача успішно додано!"));
			dispatch(setSuccess(null));
			dispatch(setApiLastError({ isErrorExists: false, errorObject: null }));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const editUser = addEditUserParams => async dispatch => {
	try {
		const response = await adminAPI.editUser(addEditUserParams);
		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));
		if (response.status === 202) {
			dispatch(getUsers(usersGetParameters));
			dispatch(setSuccess("Користувача успішно відредаговано!"));
			dispatch(setSuccess(null));
			dispatch(setApiLastError({ isErrorExists: false, errorObject: null }));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const deleteUser = (id, tableFilter) => async dispatch => {
	try {
		const response = await adminAPI.deleteUser(id);
		if (response.status === 204) {
			usersGetParameters.filter = tableFilter;
			dispatch(getUsers(usersGetParameters));
			dispatch(setSuccess("Користувача успішно видалено!"));
			dispatch(setSuccess(""));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

//ShutdownJobTypes
export const setShutdownJobTypesData = shutdownJobTypes => {
	return {
		type: SET_SHUTDOWN_JOB_TYPES_DATA,
		payload: shutdownJobTypes,
	};
};

export const setChosenShutdownJobType = shutdownJobType => {
	return {
		type: SET_CHOSEN_SHUTDOWN_JOB_TYPE,
		payload: shutdownJobType,
	};
};

export const getShutdownJobTypes = shutdownJobTypesGetParameters => async dispatch => {
	try {
		const response = await adminAPI.getShutdownJobTypes(shutdownJobTypesGetParameters);
		dispatch(showLoader());
		if (response.status >= 200 && response.status < 300) {
			dispatch(setShutdownJobTypesData(response.data));
		}
		dispatch(hideLoader());
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const addShutdownJobType = addEditShutdownJobTypesParams => async dispatch => {
	try {
		const response = await adminAPI.addShutdownJobType(addEditShutdownJobTypesParams);
		dispatch(showLoader());
		if (response.status === 201) {
			dispatch(getShutdownJobTypes(shutdownJobTypesGetParameters));
		}
		dispatch(setSuccess("Вид роботи успішно додано!"));
		dispatch(hideLoader());
		dispatch(setSuccess(null));
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getShutdownJobType = id => async dispatch => {
	try {
		const response = await adminAPI.getShutdownJobType(id);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setChosenShutdownJobType(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const editShutdownJobType = addEditShutdownJobTypesParams => async dispatch => {
	try {
		const response = await adminAPI.editShutdownJobType(addEditShutdownJobTypesParams);
		dispatch(showLoader());
		if (response.status === 202) {
			dispatch(setChosenShutdownJobType({}));
			dispatch(getShutdownJobTypes(shutdownJobTypesGetParameters));
		}
		dispatch(setSuccess("Вид роботи успішно відредаговано!"));
		dispatch(hideLoader());
		dispatch(setSuccess(null));
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const deleteShutdownJobType = id => async dispatch => {
	try {
		const response = await adminAPI.deleteShutdownJobType(id);
		dispatch(showLoader());
		if (response.status === 204) {
			dispatch(getShutdownJobTypes(shutdownJobTypesGetParameters));
		}
		dispatch(setSuccess("Вид роботи успішно видалено!"));
		dispatch(hideLoader());
		dispatch(setSuccess(null));
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

//For Recipients
export const setRecipientsData = recipients => {
	return {
		type: SET_RECIPIENTS_DATA,
		payload: recipients,
	};
};

export const setChosenRecipient = recipient => {
	return {
		type: SET_CHOSEN_RECIPIENT,
		payload: recipient,
	};
};

export const getRecipients = recipientsGetParameters => async dispatch => {
	try {
		const response = await adminAPI.getRecipients(recipientsGetParameters);
		dispatch(showLoader());
		if (response.status >= 200 && response.status < 300) {
			dispatch(setRecipientsData(response.data));
		}
		dispatch(hideLoader());
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getRecipient = id => async dispatch => {
	try {
		const response = await adminAPI.getRecipient(id);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setChosenRecipient(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const addRecipient = addEditRecipientsParams => async dispatch => {
	try {
		const response = await adminAPI.addRecipient(addEditRecipientsParams);
		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));
		if (response.status === 201) {
			dispatch(getRecipients(recipientsGetParameters));
			dispatch(setSuccess("Отримувача успішно додано!"));
			dispatch(setSuccess(null));
			dispatch(setApiLastError({ isErrorExists: false, errorObject: null }));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const editRecipient = addEditRecipientsParams => async dispatch => {
	try {
		const response = await adminAPI.editRecipient(addEditRecipientsParams);
		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));
		if (response.status === 202) {
			dispatch(getRecipients(recipientsGetParameters));
			dispatch(setSuccess("Отримувача успішно відредаговано!"));
			dispatch(setSuccess(null));
			dispatch(setApiLastError({ isErrorExists: false, errorObject: null }));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const deleteRecipient = (id, tableFilter) => async dispatch => {
	try {
		const response = await adminAPI.deleteRecipient(id);
		if (response.status === 204) {
			recipientsGetParameters.filter = tableFilter;
			dispatch(getRecipients(recipientsGetParameters));
			dispatch(setSuccess("Отримувача успішно видалено!"));
			dispatch(setSuccess(null));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const setDepartmentHierarchyUsersTree = departmentHierarchyUsersTree => {
	return {
		type: SET_DEPARTMENT_HIERARCHY_USERS_TREE,
		payload: departmentHierarchyUsersTree,
	};
};

export const getDepartmentHierarchyUsersTree = departmentId => async dispatch => {
	try {
		const response = await departmentHierarchyAPI.getDepartmentHierarchyUsersTree(departmentId);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setDepartmentHierarchyUsersTree(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const setDepartmentHierarchyFreeUsers = departmentHierarchyFreeUsers => {
	return {
		type: SET_DEPARTMENT_HIERARCHY_FREE_USERS,
		payload: departmentHierarchyFreeUsers,
	};
};

export const getDepartmentHierarchyFreeUsers = departmentId => async dispatch => {
	try {
		const response = await departmentHierarchyAPI.getDepartmentHierarchyFreeUsers(departmentId);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setDepartmentHierarchyFreeUsers(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const addDepartmentHierarchyUser = (departmentHierarchyId, userId) => async dispatch => {
	try {
		const response = await departmentHierarchyAPI.addDepartmentHierarchyUser(departmentHierarchyId, userId);
		if (response.status === 201) {
			dispatch(setSuccess("Користувача успішно прив'язано!"));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const deleteDepartmentHierarchyUser = departmentHierarchyUserId => async dispatch => {
	try {
		const response = await departmentHierarchyAPI.deleteDepartmentHierarchyUser(departmentHierarchyUserId);
		if (response.status === 204) {
			dispatch(setSuccess("Користувача успішно відв'язано!"));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
