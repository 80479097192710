import { Col, Row, Spin } from "antd";

import React from "react";
import css from "./StatisticIndicatorType0.module.scss";
import { isDataExist } from "../../../assets/js/helpers/isDataExist";

const StatisticIndicatorType0 = ({
	value,
	valueColor,
	valueBgColor,
	title,
	titleColor,
	status,
	statusColor,
	width,
	background,
	border,
	marginRight,
	description,
}) => {
	return (
		<Row
			className={css.statistic_indicator_type0_wrapper}
			style={{ border: border, backgroundColor: background, minWidth: width, marginRight: marginRight }}
		>
			<Col className={css.value} style={{ backgroundColor: valueBgColor, color: valueColor }}>
				{isDataExist(value) || <Spin />}
			</Col>
			<Col>
				<Row className={css.title} style={{ color: titleColor }}>
					{title}
				</Row>
				<Row className={css.status} style={{ color: statusColor }}>
					{status}
				</Row>
				<Row className={css.description} style={{ color: statusColor }}>
					{description}
				</Row>
			</Col>
		</Row>
	);
};

export default StatisticIndicatorType0;
