import {
	AppstoreAddOutlined,
	DeleteOutlined,
	DollarOutlined,
	EditOutlined,
	EyeOutlined,
	PartitionOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Input, Row, Table, Tag, Tooltip, TreeSelect, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { defaultPageSize, paginationPosition, tableButtonsSize, tableSize } from "../../assets/js/options";
import { useDispatch, useSelector } from "react-redux";

import AppealPaidServiceDeleteModal from "./AppealPaidServiceDeleteModal/AppealPaidServiceDeleteModal";
import ClearOutlined from "@ant-design/icons/es/icons/ClearOutlined";
import FilterFilled from "@ant-design/icons/es/icons/FilterFilled";
import { NavLink } from "react-router-dom";
import ViewAppealPaidServiceDrawer from "./ViewAppealPaidServiceDrawer/ViewAppealPaidServiceDrawer";
import { appealGetParameters } from "../../api/parameters";
import { appealStatusEnum } from "./enums/appealEnums";
import css from "./AppealPaidService.module.scss";
import { dictionaryTypeEnum } from "../../dictionaries/models/dictionaryTypeEnum";
import { getAppealPaidServices } from "../../redux/actions/appealActions";
import { scrollAntTableToTop } from "../../assets/js/helpers/scrollAntTableToTop";
import { setPageBreadcrumb } from "../../redux/actions/appActions";
import { showTotal } from "../../assets/js/helpers/showTotal";
import { tableFilterStringBuilder } from "../../assets/js/helpers/tableFilterStringBuilder";
import { tableSortBuilder } from "../../assets/js/helpers/tableSortBuilder";
import { taskInitiatorsEnum } from "../Tasks/enums/taskEnums";
import { useCallback } from "react";
import useDebounce from "../../assets/js/hooks/useDebounce";
import useDebouncedEffect from "../../assets/js/hooks/useDebouncedEffect";
import useDictionary from "../../dictionaries/useDictionaries";
import useIsMount from "../../assets/js/hooks/useIsMount";
import { useSearchParams } from "react-router-dom/dist";

const AppealPaidService = () => {
	const [queryParams] = useSearchParams();
	const dateFormat = "YYYY-MM-DD HH:mm";
	const dispatch = useDispatch();
	const isMount = useIsMount();
	const { SHOW_PARENT } = TreeSelect;

	const departmentsState = useDictionary(dictionaryTypeEnum.DEPARTMENTS);
	const userDepartmentId = JSON.parse(useSelector(state => state.auth.departmentId));
	const currentUserRole = useSelector(state => state.auth.userRole);
	const appealPaidServices = useSelector(state => state.appeal.appealPaidServices.data);
	const appealTotalItems = useSelector(state => state.appeal.appealPaidServices.count);
	const counterpartyTypes = useDictionary(dictionaryTypeEnum.COUNTERPARTY_TYPES);

	//Is Admin or Management Department
	const currentUser = useSelector(state => state.auth);
	const isAdminOr25 = currentUser.userRole.includes("Administrator") || Number(currentUser.departmentId) === 25;

	//Enable-Disable controls
	const [createAppealButton, setDisableCreateAppealButton] = useState(false);
	const [editButton, setDisableEditButton] = useState(false);
	const [departments, setDepartments] = useState([]);

	//Local states
	//Modals
	const [viewModalVisible, setViewModalVisible] = useState(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const [selectedAppealId, setSelectedAppealId] = useState(null);

	//For table
	//For table filters selectors
	const appealStatuses = useDictionary(dictionaryTypeEnum.APPEAL_STATUSES);
	const branches = useDictionary(dictionaryTypeEnum.BRANCHES);
	const treeData = useDictionary(dictionaryTypeEnum.APPEAL_PAID_SERVICE_TYPES);

	//For sorters & filters
	const [sorteredInfo, setSorteredInfo] = useState(null);
	const [filteredInfo, setFilteredInfo] = useState(null);
	const [filterString, setFilterString] = useState(
		`appealStatusId=${appealStatusEnum.NEW}|appealStatusId=${appealStatusEnum.IN_PROCESS}`
	);
	const [searchString, setSearchString] = useState("");
	const [appealGetParametersLocal, setAppealGetParametersLocal] = useState({
		...appealGetParameters,
		filter: filterString,
	});

	const [counterpartyPersonalAccountSearch, setCounterpartyPersonalAccountSearch] = useState("");
	const [counterpartyNameSearch, setCounterpartyNameSearch] = useState("");
	const [phoneSearch, setPhoneSearch] = useState("");
	const [appealPaidServicesTypesTree, setAppealPaidServicesTypesTree] = useState(undefined);
	const [appealPaidServiceCheckedItems, setAppealPaidServiceCheckedItems] = useState("");
	const [appealPaidServiceSearch, setAppealPaidServiceSearch] = useState("");

	const debouncedCounterpartyPersonalAccountSearch = useDebounce(counterpartyPersonalAccountSearch, 400);
	const debouncedCounterpartyNameSearch = useDebounce(counterpartyNameSearch, 400);
	const debouncedPhoneSearch = useDebounce(phoneSearch, 400);

	const [innerRegistrationDate, setInnerRegistrationDate] = useState("");

	//For table filter rangepicker
	const { RangePicker } = DatePicker;

	const getInnerRegistrationDateFilterProps = () => {
		return {
			filterDropdown: ({ confirm }) => (
				<RangePicker
					value={innerRegistrationDate ? innerRegistrationDate : ""}
					onChange={value => {
						setInnerRegistrationDate(value);
						confirm();
					}}
				/>
			),
			filterIcon: () => <FilterFilled style={{ color: innerRegistrationDate ? "#1890ff" : undefined }} />,
		};
	};

	//For table filter tree
	const getAppealPaidServiceFilterProps = () => {
		return {
			filterDropdown: ({ confirm }) => (
				<TreeSelect
					style={{ minWidth: "250px" }}
					required={true}
					treeData={appealPaidServicesTypesTree}
					placeholder='Оберіть послугу'
					treeDataSimpleMode={{
						id: "id",
						pId: "parentId",
						rootPId: "/",
						key: "id",
					}}
					treeNodeFilterProp='title'
					treeNodeLabelProp='title'
					showCheckedStrategy={SHOW_PARENT}
					showSearch={true}
					listHeight={500}
					popupMatchSelectWidth={700}
					treeDefaultExpandAll={false}
					onChange={value => {
						if (!value || !value.length) {
							setAppealPaidServiceSearch("");
							setAppealPaidServiceCheckedItems(undefined);
						}
						setAppealPaidServiceCheckedItems(value);
					}}
					onDropdownVisibleChange={open => {
						if (!open) setAppealPaidServiceSearch(appealPaidServiceCheckedItems);
					}}
					multiple={true}
					allowClear
					treeCheckable
					treeIcon
					maxTagCount={1}
					value={appealPaidServiceCheckedItems}
				/>
			),
			filterIcon: () => <FilterFilled style={{ color: appealPaidServiceSearch ? "#1890ff" : undefined }} />,
		};
	};

	const columns = [
		{
			title: "Внутр. № реєстр-ра",
			dataIndex: "innerRegistrationNumber",
			key: "innerRegistrationNumber",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "innerRegistrationNumber" && sorteredInfo.order : null,
			filteredValue: null,
			width: 110,
		},
		{
			title: "Статус",
			dataIndex: "appealStatusName",
			key: "appealStatusName",
			align: "center",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "appealStatusName" && sorteredInfo.order : null,
			filters: appealStatuses?.map(item => {
				return {
					text: item.name,
					value: item.id,
				};
			}),
			filteredValue:
				queryParams.get("cameFrom") === "bell"
					? filteredInfo
						? filteredInfo.appealStatusName || ["1"]
						: ["1"]
					: filteredInfo
					? filteredInfo.appealStatusName || ["1", "2"]
					: ["1", "2"],
			width: 100,
		},
		{
			title: (
				<Input
					value={counterpartyPersonalAccountSearch}
					placeholder='О/Р'
					style={{ fontSize: "12px" }}
					onChange={e => {
						onTableColumnSearch(e.target.value, "counterpartyPersonalAccount");
					}}
				/>
			),
			dataIndex: "counterpartyPersonalAccount",
			key: "counterpartyPersonalAccount",
			filters: counterpartyTypes
				?.filter(x => x.id)
				?.map(x => {
					return {
						text: x.name,
						value: x.id,
					};
				}),
			filteredValue: filteredInfo ? filteredInfo.counterpartyPersonalAccount || null : null,
			width: 140,
		},
		{
			title: "Дата реєстр",
			dataIndex: "innerRegistrationDate",
			key: "innerRegistrationDate",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "innerRegistrationDate" && sorteredInfo.order : null,
			filteredValue: null,
			width: 100,
			...getInnerRegistrationDateFilterProps("innerRegistrationDate"),
		},
		{
			title: "Підрозділ",
			dataIndex: "departmentName",
			key: "departmentName",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "departmentName" && sorteredInfo.order : null,
			width: 130,
			ellipsis: true,
			filters: departments?.map(item => {
				return {
					text: item.name,
					value: item.id,
				};
			}),
			filteredValue: filteredInfo ? filteredInfo.departmentName || null : null,
		},
		{
			title: "Відділення",
			dataIndex: "branchName",
			key: "branchName",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "branchName" && sorteredInfo.order : null,
			width: 130,
			ellipsis: true,
			filters: branches
				?.filter(b => (isAdminOr25 ? b : b.departmentId === userDepartmentId))
				.map(item => {
					return {
						text: item.name,
						value: item.id,
					};
				}),
			filteredValue: filteredInfo ? filteredInfo.branchName || null : null,
		},
		{
			title: (
				<Input
					value={counterpartyNameSearch}
					placeholder='Заявник'
					style={{ fontSize: "12px" }}
					onChange={e => {
						onTableColumnSearch(e.target.value, "counterpartyName");
					}}
				/>
			),
			dataIndex: "counterpartyName",
			key: "counterpartyName",
			filteredValue: null,
			width: 150,
		},
		{
			title: (
				<Input
					value={phoneSearch}
					placeholder='Телефон'
					style={{ fontSize: "12px" }}
					onChange={e => {
						onTableColumnSearch(e.target.value, "phone");
					}}
				/>
			),
			dataIndex: "phone",
			key: "phone",
			filteredValue: null,
			width: 110,
		},
		{
			title: "Назва послуги",
			dataIndex: "appealPaidServiceName",
			key: "appealPaidServiceName",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "appealPaidServiceName" && sorteredInfo.order : null,
			filteredValue: null,
			width: 240,
			...getAppealPaidServiceFilterProps("appealPaidServiceName"),
			render: text => (
				<Typography.Paragraph
					ellipsis={{ rows: 3, expandable: true, symbol: <div style={{ fontSize: "11px" }}>більше</div> }}
				>
					{text}
				</Typography.Paragraph>
			),
		},
		{
			title: "Вартість, грн",
			dataIndex: "appealPaidServicePrice",
			key: "appealPaidServicePrice",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "appealPaidServicePrice" && sorteredInfo.order : null,
			filteredValue: null,
			width: 130,
			align: "center",
		},
		{
			title: "Дата оплати",
			dataIndex: "datePaidServiceWasPaid",
			key: "datePaidServiceWasPaid",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "datePaidServiceWasPaid" && sorteredInfo.order : null,
			filteredValue: null,
			width: 90,
		},
		{
			title: "Термін",
			dataIndex: "appealPaidServiceTimeName",
			key: "appealPaidServiceTimeName",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "appealPaidServiceTimeName" && sorteredInfo.order : null,
			filteredValue: null,
			width: 80,
		},
		{
			title: "Дії",
			dataIndex: "actions",
			key: "actions",
			align: "left",
			filteredValue: null,
			width: 100,
		},
	];

	const onAppealTableChange = (pagination, filters, sorter) => {
		setFilteredInfo(filters);
		let appealGetParametersChanged = { ...appealGetParametersLocal };
		//Sorting
		tableSortBuilder(appealGetParametersChanged, pagination, sorter);
		setSorteredInfo(sorter);

		//Filtering
		const tableFilterNamesArr = [
			"appealStatusName",
			"departmentName",
			"branchName",
			"innerRegistrationDate",
			"counterpartyPersonalAccount",
			"appealPaidServiceName",
		];
		const fieldNamesInDBArr = [
			"appealStatusId",
			"departmentId",
			"branchId",
			"innerRegistrationDate",
			"counterpartyTypeId",
			"appealPaidServiceId",
		];

		const filterStringLocal = tableFilterStringBuilder(filters, tableFilterNamesArr, fieldNamesInDBArr);
		setFilterString(filterStringLocal);
		appealGetParametersChanged.filter = combineFilter(filterStringLocal, searchString);
		setAppealGetParametersLocal(appealGetParametersChanged);
		scrollAntTableToTop();
	};

	const combineFilter = (filterString, searchString) => {
		if (filterString && searchString) return `(${searchString}), (${filterString})`;
		else if (filterString) return `(${filterString})`;
		else if (searchString) return `(${searchString})`;
		else return `(appealStatusId=${appealStatusEnum.NEW}|appealStatusId=${appealStatusEnum.IN_PROCESS})`;
	};

	const onClearFilters = useCallback(() => {
		setSorteredInfo(null);
		setFilteredInfo(null);
		setCounterpartyPersonalAccountSearch("");
		setCounterpartyNameSearch("");
		setPhoneSearch("");
		setInnerRegistrationDate("");
		setSearchString("");
		setFilterString(`appealStatusId=${appealStatusEnum.NEW}|appealStatusId=${appealStatusEnum.IN_PROCESS}`);
		setAppealPaidServiceSearch("");
		setAppealPaidServiceCheckedItems(undefined);
		scrollAntTableToTop();

		setAppealGetParametersLocal({
			...appealGetParameters,
			filter: `appealStatusId=${appealStatusEnum.NEW}|appealStatusId=${appealStatusEnum.IN_PROCESS}`,
		});
	}, []);

	const getAppealsDataForList = () => {
		let moment = require("moment");
		if (appealPaidServices) {
			return appealPaidServices.map(appeal => {
				return {
					key: appeal.id,
					id: appeal.id,
					appealStatusName: (
						<NavLink
							onClick={e => {
								if (editButton || appeal.appealStatusId === appealStatusEnum.CLOSED) e.preventDefault();
							}}
							to={{
								pathname: `/appeal-paid-service/edit-appeal/${appeal.id}`,
							}}
						>
							<Tag
								style={{ minWidth: 80, textAlign: "center" }}
								color={setStatusTagColor(appeal.appealStatusId)}
							>
								{appeal.appealStatusName}
							</Tag>
						</NavLink>
					),
					innerRegistrationNumber: appeal.innerRegistrationNumber,
					settlementName: appeal.settlementName,
					counterpartyPersonalAccount: appeal.counterpartyPersonalAccount,
					innerRegistrationDate: moment(appeal.innerRegistrationDate).format(dateFormat),
					electricAddress: appeal.electricAddress,
					departmentName: appeal.departmentName,
					branchName: appeal.branchName,
					counterpartyName: appeal.counterpartyName,
					phone: appeal.phone,
					appealPaidServicePrice: appeal.appealPaidServicePrice,
					appealPaidServiceName: appeal.appealPaidServiceName,
					appealPaidServiceTimeName: appeal.appealPaidServiceTimeName,
					datePaidServiceWasPaid: appeal.datePaidServiceWasPaid
						? moment(appeal.datePaidServiceWasPaid).format(dateFormat)
						: null,
					actions: (
						<Row gutter={[10, 10]}>
							<Col>
								<Tooltip placement='topLeft' title='Перегляд послуги' mouseEnterDelay={0.7}>
									<Button size={tableButtonsSize} onClick={() => onViewAppeal(appeal.id)}>
										<EyeOutlined />
									</Button>
								</Tooltip>
							</Col>
							<Col>
								{appeal.appealStatusId !== 3 && (
									<NavLink
										to={{
											pathname: "/tasks/create-task",
											search: `appealId=${appeal.id}&taskInitiatorId=${taskInitiatorsEnum.APPEAL_PAID_SERVICE}`,
										}}
									>
										<Tooltip placement='topLeft' title='Додати завдання' mouseEnterDelay={0.7}>
											<Button size={tableButtonsSize}>
												<AppstoreAddOutlined />
											</Button>
										</Tooltip>
									</NavLink>
								)}
							</Col>
							<Col>
								{!(editButton || appeal.appealStatusId === appealStatusEnum.CLOSED) && (
									<NavLink
										to={{
											pathname: `/appeal-paid-service/edit-appeal/${appeal.id}`,
										}}
									>
										<Tooltip placement='topLeft' title='Редагування послуги' mouseEnterDelay={0.7}>
											<Button size={tableButtonsSize}>
												<EditOutlined />
											</Button>
										</Tooltip>
									</NavLink>
								)}
							</Col>
							<Col>
								{currentUserRole.includes("Administrator") && (
									<Tooltip placement='topLeft' title='Видалення послуги' mouseEnterDelay={0.7}>
										<Button size={tableButtonsSize} danger onClick={() => onDeleteAppeal(appeal.id)}>
											<DeleteOutlined />
										</Button>
									</Tooltip>
								)}
							</Col>
						</Row>
					),
				};
			});
		}
	};

	//Set statuses color
	const setStatusTagColor = status => {
		let color = "default";
		if (status === 1) color = "red";
		if (status === 2) color = "blue";
		if (status === 3) color = "default";
		return color;
	};

	//On View Appeal
	const onViewAppeal = id => {
		setSelectedAppealId(id);
		setViewModalVisible(true);
	};

	//On Delete Appeal
	const onDeleteAppeal = id => {
		setSelectedAppealId(id);
		setDeleteModalVisible(true);
	};

	//EFFECTS
	//Breadcrumbs, first requests
	useEffect(() => {
		const breadCrumbData = [{ name: "Заяви на послуги", link: false }];

		dispatch(setPageBreadcrumb(breadCrumbData));
	}, [dispatch]);

	//Change filter by came from (menu/bell)
	useEffect(() => {
		if (queryParams.get("cameFrom") === "bell") {
			setAppealGetParametersLocal({
				...appealGetParameters,
				filter: `isRead = false, appealStatusId=${appealStatusEnum.NEW}`,
			});
		} else {
			onClearFilters();
		}
	}, [queryParams, onClearFilters]);

	//For department selector when without department
	useEffect(() => {
		setDepartments([].concat(departmentsState, [{ id: null, name: "Відсутній" }]));
	}, [departmentsState]);

	//Get all paid services
	useDebouncedEffect(
		() => {
			dispatch(getAppealPaidServices(appealGetParametersLocal));
		},
		[appealGetParametersLocal],
		700
	);

	//Paid service's types tree converting
	useEffect(() => {
		if (!treeData) {
			setAppealPaidServicesTypesTree([]);
			return;
		}

		const data = [...treeData];

		data.forEach(el => {
			el.value = el.id;
			el.icon = el.selectable ? (
				<DollarOutlined style={{ color: "orange" }} />
			) : (
				<PartitionOutlined style={{ color: "red" }} />
			);
			el.checkable = el.selectable;
		});

		setAppealPaidServicesTypesTree(data);
	}, [treeData]);

	//Role access control
	useEffect(() => {
		if (
			currentUserRole.includes("Administrator") ||
			currentUserRole.includes("DepartmentUser") ||
			currentUserRole.includes("DepartmentDispatcher")
		) {
			setDisableCreateAppealButton(false);
			setDisableEditButton(false);
		} else {
			setDisableCreateAppealButton(true);
			setDisableEditButton(true);
		}
	}, [dispatch, currentUserRole]);

	//Search in inputs header table with delay
	const onTableColumnSearch = (value, column) => {
		if (column === "counterpartyPersonalAccount") {
			setCounterpartyPersonalAccountSearch(value);
		} else if (column === "counterpartyName") {
			setCounterpartyNameSearch(value);
		} else if (column === "phone") {
			setPhoneSearch(value);
		}
	};

	//Full search string creating
	useDebouncedEffect(
		() => {
			if (isMount) return;
			let searchStr = "";
			let appealPaidServiceSearchStr = "";

			if (appealPaidServiceSearch && appealPaidServiceSearch.length) {
				for (let i = 0; i < appealPaidServiceSearch.length; i++) {
					appealPaidServiceSearchStr += "appealPaidServiceId=" + appealPaidServiceSearch[i] + "|";
				}
				appealPaidServiceSearchStr = appealPaidServiceSearchStr.slice(0, appealPaidServiceSearchStr.length - 1);
			}

			const searchObjArray = [
				{
					stringValue: debouncedCounterpartyNameSearch
						? `counterpartyName =*${debouncedCounterpartyNameSearch}`
						: "",
				},
				{
					stringValue: debouncedCounterpartyPersonalAccountSearch
						? debouncedCounterpartyPersonalAccountSearch === " "
							? `counterpartyPersonalAccount = null`
							: `counterpartyPersonalAccount =*${debouncedCounterpartyPersonalAccountSearch}`
						: "",
				},
				{
					stringValue: debouncedPhoneSearch ? `phone =*${debouncedPhoneSearch}` : "",
				},
				{
					stringValue: innerRegistrationDate
						? `innerRegistrationDate >=${innerRegistrationDate[0].format(
								"YYYY-MM-DD"
						  )}, innerRegistrationDate <=${innerRegistrationDate[1].format("YYYY-MM-DD")}T23:59:59`
						: "",
				},
				{
					stringValue: appealPaidServiceSearchStr,
				},
			];

			searchObjArray.forEach(item => {
				if (item.stringValue) {
					searchStr += "" + item.stringValue + ",";
				}
			});

			searchStr = searchStr.slice(0, -1); //delete last comma in string
			setSearchString(searchStr);

			setAppealGetParametersLocal({
				...appealGetParametersLocal,
				filter: combineFilter(filterString, searchStr),
				page: 1,
			});
		},
		[
			dispatch,
			debouncedCounterpartyPersonalAccountSearch,
			debouncedCounterpartyNameSearch,
			debouncedPhoneSearch,
			innerRegistrationDate,
			appealPaidServiceSearch,
		],
		100
	);

	return (
		<>
			<Row className={css.appeals}>
				<Col span={12}>
					<NavLink
						to={{
							pathname: `/appeal-paid-service/create-appeal`,
						}}
					>
						<Button hidden={createAppealButton} className={css.site_button} type='primary'>
							<PlusOutlined />
							Створити послугу
						</Button>
					</NavLink>
				</Col>
				<Col span={12} className={css.clear_filters_btn_wrap}>
					<Button type='default' className={css.filter_button} onClick={onClearFilters}>
						<ClearOutlined />
						Скинути всі фільтри
					</Button>
				</Col>
				<Card bodyStyle={{ padding: "0px" }} bordered={true} style={{ width: "100%" }}>
					<Table
						onChange={onAppealTableChange}
						className={css.ant_table_wrapper}
						size={tableSize}
						pagination={{
							position: paginationPosition,
							defaultPageSize: defaultPageSize,
							showSizeChanger: true,
							total: appealTotalItems,
							current: appealGetParametersLocal.page,
							showTotal: showTotal,
						}}
						showSorterTooltip={false}
						//scroll={appealsTableScroll}
						columns={columns}
						dataSource={getAppealsDataForList()}
						//rowClassName={(record, index) => onOverdueAppealCheck(record, index)}
						rowClassName={(record, index) => (index % 2 === 0 ? css.table_row_light : css.table_row_dark)}
					/>
				</Card>
				<ViewAppealPaidServiceDrawer
					visible={viewModalVisible}
					onClose={() => setViewModalVisible(false)}
					appealId={selectedAppealId}
				/>
				<AppealPaidServiceDeleteModal
					open={deleteModalVisible}
					onCancel={() => setDeleteModalVisible(false)}
					appealId={selectedAppealId}
				/>
			</Row>
		</>
	);
};

export default AppealPaidService;
