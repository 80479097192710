import { SET_MAP_DATA_BY_OVB } from "../types";
import { ovbAPI } from "../../api/ovbEndpoints";
import { setApiLastError } from "./appActions";
import { thunkErrorHandler } from "./actionHandlers";

export const setMapDataByOvb = mapDataByOvb => {
	return {
		type: SET_MAP_DATA_BY_OVB,
		payload: mapDataByOvb,
	};
};

//Getters
export const getMapDataByOvb = departmentId => async dispatch => {
	try {
		const response = await ovbAPI.getMapDataByOvb(departmentId);
		if (response.status === 200) {
			dispatch(setMapDataByOvb(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
