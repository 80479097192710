import { Button, Col, Row, Upload } from "antd";
import React, { useEffect, useState } from "react";
import {
	deleteFileAsync,
	getFileAsync,
	getFilesList,
	setAppealFilesToUpload,
} from "../../../../redux/actions/appealActions";
import { useDispatch, useSelector } from "react-redux";

import DeleteOutlined from "@ant-design/icons/es/icons/DeleteOutlined";
import DividerGrid from "../../../common/DividerGrid/DividerGrid";
import DownloadOutlined from "@ant-design/icons/es/icons/DownloadOutlined";
import PlusOutlined from "@ant-design/icons/es/icons/PlusOutlined";
import css from "./AppealPaidServiceFilesList.module.scss";
import debounce from "lodash/debounce";
import moment from "moment";

const AppealPaidServiceFilesList = ({ appealId }) => {
	const dispatch = useDispatch();
	const appealFilesList = useSelector(state => state.appeal.appealFilesList);
	const appealFilesListToUpload = useSelector(state => state.appeal.appealFilesListToUpload);

	const [mixedFileList, setMixedFileList] = useState([]);

	useEffect(() => {
		dispatch(getFilesList(appealId));
	}, [dispatch, appealId]);

	useEffect(() => {
		const list = [];
		appealFilesListToUpload.forEach((item, index) => {
			item.id = index.toString();
			item.isNew = true;
			list.push(item);
		});

		setMixedFileList(appealFilesList.concat(list));
	}, [appealFilesList, appealFilesListToUpload]);

	const debouncedOnFilesChange = debounce(
		({ _, fileList }) => {
			dispatch(setAppealFilesToUpload(fileList));
		},
		400,
		{ leading: true, trailing: false }
	);

	const props = {
		showUploadList: false,
		beforeUpload: Upload.LIST_IGNORE,
		multiple: true,
		fileList: appealFilesListToUpload,
		onChange: debouncedOnFilesChange,
	};

	const onDeleteFileFromList = (id, isNewFile) => {
		if (isNewFile) {
			const newList = [...appealFilesListToUpload];
			newList.splice(Number(id), 1);
			dispatch(setAppealFilesToUpload(newList));
		} else {
			dispatch(deleteFileAsync(id, appealId));
		}
	};

	const onViewAppealFile = index => {
		const fileId = appealFilesList[index].id;
		const fileName = appealFilesList[index].name;
		dispatch(getFileAsync(fileId, fileName));
	};

	return (
		<div className={css.appeal_files_list_wrapper}>
			<DividerGrid title={"Приєднані документи"} />

			<Row>
				<Col className={css.upload}>
					<Upload {...props}>
						<Button className={css.site_button} type='primary' icon={<PlusOutlined />}>
							Вибрати файл
						</Button>
					</Upload>
				</Col>
				<Col className={css.file_size_description}>Максимальний розмір файлу 5MБ</Col>
			</Row>

			<Row>
				<Col>
					{mixedFileList.map((file, index) => {
						return (
							<Row className={css.files_list} key={file.id}>
								{!file.isNew && (
									<Col className={css.files_list_item_wrapper}>
										<Button
											type='link'
											size={"small"}
											style={{ color: "#357ca5", border: "1px solid lightblue" }}
											onClick={() => onViewAppealFile(index)}
										>
											<DownloadOutlined />
										</Button>
									</Col>
								)}
								<Col className={css.files_list_item_wrapper}>
									<Button
										type='link'
										size={"small"}
										style={{ color: "#ff7875", border: "1px solid #ff7875" }}
										onClick={() => onDeleteFileFromList(file.id, file.isNew)}
									>
										<DeleteOutlined />
									</Button>
								</Col>
								<Col className={css.files_list_item_wrapper}>{file.name}</Col>
								<Col className={css.files_list_file_create_time}>
									{moment(file.created).format("YYYY-MM-DD (HH:mm)")}
								</Col>
							</Row>
						);
					})}
				</Col>
			</Row>
		</div>
	);
};

export default AppealPaidServiceFilesList;
