//Types: 'success', 'info', 'warning', 'error'

import { notification } from "antd";

const openNotify = (type, title, description) => {
	if (description) {
		notification[type]({
			message: title,
			description,
			style: {
				marginTop: 25,
			},
		});
	}
};

export default openNotify;
