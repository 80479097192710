import {
	APP_INIT,
	HIDE_LOADER,
	SET_API_HEALTH,
	SET_API_LAST_ERROR,
	SET_APP_ENVIRONMENT,
	SET_APP_VERSION,
	SET_PAGE_BREADCRUMB,
	SET_TABLE_FILTER,
	SHOW_ERROR,
	SHOW_LOADER,
	SHOW_SUCCESS,
	SHOW_WARNING,
} from "../types";

const initialState = {
	appInit: false,
	isLoading: false,
	appVersion: null,
	appEnvironment: null,
	error: null,
	success: null,
	warning: null,
	pageBreadcrumb: [{ name: "Головна", link: false }],
	apiLastError: { isErrorExists: undefined, errorObject: null },
	tableFilter: null,
	apiHealthy: false,
	dispatchLoader: { name: undefined, value: false },
};

export const appReducer = (state = initialState, action) => {
	switch (action.type) {
		case APP_INIT:
			return { ...state, appInit: action.payload };
		case SHOW_LOADER:
			return { ...state, isLoading: true };
		case HIDE_LOADER:
			return { ...state, isLoading: false };
		case SET_APP_VERSION:
			return { ...state, appVersion: action.payload };
		case SET_APP_ENVIRONMENT:
			return { ...state, appEnvironment: action.payload };
		case SHOW_ERROR:
			return { ...state, error: action.payload };
		case SHOW_SUCCESS:
			return { ...state, success: action.payload };
		case SHOW_WARNING:
			return { ...state, warning: action.payload };
		case SET_PAGE_BREADCRUMB:
			return { ...state, pageBreadcrumb: action.payload };
		case SET_API_LAST_ERROR:
			return { ...state, apiLastError: action.payload };
		case SET_TABLE_FILTER:
			return { ...state, tableFilter: action.payload };
		case SET_API_HEALTH:
			return { ...state, apiHealthy: action.payload };
		default:
			return state;
	}
};
