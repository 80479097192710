import { Col, Form, Row, Select } from "antd";

import React from "react";
import css from "./AppealPaidServiceInputOptions.module.scss";
import { dictionaryTypeEnum } from "../../../../dictionaries/models/dictionaryTypeEnum";
import useDictionary from "../../../../dictionaries/useDictionaries";
import { useSelector } from "react-redux";

const AppealPaidServiceInputOptions = () => {
	const { Option } = Select;
	const userRole = useSelector(state => state.auth.userRole);
	const appealClassifiers = useDictionary(dictionaryTypeEnum.APPEAL_CLASSIFIERS);
	const counterpartyTypes = useDictionary(dictionaryTypeEnum.COUNTERPARTY_TYPES);
	const appealObtainingMethods = useDictionary(dictionaryTypeEnum.APPEAL_OBTAINING_METHODS);
	const departmentEntryPoints = useDictionary(dictionaryTypeEnum.DEPARTMENT_ENTRY_POINTS);

	return (
		<div className={css.appeal_input_options_wrapper}>
			<Row gutter={20}>
				<Col lg={12} md={24}>
					<Form.Item
						labelCol={{ span: 8 }}
						label='Точка входу:'
						name='departmentEntryPointId'
						rules={[{ required: true, message: "Будь ласка  вкажіть точку входу!" }]}
					>
						<Select
							placeholder='Точка входу'
							popupMatchSelectWidth={false}
							disabled={userRole[0] !== "Administrator"}
						>
							{departmentEntryPoints?.map(departmentEntryPoint => (
								<Option key={departmentEntryPoint.id} value={departmentEntryPoint.id}>
									{departmentEntryPoint.name}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={20}>
				<Col lg={12} md={24}>
					<Form.Item
						labelCol={{ span: 8 }}
						label='Класифікатор:'
						name='appealClassifierId'
						rules={[{ required: true, message: "Будь ласка, оберіть класифікатор!" }]}
					>
						<Select placeholder='Класифікатор' popupMatchSelectWidth={false} disabled>
							{appealClassifiers?.map(classifier => (
								<Option key={classifier.id} value={classifier.id}>
									{classifier.name}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>

				<Col lg={12} md={24}>
					<Form.Item
						labelCol={{ span: 8 }}
						label='Спосіб отримання:'
						name='appealObtainingMethodId'
						rules={[{ required: true, message: "Будь ласка, оберіть спосіб отримання!" }]}
					>
						<Select placeholder='Спосіб отримання' popupMatchSelectWidth={false} onChange={null}>
							{appealObtainingMethods?.map(type => (
								<Option key={type.id} value={type.id}>
									{type.name}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={20}>
				<Col lg={12} md={24}>
					<Form.Item
						labelCol={{ span: 8 }}
						label='Тип заявника:'
						name='counterpartyTypeId'
						rules={[{ required: true, message: "Будь ласка, оберіть тип споживача!" }]}
					>
						<Select placeholder='Оберіть тип заявника' popupMatchSelectWidth={false} onChange={null}>
							{counterpartyTypes?.map(type => (
								<Option key={type.id} value={type.id}>
									{type.name}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>
			</Row>
		</div>
	);
};

export default AppealPaidServiceInputOptions;
