import { SET_USER_APPEAL_OMISSION_MASK, SET_USER_DEPARTMENT_HIERARCHY_NAME } from "../types";
import { setApiLastError, setSuccess } from "./appActions";

import { departmentHierarchyAPI } from "../../api/departmentHierarchyEndpoints";
import { thunkErrorHandler } from "./actionHandlers";
import { userProfileAPI } from "../../api/profileEndpoints";

export const changeUser = userProfileData => async dispatch => {
	try {
		const response = await userProfileAPI.changeUser(userProfileData);
		if (response.status >= 200 && response.status < 300) {
			await dispatch(setSuccess("Отримувача успішно відредаговано!"));
			await dispatch(setSuccess(""));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

//User appeal omission mask
export const getUserAppealOmissionMask = () => async dispatch => {
	try {
		const response = await departmentHierarchyAPI.getUserAppealOmissionMask();
		if (response.status >= 200 && response.status < 300) {
			dispatch(setUserAppealOmissionMask(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const setUserAppealOmissionMask = userAppealOmissionMask => {
	return {
		type: SET_USER_APPEAL_OMISSION_MASK,
		payload: userAppealOmissionMask,
	};
};

//User department hierarchy name
export const getUserDepartmentHierarchyName = () => async dispatch => {
	try {
		const response = await departmentHierarchyAPI.getUserDepartmentHierarchyName();
		if (response.status >= 200 && response.status < 300) {
			dispatch(setUserDepartmentHierarchyName(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const setUserDepartmentHierarchyName = userDepartmentHierarchyName => {
	return {
		type: SET_USER_DEPARTMENT_HIERARCHY_NAME,
		payload: userDepartmentHierarchyName,
	};
};

//Set user appeals omission mask to default
export const setUserAppealOmissionMaskDefault = () => async dispatch => {
	try {
		const response = await departmentHierarchyAPI.setUserAppealOmissionMaskDefault();
		if (response.status === 202) {
			dispatch(setSuccess("Встановлено налаштування по замовчуванню!"));
			dispatch(setSuccess(""));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

//Set user appeals omission new mask
export const setUserAppealOmissionMaskCurrent = mask => async dispatch => {
	try {
		const response = await departmentHierarchyAPI.setUserAppealOmissionMaskCurrent(mask);
		if (response.status === 202) {
			dispatch(setSuccess("Нові налаштування застосовані!"));
			dispatch(setSuccess(""));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
