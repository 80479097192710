import { Form, Input } from "antd";
import React, { useState } from "react";

const PriceInput = ({ formFieldName, isRequired }) => {
	const [value, setValue] = useState();

	const handleInputChange = e => {
		let input = e.target.value;
		const parts = input.split(".");

		if (parts[1] && parts[1].length > 2) {
			input = `${parts[0]}.${parts[1].slice(0, 2)}`;
		}

		setValue(input);
	};

	return (
		<Form.Item
			labelCol={{ span: 8 }}
			name={formFieldName}
			label='Вартість:'
			rules={[{ required: isRequired, message: "Будь ласка вкажіть вартість!" }]}
		>
			<Input type='number' value={value} onChange={handleInputChange} placeholder='Введіть вартість' />
		</Form.Item>
	);
};

export default PriceInput;
