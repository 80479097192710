import { Button, Col, DatePicker, Result, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
	generateStatisticsDocument,
	getMaifiCalculationsXlsx,
	getSaidiSaifiCalculationsXlsx,
} from "../../../redux/actions/reportsActions";
import { useDispatch, useSelector } from "react-redux";

import DatabaseOutlined from "@ant-design/icons/es/icons/DatabaseOutlined";
import { NavLink } from "react-router-dom";
import css from "./StatsReport.module.scss";
import { dictionaryTypeEnum } from "../../../dictionaries/models/dictionaryTypeEnum";
import locale from "antd/es/date-picker/locale/uk_UA";
import moment from "moment";
import { setPageBreadcrumb } from "../../../redux/actions/appActions";
import useDictionary from "../../../dictionaries/useDictionaries";

const StatsReport = ({ text }) => {
	const dispatch = useDispatch();
	const { Option } = Select;
	const { RangePicker } = DatePicker;
	const rangePickerDateFormat = "YYYY-MM-DD";

	const departments = useDictionary(dictionaryTypeEnum.DEPARTMENTS);
	const currentUser = useSelector(state => state.auth);
	const currentUserRole = useSelector(state => state.auth.userRole);
	const isLoading = useSelector(state => state.app.isLoading);

	const [reportId, setReportId] = useState(1);
	const [accessDenided, setAccessDenided] = useState(false);

	const [params, setParams] = useState({
		departmentId: JSON.parse(currentUser.departmentId) === 25 ? null : JSON.parse(currentUser.departmentId),
		settlementTypeId: 1,
		dateBegin: moment().add(-30, "days"),
		dateEnd: moment(),
	});

	const onReportChange = value => {
		if (value === 1 || value === 3) setParams(prev => ({ ...prev, settlementTypeId: 1 })); //setSettlementTypeId(1);
		if (value === 2 || value === 4) setParams(prev => ({ ...prev, settlementTypeId: 2 })); //setSettlementTypeId(2);
		setReportId(value);
	};

	const onDepartmentChange = value => {
		setParams(prev => ({ ...prev, departmentId: value }));
	};

	const onDateChange = value => {
		if (!value) return;
		setParams(prev => ({ ...prev, dateBegin: value[0], dateEnd: value[1] }));
	};

	const onReportsCreate = () => {
		if (reportId === 1 || reportId === 2)
			dispatch(
				getSaidiSaifiCalculationsXlsx(
					params.departmentId,
					params.settlementTypeId,
					params.dateBegin.format(rangePickerDateFormat),
					params.dateEnd.format(rangePickerDateFormat)
				)
			);
		if (reportId === 3 || reportId === 4)
			dispatch(
				getMaifiCalculationsXlsx(
					params.departmentId,
					params.settlementTypeId,
					params.dateBegin.format(rangePickerDateFormat),
					params.dateEnd.format(rangePickerDateFormat)
				)
			);
	};

	const onMainReportCreate = () => {
		let mainReportParams = { ...params };
		delete mainReportParams.settlementTypeId;
		dispatch(
			generateStatisticsDocument(
				mainReportParams.departmentId,
				mainReportParams.settlementTypeId,
				mainReportParams.dateBegin.format(rangePickerDateFormat),
				mainReportParams.dateEnd.format(rangePickerDateFormat)
			)
		);
	};

	//Bradcrums etc..
	useEffect(() => {
		const breadCrumbData = [{ name: text, link: false }];
		dispatch(setPageBreadcrumb(breadCrumbData));
	}, [dispatch, text]);

	//Roles and access control
	useEffect(() => {
		if (
			currentUserRole.includes("Administrator") ||
			currentUserRole.includes("DepartmentUser") ||
			currentUserRole.includes("DepartmentDispatcher")
		) {
			setAccessDenided(false);
		} else {
			setAccessDenided(true);
		}
	}, [dispatch, currentUserRole]);

	if (accessDenided)
		return (
			<Result
				status='info'
				title='Вибачте, у Вашої ролі немає прав доступу до цієї сторінки!'
				extra={
					<Button>
						<NavLink to='/'>Повернутися на головну</NavLink>
					</Button>
				}
			/>
		);

	return (
		<div className={css.stats_report_wrapp}>
			<Row>
				<Col className={css.controls_wrap}>
					{/* <span>Підрозділ: </span> */}
					<Select
						className={css.department_select}
						placeholder='Підрозділ (всі)'
						popupMatchSelectWidth={false}
						onChange={onDepartmentChange}
						allowClear={true}
						value={params.departmentId}
					>
						{departments.map(item => (
							<Option key={item.id} value={item.id}>
								{item.name}
							</Option>
						))}
					</Select>
				</Col>

				<Col className={css.controls_wrap}>
					{/* <span>Період: </span> */}
					<RangePicker
						locale={locale}
						defaultValue={[params.dateBegin, params.dateEnd]}
						onChange={onDateChange}
						picker='month'
						allowClear={false}
					/>
				</Col>

				<Col>
					<Button
						className={![params.dateBegin, params.dateEnd] ? css.site_button_disabled : css.site_button}
						type='primary'
						onClick={onMainReportCreate}
						icon={<DatabaseOutlined />}
						loading={!!isLoading}
						disabled={![params.dateBegin, params.dateEnd]}
					>
						Загальний звіт (Docx)
					</Button>
				</Col>

				<Col className={css.controls_wrap}>
					<span>Детальні розрахунки: </span>
					<Select
						className={css.report_select}
						placeholder='Звіт'
						popupMatchSelectWidth={false}
						onChange={onReportChange}
						defaultValue='SAIDI + SAIFI (місто)'
					>
						<Option key={1} value={1}>
							SAIDI + SAIFI (місто)
						</Option>
						<Option key={2} value={2}>
							SAIDI + SAIFI (село)
						</Option>
						<Option key={3} value={3}>
							MAIFI (місто)
						</Option>
						<Option key={4} value={4}>
							MAIFI (село)
						</Option>
					</Select>
				</Col>

				<Col>
					<Button
						className={![params.dateBegin, params.dateEnd] ? css.site_button_disabled : css.site_button}
						type='primary'
						onClick={onReportsCreate}
						icon={<DatabaseOutlined />}
						loading={!!isLoading}
						disabled={![params.dateBegin, params.dateEnd]}
					>
						Детальні розрахунки (Xlsx)
					</Button>
				</Col>
			</Row>
		</div>
	);
};

export default StatsReport;
