import { Col, Row, Spin } from "antd";

import React from "react";
import css from "./StatisticIndicatorType2.module.scss";
import { isDataExist } from "../../../assets/js/helpers/isDataExist";

const StatisticIndicatorType2 = ({ title, subTitle, value, color, marginRight }) => {
	return (
		<Row
			className={css.indicator_wrapper}
			style={{
				marginRight: marginRight,
				borderBottom: `5px solid ${color}`,
			}}
		>
			<Col>
				<Row className={css.title}>{title}</Row>
				<Row className={css.sub_title} style={{ color: color }}>
					{subTitle}
				</Row>
				<Row className={css.value}>{isDataExist(value) || <Spin />}</Row>
			</Col>
		</Row>
	);
};

export default StatisticIndicatorType2;
