import React from "react";
import { Table } from "antd";
import css from "./StatisticIndicatorType3.module.scss";

const StatisticIndicatorType3 = ({ data }) => {
	const dataSource = [
		{
			key: 1,
			pl110: data.pl110,
			ps110: data.ps110,
			ps35: data.ps35,
			pl35: data.pl35,
			tp: data.tp,
			pl10: data.pl10,
			line04: data.line04,
		},
	];

	const columns = [
		{
			title: "ПС 110кВ",
			dataIndex: "ps110",
			key: "ps110",
			align: "center",
			width: 100,
		},
		{
			title: "ПЛ 110кВ",
			dataIndex: "pl110",
			key: "pl110",
			align: "center",
			width: 100,
		},
		{
			title: "ПС 35кВ",
			dataIndex: "ps35",
			key: "ps35",
			align: "center",
			width: 100,
		},
		{
			title: "ПЛ 35кВ",
			dataIndex: "pl35",
			key: "pl35",
			align: "center",
			width: 100,
		},
		{
			title: "ПЛ 10кВ",
			dataIndex: "pl10",
			key: "pl10",
			align: "center",
			width: 100,
		},
		{
			title: "ТП",
			dataIndex: "tp",
			key: "tp",
			align: "center",
			width: 100,
		},
		{
			title: "Лінія 0.4кВ",
			dataIndex: "line04",
			key: "line04",
			align: "center",
			width: 100,
		},
	];

	return (
		<div className={css.indicator_wrapper}>
			<div className={css.title}>Знеструмлено вузлів в електромережі:</div>
			<Table
				dataSource={dataSource}
				columns={columns}
				bordered
				pagination={false}
				size={window.innerWidth < 600 ? "small" : "middle"}
				scroll={{ x: 350 }}
			/>
		</div>
	);
};

export default StatisticIndicatorType3;
