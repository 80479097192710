import React, { Suspense, lazy, useEffect } from "react";
import { Routes, useLocation } from "react-router-dom/dist";
import { setSuccess, setWarning } from "../../redux/actions/appActions";
import { useDispatch, useSelector } from "react-redux";

import { AppBreadcrumb } from "../common/Breadcrumb/AppBreadcrumb";
import AppealPaidService from "../AppealPaidService/AppealPaidService";
import AppealPaidServiceForm from "../AppealPaidService/AppealPaidServiceForm/AppealPaidServiceForm";
import Appeals from "../Appeals/Appeals";
import AppealsControl from "../Reports/AppealsControl/AppealsControl";
import ClaimApprovalsPage from "../ClaimApprovalsPage/ClaimApprovals";
import Claims from "../Claims/Claims";
import DepartmentEfficiency from "../Reports/DepartmentEfficiency/DepartmentEfficiency";
import DevInfo from "../DevInfo/DevInfo";
import { Layout } from "antd";
import LayoutHeader from "./LayoutHeader";
import LayoutMenu from "./LayoutMenu";
import Login from "../Login/Login";
import Main from "../Main/Main";
import MobileServices from "../MobileServices/MobileServices";
import NotFound from "../NotFound/NotFound";
import OverduedAppealPaidServices from "../Reports/OverduedAppealPaidServices/OverduedAppealPaidServices";
import Preloader from "../common/Preloader/Preloader";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import { Route } from "react-router-dom";
import Scada from "../Shutdowns/Scada/Scada";
import ShutdownMaps from "../ShutdownMaps/ShutdownMaps";
import Shutdowns from "../Shutdowns/Shutdowns";
import Smart from "../Shutdowns/Smart/Smart";
import Stats from "../Stats/Stats";
import StatsReport from "../Reports/Stats/StatsReport";
import Tasks from "../Tasks/Tasks";
import TempRM3 from "../Temp/TempRM3/TempRM3";
import css from "./AppLayout.module.scss";
import { getNotApprovedClaimsCount } from "../../redux/actions/claimActions";
import { getNotReadAppealsCount } from "../../redux/actions/appealActions";
import openNotify from "../../assets/js/notification";

const Admin = lazy(() => import("../Admin/Admin"));
const AppealForm = lazy(() => import("../Appeals/AppealForm/AppealForm"));
const ShutdownForm = lazy(() => import("../Shutdowns/ShutdownForm/ShutdownForm"));
const ClaimForm = lazy(() => import("../Claims/ClaimForm/ClaimForm"));
const TaskForm = lazy(() => import("../Tasks/TaskForm/TaskForm"));
const ShutdownCalendar = lazy(() => import("../ShutdownCalendar/ShutdownCalendar"));
const Nkre373 = lazy(() => import("../Reports/Nkre373/Nkre373"));

const CounterpartySearh = lazy(() => import("../Reports/CounterpartySearсh/CounterpartySearch"));
const OverduedAppealComplains = lazy(() => import("../Reports/OverduedAppealComplains/OverduedAppealComplains"));
const ShutdownSearch = lazy(() => import("../Reports/ShutdownSearch/ShutdownSearch"));
const ShutdownsPerYear = lazy(() => import("../Reports/ShutdownsPerYear/ShutdownsPerYear"));
const ActiveShutdowns = lazy(() => import("../Reports/ActiveShutdowns/ActiveShutdowns"));
const Rm3 = lazy(() => import("../Reports/Rm3/Rm3"));

const AppLayout = () => {
	const { Content } = Layout;

	const location = useLocation();
	const { pathname } = location;

	const dispatch = useDispatch();

	const isAuth = useSelector(state => state.auth.token);
	const userRole = useSelector(state => state.auth.userRole);
	const departmentId = useSelector(state => state.auth.departmentId);

	const successText = useSelector(state => state.app.success);
	const warningText = useSelector(state => state.app.warning);
	const breadcrumbData = useSelector(state => state.app.pageBreadcrumb);

	//Shared App requests (timer for unread appeals)
	useEffect(() => {
		if (!userRole || !departmentId) return;
		if (userRole[0] === "Administrator" || departmentId === "25") return;
		dispatch(getNotReadAppealsCount());
		const interval = setInterval(() => {
			dispatch(getNotReadAppealsCount());
		}, 60000);

		if (!isAuth) {
			clearInterval(interval);
		}

		return () => {
			clearInterval(interval);
		};
	}, [dispatch, isAuth, userRole, departmentId]);

	//Shared App requests (timer for not approved claims)
	useEffect(() => {
		if (!userRole) return;
		if (userRole[0] === "Administrator") return;
		dispatch(getNotApprovedClaimsCount());
		const interval = setInterval(() => {
			dispatch(getNotApprovedClaimsCount());
		}, 60000);

		if (!isAuth) {
			clearInterval(interval);
		}

		return () => {
			clearInterval(interval);
		};
	}, [dispatch, isAuth, userRole]);

	//Notifications
	useEffect(() => {
		if (!successText) return;
		openNotify("success", "Успіх!", successText);
		dispatch(setSuccess(null));
	}, [dispatch, successText, userRole]);

	useEffect(() => {
		if (!warningText) return;
		openNotify("warning", "Увага!", warningText);
		dispatch(setWarning(null));
	}, [warningText, dispatch]);

	return (
		<Layout className={css.layout}>
			<LayoutHeader />
			<Layout>
				{isAuth && pathname !== "/privacypolicy" && <LayoutMenu />}
				<Layout className={css.content_wrapper}>
					{isAuth && pathname !== "/privacypolicy" && (
						<div className={css.title_wrap}>
							<AppBreadcrumb breadcrumbData={breadcrumbData} />
						</div>
					)}
					<Content className={css.content}>
						{pathname === "/privacypolicy" ? (
							<Routes>
								<Route element={<PrivacyPolicy />} path='/privacypolicy' />
							</Routes>
						) : !isAuth ? (
							<Login />
						) : (
							<Suspense fallback={<Preloader />}>
								{pathname !== "/privacypolicy" && (
									<Routes>
										<Route element={<TempRM3 />} path='/test' />

										<Route element={<Admin />} path='/admin' />

										<Route element={<Main text={"Головна"} />} path='/' />
										<Route index element={<Main text={"Головна"} />} path='/main' />

										<Route element={<Stats />} path='/stats' />

										{/* Appeals */}
										<Route element={<Appeals text={"Звернення"} />} path='/appeals' />
										<Route element={<AppealForm />} path='/appeals/create-appeal' />
										<Route element={<AppealForm />} path='/appeals/edit-appeal/:appealId' />

										{/* AppealPaidService */}
										<Route
											element={<AppealPaidService text={"Послуги (звернення)"} />}
											path='/appeal-paid-service'
										/>
										<Route element={<AppealPaidServiceForm />} path='/appeal-paid-service/create-appeal' />
										<Route
											element={<AppealPaidServiceForm />}
											path='/appeal-paid-service/edit-appeal/:appealId'
										/>

										{/* Tasks */}
										<Route element={<Tasks text={"Завдання"} />} path='/tasks' />
										<Route element={<TaskForm />} path='/tasks/create-task' />
										<Route element={<TaskForm />} path='/tasks/edit-task/:taskId' />

										{/* Shutdowns */}
										<Route element={<Shutdowns />} path='/shutdowns/:shutdownTypeId' />
										<Route
											element={<ShutdownForm />}
											path='/shutdowns/create-shutdown/:shutdownTypeId/:externalDepartmentId?/:externalElectricNodeId?'
										/>
										<Route
											element={<ShutdownForm />}
											path='/shutdowns/edit-shutdown/:shutdownTypeId/:shutdownId'
										/>
										<Route
											element={<ShutdownCalendar text={"Календар відключень"} />}
											path='/shutdown_calendar'
										/>
										<Route element={<ShutdownMaps text={"Мапи"} />} path='/shutdown_maps' />
										<Route element={<Smart text={"SMART"} />} path='/smart' />
										<Route element={<Scada text={"SCADA"} />} path='/scada' />

										{/* Claims */}
										<Route element={<Claims text={"Заявки"} />} path='/claims' />
										<Route element={<ClaimForm text={"Створення заявки"} />} path='/claims/create-claim' />
										<Route element={<ClaimForm />} path='/claims/edit-claim/:claimId' />
										<Route
											element={<ClaimApprovalsPage text={"Погодження заявок"} />}
											path='/claims_approval'
										/>

										{/* Reports */}
										<Route
											element={<ActiveShutdowns text={"Активні відключення (звіти)"} />}
											path='/active-shutdowns-report'
										/>
										<Route
											element={<OverduedAppealComplains text={"Протерміновані звернення (звіти)"} />}
											path='/overdued-appeal-complains-report'
										/>
										<Route
											element={<OverduedAppealPaidServices text={"Заяви на послуги (звіти)"} />}
											path='/overdued-appeal-paid-services-report'
										/>
										<Route
											element={<Nkre373 text={"НКРЕКП звернення КЦ (звіти)"} />}
											path='/nkre373-report'
										/>
										<Route element={<StatsReport text={"Показники (звіти)"} />} path='/stats-report' />
										<Route
											element={<ShutdownSearch text={"Пошук відключень (звіти)"} />}
											path='/shutdown-search-report'
										/>
										<Route
											element={<CounterpartySearh text={"Пошук споживачів (звіти)"} />}
											path='/counterparty-search-report'
										/>
										<Route
											element={<ShutdownsPerYear text={"ТОП відключень (звіти)"} />}
											path='/shutdowns-per-year-report'
										/>
										<Route
											element={<Rm3 text={"Форма РМ-3: Порушення в електричних мережах (звіти)"} />}
											path='/rm3-report'
										/>
										<Route
											element={<DepartmentEfficiency text={"Ефективність РЕМ (звіти)"} />}
											path='/department-efficiency-report'
										/>
										<Route
											element={<AppealsControl text={"Контроль звернень (звіти)"} />}
											path='/appeals-control-report'
										/>

										{/* Other */}
										<Route element={<MobileServices text={"Мобільні сервіси"} />} path='/mobile-services' />
										<Route element={<DevInfo text={"Оновлення системи"} />} path='/dev-info' />
										<Route element={<NotFound text={"Сторінку не знайдено"} />} path='/*' />
									</Routes>
								)}
							</Suspense>
						)}
					</Content>
				</Layout>
			</Layout>
		</Layout>
	);
};

export default AppLayout;
