import {
	SET_CHART_DATA_SELECTOR,
	SET_CHART_TYPE_SELECTOR,
	SET_ENS_DATA_CITY,
	SET_ENS_DATA_VILAGE,
	SET_MAIFI_DATA_CITY,
	SET_MAIFI_DATA_VILAGE,
	SET_SAIDI_SAIFI_DATA_CITY,
	SET_SAIDI_SAIFI_DATA_VILAGE,
} from "../types";
import { hideLoader, setApiLastError, showLoader } from "./appActions";

import { statsAPI } from "../../api/statsEndpoints";
import { thunkErrorHandler } from "./actionHandlers";

//For STATS

export const setSaidiSaifiDataCity = data => {
	return {
		type: SET_SAIDI_SAIFI_DATA_CITY,
		payload: data,
	};
};

export const setSaidiSaifiDataVilage = data => {
	return {
		type: SET_SAIDI_SAIFI_DATA_VILAGE,
		payload: data,
	};
};

export const setMaifiDataCity = data => {
	return {
		type: SET_MAIFI_DATA_CITY,
		payload: data,
	};
};

export const setMaifiDataVilage = data => {
	return {
		type: SET_MAIFI_DATA_VILAGE,
		payload: data,
	};
};

export const setEnsDataCity = data => {
	return {
		type: SET_ENS_DATA_CITY,
		payload: data,
	};
};

export const setEnsDataVilage = data => {
	return {
		type: SET_ENS_DATA_VILAGE,
		payload: data,
	};
};

export const setChartDataSelector = data => {
	return {
		type: SET_CHART_DATA_SELECTOR,
		payload: data,
	};
};

export const setChartTypeSelector = data => {
	return {
		type: SET_CHART_TYPE_SELECTOR,
		payload: data,
	};
};

export const getSaidiSaifiData = (departmentId, settlementTypeId, dateBegin, dateEnd) => async dispatch => {
	try {
		dispatch(showLoader());
		const response = await statsAPI.getSaidiSaifiData(departmentId, settlementTypeId, dateBegin, dateEnd);
		if (response.status === 200) {
			const values = response.data;
			if (settlementTypeId === 1) {
				dispatch(setSaidiSaifiDataCity(values));
			} else if (settlementTypeId === 2) {
				dispatch(setSaidiSaifiDataVilage(values));
			}
		}
		dispatch(hideLoader());
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getMaifiData = (departmentId, settlementTypeId, dateBegin, dateEnd) => async dispatch => {
	try {
		dispatch(showLoader());
		const response = await statsAPI.getMaifiData(departmentId, settlementTypeId, dateBegin, dateEnd);
		if (response.status === 200) {
			const values = response.data;
			if (settlementTypeId === 1) {
				dispatch(setMaifiDataCity(values));
			} else if (settlementTypeId === 2) {
				dispatch(setMaifiDataVilage(values));
			}
		}
		dispatch(hideLoader());
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getEnsData = (departmentId, settlementTypeId, dateBegin, dateEnd) => async dispatch => {
	try {
		dispatch(showLoader());
		const response = await statsAPI.getEnsData(departmentId, settlementTypeId, dateBegin, dateEnd);
		if (response.status === 200) {
			const values = response.data;
			if (settlementTypeId === 1) {
				dispatch(setEnsDataCity(values));
			} else if (settlementTypeId === 2) {
				dispatch(setEnsDataVilage(values));
			}
		}
		dispatch(hideLoader());
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getAllStatsData = settings => dispatch => {
	//Promises
	let saidiSaifiM = dispatch(
		getSaidiSaifiData(
			settings[0].departmentId,
			settings[0].settlementTypeId,
			settings[0].dateBegin,
			settings[0].dateEnd
		)
	);
	let saidiSaifiS = dispatch(
		getSaidiSaifiData(
			settings[1].departmentId,
			settings[1].settlementTypeId,
			settings[1].dateBegin,
			settings[1].dateEnd
		)
	);
	let maifiM = dispatch(
		getMaifiData(settings[0].departmentId, settings[0].settlementTypeId, settings[0].dateBegin, settings[0].dateEnd)
	);
	let maifiS = dispatch(
		getMaifiData(settings[1].departmentId, settings[1].settlementTypeId, settings[1].dateBegin, settings[1].dateEnd)
	);
	let ensM = dispatch(
		getEnsData(settings[0].departmentId, settings[0].settlementTypeId, settings[0].dateBegin, settings[0].dateEnd)
	);
	let ensS = dispatch(
		getEnsData(settings[1].departmentId, settings[1].settlementTypeId, settings[1].dateBegin, settings[1].dateEnd)
	);
	Promise.all([saidiSaifiM, saidiSaifiS, maifiM, maifiS, ensM, ensS]);
};
