import {
	SET_COMMERCIAL_COUNTERPARTIES_COUNT,
	SET_COMMERCIAL_COUNTERPARTIES_NAMES,
	SET_COUNTERPARTIES_BY_SMART_METER,
	SET_COUNTERPARTIES_NOTIFICATIONS_HISTORY_DATA,
	SET_CURRENT_SHUTDOWN_STATUS,
	SET_ELECTRIC_ADDRES_BY_COUNTERPARTY_PERSONAL_ACCOUNT,
	SET_ELECTRIC_NODES,
	SET_MAP_DATA_BY_SHUTDOWN_FORECAST,
	SET_MAP_DATA_BY_SHUTDOWN_LINES,
	SET_MAP_DATA_BY_SHUTDOWN_PS,
	SET_RECIPIENT_NOTIFICATION_HISTORY_DATA,
	SET_RESIDENTIAL_COUNTERPARTIES_COUNT,
	SET_SELECTED_SHUTDOWN,
	SET_SHUTDOWNS_DATA,
	SET_SHUTDOWN_BY_ID,
	SET_SHUTDOWN_STATUS_CHANGE_FLAG,
	SET_START_SHUTDOWN_STATUS,
	SET_START_SHUTDOWN_TYPE,
} from "../types";
import { hideLoader, setApiLastError, setSuccess, setWarning, showLoader } from "./appActions";

import { dictionariAPI } from "../../api/dictionaryEndpoints";
import { notificationAPI } from "../../api/notificationEndpoints";
import { shutdownAPI } from "../../api/shutdownEndpoints";
import { shutdownsGetParameters } from "../../api/parameters";
import { thunkErrorHandler } from "./actionHandlers";

//For Shutdowns
//Setters

export const setStartShutdownStatus = startShutdownStatus => {
	return {
		type: SET_START_SHUTDOWN_STATUS,
		payload: startShutdownStatus,
	};
};

export const setCurrentShutdownStatus = currentShutdownStatus => {
	return {
		type: SET_CURRENT_SHUTDOWN_STATUS,
		payload: currentShutdownStatus,
	};
};

export const setStartShutdownType = startShutdownType => {
	return {
		type: SET_START_SHUTDOWN_TYPE,
		payload: startShutdownType,
	};
};

export const setElectricNodes = electricNodes => {
	return {
		type: SET_ELECTRIC_NODES,
		payload: electricNodes,
	};
};

export const setResidentialCounterpartiesCount = counterpartiesCount => {
	return {
		type: SET_RESIDENTIAL_COUNTERPARTIES_COUNT,
		payload: counterpartiesCount,
	};
};

export const setCommercialCounterpartiesCount = counterpartiesCount => {
	return {
		type: SET_COMMERCIAL_COUNTERPARTIES_COUNT,
		payload: counterpartiesCount,
	};
};

export const setSelectedShutdown = shutdown => {
	return {
		type: SET_SELECTED_SHUTDOWN,
		payload: shutdown,
	};
}; //For delete shutdown

export const setRecipientsNotificationsHistory = notificationHistoryData => {
	return {
		type: SET_RECIPIENT_NOTIFICATION_HISTORY_DATA,
		payload: notificationHistoryData,
	};
};

export const setCounterpartiesNotificationsHistory = counterpartiesNotificationHistoryData => {
	return {
		type: SET_COUNTERPARTIES_NOTIFICATIONS_HISTORY_DATA,
		payload: counterpartiesNotificationHistoryData,
	};
};

export const setShutdownStatusChangeFlag = statusChangeFlag => {
	return {
		type: SET_SHUTDOWN_STATUS_CHANGE_FLAG,
		payload: statusChangeFlag,
	};
};

export const setCommercialCounterpartiesNames = commercialCounterpartiesNames => {
	return {
		type: SET_COMMERCIAL_COUNTERPARTIES_NAMES,
		payload: commercialCounterpartiesNames,
	};
};

export const setMapDataByShutdownLines = mapDataByShutdownLines => {
	return {
		type: SET_MAP_DATA_BY_SHUTDOWN_LINES,
		payload: mapDataByShutdownLines,
	};
};

export const setMapDataByShutdownPs = mapDataByShutdownPs => {
	return {
		type: SET_MAP_DATA_BY_SHUTDOWN_PS,
		payload: mapDataByShutdownPs,
	};
};

export const setMapDataByShutdownForecast = mapDataByShutdownForecast => {
	return {
		type: SET_MAP_DATA_BY_SHUTDOWN_FORECAST,
		payload: mapDataByShutdownForecast,
	};
};

export const setCounterpartiesBySmartMerer = counterpartiesBySmartMerer => {
	return {
		type: SET_COUNTERPARTIES_BY_SMART_METER,
		payload: counterpartiesBySmartMerer,
	};
};

//Getters
export const getElectricNodes = res => async dispatch => {
	try {
		dispatch(showLoader());
		const response = await dictionariAPI.getElectricNodes(res); //All RES param = '' (default)
		if (response.status === 200) {
			const electricNodes = response.data;
			dispatch(setElectricNodes(electricNodes));
		}
		dispatch(hideLoader());
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getResidentialCounterpartiesCount = id => async dispatch => {
	try {
		const response = await shutdownAPI.getResidentialCounterpartiesCount(id);
		if (response.status === 200) {
			dispatch(setResidentialCounterpartiesCount(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getCommercialCounterpartiesCount = id => async dispatch => {
	try {
		const response = await shutdownAPI.getCommercialCounterpartiesCount(id);
		if (response.status === 200) {
			dispatch(setCommercialCounterpartiesCount(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getCommercialCounterpartiesNames = id => async dispatch => {
	try {
		const response = await shutdownAPI.getCommercialCounterpartiesNames(id);
		if (response.status === 200) {
			dispatch(setCommercialCounterpartiesNames(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getShutdowns = shutdownsGetParameters => async dispatch => {
	try {
		const response = await shutdownAPI.getShutdowns(shutdownsGetParameters);
		if (response.status === 200) {
			dispatch(setShutdownsData(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
export const setShutdownsData = shutdowns => {
	return {
		type: SET_SHUTDOWNS_DATA,
		payload: shutdowns,
	};
};

export const getShutdownById = shutdownId => async dispatch => {
	try {
		const response = await shutdownAPI.getShutdownById(shutdownId);
		if (response.status === 200) {
			const result = response.data.data?.shift(0);
			dispatch(setShutdownById(result));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
export const setShutdownById = shutdown => {
	return {
		type: SET_SHUTDOWN_BY_ID,
		payload: shutdown,
	};
};

export const getShutdown = id => async dispatch => {
	try {
		const response = await shutdownAPI.getShutdown(id);
		if (response.status === 200) {
			dispatch(setSelectedShutdown(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const createRecipientNotification = recipientNotificationPostParameters => async dispatch => {
	try {
		const response = await notificationAPI.createRecipientNotification(recipientNotificationPostParameters);
		if (response.status === 200) {
			dispatch(setSuccess("Оповіщення персоналу відправлено!"));
			dispatch(setSuccess(null));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const createCounterpartyNotification = counterpartyNotificationPostParameters => async dispatch => {
	try {
		const response = await notificationAPI.createCounterpartyNotification(counterpartyNotificationPostParameters);
		if (response.status === 200) {
			dispatch(setSuccess("Оповіщення контрагентів відправлено!"));
			dispatch(setSuccess(null));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getShutdownsRecipientsNotificationsHistory = id => async dispatch => {
	//debugger
	try {
		const response = await notificationAPI.getShutdownsRecipientsNotificationsHistory(id);
		if (response.status === 200) {
			dispatch(setRecipientsNotificationsHistory(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getCounterpartiesNotificationsHistory = id => async dispatch => {
	try {
		const response = await notificationAPI.getCounterpartiesNotificationsHistory(id);
		if (response.status === 200) {
			dispatch(setCounterpartiesNotificationsHistory(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getElectricAddressByCounterpartyPersonalAccount = counterpartyPersonalAccount => async dispatch => {
	try {
		const response = await shutdownAPI.getElectricAddressByCounterpartyPersonalAccount(counterpartyPersonalAccount);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setElectricAddressByCounterpartyPersonalAccount(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
export const setElectricAddressByCounterpartyPersonalAccount = electricAddressByCounterpartyPersonalAccount => {
	return {
		type: SET_ELECTRIC_ADDRES_BY_COUNTERPARTY_PERSONAL_ACCOUNT,
		payload: electricAddressByCounterpartyPersonalAccount,
	};
};

export const addShutdown = shutdownPostParameters => async dispatch => {
	try {
		dispatch(showLoader());
		const response = await shutdownAPI.addShutdown(shutdownPostParameters);
		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));
		if (response.status === 201) {
			dispatch(setSuccess("Відключення успішно додано!"));
			dispatch(setSuccess(null));
			dispatch(setApiLastError({ isErrorExists: false, errorObject: null }));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	} finally {
		dispatch(hideLoader());
	}
};

export const editShutdown = shutdownsPostParameters => async dispatch => {
	try {
		dispatch(showLoader());
		const response = await shutdownAPI.editShutdown(shutdownsPostParameters);
		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));
		if (response.status === 202) {
			dispatch(setSuccess("Відключення успішно відредаговано!"));
			dispatch(setSuccess(null));
			dispatch(setApiLastError({ isErrorExists: false, errorObject: null }));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	} finally {
		dispatch(hideLoader());
	}
};

export const deleteShutdown = (id, tableFilter) => async dispatch => {
	try {
		dispatch(showLoader());
		const response = await shutdownAPI.deleteShutdown(id);
		if (response.status === 204) {
			//shutdownsGetParameters.filter = tableFilter;
			dispatch(getShutdowns(shutdownsGetParameters));
			dispatch(setSuccess("Відключення успішно видалено!"));
			dispatch(setSuccess(null));
		}
		dispatch(hideLoader());
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const updateBulk = shutdowns => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await shutdownAPI.updateBulk(shutdowns);
		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));
		if (response.status >= 200 && response.status < 300) {
			if (response.data.length) {
				dispatch(setApiLastError(response.data)); //For group action errors Drawer
				dispatch(setWarning("Відключення відредаговані з помилками!"));
				dispatch(setWarning(null));
			} else {
				dispatch(setSuccess("Відключення успішно відредаговані!"));
				dispatch(setSuccess(null));
				dispatch(setApiLastError({ isErrorExists: false, errorObject: null }));
			}
			dispatch(getShutdowns(shutdownsGetParameters));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const getMapDataByShutdownLines = departmentId => async dispatch => {
	try {
		const response = await shutdownAPI.getMapDataByShutdownLines(departmentId);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setMapDataByShutdownLines(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getMapDataByShutdownPs = departmentId => async dispatch => {
	try {
		const response = await shutdownAPI.getMapDataByShutdownPs(departmentId);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setMapDataByShutdownPs(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getMapDataByShutdownForecast = departmentId => async dispatch => {
	try {
		const response = await shutdownAPI.getMapDataByShutdownForecast(departmentId);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setMapDataByShutdownForecast(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getCounterpartiesBySmartMerer = electricNodeId => async dispatch => {
	try {
		const response = await shutdownAPI.getCounterpartiesBySmartMerer(electricNodeId);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setCounterpartiesBySmartMerer(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
