import { apiClient } from "./api";
import axios from "axios";

//Appeal endpoints
export const appealAPI = {
	getAppealPaidServices(appealGetParameters) {
		return apiClient(axios)
			.post(`/Appeal/GetAllPaidServices`, { ...appealGetParameters })
			.then(response => response);
	},
	getAppeals(appealGetParameters) {
		return apiClient(axios)
			.post(`/Appeal/GetAll`, { ...appealGetParameters })
			.then(response => response);
	},
	addAppeal(appealPostParameters) {
		return apiClient(axios)
			.post("/Appeal", { ...appealPostParameters })
			.then(response => response);
	},
	getAppeal(id) {
		return apiClient(axios)
			.get(`/Appeal/${id}`)
			.then(response => response);
	},
	editAppeal(appealPostParameters) {
		return apiClient(axios)
			.put(`/Appeal/${appealPostParameters.id}`, { ...appealPostParameters })
			.then(response => response);
	},
	deleteAppeal(id) {
		return apiClient(axios)
			.delete(`/Appeal/${id}`)
			.then(response => response);
	},
	getFilesList(appealId) {
		return apiClient(axios)
			.get(`/Appeal/GetFilesList/${appealId}`)
			.then(response => response);
	},
	getFileAsync(appealFileId) {
		return apiClient(axios)
			.get(`/Appeal/GetFileAsync/${appealFileId}`, { responseType: "blob" })
			.then(response => response);
	},
	addFilesAsync(appealId, formData) {
		return apiClient(axios)
			.post(`/Appeal/AddFilesAsync/${appealId}`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => response);
	},
	deleteFileAsync(appealFileId) {
		return apiClient(axios)
			.delete(`/Appeal/DeleteFileAsync/${appealFileId}`)
			.then(response => response);
	},

	getBillFilesList(appealId) {
		return apiClient(axios)
			.get(`/Appeal/GetBillFilesList/${appealId}`)
			.then(response => response);
	},
	getBillFileAsync(appealBillFileId) {
		return apiClient(axios)
			.get(`/Appeal/GetBillFileAsync/${appealBillFileId}`, { responseType: "blob" })
			.then(response => response);
	},
	addBillFilesAsync(appealId, formData) {
		return apiClient(axios)
			.post(`/Appeal/AddBillFilesAsync/${appealId}`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => response);
	},
	deleteBillFileAsync(appealBillFileId) {
		return apiClient(axios)
			.delete(`/Appeal/DeleteBillFileAsync/${appealBillFileId}`)
			.then(response => response);
	},

	getAppealById(appealId) {
		return apiClient(axios)
			.post(`/Appeal/GetAll`, { filter: `id=${appealId}` })
			.then(response => response);
	},

	getAppealPaidServiceById(appealId) {
		return apiClient(axios)
			.post(`/Appeal/GetAllPaidServices`, { filter: `id=${appealId}` })
			.then(response => response);
	},

	getMapDataByAppeals(departmentId) {
		return apiClient(axios)
			.get(`/Appeal/GetMapDataByAppeals/${departmentId ? departmentId : ""}`)
			.then(response => response);
	},

	getNotReadAppealsCount() {
		return apiClient(axios)
			.get(`/Appeal/GetNotReadAppealsCount`)
			.then(response => response);
	},
	updateBulk(appeals) {
		return apiClient(axios)
			.post(`/Appeal/UpdateBulk`, appeals)
			.then(response => response);
	},
};
