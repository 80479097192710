import { apiClient } from "./api";
import axios from "axios";

export const taskAPI = {
	getTasks(tasksGetParameters) {
		return apiClient(axios)
			.post(`/Task/GetAll`, { ...tasksGetParameters })
			.then(response => response);
	},

	addTask(tasksPostParameters) {
		return apiClient(axios)
			.post("/Task", { ...tasksPostParameters })
			.then(response => response);
	},

	editTask(taskId, tasksPostParameters) {
		return apiClient(axios)
			.put(`/Task/${taskId}`, { ...tasksPostParameters })
			.then(response => response);
	},

	deleteTask(taskId) {
		return apiClient(axios)
			.delete(`/Task/${taskId}`)
			.then(response => response);
	},

	getTaskExecutionLog(tasksId) {
		return apiClient(axios)
			.get(`/Task/GetTaskExecutionLog/${tasksId}`)
			.then(response => response);
	},

	getTask(taskId) {
		return apiClient(axios)
			.get(`/Task/${taskId}`)
			.then(response => response);
	},

	getTaskById(taskId) {
		return apiClient(axios)
			.post(`/Task/GetAll`, { filter: `id=${taskId}` })
			.then(response => response);
	},
};
