import axios from "axios";
import { apiClient } from "./api";

//UserProfile endpoints
export const userProfileAPI = {
	changeUser(userProfileData) {
		return apiClient(axios)
			.put(`/User/ChangeUser`, { ...userProfileData })
			.then(response => response);
	},
};
