import {
	Button,
	Card,
	Col,
	DatePicker,
	Divider,
	Drawer,
	Popover,
	Row,
	Spin,
	Table,
	Tag,
	Tooltip,
	Typography,
} from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { NavLink, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { defaultPageSize, paginationPosition, tableButtonsSize, tableSize } from "../../assets/js/options";
import { emergencyShutdownStatusEnum, plannedShutdownStatusEnum, shutdownTypeEnum } from "./enums/shutdownEnums";
import {
	getShutdowns,
	setCurrentShutdownStatus,
	setShutdownStatusChangeFlag,
	setStartShutdownType,
	updateBulk,
} from "../../redux/actions/shutdownActions";
import { setPageBreadcrumb, setTableFilter } from "../../redux/actions/appActions";
import { shutdownJobTypesGetParameters, shutdownsGetParameters } from "../../api/parameters";
import { useDispatch, useSelector } from "react-redux";

import ClearOutlined from "@ant-design/icons/es/icons/ClearOutlined";
import EyeOutlined from "@ant-design/icons/es/icons/EyeOutlined";
import FilterFilled from "@ant-design/icons/es/icons/FilterFilled";
import GroupActionsModal from "./GroupActionsModal/GroupActionsModal";
import ModalForDeleteShutdown from "./ModalForDeleteShutdown/ModalForDeleteShutdown";
import ViewShutdownDrawer from "./ViewShutdownDrawer/ViewShutdownDrawer";
import { convertStringToSimpleType } from "../../assets/js/helpers/convertStringToSimpleType";
import css from "./Shutdowns.module.scss";
import { dictionaryTypeEnum } from "../../dictionaries/models/dictionaryTypeEnum";
import { getShutdownJobTypes } from "../../redux/actions/adminActions";
import moment from "moment";
import { scrollAntTableToTop } from "../../assets/js/helpers/scrollAntTableToTop";
import { setMenuSelectedKeys } from "../Layout/helpers/menuSelectedKeysHelper";
import { showTotal } from "../../assets/js/helpers/showTotal";
import { tableFilterStringBuilder } from "../../assets/js/helpers/tableFilterStringBuilder";
import { tableSortBuilder } from "../../assets/js/helpers/tableSortBuilder";
import useDictionary from "../../dictionaries/useDictionaries";

const Shutdowns = () => {
	const { shutdownTypeId } = useParams();
	const shutdownTypeIdParam = convertStringToSimpleType(shutdownTypeId);

	const dispatch = useDispatch();
	const dateFormat = "YYYY-MM-DD HH:mm";

	const departments = useDictionary(dictionaryTypeEnum.DEPARTMENTS);
	const shutdownStatuses = useDictionary(dictionaryTypeEnum.SHUTDOWN_STATUSES);
	const branches = useDictionary(dictionaryTypeEnum.BRANCHES);
	const userDepartmentId = JSON.parse(useSelector(state => state.auth.departmentId));
	const shutdownJobTypes = useSelector(state => state.admin.shutdownJobTypes.data);
	const userRole = useSelector(state => state.auth.userRole);
	const shutdowns = useSelector(state => state.shutdown.shutdowns.data);
	const shutdownTotalItems = useSelector(state => state.shutdown.shutdowns.count);
	const groupActionErrors = useSelector(state => state.app.apiLastError);
	const isLoading = useSelector(state => state.app.isLoading);

	const isAdminOr25 = userRole.includes("Administrator") || Number(userDepartmentId) === 25;

	const [selectedShutdownId, setSelectedShutdownId] = useState(null);
	const [viewModalVisible, setViewModalVisible] = useState(false);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

	const [cleared, setCleared] = useState(true);
	const [sortersInfo, setSortersInfo] = useState(null);
	const [filtersInfo, setFiltersInfo] = useState(null);
	const [paginationInfo, setPaginationInfo] = useState(null);

	//For table filter rangepicker
	const { RangePicker } = DatePicker;
	const [startDateRagePickerFilter, setStartDateRagePickerFilter] = useState(null);
	const [isStartDateChanged, setIsStartDateChanged] = useState(false);

	//For selected table rows
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);
	const [closeButtonDisabled, setCloseButtonDisabled] = useState(false);
	const [groupActionModalVisible, setGroupActionModalVisible] = useState(false);
	const [groupActionType, setGroupActionType] = useState("");
	const [errorDrawerOpen, setErrorDrawerOpen] = useState(false);

	const groupActionsContent = (
		<div className={css.group_operations_content}>
			{selectedRows.length > 1 ? (
				<div>
					<Button
						className={css.group_actions_button}
						disabled={confirmButtonDisabled}
						type='default'
						onClick={() => {
							setGroupActionModalVisible(true);
							setGroupActionType("confirm");
						}}
					>
						Підтвердження
					</Button>
					<Button
						className={css.group_actions_button}
						disabled={closeButtonDisabled}
						type='default'
						onClick={() => {
							setGroupActionModalVisible(true);
							setGroupActionType("close");
						}}
					>
						Закриття
					</Button>
				</div>
			) : (
				<div style={{ color: "red" }}>Не обрано жодного відключення, або кількість обраних недостатня!</div>
			)}
		</div>
	);

	const getColumnFilterProps = () => {
		return {
			filterDropdown: ({ confirm }) => (
				<RangePicker
					value={startDateRagePickerFilter ? startDateRagePickerFilter : ""}
					onChange={value => {
						setStartDateRagePickerFilter(value);
						setIsStartDateChanged(true);
						confirm();
					}}
				/>
			),
			filterIcon: filtered => <FilterFilled style={{ color: startDateRagePickerFilter ? "#1890ff" : undefined }} />,
		};
	};

	const getShutdownsInitial = useCallback(() => {
		shutdownsGetParameters.page = 1;
		shutdownsGetParameters.pageSize = defaultPageSize;
		shutdownsGetParameters.orderBy = null;
		shutdownsGetParameters.filter = "shutdownTypeId = " + shutdownTypeId; // filter by type
		dispatch(getShutdowns(shutdownsGetParameters));
	}, [dispatch, shutdownTypeId]);

	const columns = [
		{
			title: "№",
			dataIndex: "id",
			key: "id",
			sorter: true,
			sortOrder: sortersInfo ? sortersInfo.columnKey === "id" && sortersInfo.order : null,
			filteredValue: null,
			width: 80,
		},
		{
			title: "Статус",
			dataIndex: "shutdownStatusName",
			key: "shutdownStatusName",
			sorter: true,
			sortOrder: sortersInfo ? sortersInfo.columnKey === "shutdownStatusName" && sortersInfo.order : null,
			width: 140,
			filters: shutdownStatuses
				?.filter(x => x.shutdownTypeId === shutdownTypeIdParam)
				?.map(item => {
					return {
						text: item.name,
						value: item.id,
					};
				}),
			filteredValue: filtersInfo ? filtersInfo.shutdownStatusName || null : null,
		},
		{
			title: "Підрозділ",
			dataIndex: "departmentName",
			key: "departmentName",
			sorter: true,
			sortOrder: sortersInfo ? sortersInfo.columnKey === "departmentName" && sortersInfo.order : null,
			width: 180,
			ellipsis: true,
			filters: departments?.map(item => {
				return {
					text: item.name,
					value: item.id,
				};
			}),
			filteredValue: filtersInfo ? filtersInfo.departmentName || null : null,
		},
		{
			title: "Відділення",
			dataIndex: "branchName",
			key: "branchName",
			sorter: true,
			sortOrder: sortersInfo ? sortersInfo.columnKey === "branchName" && sortersInfo.order : null,
			width: 180,
			ellipsis: true,
			filters: branches
				?.filter(b => (isAdminOr25 ? b : b.departmentId === userDepartmentId))
				.map(item => {
					return {
						text: item.name,
						value: item.id,
					};
				}),
			filteredValue: filtersInfo ? filtersInfo.branchName || null : null,
		},
		{
			title: "Дата початку",
			dataIndex: "startDate",
			key: "startDate",
			sorter: true,
			sortOrder: sortersInfo ? sortersInfo.columnKey === "startDate" && sortersInfo.order : null,
			filteredValue: null,
			width: 95,
			...getColumnFilterProps("startDate"),
		},
		{
			title: "Дата завер-ня",
			dataIndex: "endDate",
			key: "endDate",
			sorter: true,
			sortOrder: sortersInfo ? sortersInfo.columnKey === "endDate" && sortersInfo.order : null,
			filteredValue: null,
			width: 95,
		},
		{
			title: "Електрична адреса",
			dataIndex: "electricAddress",
			key: "electricAddress",
			filteredValue: null,
			width: 420,
			render: text => (
				<Typography.Paragraph
					ellipsis={{ rows: 3, expandable: true, symbol: <div style={{ fontSize: "11px" }}>більше</div> }}
				>
					{text}
				</Typography.Paragraph>
			),
		},
		{
			title: "Вид робіт",
			dataIndex: "shutdownJobTypeName",
			key: "shutdownJobTypeName",
			sorter: true,
			sortOrder: sortersInfo ? sortersInfo.columnKey === "shutdownJobTypeName" && sortersInfo.order : null,
			width: 250,
			ellipsis: true,
			filters: shutdownJobTypes?.map(item => {
				return {
					text: item.name,
					value: item.id,
				};
			}),
			filteredValue: filtersInfo ? filtersInfo.shutdownJobTypeName || null : null,
		},
		// {
		// 	title: "Призвідник вимкнення",
		// 	dataIndex: "shutdownDriverName",
		// 	key: "shutdownDriverName",
		// 	sorter: true,
		// 	filteredValue: null,
		// 	width: 300,
		// },
		{
			title: "Дії",
			dataIndex: "actions",
			key: "actions",
			align: "left",
			filteredValue: null,
			width: 140,
		},
	];

	const onShutdownTableChange = (pagination, filters, sorter) => {
		setFiltersInfo(filters);
		setSortersInfo(sorter);
		setPaginationInfo(pagination);
		scrollAntTableToTop();
	};

	const onClearFilters = () => {
		dispatch(setTableFilter(null)); //Global state for to remember filter params (reset for new change)
		setSortersInfo(null);
		setFiltersInfo(null);
		setCleared(false);
		setStartDateRagePickerFilter(null);
		setPaginationInfo(null);
		scrollAntTableToTop();
	};

	const setStatusTagColor = status => {
		let color = "default";
		if (status === 1) color = "blue";
		if (status === 2) color = "green";
		if (status === 3) color = "orange";
		if (status === 4) color = "red";
		if (status === 5) color = "default";
		if (status === 6) color = "red";
		if (status === 7) color = "green";
		if (status === 8) color = "default";
		return color;
	};

	//View shutdown
	const onViewShutdown = id => {
		setSelectedShutdownId(id);
		setViewModalVisible(true);
	};

	//Delete shutdown
	const onDeletePlannedShutdown = id => {
		setSelectedShutdownId(id);
		setIsDeleteModalVisible(true);
	};

	//buttonType:
	//0 - create,
	//1 - edit,
	//2 - delete
	const isButtonDisabled = (buttonType, shutdown) => {
		if (userRole.includes("Administrator")) return false;

		if (buttonType === 0) {
			if (userRole.includes("DepartmentDispatcher")) return false;
		} else if (buttonType === 1) {
			if (
				userRole.includes("DepartmentDispatcher") &&
				!(
					(shutdownTypeIdParam === shutdownTypeEnum.EMERGENCY &&
						shutdown.shutdownStatusId === emergencyShutdownStatusEnum.CLOSED) ||
					(shutdownTypeIdParam === shutdownTypeEnum.PLANNED &&
						(shutdown.shutdownStatusId === plannedShutdownStatusEnum.DISCARD ||
							shutdown.shutdownStatusId === plannedShutdownStatusEnum.CLOSED))
				)
			)
				return false;
		} else if (
			buttonType === 2 &&
			userRole.includes("DepartmentDispatcher") &&
			shutdown.shutdownStatusId === plannedShutdownStatusEnum.PROJECT
		)
			return false;

		return true;
	};

	const getPlannedShutdownDataForList = () => {
		const moment = require("moment");
		if (shutdowns) {
			return shutdowns.map(sd => {
				const isDisabledEditPermission = isButtonDisabled(1, sd);
				return {
					key: sd.id,
					id: sd.id,
					shutdownStatusName: (
						<NavLink
							disabled={isDisabledEditPermission}
							to={{
								pathname: !isDisabledEditPermission
									? `/shutdowns/edit-shutdown/${shutdownTypeId}/${sd.id}`
									: "",
							}}
						>
							<Tag style={{ minWidth: 115, textAlign: "center" }} color={setStatusTagColor(sd.shutdownStatusId)}>
								{sd.shutdownStatusName}
							</Tag>
						</NavLink>
					),

					departmentName: sd.departmentName,
					branchName: sd.branchName,
					startDate: moment(sd.startDate).format(dateFormat),
					endDate: moment(sd.endDate).format(dateFormat),
					electricAddress: sd.electricAddress,
					shutdownJobTypeName: sd.shutdownJobTypeName || "невідомо",
					shutdownDriverName: sd.shutdownDriverName,
					actions: (
						<>
							<span className={css.table_action_btn}>
								<Tooltip placement='topLeft' title='Перегляд відключення' mouseEnterDelay={0.7}>
									<Button size={tableButtonsSize} onClick={() => onViewShutdown(sd.id)}>
										<EyeOutlined />
									</Button>
								</Tooltip>
							</span>
							{isDisabledEditPermission ? null : (
								<span className={css.table_action_btn}>
									<NavLink
										to={{
											pathname: `/shutdowns/edit-shutdown/${shutdownTypeId}/${sd.id}`,
										}}
									>
										<Tooltip placement='topLeft' title='Редагування відключення' mouseEnterDelay={0.7}>
											<Button size={tableButtonsSize}>
												<EditOutlined />
											</Button>
										</Tooltip>
									</NavLink>
								</span>
							)}
							{isButtonDisabled(2, sd) ? null : (
								<span className={css.table_action_btn}>
									<Tooltip placement='topLeft' title='Видалення відключення' mouseEnterDelay={0.7}>
										<Button size={tableButtonsSize} danger onClick={() => onDeletePlannedShutdown(sd.id)}>
											<DeleteOutlined />
										</Button>
									</Tooltip>
								</span>
							)}
						</>
					),
				};
			});
		}
	};

	//Get className for table row + overdue shutdown check (+ className if needed)
	const onOverdueShutdownCheck = (record, index) => {
		const moment = require("moment");
		const dateTimeNow = moment().format(dateFormat);
		const thisShutdown = shutdowns.find(item => item.id === record.id);
		//Red row if over endDate (only for "In Work" status)
		let overdueShutdownClassName = "";
		if (
			(thisShutdown.shutdownTypeId === shutdownTypeEnum.PLANNED &&
				dateTimeNow > thisShutdown.endDate &&
				thisShutdown.shutdownStatusId === plannedShutdownStatusEnum.ACTIVE) ||
			(thisShutdown.shutdownTypeId === shutdownTypeEnum.EMERGENCY &&
				dateTimeNow > thisShutdown.endDate &&
				thisShutdown.shutdownStatusId === emergencyShutdownStatusEnum.CONFIRMED)
		) {
			overdueShutdownClassName = `${css.attention_row}`;
		}

		//For dark and light rows
		if (index % 2 === 0) {
			return `${css.table_row_light} ${overdueShutdownClassName}`;
		} else {
			return `${css.table_row_dark} ${overdueShutdownClassName}`;
		}
	};

	// rowSelection object indicates the need for row selection
	const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
		setSelectedRowKeys(newSelectedRowKeys);
		setSelectedRows(newSelectedRows);

		//Shutdowns status check
		const selectedShutdowns = shutdowns.filter(x => x.id === newSelectedRowKeys.find(y => y === x.id));
		let selectedShutdownStatuses = selectedShutdowns.map(x => x.shutdownStatusId);
		selectedShutdownStatuses = [...new Set(selectedShutdownStatuses)];
		if (
			selectedShutdownStatuses.length > 1 ||
			selectedShutdownStatuses.includes(emergencyShutdownStatusEnum.CLOSED)
		) {
			//console.log('All actions blocked');
			setConfirmButtonDisabled(true);
			setCloseButtonDisabled(true);
		} else if (
			selectedShutdownStatuses.length === 1 &&
			selectedShutdownStatuses[0] === emergencyShutdownStatusEnum.UNCONFIRMED
		) {
			//console.log('Activate only "Confirm" button');
			setConfirmButtonDisabled(false);
			setCloseButtonDisabled(true);
		} else if (
			selectedShutdownStatuses.length === 1 &&
			selectedShutdownStatuses[0] === emergencyShutdownStatusEnum.CONFIRMED
		) {
			//console.log('Activate "Close" button');
			setConfirmButtonDisabled(true);
			setCloseButtonDisabled(false);
		}
	};

	const tableRowSelection = {
		selectedRowKeys,
		selectedRows,
		onChange: onSelectChange,
	};

	const onGroupModalSave = modalValues => {
		if (!modalValues) return;

		let shutdownsForGroupChange = [];
		selectedRowKeys.forEach(x => {
			let sd = shutdowns.find(item => item.id === x);
			shutdownsForGroupChange.push({ ...sd });
		});

		//To confirm
		if (groupActionType === "confirm") {
			shutdownsForGroupChange.forEach(x => {
				x.shutdownStatusId = emergencyShutdownStatusEnum.CONFIRMED;
				x.shutdownDriverId = modalValues.shutdownDriverId;
				x.shutdownJobTypeId = modalValues.shutdownJobTypeId;
				x.startDate = modalValues.waitingStartDate ? moment(modalValues.waitingStartDate[0]).format() : x.startDate;
				x.endDate = modalValues.waitingEndDate ? moment(modalValues.waitingEndDate[0]).format() : x.endDate;
			});
		}

		//To close
		if (groupActionType === "close") {
			shutdownsForGroupChange.forEach(x => {
				x.shutdownStatusId = emergencyShutdownStatusEnum.CLOSED;
				x.factStartDate = moment(modalValues.realStartDate[0]).format();
				x.factEndDate = moment(modalValues.realEndDate[0]).format();
			});
		}

		dispatch(updateBulk(shutdownsForGroupChange));
		onGroupModalCancel();
	};

	const onGroupModalCancel = () => {
		setGroupActionModalVisible(false);
		setSelectedRowKeys([]);
		setSelectedRows([]);
		setGroupActionType("");
	};

	//Breadcrumbs, startShutdownType, controlResDisabled, some roles control
	useEffect(() => {
		if (!shutdownTypeIdParam) return;

		const setStartShutdownTypeLocal = async () => await dispatch(setStartShutdownType(shutdownTypeIdParam));

		dispatch(setShutdownStatusChangeFlag(false)); //Reset editing if it was before
		const breadCrumbData = [
			{ name: "Відключення", link: false },
			{
				name: shutdownTypeIdParam === shutdownTypeEnum.PLANNED ? "Планові відключення" : "Аварійні відключення",
				link: false,
			},
		];

		dispatch(setPageBreadcrumb(breadCrumbData));
		setStartShutdownTypeLocal();

		setFiltersInfo(null);
		setSortersInfo(null);
		setPaginationInfo(null);
		setStartDateRagePickerFilter(undefined);

		getShutdownsInitial();
		setMenuSelectedKeys([`5_${shutdownTypeIdParam}`]);
	}, [dispatch, getShutdownsInitial, shutdownTypeIdParam]);

	//Get SLs
	useEffect(() => {
		dispatch(getShutdownJobTypes(shutdownJobTypesGetParameters));
		dispatch(setCurrentShutdownStatus(undefined));
	}, [dispatch]);

	useEffect(() => {
		if (cleared) return;
		getShutdownsInitial();
		setCleared(true);
	}, [dispatch, getShutdownsInitial, cleared]);

	//Filter string creating with startDate filter datapicker and dispatching
	useEffect(() => {
		if ((!startDateRagePickerFilter && !filtersInfo && !sortersInfo && !paginationInfo) || !shutdownTypeId) return;

		//Filtering
		const tableFilterNamesArr = ["shutdownStatusName", "departmentName", "branchName", "shutdownJobTypeName"];
		const fieldNamesInDBArr = ["shutdownStatusId", "departmentId", "branchId", "shutdownJobTypeId"];
		let filter = tableFilterStringBuilder(filtersInfo, tableFilterNamesArr, fieldNamesInDBArr);

		//Sorting
		tableSortBuilder(shutdownsGetParameters, paginationInfo, sortersInfo);

		let startDateFilterString;
		if (startDateRagePickerFilter) {
			startDateFilterString = `startDate >=${startDateRagePickerFilter[0].format(
				"YYYY-MM-DD"
			)}, startDate <=${startDateRagePickerFilter[1].format("YYYY-MM-DD")}T23:59:59`;
		}

		if (isStartDateChanged) {
			//sets page = 1 if we change rangePicker
			shutdownsGetParameters.page = 1;
			setIsStartDateChanged(false);
		}

		let searchStr;
		if (!startDateFilterString && !filter) {
			searchStr = "";
		} else if (!startDateFilterString) {
			searchStr = filter;
		} else if (!filter) {
			searchStr = startDateFilterString;
		} else searchStr = `${filter},(${startDateFilterString})`;

		shutdownsGetParameters.filter = "shutdownTypeId = " + shutdownTypeId + (searchStr ? `,(${searchStr})` : "");
		dispatch(getShutdowns(shutdownsGetParameters));
	}, [
		dispatch,
		shutdownTypeId,
		startDateRagePickerFilter,
		filtersInfo,
		sortersInfo,
		paginationInfo,
		isStartDateChanged,
	]);

	return (
		<Spin spinning={isLoading} size='large'>
			<Row className={css.shutdowns}>
				{(userRole.includes("Administrator") || userRole.includes("DepartmentDispatcher")) && (
					<Col span={12}>
						<NavLink
							to={{
								pathname: `/shutdowns/create-shutdown/${shutdownTypeId}`,
							}}
						>
							<Button hidden={isButtonDisabled(0, null)} className={css.site_button} type='primary'>
								<PlusOutlined />
								{shutdownTypeIdParam === shutdownTypeEnum.PLANNED
									? "Створити планове відключення"
									: "Створити аварійне відключення"}
							</Button>
						</NavLink>

						{shutdownTypeIdParam === shutdownTypeEnum.EMERGENCY && (
							<>
								<Popover content={groupActionsContent} title='Групові операції:'>
									{selectedRows.length >= 2 && (
										<Button className={css.site_button} type='primary'>
											Групові операції ({selectedRows.length})
										</Button>
									)}
								</Popover>
								{groupActionErrors.length && (
									<Button
										style={{ margin: "0 0 15px 0" }}
										type='primary'
										danger
										disabled={!groupActionErrors.length}
										onClick={() => setErrorDrawerOpen(true)}
									>
										{`Помилки (${groupActionErrors.length})`}
									</Button>
								)}
							</>
						)}
					</Col>
				)}
				<Col
					span={userRole.includes("Administrator") || userRole.includes("DepartmentDispatcher") ? 12 : 24}
					className={css.clear_filters_btn_wrap}
				>
					<Button type='default' className={css.filter_button} onClick={onClearFilters}>
						<ClearOutlined />
						Скинути всі фільтри
					</Button>
				</Col>
				<Card bodyStyle={{ padding: "0px" }} bordered={true} style={{ width: "100%" }}>
					<Table
						onChange={onShutdownTableChange}
						className={css.ant_table_wrapper}
						size={tableSize}
						rowSelection={
							shutdownTypeIdParam === shutdownTypeEnum.EMERGENCY && {
								...tableRowSelection,
							}
						}
						pagination={{
							position: paginationPosition,
							defaultPageSize: defaultPageSize,
							showSizeChanger: true,
							total: shutdownTotalItems,
							current: shutdownsGetParameters.page,
							showTotal: showTotal,
						}}
						//scroll={shutdownsTableScroll}
						showSorterTooltip={false}
						columns={columns}
						dataSource={getPlannedShutdownDataForList()}
						rowClassName={(record, index) => onOverdueShutdownCheck(record, index)}
					/>
				</Card>

				<ViewShutdownDrawer
					open={viewModalVisible}
					onClose={() => setViewModalVisible(false)}
					shutdownId={selectedShutdownId}
				/>

				<ModalForDeleteShutdown
					open={isDeleteModalVisible}
					onCancel={() => setIsDeleteModalVisible(false)}
					shutdownId={selectedShutdownId}
				/>

				<GroupActionsModal
					showModal={groupActionModalVisible}
					onCancel={onGroupModalCancel}
					groupActionType={groupActionType}
					selectedRowsCount={selectedRows.length}
					onSave={onGroupModalSave}
				/>

				<Drawer
					title={`Помилки при групових операціях (${groupActionErrors.length})`}
					headerStyle={{ backgroundColor: "orange", borderRadius: "0px" }}
					drawerStyle={{ border: `2px solid orange`, backgroundColor: "#FFEDBD" }}
					placement='left'
					onClose={() => setErrorDrawerOpen(false)}
					open={errorDrawerOpen}
				>
					{groupActionErrors.length
						? groupActionErrors.map((e, i) => (
								<>
									<p key={e.id}>{`${i + 1}) Аварійне відключення №${e.id}, помилка: "${e.message}."`}</p>
									<Divider />
								</>
						  ))
						: ""}
				</Drawer>
			</Row>
		</Spin>
	);
};

export default Shutdowns;
