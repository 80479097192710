import { hideLoader, setApiLastError, showLoader } from "./appActions";

import { SET_SMART_DATA } from "../types";
import { smartAPI } from "../../api/smartEndpoints";
import { thunkErrorHandler } from "./actionHandlers";

export const getSmartData = params => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await smartAPI.getSmartData(params);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setSmartData(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const setSmartData = smartData => {
	return {
		type: SET_SMART_DATA,
		payload: smartData,
	};
};
