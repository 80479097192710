import {
	SET_COMMERCIAL_COUNTERPARTIES_COUNT,
	SET_COMMERCIAL_COUNTERPARTIES_NAMES,
	SET_COUNTERPARTIES_BY_SMART_METER,
	SET_COUNTERPARTIES_NOTIFICATIONS_HISTORY_DATA,
	SET_CURRENT_SHUTDOWN_STATUS,
	SET_ELECTRIC_ADDRES_BY_COUNTERPARTY_PERSONAL_ACCOUNT,
	SET_ELECTRIC_NODES,
	SET_MAP_DATA_BY_SHUTDOWN_FORECAST,
	SET_MAP_DATA_BY_SHUTDOWN_LINES,
	SET_MAP_DATA_BY_SHUTDOWN_PS,
	SET_RECIPIENT_NOTIFICATION_HISTORY_DATA,
	SET_RESIDENTIAL_COUNTERPARTIES_COUNT,
	SET_SELECTED_SHUTDOWN,
	SET_SHUTDOWNS_DATA,
	SET_SHUTDOWN_BY_ID,
	SET_SHUTDOWN_STATUS_CHANGE_FLAG,
	SET_START_SHUTDOWN_STATUS,
	SET_START_SHUTDOWN_TYPE,
} from "../types";

const initialState = {
	notificationHistoryData: [],
	counterpartiesNotificationsHistoryData: [],
	electricNodes: [],
	residentialCounterpartiesCount: 0,
	commercialCounterpartiesCount: 0,
	startShutdownStatus: 1,
	currentShutdownStatus: null,
	startShutdownType: 0,
	shutdowns: [],
	selectedShutdown: {},
	statusChangeFlag: false, //Flag for watching if shutdown status was changed (for reset editing)
	commercialCounterpartiesNames: [],
	shutdownById: undefined,
	electricAddressByCounterpartyPersonalAccount: undefined,
	mapDataByShutdownLines: [],
	mapDataByShutdownPs: [],
	mapDataByShutdownForecast: [],
	counterpartiesBySmartMerer: [],
};

export const shutdownReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_RECIPIENT_NOTIFICATION_HISTORY_DATA:
			return { ...state, notificationHistoryData: action.payload };
		case SET_COUNTERPARTIES_NOTIFICATIONS_HISTORY_DATA:
			return {
				...state,
				counterpartiesNotificationsHistoryData: action.payload,
			};
		case SET_ELECTRIC_NODES:
			return { ...state, electricNodes: action.payload };
		case SET_RESIDENTIAL_COUNTERPARTIES_COUNT:
			return { ...state, residentialCounterpartiesCount: action.payload };
		case SET_COMMERCIAL_COUNTERPARTIES_COUNT:
			return { ...state, commercialCounterpartiesCount: action.payload };
		case SET_START_SHUTDOWN_STATUS:
			return { ...state, startShutdownStatus: action.payload };
		case SET_CURRENT_SHUTDOWN_STATUS:
			return { ...state, currentShutdownStatus: action.payload };
		case SET_START_SHUTDOWN_TYPE:
			return { ...state, startShutdownType: action.payload };
		case SET_SHUTDOWNS_DATA:
			return { ...state, shutdowns: action.payload };
		case SET_SELECTED_SHUTDOWN:
			return { ...state, selectedShutdown: action.payload };
		case SET_SHUTDOWN_STATUS_CHANGE_FLAG:
			return { ...state, statusChangeFlag: action.payload };
		case SET_COMMERCIAL_COUNTERPARTIES_NAMES:
			return { ...state, commercialCounterpartiesNames: action.payload };
		case SET_SHUTDOWN_BY_ID:
			return { ...state, shutdownById: action.payload };
		case SET_ELECTRIC_ADDRES_BY_COUNTERPARTY_PERSONAL_ACCOUNT:
			return {
				...state,
				electricAddressByCounterpartyPersonalAccount: action.payload,
			};
		case SET_MAP_DATA_BY_SHUTDOWN_LINES:
			return { ...state, mapDataByShutdownLines: action.payload };
		case SET_MAP_DATA_BY_SHUTDOWN_PS:
			return { ...state, mapDataByShutdownPs: action.payload };
		case SET_MAP_DATA_BY_SHUTDOWN_FORECAST:
			return { ...state, mapDataByShutdownForecast: action.payload };
		case SET_COUNTERPARTIES_BY_SMART_METER:
			return { ...state, counterpartiesBySmartMerer: action.payload };
		default:
			return state;
	}
};
