import { Button, Result } from "antd";
import React, { useEffect } from "react";

import css from "./NotFound.module.scss";
import logo from "../../assets/images/underConstruction/Logo_525.png";
import { setPageBreadcrumb } from "../../redux/actions/appActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const NotFound = ({ text }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		const breadCrumbData = [{ name: text, link: false }];
		dispatch(setPageBreadcrumb(breadCrumbData));
	}, [dispatch, text]);

	return (
		<Result
			className={css.not_found_container}
			icon={<img src={logo} alt='logo' />}
			//status='404'
			title='404'
			subTitle='Вибачте, такої сторінки не їснує.'
			extra={
				<>
					<Button className={css.site_button} onClick={() => navigate("/")}>
						Повернутися на головну
					</Button>
					<Button onClick={() => navigate(-1)}>Назад</Button>
				</>
			}
		/>
	);
};

export default NotFound;
