import { hideLoader, setApiLastError, showLoader } from "./appActions";

import { SET_SCADA_EVENTS } from "../types";
import { scadaEventsAPI } from "../../api/scadaEventsEndpoints";
import { thunkErrorHandler } from "./actionHandlers";

export const getScadaEvents = params => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await scadaEventsAPI.getScadaEvents(params);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setScadaEvents(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const setScadaEvents = scadaEvents => {
	return {
		type: SET_SCADA_EVENTS,
		payload: scadaEvents,
	};
};
