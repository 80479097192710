import {
	APP_INIT,
	HIDE_LOADER,
	SET_API_HEALTH,
	SET_API_LAST_ERROR,
	SET_APP_ENVIRONMENT,
	SET_APP_VERSION,
	SET_PAGE_BREADCRUMB,
	SET_TABLE_FILTER,
	SHOW_ERROR,
	SHOW_LOADER,
	SHOW_SUCCESS,
	SHOW_WARNING,
} from "../types";

import { appAPI } from "../../api/appEndpoints";
import { authAPI } from "../../api/authEndpoints";
import { getAuthData } from "./loginActions";
import { thunkErrorHandler } from "./actionHandlers";

//For APP
export const appInit = () => dispatch => {
	let authData = dispatch(getAuthData());
	Promise.all([authData]).then(() => {
		dispatch(setAppInit(true));
	});
};

export const getApiHealth = () => async dispatch => {
	try {
		const response = await authAPI.getApiHealth();
		if (response.status === 200) {
			dispatch(setIsApiHealthy(true));
		}
	} catch (error) {
		if (error.response.status === 401) {
			dispatch(setIsApiHealthy(false));
		}
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getAppVersion = () => async dispatch => {
	try {
		const response = await appAPI.getAppVersion();
		if (response.status === 200) {
			dispatch(setAppVersion(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getAppEnvironment = () => async dispatch => {
	try {
		const response = await appAPI.getAppEnvironment();
		if (response.status === 200) {
			dispatch(setAppEnvironment(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

//For all Pages
//Setters
export const setPageBreadcrumb = pageTitle => {
	return {
		type: SET_PAGE_BREADCRUMB,
		payload: pageTitle,
	};
};

export const showLoader = () => {
	return { type: SHOW_LOADER };
};

export const hideLoader = () => {
	return { type: HIDE_LOADER };
};

export const setAppInit = value => {
	return {
		type: APP_INIT,
		payload: value,
	};
};

export const setIsApiHealthy = apiHealthy => {
	return {
		type: SET_API_HEALTH,
		payload: apiHealthy,
	};
};

export const setAppVersion = appVersion => {
	return {
		type: SET_APP_VERSION,
		payload: appVersion,
	};
};

export const setAppEnvironment = appEnvironment => {
	return {
		type: SET_APP_ENVIRONMENT,
		payload: appEnvironment,
	};
};

export const setError = error => {
	return {
		type: SHOW_ERROR,
		payload: error,
	};
};

export const setSuccess = success => {
	return {
		type: SHOW_SUCCESS,
		payload: success,
	};
};

export const setWarning = warning => {
	return {
		type: SHOW_WARNING,
		payload: warning,
	};
};

export const setApiLastError = lastError => {
	return {
		type: SET_API_LAST_ERROR,
		payload: lastError,
	};
};

//Filter parameters in state to remember parameters if delete any from tables
export const setTableFilter = tableFilter => {
	return {
		type: SET_TABLE_FILTER,
		payload: tableFilter,
	};
};
