//STATUS TAB STYLE BUILDER
//PARAMS
    //targetValue - value of active radio button from radio group
    //classListArr - default styles array (useState) for tabs (exampe exists)
    //stylesSetStateFn - function for setting style array changes in state (useState)
    //cssModule - css file(module) where current component styles exists (exampe exists)

export const createStatusTabStyleList = (targetValue, defaultStatusTabStyles, cssModule) => {
    
    return defaultStatusTabStyles.map((item, i) => {
        i = i + 1;
        if (targetValue === i) {
            item.body = `${cssModule.status_item} ${cssModule[`item_${i}`]} ${cssModule[`item_${i}_active`]}`;
            item.text = `${cssModule.radio_status_text} ${cssModule[`label_${i}_active`]}`
        } else {
            item.body = `${cssModule.status_item} ${cssModule[`item_${i}`]}`;
            item.text = `${cssModule.radio_status_text}`
        }

        return item
    });
};


//Class list example
// const [statusTabStyles, setStatusTabStyles] = useState([
//     {body: `${css.status_item} ${css.item_1}`, text: `${css.radio_status_text}`},
//     {body: `${css.status_item} ${css.item_2}`, text: `${css.radio_status_text}`},
//     {body: `${css.status_item} ${css.item_3}`, text: `${css.radio_status_text}`},
// ]);

// //Css(file) module examle
// .statuses {
//     width: 100%;
//
// .status_item {
//         min-width: 260px; //Or calc(25% - 4px)
//         background-color: $box_border_color;
//         margin: 0 2px;
//         height: 65px;
//         border-radius: 5px 5px 0 0;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         text-transform: uppercase;
//     }
//
// .status_item_E {
//         width: calc(33.333% - 4px);
//     }
//
//
// .radio_status_text {
//         position: relative;
//         display: inline-block;
//         top: 5px;
//         font-size: 24px;
//         margin-top: -5px;
//     }
//
// .item_1 {border-bottom: 6px solid #c64333;}
//
// .item_2 {border-bottom: 6px solid #00acd7;}
//
// .item_3 {border-bottom: 6px solid #828282;}
//
// .item_1_active {background-color: #dd4b39; font-weight: bold;}
//
// .item_2_active {background-color: #00c0ef; font-weight: bold;}
//
// .item_3_active {background-color: #bdbdbd; font-weight: bold;}
//
// .label_1_active, .label_2_active, .label_3_active {
//         color: #fff;
//     }
// }

//Tab component example
/*<Form.Item name="appealStatusId">*/
/*    <Radio.Group className={css.statuses} onChange={onStatusChange}>*/
/*        <Row>*/
/*            <Col className={statusTabStyles[0].body + ' ' + css.status_item_E}>*/
/*                <Radio value={1}><span className={statusTabStyles[0].text}>Нове</span></Radio>*/
/*            </Col>*/
/*            <Col className={statusTabStyles[1].body + ' ' + css.status_item_E}>*/
/*                <Radio disabled={!editMode} value={2}><span className={statusTabStyles[1].text}>В роботі</span></Radio>*/
/*            </Col>*/
/*            <Col className={statusTabStyles[2].body + ' ' + css.status_item_E}>*/
/*                <Radio disabled={!editMode} value={3}><span className={statusTabStyles[2].text}>Закрите</span></Radio>*/
/*            </Col>*/
/*        </Row>*/
/*    </Radio.Group>*/
/*</Form.Item>*/