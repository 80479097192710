import { Button, Checkbox, DatePicker, Descriptions, Form, Select, Space, Spin, Table, TreeSelect } from "antd";
import React, { useEffect } from "react";
import { defaultPageSize, paginationPosition, tableSize } from "../../../assets/js/options";
import { getElectricNodes, setElectricNodes } from "../../../redux/actions/shutdownActions";
import { getRemShutdownsEfficiency, getRemShutdownsEfficiencyXlsx } from "../../../redux/actions/reportsActions";
import { useDispatch, useSelector } from "react-redux";

import ClearOutlined from "@ant-design/icons/es/icons/ClearOutlined";
import DatabaseOutlined from "@ant-design/icons/es/icons/DatabaseOutlined";
import FileExcelOutlined from "@ant-design/icons/es/icons/FileExcelOutlined";
import css from "./DepartmentEfficiency.module.scss";
import { dictionaryTypeEnum } from "../../../dictionaries/models/dictionaryTypeEnum";
import { getShutdownJobTypes } from "../../../redux/actions/adminActions";
import locale from "antd/es/date-picker/locale/uk_UA";
import { minutesToHours } from "../../../assets/js/helpers/minutesToHours";
import moment from "moment";
import { setPageBreadcrumb } from "../../../redux/actions/appActions";
import { showTotal } from "../../../assets/js/helpers/showTotal";
import { shutdownJobTypesGetParameters } from "../../../api/parameters";
import { shutdownTypeEnum } from "../../Shutdowns/enums/shutdownEnums";
import { useCallback } from "react";
import useDictionary from "../../../dictionaries/useDictionaries";
import { useState } from "react";

const DepartmentEfficiency = ({ text }) => {
	const [form] = Form.useForm();
	const { Option } = Select;
	const { RangePicker } = DatePicker;
	const { SHOW_PARENT } = TreeSelect;
	const rangePickerDateFormat = "YYYY-MM-DD";
	const tableDateFormat = "YYYY-MM-DD HH:mm";

	const dispatch = useDispatch();

	const currentUser = useSelector(state => state.auth);
	const departments = useDictionary(dictionaryTypeEnum.DEPARTMENTS);
	const branches = useDictionary(dictionaryTypeEnum.BRANCHES);
	const treeData = useSelector(state => state.shutdown.electricNodes);
	const shutdownJobTypes = useSelector(state => state.admin.shutdownJobTypes.data);
	const shutdownTypes = useDictionary(dictionaryTypeEnum.SHUTDOWN_TYPES);
	const shutdownDrivers = useDictionary(dictionaryTypeEnum.SHUTDOWN_DRIVERS);
	const remShutdowns = useSelector(state => state.reports.remShutdownsEfficiency?.remShutdowns);
	const remShutdownsStatistic = useSelector(state => state.reports.remShutdownsEfficiency?.remShutdownsStatistic);
	const isLoading = useSelector(state => state.app.isLoading);

	const [branchesFiltered, setBranchesFiltered] = useState([]);
	const departmentIdWatch = Form.useWatch("departmentId", form);

	const [departmentEfficiencyRequestParams, setDepartmentEfficiencyRequestParams] = useState({
		departmentId: JSON.parse(currentUser.departmentId) || departments[0]?.id,
		branchId: null,
		startDate: moment().add(-30, "days").format(rangePickerDateFormat),
		endDate: moment().format(rangePickerDateFormat),
		shutdownTypeId: null,
		electricNodeIds: [],
		shutdownJobTypeIds: [],
		shutdownDriverIds: [],
		showPlannedShutdownWithoutWarning: false,
	});

	const remShutdownsColumns = [
		{
			title: "Підрозділ",
			dataIndex: "departmentName",
			key: "departmentName",
			sorter: (a, b) => a.departmentName.localeCompare(b.departmentName),
		},
		{
			title: "Відділення",
			dataIndex: "branchName",
			key: "branchName",
			sorter: (a, b) => a.branchName?.localeCompare(b.branchName),
		},
		{
			title: "Електрична адреса",
			dataIndex: "electricAddress",
			key: "electricAddress",
			sorter: (a, b) => a.electricAddress.localeCompare(b.electricAddress),
		},
		{
			title: "Тип відключення",
			dataIndex: "shutdownTypeName",
			key: "shutdownTypeName",
			sorter: (a, b) => a.shutdownTypeName.localeCompare(b.shutdownTypeName),
			align: "center",
			width: 200,
		},
		{
			title: "Вид робіт",
			dataIndex: "shutdownJobTypeName",
			key: "shutdownJobTypeName",
			sorter: (a, b) => a.shutdownJobTypeName.localeCompare(b.shutdownJobTypeName),
			width: 200,
		},
		{
			title: "Призвідник вимкнення",
			dataIndex: "shutdownDriverName",
			key: "shutdownDriverName",
			width: 200,
			sorter: (a, b) => a.shutdownDriverName?.localeCompare(b.shutdownDriverName),
		},
		{
			title: "Дата створення",
			dataIndex: "created",
			key: "created",
			width: 90,
			sorter: (a, b) => moment(a.endDate) - moment(b.endDate),
		},
		{
			title: "Початок",
			dataIndex: "factStartDate",
			key: "factStartDate",
			width: 90,
			sorter: (a, b) => moment(a.endDate) - moment(b.endDate),
		},
		{
			title: "Завершення",
			dataIndex: "factEndDate",
			key: "factEndDate",
			width: 90,
			sorter: (a, b) => moment(a.endDate) - moment(b.endDate),
		},
		{
			title: "Тривалість",
			dataIndex: "durationInMinutes",
			key: "durationInMinutes",
			sorter: (a, b) => a.durationInMinutes - b.durationInMinutes,
			render: text => <>{minutesToHours(text)}</>,
			align: "center",
			width: 60,
		},
	];

	const descriptionsItems = [
		{
			key: "1",
			label: "Загальна кількість відключень",
			children: remShutdownsStatistic?.shutdownsCount || "0",
		},
		{
			key: "2",
			label: "Мінімальний час відключення",
			children: minutesToHours(remShutdownsStatistic?.minimalDurationInMinutes),
		},
		{
			key: "3",
			label: "Максимальний час відключення",
			children: minutesToHours(remShutdownsStatistic?.maximalDurationInMinutes),
		},
		{
			key: "4",
			label: "Середній час відключення",
			children: minutesToHours(remShutdownsStatistic?.averageDurationInMinutes),
		},
	];

	const remShutdownsTableData = useCallback(() => {
		if (remShutdowns) {
			return remShutdowns.map((x, i) => {
				return {
					key: i,
					departmentName: x.departmentName,
					branchName: x.branchName,
					electricAddress: x.electricAddress,
					shutdownTypeName: x.shutdownTypeName,
					shutdownJobTypeName: x.shutdownJobTypeName,
					shutdownDriverName: x.shutdownDriverName,
					created: moment(x.created).format(tableDateFormat),
					factStartDate: moment(x.factStartDate).format(tableDateFormat),
					factEndDate: moment(x.factEndDate).format(tableDateFormat),
					durationInMinutes: x.durationInMinutes,
					hasShutdownMaxDuration: x.hasShutdownMaxDuration,
				};
			});
		}
	}, [remShutdowns]);

	const onShutdownMaxDurationCheck = (record, index) => {
		//Red row if ShutdownMaxDuration is maximum"
		const shutdownMaxDurationClassName = record?.hasShutdownMaxDuration ? `${css.attention_row}` : "";

		//For dark and light table rows
		return index % 2 === 0
			? `${css.table_row_light} ${shutdownMaxDurationClassName}`
			: `${css.table_row_dark} ${shutdownMaxDurationClassName}`;
	};

	const onDepartmentChange = value => {
		form.setFieldsValue({ electricAddress: undefined });
		setDepartmentEfficiencyRequestParams(prev => ({ ...prev, departmentId: value }));
		if (value) dispatch(getElectricNodes(value));
		else dispatch(setElectricNodes(null));
	};

	const onPeriodChange = value => {
		if (!value) return;
		setDepartmentEfficiencyRequestParams(prev => ({
			...prev,
			startDate: value[0].format(rangePickerDateFormat),
			endDate: value[1].format(rangePickerDateFormat),
		}));
	};

	const onElectricAddressChange = value => {
		setDepartmentEfficiencyRequestParams(prev => ({ ...prev, electricNodeIds: value }));
	};

	const onShutdownJobTypeChange = value => {
		setDepartmentEfficiencyRequestParams(prev => ({ ...prev, shutdownJobTypeIds: value || null }));
	};

	const onShutdownTypeChange = value => {
		form.setFieldsValue({ showPlannedShutdownWithoutWarning: false });
		setDepartmentEfficiencyRequestParams(prev => ({ ...prev, shutdownTypeId: value || null }));
	};

	const onShowPlannedShutdownWithoutWarningChange = e => {
		setDepartmentEfficiencyRequestParams(prev => ({
			...prev,
			showPlannedShutdownWithoutWarning: e.target.checked || false,
		}));
	};

	const onShutdownDriverChange = value => {
		setDepartmentEfficiencyRequestParams(prev => ({ ...prev, shutdownDriverIds: value || null }));
	};

	const onBranchChange = value => {
		setDepartmentEfficiencyRequestParams(prev => ({ ...prev, branchId: value || null }));
	};

	const filterTreeNode = (input, treeNode) => {
		return treeNode.title.toLowerCase().includes(input.toLowerCase());
	};

	const onReportCreate = () => {
		dispatch(getRemShutdownsEfficiency(departmentEfficiencyRequestParams));
	};

	const onXlsxFileCreate = () => {
		dispatch(getRemShutdownsEfficiencyXlsx(departmentEfficiencyRequestParams));
	};

	const onClearFields = () => {
		form.resetFields();
		form.setFieldsValue({ period: [moment().add(-30, "days"), moment()] });
		setDepartmentEfficiencyRequestParams({
			departmentId: JSON.parse(currentUser.departmentId) || departments[0]?.id,
			branchId: null,
			startDate: moment().add(-30, "days"),
			endDate: moment(),
			shutdownTypeId: 1,
			electricNodeIds: [],
			shutdownJobTypeIds: [],
			shutdownDriverIds: [],
			showPlannedShutdownWithoutWarning: false,
		});
	};

	useEffect(() => {
		const breadCrumbData = [{ name: text, link: false }];
		form.setFieldsValue({ period: [moment().add(-30, "days"), moment()] });
		dispatch(setPageBreadcrumb(breadCrumbData));
		dispatch(getShutdownJobTypes(shutdownJobTypesGetParameters));
	}, [dispatch, form, text]);

	useEffect(() => {
		const departmentId = JSON.parse(currentUser.departmentId) || departments[0]?.id;
		form.setFieldsValue({ departmentId: departmentId });

		if (departmentId) dispatch(getElectricNodes(departmentId));
	}, [currentUser.departmentId, departments, dispatch, form]);

	useEffect(() => {
		setBranchesFiltered(branches?.filter(x => x.departmentId === departmentIdWatch));
	}, [branches, departmentIdWatch]);

	return (
		<Form form={form} className={css.department_efficiency_container}>
			<Space className={css.filter} size={[8, 16]} wrap>
				<Form.Item
					className={css.no_margin}
					name='departmentId'
					label='Підрозділ'
					rules={[{ required: true, message: "Будь ласка оберіть підрозділ!" }]}
				>
					<Select
						className={css.department_select}
						placeholder='Оберіть підрозділ'
						popupMatchSelectWidth={false}
						onChange={onDepartmentChange}
						value={departmentEfficiencyRequestParams.departmentId}
						disabled={
							!(currentUser.userRole.includes("Administrator") || Number(currentUser.departmentId) === 25)
						}
					>
						{departments.map(item => (
							<Option key={item.id} value={item.id}>
								{item.name}
							</Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item className={css.no_margin} name='branchId' label='Відділення'>
					<Select
						className={css.department_select}
						placeholder='Оберіть відділення'
						popupMatchSelectWidth={false}
						onChange={onBranchChange}
						allowClear={true}
						value={departmentEfficiencyRequestParams.branchId}
					>
						{branchesFiltered.map(item => (
							<Option key={item.id} value={item.id}>
								{item.name}
							</Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item
					className={css.no_margin}
					name='period'
					label='Період'
					rules={[{ required: true, message: "Будь ласка оберіть період!" }]}
				>
					<RangePicker
						locale={locale}
						allowClear={true}
						showToday={true}
						format={rangePickerDateFormat}
						onChange={value => onPeriodChange(value)}
					/>
				</Form.Item>

				<Form.Item className={css.no_margin} name='electricAddress' label='Електрична адреса'>
					<TreeSelect
						className={css.tree_select}
						required={true}
						style={{ width: "100%" }}
						treeData={treeData}
						treeDefaultExpandedKeys={[null]}
						placeholder='Оберіть електричну адресу'
						treeDataSimpleMode={{
							id: "electricNodeId",
							pId: "electricNodeParentId",
							rootPId: 0,
							key: "electricNodeId",
						}}
						treeNodeFilterProp='title'
						treeNodeLabelProp='title'
						showSearch={true}
						allowClear
						listHeight={400}
						popupMatchSelectWidth={500}
						treeCheckable={true}
						showCheckedStrategy={SHOW_PARENT}
						value={departmentEfficiencyRequestParams.electricNodeIds}
						onChange={value => onElectricAddressChange(value)}
						filterTreeNode={filterTreeNode}
						maxTagCount={1}
					/>
				</Form.Item>

				<Form.Item
					className={css.no_margin}
					name='shutdownJobType'
					label='Вид робіт'
					rules={[{ required: false, message: "Будь ласка оберіть вид робіт!" }]}
				>
					<Select
						style={{ minWidth: 200 }}
						mode='multiple'
						maxTagCount={1}
						placeholder='Оберіть вид робіт'
						popupMatchSelectWidth={false}
						allowClear={true}
						onChange={value => onShutdownJobTypeChange(value)}
					>
						{shutdownJobTypes &&
							shutdownJobTypes.map(type => (
								<Option key={type.id} value={type.id}>
									{type.name}
								</Option>
							))}
					</Select>
				</Form.Item>

				<Form.Item
					style={{ minWidth: "200px !important" }}
					className={css.no_margin}
					name='shutdownType'
					label='Тип відключення'
					rules={[{ required: false, message: "Будь ласка оберіть тип відключення!" }]}
				>
					<Select
						placeholder='Оберіть тип відключення'
						popupMatchSelectWidth={false}
						onChange={value => onShutdownTypeChange(value)}
						allowClear
					>
						{shutdownTypes &&
							shutdownTypes.map(type => (
								<Option key={type.id} value={type.id}>
									{type.name}
								</Option>
							))}
					</Select>
				</Form.Item>

				<Form.Item
					className={css.no_margin}
					name='shutdownDriver'
					label='Призвідник вимкнення'
					rules={[{ required: false, message: "Будь ласка оберіть призвідника вимкнення!" }]}
				>
					<Select
						style={{ minWidth: 200 }}
						mode='multiple'
						maxTagCount={1}
						placeholder='Оберіть призвідника вимкнення'
						popupMatchSelectWidth={false}
						onChange={value => onShutdownDriverChange(value)}
						allowClear
					>
						{shutdownDrivers &&
							shutdownDrivers.map(d => (
								<Option key={d.id} value={d.id}>
									{d.name}
								</Option>
							))}
					</Select>
				</Form.Item>

				{departmentEfficiencyRequestParams.shutdownTypeId === shutdownTypeEnum.PLANNED && (
					<Form.Item name='showPlannedShutdownWithoutWarning' valuePropName='checked' style={{ marginBottom: 0 }}>
						<Checkbox onChange={e => onShowPlannedShutdownWithoutWarningChange(e)}>без попередження</Checkbox>
					</Form.Item>
				)}

				<Form.Item className={css.no_margin}>
					<Button
						style={{ marginRight: 0 }}
						className={css.site_button}
						type='primary'
						htmlType='submit'
						icon={<DatabaseOutlined />}
						onClick={onReportCreate}
						loading={!!isLoading}
					>
						Сформувати
					</Button>
				</Form.Item>

				<Form.Item className={css.no_margin}>
					<Button
						className={css.site_button}
						type='primary'
						icon={<FileExcelOutlined />}
						onClick={onXlsxFileCreate}
						loading={!!isLoading}
					>
						XLSX
					</Button>
				</Form.Item>

				<Form.Item className={css.no_margin}>
					<Button type='default' className={css.filter_button} onClick={onClearFields}>
						<ClearOutlined />
						Скинути всі фільтри
					</Button>
				</Form.Item>
			</Space>

			{
				<Spin spinning={isLoading} tip={"Завантаження"}>
					<Descriptions
						items={descriptionsItems}
						column={4}
						bordered
						size='small'
						style={{ marginBottom: "10px" }}
					/>
					<Table
						className={css.ant_table_wrapper}
						size={tableSize}
						pagination={{
							position: paginationPosition,
							defaultPageSize: defaultPageSize,
							showSizeChanger: true,
							total: remShutdowns && remShutdowns.length ? remShutdowns.length : 0,
							showTotal: showTotal,
						}}
						showSorterTooltip={false}
						columns={remShutdownsColumns}
						bordered
						dataSource={remShutdownsTableData()}
						rowClassName={(record, index) => onShutdownMaxDurationCheck(record, index)}
					/>
				</Spin>
			}
		</Form>
	);
};

export default DepartmentEfficiency;
