import { Button, Card, Col, DatePicker, Input, Row, Table, Tag, Tooltip, Typography } from "antd";
import {
	ClearOutlined,
	DeleteOutlined,
	EditOutlined,
	EyeOutlined,
	FileDoneOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { claimApprovalLevelEnum, claimStatusEnum } from "./enums/claimEnums";
import { defaultPageSize, paginationPosition, tableButtonsSize, tableSize } from "../../assets/js/options";
import { generateClaimDocument, getClaims } from "../../redux/actions/claimActions";
import { useDispatch, useSelector } from "react-redux";

import DeleteClaimModal from "./DeleteClaimModal/DeleteClaimModal";
import FilterFilled from "@ant-design/icons/es/icons/FilterFilled";
import { NavLink } from "react-router-dom";
import ViewClaimDrawer from "./ViewClaimDrawer/ViewClaimDrawer";
import { claimGetParameters } from "../../api/parameters";
import css from "./Claims.module.scss";
import { dictionaryTypeEnum } from "../../dictionaries/models/dictionaryTypeEnum";
import { scrollAntTableToTop } from "../../assets/js/helpers/scrollAntTableToTop";
import { setClaimStatusTagColor } from "../Tasks/helpers/otherTaskFns";
import { setPageBreadcrumb } from "../../redux/actions/appActions";
import { showTotal } from "../../assets/js/helpers/showTotal";
import { tableFilterStringBuilder } from "../../assets/js/helpers/tableFilterStringBuilder";
import { tableSortBuilder } from "../../assets/js/helpers/tableSortBuilder";
import useDebounce from "../../assets/js/hooks/useDebounce";
import useDictionary from "../../dictionaries/useDictionaries";
import { useNavigate } from "react-router-dom/dist";

const Claims = ({ text }) => {
	const dateFormat = "YYYY-MM-DD HH:mm";
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { RangePicker } = DatePicker;

	//Selectors
	const userRole = useSelector(state => state.auth.userRole);
	const userDepartmentId = useSelector(state => state.auth.departmentId);
	const userDepartmentHierarchyId = useSelector(state => state.auth.departmentHierarchyId);
	const claims = useSelector(state => state.claim.claims.data);
	const claimTotalItems = useSelector(state => state.claim.claims.count);
	//For table filters, sorters and searchers
	const claimStatuses = useDictionary(dictionaryTypeEnum.CLAIM_STATUSES);
	const claimTypes = useDictionary(dictionaryTypeEnum.CLAIM_TYPES);
	const claimApprovalDefaults = useDictionary(dictionaryTypeEnum.CLAIM_APPROVAL_DEFAULTS);
	const claimEmergencyReadiness = useDictionary(dictionaryTypeEnum.CLAIM_EMERGENCY_READINESS);

	//Local states
	const [sorteredInfo, setSorteredInfo] = useState(null);
	const [filteredInfo, setFilteredInfo] = useState(null);
	const [paginationInfo, setPaginationInfo] = useState(null);
	const [startDateRagePickerFilter, setStartDateRagePickerFilter] = useState(null);
	const [rdcRegistrationNumberSearch, setRdcRegistrationNumberSearch] = useState("");
	const [claimGetParametersLocal, setClaimGetParametersLocal] = useState({ ...claimGetParameters });
	const [selectedClaimId, setSelectedClaimId] = useState(null);
	const [viewModalVisible, setViewModalVisible] = useState(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);

	const debouncedRdcRegistrationNumber = useDebounce(rdcRegistrationNumberSearch, 400);

	const isOdsEditingEnabledRef = useRef(undefined);

	const claimTableColumns = [
		{
			title: "№",
			dataIndex: "id",
			key: "id",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "id" && sorteredInfo.order : null,
			filteredValue: null,
			width: 60,
		},
		{
			title: "Статус",
			dataIndex: "claimStatusName",
			key: "claimStatusName",
			align: "center",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "claimStatusName" && sorteredInfo.order : null,
			filters:
				claimStatuses &&
				claimStatuses.map(item => {
					return {
						text: item.name,
						value: item.id,
					};
				}),
			filteredValue: filteredInfo ? filteredInfo.claimStatusName : null, //|| ["1", "2", "3"] : ["1", "2", "3"],
			width: 80,
		},
		{
			title: "Служба",
			dataIndex: "createdByDepartmentHierarchyName",
			key: "createdByDepartmentHierarchyName",
			sorter: true,
			sortOrder: sorteredInfo
				? sorteredInfo.columnKey === "createdByDepartmentHierarchyName" && sorteredInfo.order
				: null,
			width: 180,
			filters: claimApprovalDefaults
				.filter(x => x.claimApprovalLevelId === claimApprovalLevelEnum.INITIATOR)
				.map(item => {
					return {
						text: item.departmentHierarchyName,
						value: item.departmentHierarchyId,
					};
				}),
			filteredValue: filteredInfo ? filteredInfo.createdByDepartmentHierarchyName || null : null,
		},
		{
			title: "Вид заявки",
			dataIndex: "claimTypeName",
			key: "claimTypeName",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "claimTypeName" && sorteredInfo.order : null,
			width: 100,
			filters: claimTypes.map(item => {
				return {
					text: item.name,
					value: item.id,
				};
			}),
			filteredValue: filteredInfo ? filteredInfo.claimTypeName || null : null,
		},
		{
			title: "Авар. готов, год",
			dataIndex: "claimEmergencyReadinessValue",
			key: "claimEmergencyReadinessValue",
			sorter: true,
			width: 120,
			sortOrder: sorteredInfo
				? sorteredInfo.columnKey === "claimEmergencyReadinessValue" && sorteredInfo.order
				: null,
			filters: claimEmergencyReadiness.map(item => {
				return {
					text: item.value,
					value: item.id,
				};
			}),
			filteredValue: filteredInfo ? filteredInfo.claimEmergencyReadinessValue || null : null,
			align: "center",
		},
		{
			title: "Дата початку",
			dataIndex: "startDate",
			key: "startDate",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "startDate" && sorteredInfo.order : null,
			width: 95,
			...getEventDateColumnFilterProps("eventDate"),
			filteredValue: null,
		},
		{
			title: "Дата заверш.",
			dataIndex: "endDate",
			key: "endDate",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "endDate" && sorteredInfo.order : null,
			filteredValue: null,
			width: 95,
		},
		{
			title: "Назва електроустановки",
			dataIndex: "electricAddress",
			key: "electricAddress",
			sorter: false,
			filteredValue: null,
			render: text => (
				<Typography.Paragraph
					ellipsis={{ rows: 4, expandable: true, symbol: <div style={{ fontSize: "11px" }}>більше</div> }}
				>
					{text}
				</Typography.Paragraph>
			),
		},
		{
			title: "Мета відключення",
			dataIndex: "shutdownPurpose",
			key: "shutdownPurpose",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "shutdownPurpose" && sorteredInfo.order : null,
			filteredValue: null,
			width: 320,
		},
		{
			title: (
				<Input
					value={rdcRegistrationNumberSearch}
					placeholder='Рдц'
					style={{ fontSize: "12px" }}
					onChange={e => {
						onTableColumnSearch(e.target.value, "rdcRegistrationNumber");
					}}
				/>
			),
			dataIndex: "rdcRegistrationNumber",
			key: "rdcRegistrationNumber",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "rdcRegistrationNumber" && sorteredInfo.order : null,
			filteredValue: null,
			width: 90,
		},
		{
			title: "Дії",
			dataIndex: "actions",
			key: "actions",
			align: "left",
			filteredValue: null,
			width: 90,
		},
	];

	//Search in inputs header table with delay
	const onTableColumnSearch = (value, column) => {
		if (column === "rdcRegistrationNumber") {
			setRdcRegistrationNumberSearch(value);
		}
	};

	function getEventDateColumnFilterProps() {
		return {
			filterDropdown: ({ confirm }) => (
				<RangePicker
					value={startDateRagePickerFilter ? startDateRagePickerFilter : ""}
					onChange={value => {
						console.log(value);
						setStartDateRagePickerFilter(value);
						confirm();
					}}
				/>
			),
			filterIcon: filtered => <FilterFilled style={{ color: startDateRagePickerFilter ? "#1890ff" : undefined }} />,
		};
	}

	const getClaimDataForTable = () => {
		let moment = require("moment");
		if (claims) {
			return claims.map(claim => {
				return {
					key: claim.id,
					claimStatusName: (
						<NavLink
							disabled={claim.claimStatusId === claimStatusEnum.CLOSED || !isOdsUser(claim.isODSEditingEnabled)}
							to={{
								pathname:
									claim.claimStatusId === claimStatusEnum.CLOSED || !isOdsUser(claim.isODSEditingEnabled)
										? ""
										: `/claims/edit-claim/${claim.id}`,
							}}
						>
							<Tag
								style={{ minWidth: 80, textAlign: "center" }}
								color={setClaimStatusTagColor(claim.claimStatusId)}
							>
								{claim.claimStatusName}
							</Tag>
						</NavLink>
					),
					id: claim.id,
					createdByDepartmentHierarchyName: claim.createdByDepartmentHierarchyName,
					claimTypeName: claim.claimTypeName,
					claimEmergencyReadinessValue: claim.claimEmergencyReadinessValue,
					startDate: moment(claim.startDate).format(dateFormat),
					endDate: moment(claim.endDate).format(dateFormat),
					electricAddress: claim.electricAddress,
					shutdownPurpose: claim.shutdownPurpose,
					rdcRegistrationNumber: claim.rdcRegistrationNumber,
					actions: (
						<Row gutter={[10, 10]}>
							<Col>
								<Tooltip placement='topLeft' title='Перегляд завдання' mouseEnterDelay={0.7}>
									<Button size={tableButtonsSize} onClick={() => onViewClaim(claim.id)}>
										<EyeOutlined />
									</Button>
								</Tooltip>
							</Col>
							<Col>
								{userRole.includes("Administrator") && (
									<Tooltip placement='topLeft' title='Видалення заявки' mouseEnterDelay={0.7}>
										<Button size={tableButtonsSize} danger onClick={() => onDeleteClaim(claim.id)}>
											<DeleteOutlined />
										</Button>
									</Tooltip>
								)}
							</Col>
							{claim.claimStatusId !== claimStatusEnum.CLOSED && isOdsUser(claim.isODSEditingEnabled) && (
								<Col>
									<NavLink
										disabled={claim.claimStatusId === claimStatusEnum.CLOSED}
										to={{
											pathname:
												claim.claimStatusId === claimStatusEnum.CLOSED
													? ""
													: `/claims/edit-claim/${claim.id}`,
										}}
									>
										<Tooltip placement='topLeft' title='Редагування заявки' mouseEnterDelay={0.7}>
											<Button size={tableButtonsSize}>
												<EditOutlined />
											</Button>
										</Tooltip>
									</NavLink>
								</Col>
							)}

							{claim.claimStatusId === claimStatusEnum.CLOSED && (
								<Col>
									<Tooltip placement='topLeft' title='Згенерувати документ' mouseEnterDelay={0.7}>
										<Button size={tableButtonsSize} onClick={() => onGenerateClaimDocument(claim.id)}>
											<FileDoneOutlined />
										</Button>
									</Tooltip>
								</Col>
							)}
						</Row>
					),
				};
			});
		}
	};

	const onClaimTableChange = (pagination, filters, sorter) => {
		setFilteredInfo(filters);
		setSorteredInfo(sorter);
		setPaginationInfo(pagination);
		scrollAntTableToTop();
	};

	const combineFilter = (filterString, searchString) => {
		if (filterString && searchString) return `(${searchString}),(${filterString})`;
		else if (filterString) return filterString;
		else if (searchString) return searchString;
		else return "";
	};

	const onClearFilters = () => {
		setSorteredInfo(null);
		setFilteredInfo(null);
		setRdcRegistrationNumberSearch(null);
		setStartDateRagePickerFilter(null);
		setClaimGetParametersLocal({ ...claimGetParameters });

		scrollAntTableToTop();
	};

	const onViewClaim = claimId => {
		setSelectedClaimId(claimId);
		setViewModalVisible(true);
	};

	const onGenerateClaimDocument = claimId => {
		dispatch(generateClaimDocument(claimId));
	};

	const onDeleteClaim = claimId => {
		setSelectedClaimId(claimId);
		setDeleteModalVisible(true);
	};

	const isInitiator = () => {
		return claimApprovalDefaults.some(
			x =>
				userDepartmentHierarchyId === x.departmentHierarchyId &&
				x.claimApprovalLevelId === claimApprovalLevelEnum.INITIATOR
		);
	};

	const isOdsUser = isODSEditingEnabled => {
		return isODSEditingEnabled && isOdsEditingEnabledRef.current;
	};

	useEffect(() => {
		const breadCrumbData = [{ name: text, link: false }];
		dispatch(setPageBreadcrumb(breadCrumbData));
		dispatch(getClaims(claimGetParametersLocal));
	}, [dispatch, text, claimGetParametersLocal]);

	useEffect(() => {
		isOdsEditingEnabledRef.current = claimApprovalDefaults.some(
			x =>
				userDepartmentHierarchyId === x.departmentHierarchyId &&
				x.departmentId === +userDepartmentId &&
				x.departmentHierarchyId === "/2/9/2/"
		);
	}, [claimApprovalDefaults, userDepartmentHierarchyId, userDepartmentId]);

	useEffect(() => {
		//Filtering
		let filter;
		const tableFilterNamesArr = [
			"claimStatusName",
			"createdByDepartmentHierarchyName",
			"claimTypeName",
			"claimEmergencyReadinessValue",
		];
		const fieldNamesInDBArr = [
			"claimStatusId",
			"createdByDepartmentHierarchyId",
			"claimTypeId",
			"claimEmergencyReadinessId",
		];

		if (filteredInfo) filter = tableFilterStringBuilder(filteredInfo, tableFilterNamesArr, fieldNamesInDBArr);

		//Sorting
		tableSortBuilder(claimGetParametersLocal, paginationInfo, sorteredInfo);

		let searchStr = "";

		const searchObjArray = [
			{
				stringValue: debouncedRdcRegistrationNumber
					? `(rdcRegistrationNumber =*${debouncedRdcRegistrationNumber})`
					: "",
			},
			{
				stringValue: startDateRagePickerFilter
					? `startDate >=${startDateRagePickerFilter[0].format(
							"YYYY-MM-DD"
					  )}, startDate <=${startDateRagePickerFilter[1].format("YYYY-MM-DD")}T23:59:59`
					: undefined,
			},
		];

		searchObjArray.forEach(item => {
			if (item.stringValue) {
				searchStr += item.stringValue + ",";
			}
		});

		searchStr = searchStr.slice(0, -1); //delete last comma in string

		claimGetParametersLocal.filter = combineFilter(filter, searchStr);
		setClaimGetParametersLocal(claimGetParametersLocal);
		dispatch(getClaims(claimGetParametersLocal));
	}, [
		claimGetParametersLocal,
		debouncedRdcRegistrationNumber,
		dispatch,
		filteredInfo,
		paginationInfo,
		sorteredInfo,
		startDateRagePickerFilter,
	]);

	return (
		<>
			<Row className={css.claims}>
				<Col span={12}>
					{isInitiator() && (
						<Button className={css.site_button} type='primary' onClick={() => navigate("/claims/create-claim")}>
							<PlusOutlined />
							Створити заявку
						</Button>
					)}
				</Col>
				<Col span={12} className={css.clear_filters_btn_wrap}>
					<Button type='default' className={css.filter_button} onClick={onClearFilters}>
						<ClearOutlined />
						Скинути всі фільтри
					</Button>
				</Col>
				<Card bodyStyle={{ padding: "0px" }} bordered={true} style={{ width: "100%" }}>
					<Table
						onChange={onClaimTableChange}
						className={css.ant_table_wrapper}
						size={tableSize}
						pagination={{
							position: paginationPosition,
							defaultPageSize: defaultPageSize,
							showSizeChanger: true,
							total: claimTotalItems,
							current: claimGetParametersLocal.page,
							showTotal: showTotal,
						}}
						//scroll={claimTableScroll}
						showSorterTooltip={false}
						columns={claimTableColumns}
						dataSource={getClaimDataForTable()}
						rowClassName={(record, index) => (index % 2 === 0 ? css.table_row_light : css.table_row_dark)}
					/>
				</Card>

				<ViewClaimDrawer
					open={viewModalVisible}
					onClose={() => {
						setViewModalVisible(false);
					}}
					claimId={selectedClaimId}
				/>

				<DeleteClaimModal
					open={deleteModalVisible}
					onCancel={() => setDeleteModalVisible(false)}
					claimId={selectedClaimId}
				/>
			</Row>
		</>
	);
};

export default Claims;
