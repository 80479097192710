import { Col, Divider, Drawer, Row } from "antd";
import React, { useEffect, useState } from "react";

import css from "./ViewShutdownDrawer.module.scss";
import moment from "moment";
import { shutdownTypeEnum } from "../enums/shutdownEnums";
import { useSelector } from "react-redux";

const ViewShutdownDrawer = ({ open, onClose, shutdownId }) => {
	const dateFormat = "YYYY-MM-DD (HH:mm)";
	const shutdowns = useSelector(state => state.shutdown.shutdowns.data);
	const [currentShutdown, setCurrentShutdown] = useState(null);
	const [shutdownStatusId, setShutdownStatusId] = useState(null);

	useEffect(() => {
		if (!shutdowns) return;
		setCurrentShutdown(shutdowns.filter(shutdown => shutdown.id === shutdownId)[0]);
	}, [shutdowns, shutdownId]);

	useEffect(() => {
		if (!currentShutdown) return;
		setShutdownStatusId(currentShutdown.shutdownStatusId);
	}, [currentShutdown]);

	const setStatusColor = () => {
		let color = { titleColor: null, lineColor: null, labelColor: null };
		switch (shutdownStatusId) {
			case 1:
				color.titleColor = "#D9F6FD";
				color.lineColor = "#00ACD7";
				color.labelColor = "#00ACD7";
				return color;
			case 2:
				color.titleColor = "#D9F2E6";
				color.lineColor = "#009551";
				color.labelColor = "#009551";
				return color;
			case 3:
				color.titleColor = "#F1B24E";
				color.lineColor = "#DA8C10";
				color.labelColor = "#DA8C10";
				return color;
			case 4:
				color.titleColor = "#FAE4E1";
				color.lineColor = "#C64333";
				color.labelColor = "#C64333";
				return color;
			case 5:
				color.titleColor = "#BDBDBD";
				color.lineColor = "#828282";
				color.labelColor = "#828282";
				return color;
			case 6:
				color.titleColor = "#FAE4E1";
				color.lineColor = "#C64333";
				color.labelColor = "#C64333";
				return color;
			case 7:
				color.titleColor = "#D9F2E6";
				color.lineColor = "#009551";
				color.labelColor = "#009551";
				return color;
			case 8:
				color.titleColor = "#BDBDBD";
				color.lineColor = "#828282";
				color.labelColor = "#828282";
				return color;
			default:
				return color;
		}
	};

	return (
		<>
			{currentShutdown && (
				<Drawer
					title={<b>{`Перегляд відключення №${currentShutdown.id}:`}</b>}
					placement='right'
					width={"25%"}
					headerStyle={{ backgroundColor: setStatusColor().titleColor, borderRadius: "0px" }}
					drawerStyle={{ borderLeft: `4px solid ${setStatusColor().lineColor}` }}
					onClose={onClose}
					open={open}
				>
					<div className={css.view_shutdown_wrapp}>
						<Row className={css.item}>
							<Col span={12}>
								<Row className={css.sub_item_title}>Статус</Row>
								<Row style={{ color: setStatusColor().labelColor }}>{currentShutdown.shutdownStatusName}</Row>
							</Col>
							<Col span={12}>
								<Row className={css.sub_item_title}>Тип відключення</Row>
								<Row>{currentShutdown.shutdownTypeName}</Row>
							</Col>
						</Row>

						<Row className={css.item}>
							<Col span={12}>
								<Row className={css.sub_item_title}>Очікуваний час</Row>
								<Row className={css.sub_item_title}>початок</Row>
								<Row>{moment(currentShutdown.startDate).format(dateFormat)}</Row>
							</Col>
							<Col span={12}>
								<Row className={css.sub_item_title}>Фактичний час</Row>
								<Row className={css.sub_item_title}>початок</Row>
								<Row>
									{currentShutdown.factStartDate
										? moment(currentShutdown.factStartDate).format(dateFormat)
										: "Не закрите"}
								</Row>
							</Col>
						</Row>

						<Row className={css.item}>
							<Col span={12}>
								<Row className={css.sub_item_title}>завершення</Row>
								<Row>{moment(currentShutdown.endDate).format(dateFormat)}</Row>
							</Col>
							<Col span={12}>
								<Row className={css.sub_item_title}>завершення</Row>
								<Row>
									{currentShutdown.factEndDate
										? moment(currentShutdown.factEndDate).format(dateFormat)
										: "Не закрите"}
								</Row>
							</Col>
						</Row>

						<Divider />

						<Row className={css.item}>
							<Col className={css.sub_item_title}>Дата створення відключення:</Col>
							<Col>{moment(currentShutdown.created).format(dateFormat)}</Col>
						</Row>

						<Row className={css.item}>
							<Col className={css.sub_item_title}>Підрозділ:</Col>
							<Col>{currentShutdown.departmentName ? currentShutdown.departmentName : "Відсутній"}</Col>
						</Row>

						<Row className={css.item}>
							<Col className={css.sub_item_title}>Відділення:</Col>
							<Col>{currentShutdown.branchName ? currentShutdown.branchName : "Відсутнє"}</Col>
						</Row>

						<Row className={css.item}>
							<Col>
								<span>
									<span className={css.sub_item_title}>Електрична адреса:</span>
									{currentShutdown.electricAddress ? currentShutdown.electricAddress : "Відсутня"}
								</span>
							</Col>
						</Row>

						<Divider />

						<Row className={css.item}>
							<Col>
								<span>
									<span className={css.sub_item_title}>Вид робіт:</span>
									{currentShutdown.shutdownJobTypeName}
								</span>
							</Col>
						</Row>

						{currentShutdown.shutdownTypeId === shutdownTypeEnum.EMERGENCY && (
							<Row className={css.item}>
								<Col className={css.sub_item_title}>Призвідник відключення:</Col>
								<Col>{currentShutdown.shutdownDriverName || "Відсутній"}</Col>
							</Row>
						)}

						<Row className={css.item}>
							<Col>
								<span>
									<span className={css.sub_item_title}>Додаткова інформація:</span>
									{currentShutdown.description || "Відсутня"}
								</span>
							</Col>
						</Row>

						<Divider />

						<Row className={css.item}>
							<Col className={css.sub_item_title}>Ким створене:</Col>
							<Col>{currentShutdown.userName || "Відсутній"}</Col>
						</Row>
					</div>
				</Drawer>
			)}
		</>
	);
};

export default ViewShutdownDrawer;
