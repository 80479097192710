import { Button, Modal, Table } from "antd";
import React, { useCallback } from "react";

import css from "./Smart.module.scss";
import { tableSize } from "../../../assets/js/options";

const CounterpartyBySmartMeterModal = ({ openModal, setOpenModal, counterpartiesBySmartMerer }) => {
	const counterpartyBySmartMeterColumns = [
		{
			title: "№",
			dataIndex: "id",
			key: "id",
			width: 60,
			align: "center",
		},
		{
			title: "Підрозділ",
			dataIndex: "departmentName",
			key: "departmentName",
			width: 200,
			align: "center",
		},
		{
			title: "Відділення",
			dataIndex: "branchName",
			key: "branchName",
			width: 130,
			align: "center",
		},
		{
			title: "Тип споживача",
			dataIndex: "counterpartyTypeName",
			key: "counterpartyTypeName",
			sorter: (a, b) => a.counterpartyTypeName.localeCompare(b.counterpartyTypeName),
			width: 130,
			align: "center",
		},
		{
			title: "Назва споживача",
			dataIndex: "counterpartyName",
			key: "counterpartyName",
			sorter: (a, b) => a.counterpartyTypeName.localeCompare(b.counterpartyTypeName),
			width: 250,
			align: "left",
		},
		{
			title: "Особовий рахунок",
			dataIndex: "counterpartyPersonalAccount",
			key: "counterpartyPersonalAccount",
			sorter: (a, b) => a.counterpartyPersonalAccount - b.counterpartyPersonalAccount,
			width: 130,
			align: "center",
		},
		{
			title: "Номер лічильника",
			dataIndex: "meterNumber",
			key: "meterNumber",
			sorter: (a, b) => a.meterNumber - b.meterNumber,
			width: 100,
			align: "center",
		},		
		{
			title: "Наявність показників у активному періоді",
			dataIndex: "isMeterDataExistsInActivePeriod",
			key: "isMeterDataExistsInActivePeriod",
			sorter: (a, b) => a.isMeterDataExistsInActivePeriod.localeCompare(b.isMeterDataExistsInActivePeriod),
			width: 100,
			align: "center",
		},
	];

	const counterpartyBySmartMeterTableData = useCallback(() => {
		if (counterpartiesBySmartMerer) {
			return counterpartiesBySmartMerer.map((x, i) => {
				return {
					key: i,
					id: i + 1,
					departmentName: x.departmentName ? x.departmentName : "", //Check needed when departmentName = null/undefined
					branchName: x.branchName,
					counterpartyTypeName: x.counterpartyTypeName,
					counterpartyName: x.counterpartyName,
					counterpartyPersonalAccount: x.counterpartyPersonalAccount,
					meterNumber: x.meterNumber,
					isMeterDataExistsInActivePeriod: x.isMeterDataExistsInActivePeriod ? "так" : "ні",
				};
			});
		}
	}, [counterpartiesBySmartMerer]);

	return (
		<Modal
			title='Споживачі'
			centered
			open={openModal}
			width={"90%"}
			onCancel={() => setOpenModal(false)}
			footer={[
				<Button key='cancel' onClick={() => setOpenModal(false)}>
					Закрити
				</Button>,
			]}
		>
			<Table
				className={css.ant_table_wrapper}
				size={tableSize}
				pagination={false}
				columns={counterpartyBySmartMeterColumns}
				showSorterTooltip={false}
				dataSource={counterpartyBySmartMeterTableData()}
			/>
		</Modal>
	);
};

export default CounterpartyBySmartMeterModal;
