import { Col, Radio, Row, Spin } from "antd";
import React, { useEffect } from "react";
import { setChartDataSelector, setChartTypeSelector } from "../../redux/actions/statsActions";
import { useDispatch, useSelector } from "react-redux";

import Filter from "./Filter/Filter";
import StatsInfo from "./StatsInfo/StatsInfo";
import css from "./Stats.module.scss";
import { setPageBreadcrumb } from "../../redux/actions/appActions";

const StatsChart = React.lazy(() => import("./StatsChart/StatsChart"));

const Stats = () => {
	const dispatch = useDispatch();

	//For chart colors
	const chartDataSelector = useSelector(state => state.stats.chartDataSelector);
	const getColors = () => {
		if (chartDataSelector === 1) {
			return { colorM: "#00a7d0", colorS: "#5ad6f5" };
		}
		if (chartDataSelector === 2) {
			return { colorM: "#db8b0b", colorS: "#f7bf66" };
		}
		if (chartDataSelector === 3) {
			return { colorM: "darkred", colorS: "#e98b7f" }; //#dd4b39 color (City) was istead darkred
		}
		if (chartDataSelector === 4) {
			return { colorM: "#008d4c", colorS: "#5ac594" };
		}
	};

	const isLoading = useSelector(state => state.app.isLoading);

	//Sum Stats data
	const saidiSaifiM = useSelector(state => state.stats.saidiSaifiDataCity);
	const saidiSaifiS = useSelector(state => state.stats.saidiSaifiDataVilage);
	const maifiM = useSelector(state => state.stats.maifiDataCity);
	const maifiS = useSelector(state => state.stats.maifiDataVilage);
	const ensM = useSelector(state => state.stats.ensDataCity);
	const ensS = useSelector(state => state.stats.ensDataVilage);

	let sumStatsData = [
		{ saidiSumM: Math.round(saidiSaifiM.reduce((acc, elem) => (acc += elem.saidiValue), 0) * 100) / 100 },
		{ saidiSumS: Math.round(saidiSaifiS.reduce((acc, elem) => (acc += elem.saidiValue), 0) * 100) / 100 },
		{ saifiSumM: Math.round(saidiSaifiM.reduce((acc, elem) => (acc += elem.saifiValue), 0) * 100) / 100 },
		{ saifiSumS: Math.round(saidiSaifiS.reduce((acc, elem) => (acc += elem.saifiValue), 0) * 100) / 100 },
		{ ensSumM: Math.round(ensM.reduce((acc, elem) => (acc += elem.ensValue), 0) * 100) / 100 },
		{ ensSumS: Math.round(ensS.reduce((acc, elem) => (acc += elem.ensValue), 0) * 100) / 100 },
		{ maifiSumM: Math.round(maifiM.reduce((acc, elem) => (acc += elem.maifiValue), 0) * 100) / 100 },
		{ maifiSumS: Math.round(maifiS.reduce((acc, elem) => (acc += elem.maifiValue), 0) * 100) / 100 },
	];

	useEffect(() => {
		const breadCrumbData = [{ name: "Показники", link: false }];
		dispatch(setPageBreadcrumb(breadCrumbData));
	}, [dispatch]);

	return (
		<div className={css.stats}>
			<Filter />
			{
				<Spin spinning={isLoading} tip={"Завантаження"}>
					<div className={css.content_box}>
						<Row className={css.content} gutter={[16, 16]}>
							<Col sm={24} md={12} lg={6}>
								<StatsInfo
									color={"#00c0ef"}
									dataM={sumStatsData[0].saidiSumM}
									dataS={sumStatsData[1].saidiSumS}
									title='saidi'
								/>
							</Col>
							<Col sm={24} md={12} lg={6}>
								<StatsInfo
									color={"#f39c12"}
									dataM={sumStatsData[2].saifiSumM}
									dataS={sumStatsData[3].saifiSumS}
									title='saifi'
								/>
							</Col>
							<Col sm={24} md={12} lg={6}>
								<StatsInfo
									color={"#dd4b39"}
									dataM={sumStatsData[4].ensSumM}
									dataS={sumStatsData[5].ensSumS}
									title='ens'
								/>
							</Col>
							<Col sm={24} md={12} lg={6}>
								<StatsInfo
									color={"#00a65a"}
									dataM={sumStatsData[6].maifiSumM}
									dataS={sumStatsData[7].maifiSumS}
									title='maifi'
								/>
							</Col>
						</Row>
					</div>

					<div className={css.content_box}>
						<Row className={css.content} gutter={24}>
							<Col span={6}>
								<ChartDataSelector />
							</Col>
							<Col span={6}>
								<ChartTypeSelector />
							</Col>
						</Row>
					</div>

					<div className={css.content_box}>
						<Row className={css.content} justify={"center"}>
							<StatsChart colors={getColors()} />
						</Row>
					</div>
				</Spin>
			}
		</div>
	);
};

//Radio buttons block
const ChartDataSelector = () => {
	const dispatch = useDispatch();
	const chartDataSelector = useSelector(state => state.stats.chartDataSelector);

	const onChangeType = e => {
		dispatch(setChartDataSelector(e.target.value));
	};

	return (
		<Radio.Group onChange={onChangeType} value={chartDataSelector}>
			<Radio value={1}>SAIDI</Radio>
			<Radio value={2}>SAIFI</Radio>
			<Radio value={3}>ENS</Radio>
			<Radio value={4}>MAIFI</Radio>
		</Radio.Group>
	);
};

const ChartTypeSelector = () => {
	const dispatch = useDispatch();
	const chartTypeSelector = useSelector(state => state.stats.chartTypeSelector);

	const onChangeChartType = e => {
		dispatch(setChartTypeSelector(e.target.value));
	};

	return (
		<Radio.Group onChange={onChangeChartType} value={chartTypeSelector}>
			<Radio value={1}>Лінійний графік</Radio>
			<Radio value={2}>Стовпчиковий графік</Radio>
		</Radio.Group>
	);
};

export default Stats;
