import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";

import React from "react";
import { deleteClaim } from "../../../redux/actions/claimActions";

const DeleteClaimModal = ({ open, onCancel, claimId }) => {
	const dispatch = useDispatch();
	const tabelFilter = useSelector(state => state.app.tableFilter);

	const handleOk = () => {
		dispatch(deleteClaim(claimId, tabelFilter));
		onCancel();
	};

	const handleCancel = () => {
		onCancel();
	};

	return (
		<>
			<Modal
				title='Видалення заявки:'
				open={open}
				//onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Ні
					</Button>,
					<Button key='submit' type='primary' onClick={handleOk}>
						Так
					</Button>,
				]}
			>
				<p>Ви дійсно бажаєте видалити дану заявку № "{claimId}"?</p>
			</Modal>
		</>
	);
};

export default DeleteClaimModal;
