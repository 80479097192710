import { Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import { addAppeal, editAppeal, getAppealPaidServiceById, setAppealById } from "../../../redux/actions/appealActions";
import { appealClassifiersEnum, appealStatusEnum } from "../enums/appealEnums";
import { setApiLastError, setPageBreadcrumb } from "../../../redux/actions/appActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom/dist";

import AppealBillFilesList from "./AppeaBilllFilesList/AppealBillFilesList";
import { AppealPaidServiceComment } from "./AppealPaidServiceComment/AppealPaidServiceComment";
import AppealPaidServiceFilesList from "./AppealPaidServiceFilesList/AppealPaidServiceFilesList";
import AppealPaidServiceInputOptions from "./AppealPaidServiceInputOptions/AppealPaidServiceInputOptions";
import AppealPaidServiceLocation from "./AppealPaidServiceLocation/AppealPaidServiceLocation";
import AppealPaidServiceSelectedСommunication from "./AppealPaidServiceSelectedСommunication/AppealPaidServiceSelectedСommunication";
import AppealPaidServiceStatus from "./AppealPaidServiceStatus/AppealPaidServiceStatus";
import AppealPaidServiceText from "./AppealPaidServiceText/AppealPaidServiceText";
import AppealPaidServiceTime from "./AppealPaidServiceTime/AppealPaidServiceTime";
import css from "./AppealPaidServiceForm.module.scss";
import { maskPhone } from "../../../assets/js/helpers/maskPhone";
import moment from "moment";
import { setAppealFilesToUpload } from "../../../redux/actions/appealActions";

const AppealPaidServiceForm = () => {
	const { appealId } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const appeal = useSelector(state => state.appeal.appealById);
	const userId = JSON.parse(useSelector(state => state.auth.userId));
	const userDepartmentEntryPointId = JSON.parse(useSelector(state => state.auth.departmentEntryPointId));
	const appealFilesListToUpload = useSelector(state => state.appeal.appealFilesListToUpload);
	const appealBillFilesListToUpload = useSelector(state => state.appeal.appealBillFilesListToUpload);
	const userDepartmentId = JSON.parse(useSelector(state => state.auth.departmentId));
	const apiLastError = useSelector(state => state.app.apiLastError);

	const [formValues, setFormValues] = useState(undefined);
	const [isFormSaved, setIsFormSaved] = useState(false);

	const appealStatusIdWatch = Form.useWatch("appealStatusId", form);

	//SUBMIT FORM
	const dateFormat = "YYYY-MM-DD HH:mm";
	const onCreateEditAppeal = values => {
		const appealPostParameters = {
			id: appeal ? appeal.id : 0,
			rowVersion: appeal ? appeal.rowVersion : null,
			appealStatusId: values.appealStatusId,
			innerRegistrationNumber: appeal ? appeal.innerRegistrationNumber : null,
			innerRegistrationDate: appeal ? appeal.innerRegistrationDate : moment().format(),
			departmentEntryPointId: values.departmentEntryPointId,
			appealClassifierId: values.appealClassifierId,
			counterpartyPersonalAccount: values.counterpartyPersonalAccount,
			counterpartyTypeId: values.counterpartyTypeId,
			counterpartyName: values.counterpartyName,
			email: values.email,
			phone: values.phoneUnmasked || null,
			departmentId: values.departmentId,
			settlementId: values.settlementId,
			address: values.address,
			appealCommunicationTypeId: values.appealCommunicationTypeId,
			appealObtainingMethodId: values.appealObtainingMethodId,
			isEditingEnabled: true,
			createdByUserId: userId,
			appealPaidServiceId: values.appealPaidServiceId,
			description: values.description,
			datePaidServiceWasPaid:
				values.datePaidServiceWasPaid && values.datePaidServiceWasPaid[0]
					? moment(values.datePaidServiceWasPaid[0]).format()
					: null,
			comment: values.comment,
			appealPaidServiceTimeId: values.appealPaidServiceTimeId,
			appealPaidServicePrice: values.appealPaidServicePrice,
			branchId: values.branchId,
		};

		if (appealId) {
			dispatch(editAppeal(appealPostParameters, appealFilesListToUpload, appealBillFilesListToUpload));
		} else {
			dispatch(addAppeal(appealPostParameters, appealFilesListToUpload, appealBillFilesListToUpload));
		}

		setIsFormSaved(true);
		navigate("/appeal-paid-service");
	};

	const onCreateEditAppealFailed = errorInfo => {
		console.log("Failed:", errorInfo);
	};

	const onResetEditing = () => {
		form.resetFields();
		dispatch(setAppealFilesToUpload([]));
	};

	useEffect(() => {
		const breadCrumbData = [
			{ name: "Заяви на послуги", link: "appeal-paid-service" },
			appealId
				? { name: `Редагування послуги №${appealId}`, link: false }
				: { name: "Створення послуги", link: false },
		];

		dispatch(setPageBreadcrumb(breadCrumbData));

		if (appealId) {
			dispatch(getAppealPaidServiceById(appealId));
		} else {
			dispatch(setAppealById(undefined));
		}
	}, [dispatch, appealId]);

	useEffect(() => {
		if (!appealId) setFormValues(prev => ({ ...prev, departmentId: userDepartmentId }));
	}, [appealId, userDepartmentId]);

	useEffect(() => {
		if (!appealId) {
			setFormValues(prev => ({ ...prev, departmentEntryPointId: userDepartmentEntryPointId }));
		}
	}, [appealId, userDepartmentEntryPointId]);

	useEffect(() => {
		if (appealId && appeal) {
			setFormValues(prev => ({
				...prev,
				innerRegistrationDate: moment(appeal.innerRegistrationDate, dateFormat),
				innerRegistrationNumber: appeal.innerRegistrationNumber,
				departmentEntryPointId: appeal.departmentEntryPointId,
				appealStatusId: appeal.appealStatusId,
				appealClassifierId: appeal.appealClassifierId,
				appealObtainingMethodId: appeal.appealObtainingMethodId,
				counterpartyTypeId: appeal.counterpartyTypeId,
				counterpartyPersonalAccount: appeal.counterpartyPersonalAccount,
				settlementId: appeal.settlementId,
				settlementName: appeal.settlementName,
				address: appeal.address,
				departmentId: appeal.departmentId,
				counterpartyName: appeal.counterpartyName,
				appealCommunicationTypeId: appeal.appealCommunicationTypeId,
				phone: maskPhone(appeal.phone),
				phoneUnmasked: appeal.phone,
				email: appeal.email,
				appealPaidServiceId: appeal.appealPaidServiceId,
				description: appeal.description,
				datePaidServiceWasPaid: appeal.datePaidServiceWasPaid ? [appeal.datePaidServiceWasPaid] : undefined,
				appealPaidServiceTimeId: appeal.appealPaidServiceTimeId,
				appealPaidServicePrice: appeal.appealPaidServicePrice,
				branchId: appeal.branchId,
			}));
		} else if (!appealId) {
			setFormValues(prev => ({
				...prev,
				appealStatusId: appealStatusEnum.NEW,
				appealClassifierId: appealClassifiersEnum.PAID_SERVICE,
			}));
		}
	}, [form, appealId, appeal]);

	//Program rerender initial form values (by reset fields)
	useEffect(() => {
		if (!formValues) return;
		form.resetFields();
	}, [formValues, form]);

	//If error
	useEffect(() => {
		if (isFormSaved && apiLastError.isErrorExists !== undefined) {
			if (apiLastError.isErrorExists === false) {
				dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));
				navigate("/appeals");
			}
		}
	}, [apiLastError, dispatch, isFormSaved, navigate]);

	return (
		<div className={css.add_edit_appeals_wrap}>
			<Form
				form={form}
				name='createAppeal'
				initialValues={formValues}
				preserve={false}
				onFinish={onCreateEditAppeal}
				onFinishFailed={onCreateEditAppealFailed}
				scrollToFirstError
			>
				<AppealPaidServiceStatus isEditMode={!!appealId} />

				{appealId ? <AppealPaidServiceTime /> : <></>}

				<AppealPaidServiceInputOptions />

				<AppealPaidServiceLocation />

				<AppealPaidServiceSelectedСommunication />

				<AppealPaidServiceText />

				{appealStatusIdWatch === 3 ? <AppealPaidServiceComment /> : <></>}

				<AppealPaidServiceFilesList appealId={appealId} />

				<AppealBillFilesList appealId={appealId} />

				<Button className={css.site_button} type='primary' htmlType='submit'>
					{appealId ? "Зберегти редагування" : "Зберегти"}
				</Button>

				{appealId ? <Button onClick={onResetEditing}>Скасувати редагування</Button> : <></>}
			</Form>
		</div>
	);
};

export default AppealPaidServiceForm;
