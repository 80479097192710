import { Alert, Button, Form, Modal } from "antd";

import { Input } from "antd";
import React from "react";
import css from "./ClaimApprovalGroupActionsModal.module.scss";

const ClaimApprovalGroupActionsModal = ({ showModal, onCancel, groupActionType, selectedRowsCount, onSave }) => {
	const { TextArea } = Input;
	const [form] = Form.useForm();

	const handleSave = values => {
		onSave(values);
	};

	const onFinishFailed = errorInfo => {
		console.log("Failed:", errorInfo);
	};

	return (
		<Modal
			className={css.claim_approval_group_actions_modal}
			destroyOnClose
			maskClosable={false}
			open={showModal}
			title={`Групова операція (${groupActionType === "approve" ? "погодження" : "відхилення"})`}
			onOk={handleSave}
			onCancel={onCancel}
			footer={[
				<Button key='back' onClick={onCancel}>
					Скасувати
				</Button>,
				<Button
					key='submit'
					type='primary'
					className={css.site_button}
					style={{ marginRight: 0 }}
					onClick={() => form.submit()}
				>
					{groupActionType === "approve" ? "Погодити" : "Відхилити"}
				</Button>,
			]}
		>
			<Alert
				style={{ margin: "0 0 15px 0" }}
				message={`Ви здійснюєте групове ${
					groupActionType === "approve" ? "погодження" : "відхилення"
				} заявки на виведення обладнання в ремонт. 
                             Вибрано ${selectedRowsCount ? selectedRowsCount : 0} заявок(ки).`}
				type='warning'
				showIcon
			/>

			<Form
				form={form}
				name='groupActionSaveForm'
				preserve={false}
				onFinish={handleSave}
				onFinishFailed={onFinishFailed}
			>
				{groupActionType === "approve" && (
					<Form.Item
						name='description'
						rules={[{ required: false, message: "Будь ласка вкажіть рішення та зауваження!" }]}
					>
						<TextArea
							showCount
							maxLength={100}
							style={{
								width: "100%",
								height: 120,
								resize: "none",
							}}
							placeholder='рішення та зауваження'
						/>
					</Form.Item>
				)}

				{groupActionType === "decline" && (
					<Form.Item name='description' rules={[{ required: true, message: "Вкажіть причину непогодження (мін. 20 симовлів)", min: 20 }]}>
						<TextArea
							showCount
							maxLength={500}
							style={{
								height: 120,
								resize: "none",
							}}
							placeholder="причини непогодження, обов'язкове поле для заповнення"
						/>
					</Form.Item>
				)}
			</Form>
		</Modal>
	);
};

export default ClaimApprovalGroupActionsModal;
