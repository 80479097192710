import moment from "moment";

export const taskExecutionLogCreator = (taskExecutionLogState) => {
    if (!taskExecutionLogState.length) return [];

    const dateFormat = "YYYY-MM-DD HH:mm";
    const logs = [];
    let previousItem;

    taskExecutionLogState.forEach((item, index) => {
        const logDataObject = {};
        logDataObject.created = moment(item.created).format(dateFormat);
        logDataObject.createdByUserName = item.createdByUserName;
        logDataObject.operations = [];

        if (index > 0) {
            if (previousItem.depapartmentHierarchyUserName !== item.depapartmentHierarchyUserName) {

                let operation = {
                    oldValue: previousItem.depapartmentHierarchyUserName,
                    value: item.depapartmentHierarchyUserName,
                    column: 'Виконавець'
                };
                logDataObject.operations.push(operation);
            }
            if (previousItem.taskStatusName !== item.taskStatusName) {
                let operation = {
                    oldValue: previousItem.taskStatusName,
                    value: item.taskStatusName,
                    column: 'Статус'
                };
                logDataObject.operations.push(operation);
            }
            if (item.description) {
                let operation = {
                    oldValue: undefined,
                    value: item.description,
                    column: 'Коментар'
                };
                logDataObject.operations.push(operation);
            }
        } else {
            logDataObject.operations.push({
                oldValue: undefined,
                value: item.depapartmentHierarchyUserName,
                column: 'Виконавець'
            }, {
                oldValue: undefined,
                value: item.taskStatusName,
                column: 'Статус'
            });
        }

        if (logDataObject.operations.length)
            logs.push(logDataObject);

        previousItem = item;
    });

    return logs;
};