import moment from "moment";

export const claimExecutionLogCreator = claimExecutionLogState => {
	if (!claimExecutionLogState.length) return [];

	const dateFormat = "YYYY-MM-DD HH:mm";
	const logs = [];
	let previousItem;

	claimExecutionLogState.forEach((item, index) => {
		const logDataObject = {};
		logDataObject.created = moment(item.created).format(dateFormat);
		logDataObject.createdByUserName = item.createdByUserName;
		logDataObject.createdByDepartmentHierarchyName = item.createdByDepartmentHierarchyName;
		logDataObject.isApproved = item.isApproved;
		logDataObject.operations = [];

		if (index > 0) {
			if (previousItem.claimStatusName !== item.claimStatusName) {
				logDataObject.operations.push({
					oldValue: previousItem.claimStatusName,
					value: item.claimStatusName,
					column: "Статус",
				});
			}

      if (previousItem.startDate !== item.startDate) {
        logDataObject.operations.push({
          oldValue: previousItem.startDate,
          value: moment(item.startDate).format(dateFormat),
          column: "Очікуваний час початку",
        });
      }

      if (previousItem.endDate !== item.endDate) {
        logDataObject.operations.push({
          oldValue: previousItem.endDate,
          value: moment(item.endDate).format(dateFormat),
          column: "Очікуваний час завершення",
        });
      }

      if (previousItem.realStartDate !== item.realStartDate) {
        logDataObject.operations.push({
          value: moment(item.realStartDate).format(dateFormat),
          column: "Фактичний час початку",
        });
      }

      if (previousItem.realEndDate !== item.realEndDate) {
        logDataObject.operations.push({
          value: moment(item.realEndDate).format(dateFormat),
          column: "Фактичний час завершення",
        });
      }

      if (previousItem.dateEquipmentStartedWorking !== item.dateEquipmentStartedWorking) {
        logDataObject.operations.push({
          value: moment(item.dateEquipmentStartedWorking).format(dateFormat),
          column: "Дата введення обладнання в роботу",
        });
      }

      if (previousItem.shutdownPurpose !== item.shutdownPurpose) {
        logDataObject.operations.push({
          oldValue: previousItem.shutdownPurpose,
          value: item.shutdownPurpose,
          column: "Мета",
        });
      }

			if (item.isApproved !== null && item.approvalDescription) {
				logDataObject.operations.push({
					oldValue: undefined,
					value: item.approvalDescription,
					column: "Коментар",
				});
			}

			if (previousItem.rdcRegistrationNumber !== item.rdcRegistrationNumber) {
				logDataObject.operations.push({
					oldValue: previousItem.rdcRegistrationNumber,
					value: item.rdcRegistrationNumber,
					column: "РДС ПЗР №",
				});
			}

			if (previousItem.continueToDate !== item.continueToDate) {
				logDataObject.operations.push({
					oldValue: previousItem.continueToDate ? moment(previousItem.continueToDate).format(dateFormat) : undefined,
					value: moment(item.continueToDate).format(dateFormat),
					column: "Продовження заявки",
				});
			}
		} else {
			logDataObject.operations.push({
				oldValue: undefined,
				value: item.claimStatusName,
				column: "Статус",
			});
		}

		if (logDataObject.operations.length || item.isApproved !== null) logs.push(logDataObject);

		previousItem = item;
	});

	return logs;
};
