export const maskPhone = (phone) => {
    let result;

    if (phone) {
        let phoneParts = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
        result = '(' + phoneParts[1] + ')' + phoneParts[2] + '-' + phoneParts[3] + '-' + phoneParts[4];
    }

    return result;
};
