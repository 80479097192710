import {
	SET_APPEALS_COUNT,
	SET_EMERGENCY_SHUTDOWNS_COMMERCIAL_COUNTERPARTIERS_COUNT,
	SET_EMERGENCY_SHUTDOWNS_COUNT,
	SET_EMERGENCY_SHUTDOWNS_COUNT_BY_ELECTRIC_ADDRESSES,
	SET_EMERGENCY_SHUTDOWNS_RESIDENTIAL_COUNTERPARTIERS_COUNT,
	SET_PLANNED_SHUTDOWNS_COMMERCIAL_COUNTERPARTIERS_COUNT,
	SET_PLANNED_SHUTDOWNS_COUNT,
	SET_PLANNED_SHUTDOWNS_COUNT_BY_ELECTRIC_ADDRESSES,
	SET_PLANNED_SHUTDOWNS_RESIDENTIAL_COUNTERPARTIERS_COUNT,
} from "../types";
import { hideLoader, setApiLastError, showLoader } from "./appActions";

import { dashboardAPI } from "../../api/dashboardEndpoints";
import { thunkErrorHandler } from "./actionHandlers";

export const getAppealsCount = getParameters => async dispatch => {
	try {
		const response = await dashboardAPI.getAppealsCount(getParameters);
		if (response.status === 200) {
			dispatch(setAppealsCount(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const setAppealsCount = appealsCount => {
	return {
		type: SET_APPEALS_COUNT,
		payload: appealsCount,
	};
};

export const getEmergencyShutdownsCount = getParameters => async dispatch => {
	try {
		const response = await dashboardAPI.getEmergencyShutdownsCount(getParameters);
		if (response.status === 200) {
			dispatch(setEmergencyShutdownsCount(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const setEmergencyShutdownsCount = emergencyShutdownsCount => {
	return {
		type: SET_EMERGENCY_SHUTDOWNS_COUNT,
		payload: emergencyShutdownsCount,
	};
};

export const getPlannedShutdownsCount = getParameters => async dispatch => {
	try {
		const response = await dashboardAPI.getPlannedShutdownsCount(getParameters);
		if (response.status === 200) {
			dispatch(setPlannedShutdownsCount(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const setPlannedShutdownsCount = plannedShutdownsCount => {
	return {
		type: SET_PLANNED_SHUTDOWNS_COUNT,
		payload: plannedShutdownsCount,
	};
};

//-------------------------------------------------------------
export const getShutdownsCommercialCounterpartiersCount = getParameters => async dispatch => {
	try {
		const response = await dashboardAPI.getShutdownsCommercialCounterpartiersCount(getParameters);
		dispatch(showLoader());
		if (response.status === 200) {
			dispatch(
				getParameters.shutdownTypeId === 1
					? setEmergencyShutdownsCommercialCounterpartiersCount(response.data)
					: setPlannedShutdownsCommercialCounterpartiersCount(response.data)
			);
		}
		dispatch(hideLoader());
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
//Setters for two types of shutdown
export const setEmergencyShutdownsCommercialCounterpartiersCount = emergencyShutdownCommercialCounterpartiesCount => {
	return {
		type: SET_EMERGENCY_SHUTDOWNS_COMMERCIAL_COUNTERPARTIERS_COUNT,
		payload: emergencyShutdownCommercialCounterpartiesCount,
	};
};
export const setPlannedShutdownsCommercialCounterpartiersCount = plannedShutdownCommercialCounterpartiesCount => {
	return {
		type: SET_PLANNED_SHUTDOWNS_COMMERCIAL_COUNTERPARTIERS_COUNT,
		payload: plannedShutdownCommercialCounterpartiesCount,
	};
};

//--------------------------------------------------------
export const getShutdownsResidentialCounterpartiersCount = getParameters => async dispatch => {
	try {
		const response = await dashboardAPI.getShutdownsResidentialCounterpartiersCount(getParameters);
		dispatch(showLoader());
		if (response.status === 200) {
			dispatch(
				getParameters.shutdownTypeId === 1
					? setEmergencyShutdownsResidentialCounterpartiersCount(response.data)
					: setPlannedShutdownsResidentialCounterpartiersCount(response.data)
			);
		}
		dispatch(hideLoader());
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
//Setters for two types of shutdown
export const setEmergencyShutdownsResidentialCounterpartiersCount = emergencyShutdownResidentialCounterpartiesCount => {
	return {
		type: SET_EMERGENCY_SHUTDOWNS_RESIDENTIAL_COUNTERPARTIERS_COUNT,
		payload: emergencyShutdownResidentialCounterpartiesCount,
	};
};
export const setPlannedShutdownsResidentialCounterpartiersCount = plannedShutdownResidentialCounterpartiesCount => {
	return {
		type: SET_PLANNED_SHUTDOWNS_RESIDENTIAL_COUNTERPARTIERS_COUNT,
		payload: plannedShutdownResidentialCounterpartiesCount,
	};
};

//-----------------------------------------------------------
export const getShutdownsCountByElectricAddresses = getParameters => async dispatch => {
	try {
		const response = await dashboardAPI.getShutdownsCountByElectricAddresses(getParameters);
		if (response.status === 200) {
			dispatch(
				getParameters.shutdownTypeId === 1
					? setEmergencyShutdownsCountByElectricAdresses(response.data)
					: setPlannedShutdownsCountByElectricAdresses(response.data)
			);
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
//Setters for two types of shutdown
export const setEmergencyShutdownsCountByElectricAdresses = emergencyShutdownsCountByElectricAddresses => {
	return {
		type: SET_EMERGENCY_SHUTDOWNS_COUNT_BY_ELECTRIC_ADDRESSES,
		payload: emergencyShutdownsCountByElectricAddresses,
	};
};
export const setPlannedShutdownsCountByElectricAdresses = plannedShutdownsCountByElectricAddresses => {
	return {
		type: SET_PLANNED_SHUTDOWNS_COUNT_BY_ELECTRIC_ADDRESSES,
		payload: plannedShutdownsCountByElectricAddresses,
	};
};

export const getAllDashboardData = params => dispatch => {
	Promise.all([
		dispatch(getAppealsCount(params.appealsGetParams)),
		dispatch(getEmergencyShutdownsCount(params.emergencyShutdownsGetParams)),
		dispatch(getPlannedShutdownsCount(params.plannedShutdownsGetParams)),
		dispatch(getShutdownsCommercialCounterpartiersCount(params.emergencyShutdownsGetParams)),
		dispatch(getShutdownsCommercialCounterpartiersCount(params.plannedShutdownsGetParams)),
		dispatch(getShutdownsResidentialCounterpartiersCount(params.emergencyShutdownsGetParams)),
		dispatch(getShutdownsResidentialCounterpartiersCount(params.plannedShutdownsGetParams)),
		dispatch(getShutdownsCountByElectricAddresses(params.emergencyShutdownsGetParams)),
		dispatch(getShutdownsCountByElectricAddresses(params.plannedShutdownsGetParams)),
	]);
};
