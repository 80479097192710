import { Avatar, Button, Divider, Dropdown, Layout, Space } from "antd";
import { CaretDownOutlined, UserOutlined } from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Indicator from "./components/Indicator";
import React from "react";
import UserProfile from "../Profile/UserProfile";
import css from "./AppLayout.module.scss";
import isThisRole from "../../assets/js/helpers/isThisRole";
import { logOut } from "../../redux/actions/loginActions";
import logo from "../../assets/images/header/logo.png";
import { setMenuSelectedKeys } from "./helpers/menuSelectedKeysHelper";
import { useState } from "react";

const LayoutHeader = () => {
	const { Header } = Layout;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const appEnvironment = useSelector(state => state.app.appEnvironment);
	const appVersion = useSelector(state => state.app.appVersion);
	const isAuth = useSelector(state => state.auth.token);
	const userName = useSelector(state => state.auth.userName);
	const userRole = useSelector(state => state.auth.userRole);
	const departmentId = useSelector(state => state.auth.departmentId);
	const notReadAppealCount = useSelector(state => state.appeal.notReadAppealCount);
	const notApprovedClaimsCount = useSelector(state => state.claim.notApprovedClaimsCount);

	const onLogout = () => {
		dispatch(logOut());
		navigate("/");
		setMenuSelectedKeys(["1"]);
	};

	//For start user profile modal
	const [userProfileModalVisible, setUserProfileModalVisible] = useState(false);

	//Profile menu for dropdown
	const profileMenu = [
		{
			key: "1",
			label: (
				<Button
					type='link'
					style={{ color: "#000", padding: 0, width: "100%", textAlign: "left" }}
					onClick={() => setUserProfileModalVisible(true)}
				>
					Профіль
				</Button>
			),
		},
		{
			key: "2",
			label: <Divider style={{ margin: 0 }} />,
		},
		{
			key: "3",
			label: (
				<Button
					type='link'
					style={{ color: "#000", padding: 0, width: "100%", textAlign: "left" }}
					onClick={onLogout}
				>
					Вийти
				</Button>
			),
		},
	];

	return (
		<>
			<Header className={css.header}>
				<div className={css.logo}>
					<img src={logo} alt='logo' />
					{isAuth && <div className={css.version}>{`OMS. ${appEnvironment ?? ""} v.${appVersion}`}</div>}
				</div>

				{isAuth && (
					<div className={css.profile}>
						<Space size='small'>
							{userRole[0] !== "Administrator" && departmentId !== "25" && (
								<NavLink
									to={{ pathname: "/appeals", search: "cameFrom=bell" }}
									onClick={e => {
										if (!notReadAppealCount) e.preventDefault();
										setMenuSelectedKeys(["3_1"]);
									}}
								>
									<Indicator
										indicationName={"Звернення"}
										count={notReadAppealCount}
										color={"rgb(96, 125, 139)"}
									/>
								</NavLink>
							)}

							{userRole[0] !== "Administrator" && (
								<NavLink
									to={{ pathname: "/claims_approval" }}
									onClick={e => {
										if (!notApprovedClaimsCount) e.preventDefault();
										setMenuSelectedKeys(["6_2"]);
									}}
								>
									<Indicator indicationName={"Заявки"} count={notApprovedClaimsCount} color={"orange"} />
								</NavLink>
							)}

							<span className={css.user_data}>
								<b>{userName} </b>
								{isThisRole(userRole, "Administrator") ? (
									<NavLink to='/admin'>({userRole[0]})</NavLink>
								) : (
									<i>({userRole[0]})</i>
								)}
							</span>
						</Space>

						<Dropdown className={css.profile_dropdown_menu} menu={{ items: profileMenu }} trigger={["click"]}>
							<span className={css.default_site_link} onClick={e => e.preventDefault()}>
								<Space className={css.avatar_wrapper}>
									<Avatar
										className={css.avatar}
										icon={<UserOutlined />}
										style={{ backgroundColor: "#87d068" }}
									/>
									<CaretDownOutlined />
								</Space>
							</span>
						</Dropdown>
					</div>
				)}
			</Header>
			{userProfileModalVisible ? <UserProfile onCancel={() => setUserProfileModalVisible(false)} /> : null}
		</>
	);
};

export default LayoutHeader;
