import {
	SET_DEPARTMENT_HIERARCHY_USERS_SUBTREE,
	SET_DRAWER_TASKS_DATA,
	SET_TASKS_DATA,
	SET_TASK_APPEAL,
	SET_TASK_BY_ID,
	SET_TASK_EXECUTION_LOG,
} from "../types";

const initialState = {
	departmentHierarchyUsersSubtree: [],
	tasks: [],
	taskExecutionLog: [],
	taskAppeal: null,
	taskById: undefined,
	drawerTasks: [],
};

export const taskReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_DEPARTMENT_HIERARCHY_USERS_SUBTREE:
			return { ...state, departmentHierarchyUsersSubtree: action.payload };
		case SET_TASKS_DATA:
			return { ...state, tasks: action.payload };
		case SET_DRAWER_TASKS_DATA:
			return { ...state, drawerTasks: action.payload };
		case SET_TASK_EXECUTION_LOG:
			return { ...state, taskExecutionLog: action.payload };
		case SET_TASK_APPEAL:
			return { ...state, taskAppeal: action.payload };
		case SET_TASK_BY_ID:
			return { ...state, taskById: action.payload };
		default:
			return state;
	}
};
