import React, { useEffect } from "react";

import { Card } from "antd";
import { setPageBreadcrumb } from "../../redux/actions/appActions";
import { useDispatch } from "react-redux";

const MobileServices = ({ text }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		const breadCrumbData = [{ name: text, link: false }];
		dispatch(setPageBreadcrumb(breadCrumbData));
	}, [dispatch, text]);

	return (
		<>
			<Card
				title={<b>OMS Mobile</b>}
				type='inner'
				extra={
					<a href='https://minioms.hoe.com.ua/' target='_blank' rel='noreferrer'>
						перейти
					</a>
				}
				style={{
					width: 640,
					marginBottom: 15,
				}}
			>
				<p>
					Скорочена форма звіту РМ-3. Містить оперативну інформацію про відключення у розрізі РЕМ та в цілому по АТ
					«Хмельницькобленерго». <br /> Автентифікація здійснюється по паролю та логіну користувача, не по доменним
					даним.
				</p>
			</Card>

			<Card
				title={<b>Мобільний додаток OMS</b>}
				type='inner'
				extra={
					<a
						href='https://drive.google.com/drive/folders/1CPuXy8WRdwKNMrqIDyN8bH94gC_jNri1'
						target='_blank'
						rel='noreferrer'
					>
						завантажити
					</a>
				}
				style={{
					width: 640,
					marginBottom: 15,
				}}
			>
				<p>
					Отримання завдання на виїзд та вирішення звернень споживачів, створення завдання. <br />
					Для використання додатку потрібно завантажити файл за посиланням та встановити його на телефон. З метою
					забезпечення безпеки даних, додаток працює виключно при підключенні до внутрішньої корпоративної мережі
					через VPN.
				</p>
			</Card>
		</>
	);
};

export default MobileServices;
