import { apiClient } from "./api";
import axios from "axios";

//Stats endpoints
export const statsAPI = {
	getSaidiSaifiData(departmentId, settlementTypeId, dateBegin, dateEnd) {
		return apiClient(axios)
			.post("/Stats/GetSaidiSaifiAsync", { departmentId, settlementTypeId, dateBegin, dateEnd })
			.then(response => response);
	},
	getMaifiData(departmentId, settlementTypeId, dateBegin, dateEnd) {
		return apiClient(axios)
			.post("/Stats/GetMaifiAsync", { departmentId, settlementTypeId, dateBegin, dateEnd })
			.then(response => response);
	},
	getEnsData(departmentId, settlementTypeId, dateBegin, dateEnd) {
		return apiClient(axios)
			.post("/Stats/GetEnsAsync", { departmentId, settlementTypeId, dateBegin, dateEnd })
			.then(response => response);
	},
};
