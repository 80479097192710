import { apiClient } from "./api";
import axios from "axios";

//Notification
export const notificationAPI = {
	createRecipientNotification(recipientNotificationPostParameters) {
		return apiClient(axios)
			.post(`/Notification/CreateRecipientNotification`, recipientNotificationPostParameters)
			.then(response => response);
	},
	createCounterpartyNotification(counterpartyNotificationPostParameters) {
		return apiClient(axios)
			.post(`/Notification/CreateCounterpartyNotification`, counterpartyNotificationPostParameters)
			.then(response => response);
	},
	getShutdownsRecipientsNotificationsHistory(id) {
		return apiClient(axios)
			.get(`/Notification/GetShutdownsRecipientsNotificationsHistory/${id}`)
			.then(response => response);
	},

	getCounterpartiesNotificationsHistory(id) {
		return apiClient(axios)
			.get(`/Notification/GetCounterpartiesNotificationsHistory/${id}`)
			.then(response => response);
	},
};
