import React from 'react';
import { Modal, Button } from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {deleteShutdown} from "../../../redux/actions/shutdownActions";

const ModalForDeleteShutdown = ({ open, onCancel, shutdownId }) => {
    const dispatch = useDispatch();
    const tabelFilter = useSelector(state => state.app.tableFilter);

    const handleOk = () => {
        dispatch(deleteShutdown(shutdownId, tabelFilter));
        onCancel();
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <>
            <Modal
                title="Видалення відключення:"
                open={open}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Ні
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Так
                    </Button>,
                ]}
            >
              <p>Ви дійсно бажаєте видалити дане відключення № "{shutdownId}"?</p>
            </Modal>
        </>
    );
};

export default ModalForDeleteShutdown