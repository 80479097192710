import React, { useEffect } from "react";

import css from "./TempRM3.module.scss";
import { setPageBreadcrumb } from "../../../redux/actions/appActions";
import { useDispatch } from "react-redux";

const TempRM3 = ({ text }) => {
	const dispatch = useDispatch();

	const data = [
		{
			title: "",
			values: [
				{ subtitle: "ПЛ-110кВ", value: 0 },
				{ subtitle: "ПC-110кВ", value: 0 },
				{ subtitle: "ПЛ-35кВ", value: 0 },
				{ subtitle: "ПC-35кВ", value: 0 },
			],
		},
		{
			title: "ПЛ-10кВ",
			values: [
				{ subtitle: "Всього", value: 2 },
				{ subtitle: "У т.ч. частково", value: 0 },
				{ subtitle: "Більше доби", value: 0 },
			],
		},
		{
			title: "Населені пункти",
			values: [
				{ subtitle: "Всього", value: 0 },
				{ subtitle: "У т.ч. частково", value: 0 },
				{ subtitle: "Більше доби", value: 0 },
			],
		},
		{
			title: "ТП",
			values: [
				{ subtitle: "Всього", value: 0 },
				{ subtitle: "Більше доби", value: 0 },
			],
		},
		{
			title: "Задіяно для відновлення електроенергії",
			values: [
				{ subtitle: "Бригад", value: 0 },
				{ subtitle: "Робітників", value: 0 },
				{ subtitle: "Техніки", value: 0 },
			],
		},
		{
			title: "Знеструмлені споживачі",
			values: [
				{ subtitle: "Всього", value: 0 },
				{ subtitle: "Побутові", value: 0 },
				{ subtitle: "Непобутові", value: 0 },
			],
		},
		{
			title: "",
			values: [
				{ subtitle: "Фактичне навантаження", value: 0 },
				{ subtitle: "Нормоване навантаження", value: 0 },
				{ subtitle: "Різниця", value: 0 },
				{ subtitle: "Відсоток реалізації", value: 0 },
			],
		},
	];

	useEffect(() => {
		const breadCrumbData = [{ name: text, link: false }];
		dispatch(setPageBreadcrumb(breadCrumbData));
	}, [dispatch, text]);

	return (
		<div className={css.temp_rm3_container}>
			<div className={css.mobile_container}>
				{data.map((el, i) => (
					<Rm3Item key={i + 1} title={el.title} values={el.values} />
				))}
			</div>
		</div>
	);
};

export default TempRM3;

const Rm3Item = ({ title, values }) => {
	return (
		<>
			<div className={css.item_container}>
				{title && <div className={css.item_title}>{title}</div>}
				{values.map(el => (
					<div className={css.sub_item}>
						<div className={css.sub_item_title}>{el.subtitle} - </div>
						<div className={css.sub_item_value}>{el.value}</div>
					</div>
				))}
			</div>
		</>
	);
};
