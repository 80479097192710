import {
  SET_MAP_DATA_BY_OVB
} from "../types";

const initialState = {
  mapDataByOvb: []
};

export const ovbReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MAP_DATA_BY_OVB:
      return { ...state, mapDataByOvb: action.payload };
    default:
      return state;
  }
};
