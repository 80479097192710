import { apiClient } from "./api";
import axios from "axios";

//Scada events endpoints
export const scadaEventsAPI = {
	getScadaEvents(params) {
		return apiClient(axios)
			.post("/ScadaEvents/GetAll", { ...params })
			.then(response => response);
	},
};
