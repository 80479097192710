import {SET_CREATE_EDIT_SHUTDOWNS_TIME_SETTINGS} from "../types";

const initialState = {
    shutdownsTimeSettings: [],
};

export const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CREATE_EDIT_SHUTDOWNS_TIME_SETTINGS:
            return {...state, shutdownsTimeSettings: action.payload};
        default:
            return state
    }
};