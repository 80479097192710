import { apiClient } from "./api";
import axios from "axios";

//Claims endpoints
export const claimsAPI = {
	getApprovals(approvalGetParameters) {
		return apiClient(axios)
			.post(`/Claim/GetApprovals`, { ...approvalGetParameters })
			.then(response => response);
	},

	getClaims(claimGetParameters) {
		return apiClient(axios)
			.post(`/Claim/GetAll`, { ...claimGetParameters })
			.then(response => response);
	},

	getClaimById(claimId) {
		return apiClient(axios)
			.post(`/Claim/GetAll`, { filter: `id=${claimId}` })
			.then(response => response);
	},

	createClaim(claimPostParameters) {
		return apiClient(axios)
			.post("/Claim", { ...claimPostParameters })
			.then(response => response);
	},

	editClaim(claimId, claimPostParameters) {
		return apiClient(axios)
			.put(`/Claim/${claimId}`, { ...claimPostParameters })
			.then(response => response);
	},

	deleteClaim(claimId) {
		return apiClient(axios)
			.delete(`/Claim/${claimId}`)
			.then(response => response);
	},

	getClaimExecutionLog(claimId) {
		return apiClient(axios)
			.get(`/Claim/GetClaimExecutionLog/${claimId}`)
			.then(response => response);
	},

	approveClaims(data) {
		return apiClient(axios)
			.post(`/Claim/ApproveClaims`, { ...data })
			.then(response => response);
	},

	getNotApprovedClaimsCount(claimId) {
		return apiClient(axios)
			.get(`/Claim/GetNotApprovedClaimsCount`)
			.then(response => response);
	},

	generateClaimDocument(claimId) {
		return apiClient(axios)
			.get(`/Claim/GenerateClaimDocument/${claimId}`, { responseType: "blob" })
			.then(response => response);
	},
};
