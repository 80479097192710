import {
	SET_APPROVALS,
	SET_CLAIMS,
	SET_CLAIM_BY_ID,
	SET_CLAIM_EXECUTION_LOG,
	SET_NOT_APPROVED_CLAIMS_COUNT,
} from "../types";
import { approvalGetParameters, claimGetParameters } from "../../api/parameters";
import { hideLoader, setApiLastError, setSuccess, showLoader } from "./appActions";

import { claimsAPI } from "../../api/claimsEndpoints";
import { createDownloadFile, getFileName } from "../../assets/js/helpers/createDownloadFile";
import { thunkErrorHandler } from "./actionHandlers";

export const getApprovals = approvalGetParameters => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await claimsAPI.getApprovals(approvalGetParameters);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setApprovals(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};
export const setApprovals = approvals => {
	return {
		type: SET_APPROVALS,
		payload: approvals,
	};
};

export const getClaims = claimGetParameters => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await claimsAPI.getClaims(claimGetParameters);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setClaims(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};
export const setClaims = claims => {
	return {
		type: SET_CLAIMS,
		payload: claims,
	};
};

export const getClaimById = claimId => async dispatch => {
	try {
		const response = await claimsAPI.getClaimById(claimId);
		if (response.status >= 200 && response.status < 300) {
			const result = response.data.data?.shift(0);
			dispatch(setClaimById(result));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
export const setClaimById = claim => {
	return {
		type: SET_CLAIM_BY_ID,
		payload: claim,
	};
};

export const createClaim = claimPostParameters => async dispatch => {
	try {
		const response = await claimsAPI.createClaim(claimPostParameters);
		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));
		if (response.status >= 200 && response.status < 300) {
			dispatch(setSuccess("Заявку успішно створено!"));
			dispatch(setSuccess(null));
			dispatch(setApiLastError({ isErrorExists: false, errorObject: null }));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const editClaim = (claimId, claimPostParameters) => async dispatch => {
	try {
		const response = await claimsAPI.editClaim(claimId, claimPostParameters);
		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));
		if (response.status >= 200 && response.status < 300) {
			dispatch(setSuccess("Заявку успішно відредаговано!"));
			dispatch(setSuccess(null));
			dispatch(setApiLastError({ isErrorExists: false, errorObject: null }));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const deleteClaim = (claimId, tableFilter) => async dispatch => {
	try {
		const response = await claimsAPI.deleteClaim(claimId);
		if (response.status >= 200 && response.status < 300) {
			claimGetParameters.filter = tableFilter;
			dispatch(getClaims(claimGetParameters));
			dispatch(setSuccess("Заявку успішно видалено!"));
			dispatch(setSuccess(null));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getClaimExecutionLog = claimId => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await claimsAPI.getClaimExecutionLog(claimId);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setClaimExecutionLog(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};
export const setClaimExecutionLog = claimExecutionLog => {
	return {
		type: SET_CLAIM_EXECUTION_LOG,
		payload: claimExecutionLog,
	};
};

export const approveClaims = data => async dispatch => {
	try {
		const response = await claimsAPI.approveClaims(data);
		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));
		if (response.status >= 200 && response.status < 300) {
			dispatch(setSuccess("Заявки успішно змінено!"));
			dispatch(setSuccess(null));
			dispatch(setApiLastError({ isErrorExists: false, errorObject: null }));
			dispatch(getApprovals(approvalGetParameters));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getNotApprovedClaimsCount = () => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await claimsAPI.getNotApprovedClaimsCount();
		if (response.status >= 200 && response.status < 300) {
			dispatch(setNotApprovedClaimsCount(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};
export const setNotApprovedClaimsCount = notApprovedClaimsCount => {
	return {
		type: SET_NOT_APPROVED_CLAIMS_COUNT,
		payload: notApprovedClaimsCount,
	};
};

export const generateClaimDocument = claimId => async dispatch => {
	try {
		const response = await claimsAPI.generateClaimDocument(claimId);
		const contentDisposition = response.headers.get("Content-Disposition");
		let fileName = contentDisposition ? getFileName(contentDisposition) : null;
		fileName = fileName || `Заявка №${claimId}.docx`;
		
		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));

		if (response.status >= 200 && response.status < 300) {
			createDownloadFile(response, fileName);
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
