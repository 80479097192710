import { Divider, Space, Timeline } from "antd";
import React, { useEffect } from "react";

import CheckCircleTwoTone from "@ant-design/icons/es/icons/CheckCircleTwoTone";
import FireFilled from "@ant-design/icons/es/icons/FireFilled";
import { Image } from "antd";
import StarFilled from "@ant-design/icons/es/icons/StarFilled";
import css from "./DevInfo.module.scss";
import img_001 from "./img/claims_001.png";
import img_002 from "./img/header_001.png";
import img_003 from "./img/recipients_001.png";
import img_004 from "./img/recipients_002.png";
import img_005 from "./img/nkrekp_5_001.png";
import img_006 from "./img/paid_services_001.png";
import { setPageBreadcrumb } from "../../redux/actions/appActions";
import { useDispatch } from "react-redux";

const DevInfo = ({ text }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		const breadCrumbData = [{ name: text, link: false }];
		dispatch(setPageBreadcrumb(breadCrumbData));
	}, [dispatch, text]);

	return (
		<>
			<Divider className={css.caption} orientation='left'>
				Оновлення системи
			</Divider>

			<Timeline
				className={css.dev_info}
				items={[
					{
						dot: <FireFilled style={{ fontSize: "26px" }} />,
						color: "orange",
						children: (
							<p>
								Додана можливість додавання / видалення / перегляду / завантаження файлів рахунків у платних
								послугах.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								Додано групову обробку скарг/пропозицій (закриття). У статистику додано "ENS", а у "Звіти" -
								звіт по статистиці.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								На всіх причетних формах (скарги, відключення, платні послуги) та таблицях додано поле
								"Відділення".
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: <p>Доданий звіт "Контроль звернень" (меню "Звіти").</p>,
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								<Space size={12}>
									Додано модуль "Заяви на послуги" ("Платні послуги", меню "Звернення"), а також весь
									функціонал по їх створенню / редагуванню / видаленню / перегляду / створенню завдання.
									Доданий звіт "Ефективність РЕМ" (меню "Звіти").
								</Space>
								<br />
								<Space size={12}>
									<Image width={200} src={img_006} />
								</Space>
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								<Space size={12}>
									Додано модуль SCADA (меню "Відключення"). Доданого нового отримувача сповіщень. Змінено
									принцип створення отримувачів. Доданий звіт (НКРЕ) "Додаток-5".{" "}
								</Space>
								<br />
								<Space size={12}>
									<Image width={200} src={img_003} />
									<Image width={200} src={img_004} />
									<Image width={200} src={img_005} />
								</Space>
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								<Space size={12}>
									Додано погодження / відхилення заявок на ремонт обладнання. Виправлені помилки в решті
									сервісів. В перегляді заявок додано два нових блока (для перегляду натисніть на малюнок 1). В
									верхній панелі програми замість дзвіночка для звернень і заявок (погодження) буде з'являтись
									новий окремий компонент, для кожного розділу свій. По кліку він переадресує у потрібне місце
									(для перегляду натисніть на малюнок 2).
								</Space>
								<Space size={12}>
									<Image width={200} src={img_001} />
									<Image width={200} src={img_002} />
								</Space>
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								Закінчено міграцію на останню версію REACT, ANT Design Та React Router v.6. Виправлені помилки.
								Додано інструменти управління заявками на ремонт обладнання: перегляд / створення / редагування
								/ видалення.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								У меню "Відключення" додано відображення SMART відключень та до мап додано відображення
								координат відключень на карті. У "Звіти" додано пошук споживачів по адресі та електронній
								адресі.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								Додано звіт "РМ-3", та звіт "пошук відключень". Додано сповіщення про непрочитані звернення.
								Додано мультивибір тем у звіті "Звернення". Виведено версію системи.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								Додані групові операції з аварійними відключеннями для закриття та підтвердження груп
								відключень.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: <p>Додано звіт по активним відключенням з опцією створення Excel файлу.</p>,
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								Додано функціонал відновлення пароля, який тепер знаходиться на формі входу. Додано пошук по
								населеним пунктам при створенні звернень, та відображення цього ж пункту при перегляді звернень.
								Додано звіт по протермінованим зверненням (меню "Звіти").
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: <p>Виправлені помилки". Доданий звіт по ТОП відключенням.</p>,
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								У таблицю відображення завдань підключено сортування, фільтри і пошуки. Прострочені по виконанню
								завдання (у статусі "В РОБОТІ") виділяються червоним.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								В адмінку (прив'язка користувачів) додано "Апарат управління". У профіль (звернення) додано нові
								теми. Додано таблицю перегляду завдань, форму створення та редагування завдань, опцію перегляду
								та опцію їх видалення. Підключено алгоритм по зміні статусів (матриця).
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								У адмінку доданий новий розділ для прив'язки / відв'язки користувачів у підрозділах. Додана
								форма профілю користувача (клік по аватарці або стрілці поряд, далі меню), в якій є загальна
								інформація і можливо змінити ПІБ та пароль у вкладці "Загальна інформація". У вкладці
								"Звернення" можна робити прив'язку / відв'язку до дозволених користувачу тем. Кнопка "ВИЙТИ"
								тепер також у меню де профіль.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								Додана можливість приєднання файлів при створенні та редагуванні звернень, а також їх перегляд
								та завантаження.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								При створенні / редагуванні відключень активовані часові проміжки. Тепер робота з відключеннями
								відбувається тільки тоді, коли це допускається для кожного типу відключень.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								Розроблено звіти для колцентру. Розроблена головна сторінка з основною статистикою по
								відключенням і заявкам. Меню "Що нового?" зміщено вниз.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: <p>Додані деякі звіти по відключенням. Виправлені права доступу по ролям.</p>,
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								Повністю перероблена функція відображення статусів відключень. Якщо статус відключення згідно
								матриці заборонений для переходу, то він не відображається.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								При створенні відключення тепер заборонено міняти статуси. При редагуванні відключень статуси
								можна міняти тільки один раз у іншому випадку тепер є кнопка "відмінити редагування", яка
								повертає все на початок редагування. Підключено нотифікацію контрагентів. Виправлено початковий
								фактичний час відключення (-12 годин). Виправлено роботу фільтрів після видалення
								відключень/звернень/користувачів/отримувачів. Виправлено помилку відображення ел. адрес у
								диспетчерів з маленькими моніторами.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								Додано матрицю статусів відключень та звернень. Додано алгоритм, який відстежує і виводить
								помилки різного типу (+ серверні) на фронт-енд. Тепер якщо помилка, то вона виводиться на
								сторінку і ми лишаємось на тій же сторінці (формі, pop-up формі) до вирішення проблеми.
								Добавлено історію сповіщень контрагентів у форму створення відключення. Ліквідовані всі поточні
								помилки, про які повідомлялось, чи було знайдено самостійно.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								Додана форма для перегляду звернень та відключень з кольоровою диференціацією. Додано кнопку
								"Обнулити фільтр" на всі потрібні таблиці. Поправлена робота календаря відключень.'
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: <p>Проведена робота по ТЗ, крім моментів, які залежать від back-end.</p>,
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: <p>Виправлено помилки по фільтрам, датам і т.п.'</p>,
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								Додані фільтри до таблиць "Звернення", "Відключення" та "Список користувачів". Додано попередній
								перегляд "Звернення". Виправлені численні помилки.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								Додані рольові правила роботи зі зверненнями (перегляд/редагування/видалення), а також функції
								(перегляд/редагування/видалення). Додано фільтри у таблицю звернень по "Точка входу",
								"Класифікація", "Статус", та відключено селектор по точці входу.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								Змінено дизайн форми створення/редагування відключень. Додано форми створення/редагування/виводу
								звернень. Поки в альфа тестуванні. У форму користувачів додано підрозділ користувача, який у
								подальшому працює з зверненнями.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								У дерево електричних адрес додано можливість мультивибору та створення мультивідключень одного
								типу. Додано реліз календарю відключень.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								Додано фільтри по РЕМ і типам отримувачів у таблицях адмінки. Всі компоненти повністю
								локалізовані під українську мову.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								Додано календар (alpha version). Додані лічильники комерційних та побутових отримувачів.
								Виправлені граматичні помилки. Виправлені баги.
							</p>
						),
					},
					{
						dot: <CheckCircleTwoTone style={{ fontSize: "18px" }} />,
						children: (
							<p>
								Виправлені помилки по отримувачам сповіщень(персонал). При створення відключень, нотифікація
								приховується. Додано функціонал історії повідомлень.
							</p>
						),
					},
					{
						dot: <StarFilled style={{ fontSize: "26px" }} />,
						children: (
							<p>
								Додано адмінську частину та алгоритми її заповнення. Додано інтерфейс та всі сервіси
								(заповнення, видалення, відображення) планових відключень. Додано інтерфейс та всі сервіси
								(заповнення, видалення, відображення) аварійних відключень. Додано сервіс інформування
								персоналу. Йде тестування оповіщення персоналу по аварійним та плановим відключенням.
							</p>
						),
					},
				]}
			/>
		</>
	);
};

export default DevInfo;
