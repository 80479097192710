import {
	BorderOuterOutlined,
	BranchesOutlined,
	CalendarOutlined,
	CarryOutOutlined,
	DollarOutlined,
	FolderOpenFilled,
	FundFilled,
	HomeFilled,
	InfoCircleOutlined,
	LaptopOutlined,
	MobileOutlined,
	MoreOutlined,
	PhoneFilled,
	PicLeftOutlined,
	PicRightOutlined,
	ScheduleFilled,
	SoundOutlined,
	SyncOutlined,
	ThunderboltFilled,
	ToolFilled,
	UnorderedListOutlined,
	WarningOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { getMenuSelectedKeys, setMenuSelectedKeys } from "./helpers/menuSelectedKeysHelper";

import { NavLink } from "react-router-dom";
import css from "./AppLayout.module.scss";

const LayoutMenu = () => {
	const { Sider } = Layout;
	const [collapsed, setCollapsed] = useState(false);

	const menuSelectedKeys = getMenuSelectedKeys();

	const onCollapse = () => {
		setCollapsed(!collapsed);
	};

	//For marking menu item (calendar <= => shutdown)
	const onClick = item => {
		setMenuSelectedKeys([item.key]);
	};

	useEffect(() => {
		if (window.innerWidth < 1220) setCollapsed(true);
	}, []);

	const menuItems = [
		{
			key: "0",
			className: css.icon_collapse,
			title: `${collapsed ? "Розгорнути меню" : "Згорнути меню"}`,
			label: React.createElement(collapsed ? MoreOutlined : UnorderedListOutlined, {
				onClick: window.innerWidth < 600 ? null : onCollapse,
			}),
		},
		{
			key: "1",
			icon: <HomeFilled />,
			label: <NavLink to={"/main"}>Головна</NavLink>,
		},
		{
			key: "2",
			icon: <FundFilled />,
			label: <NavLink to={"/stats"}>Показники</NavLink>,
		},
		{
			key: "3",
			icon: <PhoneFilled />,
			label: "Звернення",
			children: [
				{
					key: "3_1",
					icon: <SoundOutlined />,
					label: <NavLink to={{ pathname: "/appeals" }}>Скарги, пропозиції</NavLink>,
				},
				{
					key: "3_2",
					icon: <DollarOutlined />,
					label: <NavLink to={{ pathname: "/appeal-paid-service" }}>Заяви на послуги</NavLink>,
				},
			],
		},
		{
			key: "4",
			icon: <ScheduleFilled />,
			label: <NavLink to={"/tasks"}>Завдання</NavLink>,
		},
		{
			key: "5",
			icon: <ThunderboltFilled />,
			label: "Відключення",
			children: [
				{
					key: "5_1",
					icon: <WarningOutlined />,
					label: (
						<NavLink
							to={{
								pathname: "/shutdowns/1",
							}}
						>
							Аварійні
						</NavLink>
					),
				},
				{
					key: "5_2",
					icon: <CarryOutOutlined />,
					label: (
						<NavLink
							to={{
								pathname: "/shutdowns/2",
							}}
						>
							Планові
						</NavLink>
					),
				},
				{
					key: "5_3",
					icon: <CalendarOutlined />,
					label: <NavLink to={"/shutdown_calendar"}>Календар</NavLink>,
				},
				{
					key: "5_4",
					icon: <BorderOuterOutlined />,
					label: <NavLink to={"/shutdown_maps"}>Мапи</NavLink>,
				},
				{
					key: "5_5",
					icon: <BranchesOutlined />,
					label: <NavLink to={"/scada"}>SCADA</NavLink>,
				},
				{
					key: "5_6",
					icon: <LaptopOutlined />,
					label: <NavLink to={"/smart"}>SMART</NavLink>,
				},
			],
		},
		{
			key: "6",
			icon: <ToolFilled />,
			label: "Ремонт обладнання",
			children: [
				{
					key: "6_1",
					icon: <PicLeftOutlined />,
					label: <NavLink to={"/claims"}>Заявки</NavLink>,
				},
				{
					key: "6_2",
					icon: <PicRightOutlined />,
					label: <NavLink to={"/claims_approval"}>Погодження заявок</NavLink>,
				},
			],
		},
		{
			key: "7",
			icon: <FolderOpenFilled />,
			label: "Звіти",
			children: [
				{
					key: "7_1",
					icon: <InfoCircleOutlined />,
					label: <NavLink to={"/active-shutdowns-report"}>Активні відключення</NavLink>,
				},
				{
					key: "7_2",
					icon: <InfoCircleOutlined />,
					label: "Звернення",
					children: [
						{
							key: "7_2_1",
							icon: <InfoCircleOutlined />,
							label: <NavLink to={"/overdued-appeal-complains-report"}>Скарги</NavLink>,
						},
						{
							key: "7_2_2",
							icon: <InfoCircleOutlined />,
							label: <NavLink to={"/overdued-appeal-paid-services-report"}>Заяви на послуги</NavLink>,
						},
					],
				},
				{
					key: "7_3",
					icon: <InfoCircleOutlined />,
					label: <NavLink to={"/department-efficiency-report"}>Ефективність РЕМ</NavLink>,
				},
				{
					key: "7_4",
					icon: <InfoCircleOutlined />,
					label: <NavLink to={"/appeals-control-report"}>Контроль звернень</NavLink>,
				},
				{
					key: "7_5",
					icon: <InfoCircleOutlined />,
					label: <NavLink to={"/nkre373-report"}>НКРЕКП звернення КЦ</NavLink>,
				},
				{
					key: "7_6",
					icon: <InfoCircleOutlined />,
					label: <NavLink to={"/stats-report"}>Показники</NavLink>,
				},
				{
					key: "7_7",
					icon: <InfoCircleOutlined />,
					label: <NavLink to={"/shutdown-search-report"}>Пошук відключень</NavLink>,
				},
				{
					key: "7_8",
					icon: <InfoCircleOutlined />,
					label: <NavLink to={"/counterparty-search-report"}>Пошук споживачів</NavLink>,
				},
				{
					key: "7_9",
					icon: <InfoCircleOutlined />,
					label: <NavLink to={"/shutdowns-per-year-report"}>ТОП відключень</NavLink>,
				},
				{
					key: "7_10",
					icon: <InfoCircleOutlined />,
					label: <NavLink to={"/rm3-report"}>Форма РМ-3</NavLink>,
				},
			],
		},
		{
			key: "8",
			icon: <MobileOutlined />,
			label: <NavLink to={"/mobile-services"}>Мобільні сервіси</NavLink>,
		},
		{
			key: "9",
			icon: <SyncOutlined />,
			label: <NavLink to={"/dev-info"}>Оновлення системи</NavLink>,
		},
	];

	return (
		<Sider className={css.sider_layout} trigger={null} collapsible collapsed={collapsed} width={240} theme={"light"}>
			<Menu
				theme='light'
				//defaultOpenKeys={['sub1', 'sub2', 'sub3']} //Sub menu item index if need for default open
				items={menuItems}
				defaultSelectedKeys={["1"]} //Menu selected item index
				selectedKeys={menuSelectedKeys}
				mode='inline'
				onClick={onClick}
			/>
		</Sider>
	);
};
export default LayoutMenu;
