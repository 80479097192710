import {
	SET_APPEAL_CLASSIFIERS,
	SET_APPEAL_COMMUNICATION_TYPES,
	SET_APPEAL_OBTAINING_METHODS,
	SET_APPEAL_PAID_SERVICE_TYPES,
	SET_APPEAL_STATUSES,
	SET_APPEAL_SUBJECTS,
	SET_APPEAL_SUBJECT_GROUPS,
	SET_BRANCHES,
	SET_CLAIM_APPROVAL_DEFAULTS,
	SET_CLAIM_APPROVAL_LEVELS,
	SET_CLAIM_EMERGENCY_READINESS,
	SET_CLAIM_REPAIR_TYPES,
	SET_CLAIM_STATUSES,
	SET_CLAIM_TYPES,
	SET_COUNTERPARTY_TYPES,
	SET_DEPARTMENTS_FOR_HIERARCHY,
	SET_DEPARTMENT_DATA,
	SET_HOLIDAY_TYPES,
	SET_METER_TYPES,
	SET_NOTIFICATION_STATUSES,
	SET_NOTIFICATION_TYPES,
	SET_RECIPIENT_GROUPS,
	SET_RECIPIENT_TYPES_DATA,
	SET_SCADAS,
	SET_SCADA_SUBSTANTIONS,
	SET_SCADA_SUBSTANTION_VOLTAGES,
	SET_SCADA_SYGNAL_STATUSES,
	SET_SETTLEMENT_TYPES,
	SET_SHUTDOWN_DRIVERS,
	SET_SHUTDOWN_JOB_TYPE_REASONS,
	SET_SHUTDOWN_STATUSES,
	SET_SHUTDOWN_TYPES,
	SET_STATUSES_MATRIX_TYPES,
	SET_TASK_INITIATORS,
	SET_TASK_STATUSES,
	SET_USER_DEPARTMENT_ENTRY_POINTS,
	SET_USER_ROLES,
} from "../types";

const initialState = {
	userRoles: [],
	departments: [],
	shutdownTypes: [],
	shutdownDrivers: [],
	shutdownStatuses: [],
	notificationStatuses: [],
	notificationTypes: [],

	settlementTypes: [],
	departmentEntryPoints: [],
	recipientTypes: [],
	appealClassifiers: [],
	counterpartyTypes: [],
	appealStatuses: [],
	taskStatuses: [],
	taskInitiators: [],
	appealSubjects: [],
	appealCommunicationTypes: [],
	appealObtainingMethods: [],
	statusesMatrixTypes: [],
	holidayTypes: [],
	departmentsForHierarchy: [],
	appealSubjectGroups: [],
	claimEmergencyReadiness: [],
	claimTypes: [],
	claimRepairTypes: [],
	claimApprovalDefaults: [],
	claimStatuses: [],
	recipientGroups: [],
	claimApprovalLevels: [],
	shutdownJobTypeReasons: [],
	scadas: [],
	scadaSubstantions: [],
	scadaSubstantionVoltages: [],
	scadaSygnalStatuses: [],
	appealPaidServiceTypes: [],
	branches: [],
	meterTypes: [],
};

export const dictionaryReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_USER_ROLES:
			return { ...state, userRoles: action.payload };
		case SET_DEPARTMENT_DATA:
			//Insert our(new) code res
			action.payload.forEach((item, index) => {
				item.appDepartmentId = index;
			});
			return {
				...state,
				departments: action.payload,
			};

		case SET_SHUTDOWN_TYPES:
			return { ...state, shutdownTypes: action.payload };
		case SET_SHUTDOWN_DRIVERS:
			return { ...state, shutdownDrivers: action.payload };
		case SET_SHUTDOWN_STATUSES:
			return { ...state, shutdownStatuses: action.payload };
		case SET_NOTIFICATION_STATUSES:
			return { ...state, notificationStatuses: action.payload };
		case SET_NOTIFICATION_TYPES:
			return { ...state, notificationTypes: action.payload };
		case SET_SETTLEMENT_TYPES:
			return { ...state, settlementTypes: action.payload };
		case SET_USER_DEPARTMENT_ENTRY_POINTS:
			return { ...state, departmentEntryPoints: action.payload };
		case SET_RECIPIENT_TYPES_DATA:
			return { ...state, recipientTypes: action.payload };
		case SET_APPEAL_CLASSIFIERS:
			return { ...state, appealClassifiers: action.payload };
		case SET_COUNTERPARTY_TYPES:
			return { ...state, counterpartyTypes: action.payload };
		case SET_APPEAL_STATUSES:
			return { ...state, appealStatuses: action.payload };
		case SET_TASK_STATUSES:
			return { ...state, taskStatuses: action.payload };
		case SET_TASK_INITIATORS:
			return { ...state, taskInitiators: action.payload };
		case SET_APPEAL_SUBJECTS:
			return { ...state, appealSubjects: action.payload };
		case SET_APPEAL_COMMUNICATION_TYPES:
			return { ...state, appealCommunicationTypes: action.payload };
		case SET_APPEAL_OBTAINING_METHODS:
			return { ...state, appealObtainingMethods: action.payload };
		case SET_STATUSES_MATRIX_TYPES:
			return { ...state, statusesMatrixTypes: action.payload };
		case SET_HOLIDAY_TYPES:
			return { ...state, holidayTypes: action.payload };
		case SET_DEPARTMENTS_FOR_HIERARCHY:
			return { ...state, departmentsForHierarchy: action.payload };
		case SET_APPEAL_SUBJECT_GROUPS:
			return { ...state, appealSubjectGroups: action.payload };
		case SET_CLAIM_EMERGENCY_READINESS:
			return { ...state, claimEmergencyReadiness: action.payload };
		case SET_CLAIM_TYPES:
			return { ...state, claimTypes: action.payload };
		case SET_CLAIM_REPAIR_TYPES:
			return { ...state, claimRepairTypes: action.payload };
		case SET_CLAIM_APPROVAL_DEFAULTS:
			return { ...state, claimApprovalDefaults: action.payload };
		case SET_CLAIM_STATUSES:
			return { ...state, claimStatuses: action.payload };
		case SET_RECIPIENT_GROUPS:
			return { ...state, recipientGroups: action.payload };
		case SET_CLAIM_APPROVAL_LEVELS:
			return { ...state, claimApprovalLevels: action.payload };
		case SET_SHUTDOWN_JOB_TYPE_REASONS:
			return { ...state, shutdownJobTypeReasons: action.payload };
		case SET_SCADAS:
			return { ...state, scadas: action.payload };
		case SET_SCADA_SUBSTANTIONS:
			return { ...state, scadaSubstantions: action.payload };
		case SET_SCADA_SUBSTANTION_VOLTAGES:
			return { ...state, scadaSubstantionVoltages: action.payload };
		case SET_SCADA_SYGNAL_STATUSES:
			return { ...state, scadaSygnalStatuses: action.payload };
		case SET_APPEAL_PAID_SERVICE_TYPES:
			return { ...state, appealPaidServiceTypes: action.payload };
		case SET_BRANCHES:
			return { ...state, branches: action.payload };
		case SET_METER_TYPES:
			return { ...state, meterTypes: action.payload };
		default:
			return state;
	}
};
