export const setTaskStatusTagColor = status => {
	let color = "default";
	if (status === 1) color = "blue";
	if (status === 2) color = "green";
	if (status === 3) color = "red";
	if (status === 4) color = "default";
	return color;
};

export const setAppealStatusTagColor = status => {
	let color = "default";
	if (status === 1) color = "red";
	if (status === 2) color = "blue";
	if (status === 3) color = "default";
	return color;
};

export const setClaimStatusTagColor = status => {
	let color = "default";
	if (status === 1) color = "blue";
	if (status === 2) color = "green";
	if (status === 3) color = "orange";
	if (status === 4) color = "red";
	if (status === 5) color = "default";
	return color;
};
