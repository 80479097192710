import {
	ApiOutlined,
	CheckCircleFilled,
	CheckCircleOutlined,
	CloseCircleOutlined,
	QuestionCircleOutlined,
	ThunderboltOutlined,
} from "@ant-design/icons";
import { Col, Divider, Drawer, Row, Timeline } from "antd";
import React, { useEffect, useState } from "react";
import { getClaimById, getClaimExecutionLog } from "../../../redux/actions/claimActions";
import { useDispatch, useSelector } from "react-redux";

import { claimExecutionLogCreator } from "../helpers/claimExecutionLogCreator";
import css from "./ViewClaimDrawer.module.scss";
import moment from "moment";
import { setStatusColor } from "./helpers/setStatusColor";

const ViewClaimDrawer = ({ open, onClose, claimId }) => {
	const dispatch = useDispatch();
	const dateFormat = "YYYY-MM-DD (HH:mm)";
	const timelineIndexPrefix = "timelineIndex_";
	const pIndexPrefix = "pIndex_";

	const claim = useSelector(state => state.claim.claimById);
	const claimExecutionLog = useSelector(state => state.claim.claimExecutionLog);

	const [convertedExecutionLog, setConvertedExecutionLog] = useState([]);

	//Get claim and etc
	useEffect(() => {
		if (!claimId || !open) return;
		dispatch(getClaimById(claimId));
		dispatch(getClaimExecutionLog(claimId));
	}, [dispatch, claimId, open]);

	useEffect(() => {
		if (claimExecutionLog) setConvertedExecutionLog(claimExecutionLogCreator(claimExecutionLog));
		else setConvertedExecutionLog([]);
	}, [claimExecutionLog]);

	return (
		<>
			{claim ? (
				<Drawer
					title={<b>{`Перегляд заявки №${claim.id}:`}</b>}
					placement='right'
					width={"25%"}
					headerStyle={{ backgroundColor: setStatusColor(claim?.claimStatusId).titleColor, borderRadius: "0px" }}
					drawerStyle={{ borderLeft: `4px solid ${setStatusColor(claim?.claimStatusId).lineColor}` }}
					onClose={onClose}
					open={open}
				>
					<div className={css.view_claim_wrapp}>
						<Row className={css.item}>
							<Col span={12}>
								<Row className={css.sub_item_title}>Статус</Row>
								<Row style={{ color: setStatusColor(claim?.claimStatusId).labelColor }}>
									{claim.claimStatusName}
								</Row>
							</Col>
							<Col span={12}>
								<Row className={css.sub_item_title}>Вид заявки</Row>
								<Row>{claim.claimTypeName}</Row>
							</Col>
						</Row>

						<Row className={css.item}>
							<Col span={12}>
								<Row className={css.sub_item_title}>Очікуваний час</Row>
								<Row className={css.sub_item_title}>початок</Row>
								<Row>{moment(claim.startDate).format(dateFormat)}</Row>
								<Row className={css.sub_item_title}>завершення</Row>
								<Row>{moment(claim.endDate).format(dateFormat)}</Row>
							</Col>
							<Col span={12}>
								<Row className={css.sub_item_title}>Фактичний час</Row>
								<Row className={css.sub_item_title}>початок</Row>
								<Row>{claim.realStartDate ? moment(claim.realStartDate).format(dateFormat) : "Невідомо (заявка неактивна)"}</Row>
								<Row className={css.sub_item_title}>завершення</Row>
								<Row>{claim.realEndDate ? moment(claim.realEndDate).format(dateFormat) : "Невідомо (заявка неактивна)"}</Row>
							</Col>
						</Row>

						<Row className={css.item}>
							<Col span={12}>
								<Row className={css.sub_item_title}>Аварійна готовність, год</Row>
								<Row>{claim.claimEmergencyReadinessValue}</Row>
							</Col>
							<Col span={12}>
								<Row className={css.sub_item_title}>Дата та час введення обладнання в роботу</Row>
								<Row>
									{claim.dateEquipmentStartedWorking
										? moment(claim.dateEquipmentStartedWorking).format(dateFormat)
										: "Невідомо (заявку не закрито)"}
								</Row>
							</Col>
						</Row>

						<Divider />

						<Row className={css.item}>
							<Col className={css.sub_item_title}>Дата створення заявки:</Col>
							<Col>{moment(claim.created).format(dateFormat)}</Col>
						</Row>

						<Row className={css.item}>
							<Col>
								<span>
									<span className={css.sub_item_title}>Підрозділ:</span>
									{claim.departmentName}
								</span>
							</Col>
						</Row>

						<Divider />

						<Row className={css.item}>
							<Col>
								<span>
									<span className={css.sub_item_title}>Вид ремонту:</span>
									{claim.claimRepairTypeName}
								</span>
							</Col>
						</Row>

						<Row className={css.item}>
							<Col>
								<span>
									<span className={css.sub_item_title}>Ініціатор:</span>
									{claim.createdByDepartmentHierarchyName}
								</span>
							</Col>
						</Row>

						<Row className={css.item}>
							<Col>
								<span>
									<span className={css.sub_item_title}>Назва електроустановки:</span>
									{claim.electricAddress}
								</span>
							</Col>
						</Row>

						<Row className={css.item}>
							<Col>
								<span>
									<span className={css.sub_item_title}>Мета відключення:</span>
									{claim.shutdownPurpose}
								</span>
							</Col>
						</Row>

						<Divider />

						{claim.claimSafetyActions.length > 0 && (
							<>
								<Row className={css.item}>
									<Col span={24}>
										<Row className={css.sub_item_title}>Заходи безпеки:</Row>

										<table className={css.claimSafetyActionsTable}>
											<tr>
												<th style={{ backgroundColor: "#F0F0F0" }}>
													<ThunderboltOutlined style={{ color: "DimGray" }} /> Електроустановка
												</th>
												<th style={{ backgroundColor: "#F0F0F0" }}>
													<ApiOutlined style={{ color: "DimGray" }} /> Дії
												</th>
											</tr>
											{claim.claimSafetyActions.map((item, i) => (
												<tr>
													<td>{item.nodeToShutdown}</td>
													<td>{item.actions}</td>
												</tr>
											))}
										</table>
									</Col>
								</Row>
								<Divider />
							</>
						)}

						{claim.claimApprovalsExtended.length > 0 && (
							<>
								<Row className={css.item}>
									<Col span={24}>
										<Row className={css.sub_item_title}>Погодження:</Row>
										<ul>
											{claim.claimApprovalsExtended.map(item => (
												<li key={item.id} style={{ marginBottom: "3px" }}>
													{item.isApproved !== null ? (
														<span>
															{item.isApproved ? (
																<CheckCircleOutlined style={{ color: "green", marginRight: "3px" }} />
															) : (
																<CloseCircleOutlined style={{ color: "red", marginRight: "3px" }} />
															)}
														</span>
													) : (
														<QuestionCircleOutlined style={{ color: "orange", marginRight: "3px" }} />
													)}

													<span>{item.departmentHierarchyName}</span>
												</li>
											))}
										</ul>
									</Col>
								</Row>
								<Divider />
							</>
						)}

						<Row className={css.item}>
							<Col>
								<Row className={css.sub_item_title}>Етапи виконання:</Row>
								<Row className={css.execution_log}>
									<Timeline
										items={
											convertedExecutionLog.length
												? convertedExecutionLog.map((item, timelineIndex) => ({
														key: timelineIndexPrefix + timelineIndex,
														dot:
															item.isApproved == null || item.isApproved === true ? (
																<CheckCircleFilled />
															) : (
																<CloseCircleOutlined />
															),
														color: timelineIndex === 0 || item.isApproved === false ? "red" : "blue",
														children: (
															<>
																<p
																	className={`${css.p_header_font} ${
																		timelineIndex === 0 || item.isApproved === false
																			? css.p_header_bkg_red
																			: css.p_header_bkg_blue
																	}`}
																>
																	{item.created}, <strong>{item.createdByUserName}</strong>
																	{" ("}
																	<strong>{item.createdByDepartmentHierarchyName}</strong>
																	{") "}
																	{(timelineIndex === 0
																		? "створив(ла)"
																		: item.isApproved === null
																		? "змінив(ла)"
																		: item.isApproved === true
																		? "погодив(ла)"
																		: "не погодив(ла)") + (item.operations.length ? ":" : "")}
																</p>
																{item.operations.map((operation, pIndex) =>
																	timelineIndex === 0 ? (
																		<p key={pIndexPrefix + pIndex} className={css.operation_log}>
																			<i>{operation.column}</i>: {operation.value}
																		</p>
																	) : operation.oldValue ? (
																		<p key={pIndexPrefix + pIndex} className={css.operation_log}>
																			<i>{operation.column}</i> з{" "}
																			<strong>{operation.oldValue}</strong> на{" "}
																			<strong>{operation.value}</strong>
																		</p>
																	) : (
																		<p key={pIndexPrefix + pIndex} className={css.operation_log}>
																			<i>{operation.column}</i>: {operation.value}
																		</p>
																	)
																)}
															</>
														),
												  }))
												: { children: "Намає даних" }
										}
									/>
								</Row>
							</Col>
						</Row>
					</div>
				</Drawer>
			) : null}
		</>
	);
};

export default ViewClaimDrawer;
