export const createDownloadFile = (data, fileName, type = null) => {
  if (data.headers["content-type"] === "text/plain") {
    data.headers["content-type"] = "text/plain;charset=utf8";
  }

  const url = window.URL.createObjectURL(
    new Blob([data.data], { type: data.headers["content-type"] })
  );
  const link = document.createElement("a");
  link.href = url;
  if (type !== "show") {
    link.setAttribute("download", fileName);
  } else {
    //link.download = fileName;
    link.target = "_blank";
  }
  document.body.appendChild(link);
  link.click();
};

export const getFileName = (disposition) => {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  if (utf8FilenameRegex.test(disposition)) {
    return decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
  }

  // prevent ReDos attacks by anchoring the ascii regex to string start and
  //  slicing off everything before 'filename='
  const filenameStart = disposition.toLowerCase().indexOf("filename=");
  if (filenameStart >= 0) {
    const partialDisposition = disposition.slice(filenameStart);
    const matches = asciiFilenameRegex.exec(partialDisposition);
    if (matches?.[2]) {
      return matches[2];
    }
  }

  return null;
};

// export const viewFileInIFrame = (data, fileName) => {
//     const type = data.headers['content-type'];
//     const ieEDGE = navigator.userAgent.match(/Edge/g);
//     const ie = navigator.userAgent.match(/.NET/g); // IE 11+
//     const oldIE = navigator.userAgent.match(/MSIE/g);
//     const bytes = new Uint8Array(data); //use this if data is raw bytes else directly pass resData
//     const blob = new window.Blob([bytes], { type: type });
//
//     if (ie || oldIE || ieEDGE) {
//         window.navigator.msSaveBlob(blob, fileName);
//     }
//     else {
//         const fileURL = URL.createObjectURL(blob);
//         const win = window.open();
//         win.document.write('<iframe src="' + fileURL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
//     }
// };
