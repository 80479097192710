import "./../../../assets/scss/common_components_styles/flat_pickr.css";

import { Alert, Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { Ukrainian } from "flatpickr/dist/l10n/uk";
import css from "./GroupActionsModal.module.scss";
import flatpickr from "flatpickr";
import { getAppealSubjectCloseComments } from "../../../redux/actions/appealActions";
import { useEffect } from "react";

const GroupActionsModal = ({
	showModal,
	onCancel,
	groupActionType,
	selectedRowsCount,
	onSave,
	groupAppealSubjectId,
}) => {
	flatpickr.localize(Ukrainian);
	const [form] = Form.useForm();
	const { TextArea } = Input;
	const { Option } = Select;
	const dispatch = useDispatch();

	const appealSubjectCloseComments = useSelector(state => state.appeal.appealSubjectCloseComments);

	const onCommentChange = comment => {
		form.setFieldsValue({ comment: comment });
	};

	const handleSave = values => {
		onSave(values);
	};

	const onFinishFailed = errorInfo => {
		console.log("Failed:", errorInfo);
	};

	useEffect(() => {
		if (!groupAppealSubjectId) return;
		dispatch(getAppealSubjectCloseComments(groupAppealSubjectId));
	}, [groupAppealSubjectId, dispatch]);

	return (
		<Modal
			className={css.group_actions_wrapper}
			destroyOnClose
			maskClosable={false}
			open={showModal}
			title={`Групова операція`}
			onOk={handleSave}
			onCancel={onCancel}
			footer={[
				<Button key='back' onClick={onCancel}>
					Скасувати
				</Button>,
				<Button
					key='submit'
					className={css.site_button}
					style={{ marginRight: 0 }}
					type='primary'
					onClick={() => form.submit()}
				>
					Зберегти
				</Button>,
			]}
		>
			<Alert
				style={{ margin: "0 0 15px 0" }}
				message={`Ви здійснюєте груповий перехід скарг/пропозицій в статус "${
					groupActionType === "inProcess" ? "В роботі" : "Закрите"
				}". 
                             Вибрано ${selectedRowsCount ? selectedRowsCount : 0} рядка.`}
				type='warning'
				showIcon
			/>

			<Form
				form={form}
				name='groupActionSaveForm'
				preserve={false}
				onFinish={handleSave}
				onFinishFailed={onFinishFailed}
			>
				{groupActionType === "close" && (
					<>
						<Row gutter={20}>
							<Col lg={24} md={24}>
								{groupAppealSubjectId && (
									<Form.Item style={{ marginBottom: 10 }}>
										<Select
											placeholder='Оберіть шаблон коментаря'
											popupMatchSelectWidth={false}
											onChange={onCommentChange}
										>
											{appealSubjectCloseComments.map(item => (
												<Option key={item.id} value={item.comment}>
													{item.comment}
												</Option>
											))}
										</Select>
									</Form.Item>
								)}

								<Form.Item
									name='comment'
									rules={[{ required: true, message: "Будь ласка, введіть коментар!" }]}
								>
									<TextArea
										placeholder='Введіть коментар..'
										autoSize={{ minRows: 2, maxRows: 8 }}
										showCount
										maxLength={2000}
									/>
								</Form.Item>
							</Col>
						</Row>
					</>
				)}
			</Form>
		</Modal>
	);
};

export default GroupActionsModal;
