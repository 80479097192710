import {SET_ALLOWED_STATUSES_MATRIX_VALUES} from "../types";


const initialState = {
    allowedStatusesMatrixValues: undefined,
};

export const statusesMatrixReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALLOWED_STATUSES_MATRIX_VALUES:
            return {...state, allowedStatusesMatrixValues: action.payload};
        default:
            return state
    }
};