import { useDispatch, useSelector } from "react-redux";

import { getDictionary } from "../redux/actions/dictionaryActions";
import { useEffect, useRef } from "react";

const useDictionary = dictionaryTypeEnum => {
	const dictionaryData = useSelector(state => state.dictionary[dictionaryTypeEnum]);
	const dispatch = useDispatch();
	const executionFlag = useRef([]);

	//console.log({a: dictionaryTypeEnum, b: executionFlag.current[dictionaryTypeEnum], length: dictionaryData.length})
	
	useEffect(() => {
		if (!dictionaryData.length && !executionFlag.current[dictionaryTypeEnum]) {
			executionFlag.current[dictionaryTypeEnum] = true;
			dispatch(getDictionary(dictionaryTypeEnum));
		}
	}, [dictionaryData, dispatch, dictionaryTypeEnum]);

	return dictionaryData;
};

export default useDictionary;
