import {
	SET_CHOSEN_RECIPIENT,
	SET_CHOSEN_ROLES,
	SET_CHOSEN_SHUTDOWN_JOB_TYPE,
	SET_CHOSEN_USER,
	SET_DEPARTMENT_HIERARCHY_FREE_USERS,
	SET_DEPARTMENT_HIERARCHY_USERS_TREE,
	SET_RECIPIENTS_DATA,
	SET_SHUTDOWN_JOB_TYPES_DATA,
	SET_USERS_DATA,
} from "../types";

const initialState = {
	users: [],
	chosenUser: {},
	chosenRoles: [],
	shutdownJobTypes: [],
	chosenShutdownJobType: {},
	recipients: [],
	chosenRecipient: {},
	departmentHierarchyUsersTree: [],
	departmentHierarchyFreeUsers: [],
};

export const adminReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_USERS_DATA:
			return { ...state, users: action.payload };
		case SET_CHOSEN_USER:
			return { ...state, chosenUser: action.payload };
		case SET_CHOSEN_ROLES:
			return { ...state, chosenRoles: action.payload };
		case SET_SHUTDOWN_JOB_TYPES_DATA:
			return { ...state, shutdownJobTypes: action.payload };
		case SET_CHOSEN_SHUTDOWN_JOB_TYPE:
			return { ...state, chosenShutdownJobType: action.payload };
		case SET_RECIPIENTS_DATA:
			return { ...state, recipients: action.payload };
		case SET_CHOSEN_RECIPIENT:
			return { ...state, chosenRecipient: action.payload };
		case SET_DEPARTMENT_HIERARCHY_USERS_TREE:
			return { ...state, departmentHierarchyUsersTree: action.payload };
		case SET_DEPARTMENT_HIERARCHY_FREE_USERS:
			return { ...state, departmentHierarchyFreeUsers: action.payload };
		default:
			return state;
	}
};
