import axios from "axios";
import { apiClient } from "./api";

//Holidays endpoints
export const dashboardAPI = {
	getAppealsCount(getParameters) {
		return apiClient(axios)
			.post(`/Dashboard/GetAppealsCount`, { ...getParameters })
			.then(response => response);
	},

	getEmergencyShutdownsCount(getParameters) {
		return apiClient(axios)
			.post(`/Dashboard/GetEmergencyShutdownsCount`, { ...getParameters })
			.then(response => response);
	},

	getPlannedShutdownsCount(getParameters) {
		return apiClient(axios)
			.post(`/Dashboard/GetPlannedShutdownsCount`, { ...getParameters })
			.then(response => response);
	},

	getShutdownsCommercialCounterpartiersCount(getParameters) {
		return apiClient(axios)
			.post(`/Dashboard/GetShutdownsCommercialCounterpartiersCount`, { ...getParameters })
			.then(response => response);
	},

	getShutdownsResidentialCounterpartiersCount(getParameters) {
		return apiClient(axios)
			.post(`/Dashboard/GetShutdownsResidentialCounterpartiersCount`, { ...getParameters })
			.then(response => response);
	},

	getShutdownsCountByElectricAddresses(getParameters) {
		return apiClient(axios)
			.post(`/Dashboard/GetShutdownsCountByElectricAddresses`, { ...getParameters })
			.then(response => response);
	},
};
