import { Button, Col, Divider, Drawer, Row } from "antd";
import React, { useEffect, useState } from "react";
import {
	getAppealPaidServiceById,
	getBillFileAsync,
	getBillFilesList,
	getFileAsync,
	getFilesList,
} from "../../../redux/actions/appealActions";
import { useDispatch, useSelector } from "react-redux";

import DownloadOutlined from "@ant-design/icons/es/icons/DownloadOutlined";
import LinkOutlined from "@ant-design/icons/es/icons/LinkOutlined";
import ViewTaskDrawer from "../../Tasks/ViewTaskDrawer/ViewTaskDrawer";
import css from "./ViewAppealPaidServiceDrawer.module.scss";
import { getDrawerTasks } from "../../../redux/actions/taskActions";
import moment from "moment";
import { tableButtonsSize } from "../../../assets/js/options";

const ViewAppealPaidServiceDrawer = ({ visible, onClose, appealId }) => {
	const dispatch = useDispatch();
	const dateFormat = "YYYY-MM-DD (HH:mm)";

	const appeal = useSelector(state => state.appeal.appealById);
	const appealTasks = useSelector(state => state.task.drawerTasks.data);
	const appealFilesList = useSelector(state => state.appeal.appealFilesList);
	const appealBillFilesList = useSelector(state => state.appeal.appealBillFilesList);

	//For view task
	const [currentTaskId, setCurrentTaskId] = useState(null);
	const [viewModalVisible, setViewModalVisible] = useState(false);
	const [appealStatusId, setAppealStatusId] = useState(null);

	//Get appeals and them tasks(if exists)
	useEffect(() => {
		if (!appealId || !visible) return;
		dispatch(getAppealPaidServiceById(appealId));
		dispatch(
			getDrawerTasks({
				filter: "appealId=" + appealId,
			})
		);
	}, [dispatch, appealId, visible]);

	useEffect(() => {
		if (!appeal || !appealId) return;
		setAppealStatusId(appeal.appealStatusId);
		dispatch(getFilesList(appealId));
		dispatch(getBillFilesList(appealId));
	}, [dispatch, appeal, appealId]);

	const setAppealStatus = () => {
		let color = { titleColor: null, lineColor: null, labelColor: null };
		switch (appealStatusId) {
			case 1:
				color.titleColor = "#FAE4E1";
				color.lineColor = "#C64333";
				color.labelColor = "#C64333";
				return color;
			case 2:
				color.titleColor = "#D9F6FD";
				color.lineColor = "#00ACD7";
				color.labelColor = "#00ACD7";
				return color;
			case 3:
				color.titleColor = "#BDBDBD";
				color.lineColor = "#828282";
				color.labelColor = "#828282";
				return color;
			default:
				return color;
		}
	};

	const setTaskStatusColor = taskStatusId => {
		let color = { labelColor: null };
		switch (taskStatusId) {
			case 1:
				color.labelColor = "#00ACD7";
				return color;
			case 2:
				color.labelColor = "#00a65a";
				return color;
			case 3:
				color.labelColor = "#C64333";
				return color;
			case 4:
				color.labelColor = "#828282";
				return color;
			default:
				return color;
		}
	};

	const onViewAppealFile = index => {
		const fileId = appealFilesList[index].id;
		const fileName = appealFilesList[index].name;
		dispatch(getFileAsync(fileId, fileName));
	};

	const onViewAppealBillFile = index => {
		const billFileId = appealBillFilesList[index].id;
		const billFileName = appealBillFilesList[index].name;
		dispatch(getBillFileAsync(billFileId, billFileName));
	};

	//On View Task
	const onViewTask = taskId => {
		setCurrentTaskId(taskId);
		setViewModalVisible(true);
	};

	return (
		<>
			{appeal && (
				<Drawer
					title={<b>{`Перегляд послуги №${appeal.id}:`}</b>}
					placement='right'
					width={"25%"}
					headerStyle={{ backgroundColor: setAppealStatus().titleColor, borderRadius: "0px" }}
					drawerStyle={{ borderLeft: `4px solid ${setAppealStatus().lineColor}` }}
					onClose={onClose}
					open={visible}
				>
					<div className={css.view_appeal_wrapp}>
						<Row className={css.item}>
							<Col span={12}>
								<Row className={css.sub_item_title}>Статус</Row>
								<Row style={{ color: setAppealStatus().labelColor }}>{appeal.appealStatusName}</Row>
							</Col>
							<Col span={12}>
								<Row className={css.sub_item_title}>Дата реєстрації</Row>
								<Row>{moment(appeal.innerRegistrationDate).format(dateFormat)}</Row>
							</Col>
						</Row>

						<Row className={css.item}>
							<Col span={12}>
								<Row className={css.sub_item_title}>Реєстраційний №</Row>
								<Row>{appeal.innerRegistrationNumber ? appeal.innerRegistrationNumber : "Відсутній"}</Row>
							</Col>
							<Col span={12}>
								<Row className={css.sub_item_title}>Точка входу</Row>
								<Row>{appeal.departmentEntryPointName}</Row>
							</Col>
						</Row>

						<Row className={css.item}>
							<Col span={12}>
								<Row className={css.sub_item_title}>Класифікатор</Row>
								<Row>{appeal.appealClassifierName}</Row>
							</Col>
							<Col span={12}>
								<Row className={css.sub_item_title}>Спосіб отримання</Row>
								<Row>{appeal.appealObtainingMethodName}</Row>
							</Col>
						</Row>

						<Row className={css.item}>
							<Col span={12}>
								<Row className={css.sub_item_title}>Тип споживача</Row>
								<Row>{appeal.counterpartyTypeName}</Row>
							</Col>
							<Col span={12}>
								<Row className={css.sub_item_title}>Особовий рахунок</Row>
								<Row>
									{appeal.counterpartyPersonalAccount ? appeal.counterpartyPersonalAccount : "Відсутній"}
								</Row>
							</Col>
						</Row>

						<Divider />

						<Row className={css.item}>
							<Col className={css.sub_item_title}>Підрозділ:</Col>
							<Col>{appeal.departmentName ? appeal.departmentName : "Відсутній"}</Col>
						</Row>

						<Row className={css.item}>
							<Col className={css.sub_item_title}>Відділення:</Col>
							<Col>{appeal.branchName ? appeal.branchName : "Відсутнє"}</Col>
						</Row>

						<Row className={css.item}>
							<Col className={css.sub_item_title}>Населений пункт:</Col>
							<Col>{appeal.settlementName ? appeal.settlementName : "Відсутній"}</Col>
						</Row>

						<Row className={css.item}>
							<Col>
								<span>
									<span className={css.sub_item_title}>Адреса:</span>
									{appeal.address ? appeal.address : "Відсутня"}
								</span>
							</Col>
						</Row>

						<Row className={css.item}>
							<Col>
								<span>
									<span className={css.sub_item_title}>Назва заявника:</span>
									{appeal.counterpartyName ? appeal.counterpartyName : "Відсутня"}
								</span>
							</Col>
						</Row>

						<Divider />

						<Row className={css.item}>
							<Col>
								<span>
									<span className={css.sub_item_title}>Зміст:</span>
									{appeal.description ? appeal.description : "Відсутній"}
								</span>
							</Col>
						</Row>

						<Row className={css.item}>
							<Col>
								<span>
									<span className={css.sub_item_title}>Назва послуги:</span>
									{appeal.appealPaidServiceName}
								</span>
							</Col>
						</Row>

						<Row className={css.item}>
							<Col className={css.sub_item_title}>Термін розгляду:</Col>
							<Col>{appeal.appealPaidServiceTimeName ? appeal.appealPaidServiceTimeName : "Відсутній"}</Col>
						</Row>

						<Row className={css.item}>
							<Col className={css.sub_item_title}>Дата оплати:</Col>
							<Col>
								{appeal.datePaidServiceWasPaid
									? moment(appeal.datePaidServiceWasPaid).format(dateFormat)
									: "Відсутня"}
							</Col>
						</Row>

						<Divider />

						<Row className={css.item}>
							<Col className={css.sub_item_title}>Обраний споживачем канал комунікації:</Col>
							<Col>{appeal.appealCommunicationTypeName ? appeal.appealCommunicationTypeName : "Відсутній"}</Col>
						</Row>

						<Row className={css.item}>
							<Col className={css.sub_item_title}>Телефон:</Col>
							<Col>{appeal.phone ? appeal.phone : "Відсутній"}</Col>
						</Row>

						<Row className={css.item}>
							<Col className={css.sub_item_title}>E-mail:</Col>
							<Col>{appeal.email ? appeal.email : "Відсутній"}</Col>
						</Row>

						{appealFilesList.length ? (
							<>
								<Divider />
								<Row className={css.item}>
									<Col span={24}>
										<Row className={css.file_item_title}>Приєднані документи</Row>
										{appealFilesList.map((file, index) => {
											return (
												<Row className={css.files_list} key={file.id}>
													<Col className={css.files_list_item_wrapper}>
														<Button
															type='link'
															size={"small"}
															style={{ color: "#357ca5", border: "1px solid lightblue" }}
															onClick={() => onViewAppealFile(index)}
														>
															<DownloadOutlined />
														</Button>
													</Col>
													<Col className={css.files_list_item_wrapper}>{file.name}</Col>
													<Col className={css.files_list_file_create_time}>
														{moment(file.created).format("YYYY-MM-DD (HH:mm)")}
													</Col>
												</Row>
											);
										})}
									</Col>
								</Row>
							</>
						) : (
							<></>
						)}

						{appealBillFilesList.length ? (
							<>
								<Divider />
								<Row className={css.item}>
									<Col span={24}>
										<Row className={css.file_item_title}>Приєднані рахунки</Row>
										{appealBillFilesList.map((file, index) => {
											return (
												<Row className={css.files_list} key={file.id}>
													<Col className={css.files_list_item_wrapper}>
														<Button
															type='link'
															size={"small"}
															style={{ color: "#357ca5", border: "1px solid lightblue" }}
															onClick={() => onViewAppealBillFile(index)}
														>
															<DownloadOutlined />
														</Button>
													</Col>
													<Col className={css.files_list_item_wrapper}>{file.name}</Col>
													<Col className={css.files_list_file_create_time}>
														{moment(file.created).format("YYYY-MM-DD (HH:mm)")}
													</Col>
												</Row>
											);
										})}
									</Col>
								</Row>
							</>
						) : (
							<></>
						)}

						{appealTasks && appealTasks.length ? (
							<>
								<Divider />
								<Row className={css.item}>
									<Col span={24}>
										<Row className={css.sub_item_title} style={{ marginBottom: 5 }}>
											Створені завдання:
										</Row>
										{appealTasks.map(task => {
											return (
												<Row key={task.id}>
													<Col>{`№${task.id} -`}</Col>

													<Col
														style={{
															marginLeft: 5,
															color: setTaskStatusColor(task.taskStatusId).labelColor,
														}}
													>
														{`${task.taskStatusName}`}
													</Col>

													<Col className={css.table_action_btn}>
														<Button
															size={tableButtonsSize}
															style={{ marginLeft: 10 }}
															onClick={() => onViewTask(task.id)}
														>
															<LinkOutlined />
														</Button>
													</Col>
												</Row>
											);
										})}
									</Col>
								</Row>
							</>
						) : (
							<></>
						)}
						{Number(appeal.appealStatusId) === 3 ? (
							<>
								<Divider />
								<Row className={css.item}>
									<Col className={css.sub_item_title}>Дата закриття:</Col>
									<Col>{moment(appeal.modified).format(dateFormat)}</Col>
								</Row>
								{appeal.closedByUserName ? (
									<Row className={css.item}>
										<Col>
											<span>
												<span className={css.sub_item_title}>Хто закрив:</span>
												{appeal.closedByUserName}
											</span>
										</Col>
									</Row>
								) : (
									<></>
								)}
								<Row className={css.item}>
									<Col>
										<span>
											<span className={css.sub_item_title}>Коментар при закриті:</span>
											{appeal.comment ? appeal.comment : "Відсутній"}
										</span>
									</Col>
								</Row>
							</>
						) : (
							<></>
						)}
					</div>
				</Drawer>
			)}

			{currentTaskId && (
				<>
					<ViewTaskDrawer
						visible={viewModalVisible}
						onClose={() => setViewModalVisible(false)}
						taskId={currentTaskId}
					/>
				</>
			)}
		</>
	);
};

export default ViewAppealPaidServiceDrawer;
