import { Breadcrumb } from "antd";
import { NavLink } from "react-router-dom";
import React from "react";

export const AppBreadcrumb = ({ breadcrumbData }) => {
	return (
		<>
			<Breadcrumb
				items={breadcrumbData.map((item, index) => {
					if (item.link) {
						return { title: <NavLink to={`/${item.link}`}>{item.name}</NavLink> };
					} else {
						return { title: item.name };
					}
				})}
			/>
		</>
	);
};
