import "./../../../assets/scss/common_components_styles/flat_pickr.css";

import { Alert, Button, Col, Form, Modal, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";

import Flatpickr from "react-flatpickr";
import { Ukrainian } from "flatpickr/dist/l10n/uk";
import css from "./GroupActionsModal.module.scss";
import { dictionaryTypeEnum } from "../../../dictionaries/models/dictionaryTypeEnum";
import flatpickr from "flatpickr";
import useDictionary from "../../../dictionaries/useDictionaries";
import { useSelector } from "react-redux";

const GroupActionsModal = ({ showModal, onCancel, groupActionType, selectedRowsCount, onSave }) => {
	flatpickr.localize(Ukrainian);
	const [form] = Form.useForm();
	const { Option } = Select;

	const shutdownJobTypes = useSelector(state => state.admin.shutdownJobTypes.data);
	const shutdownDrivers = useDictionary(dictionaryTypeEnum.SHUTDOWN_DRIVERS);

	//Time settings for range pickers
	//const createEditShutdownsTimeSettings = useSelector(state => state.settings.createEditShutdownsTimeSettings);
	//const createEditShutdownsTimeSettingsRef = useRef(null);

	const [isRealEndDateDisabled, setIsRealEndDateDisabled] = useState(true);

	const waitingStartPickerRef = useRef(null);
	const waitingEndPickerRef = useRef(null);
	const realStartPickerRef = useRef(null);
	const realEndPickerRef = useRef(null);

	//Enable real end picker if data exists in real start picker
	useEffect(() => {
		if (!realStartPickerRef && !realStartPickerRef.current.props.value) return;
		setIsRealEndDateDisabled(false);
	}, []);

	const onRealStartDateChange = dates => {
		setIsRealEndDateDisabled(false);
		realEndPickerRef.current.flatpickr.setDate(undefined);
	};

	const handleSave = values => {
		onSave(values);
	};

	const onReset = () => {
		if (groupActionType === "confirm") {
			waitingStartPickerRef.current.flatpickr.clear();
			waitingEndPickerRef.current.flatpickr.clear();
		} else {
			realStartPickerRef.current.flatpickr.clear();
			realEndPickerRef.current.flatpickr.clear();
		}
	};

	const onFinishFailed = errorInfo => {
		console.log("Failed:", errorInfo);
	};

	return (
		<Modal
			className={css.group_actions_wrapper}
			destroyOnClose
			maskClosable={false}
			open={showModal}
			title={`Групова операція (${groupActionType === "confirm" ? "підтвердження" : "закриття"})`}
			onOk={handleSave}
			onCancel={onCancel}
			footer={[
				<Button key='reset' onClick={onReset}>
					Скинути дати
				</Button>,
				<Button key='back' onClick={onCancel}>
					Скасувати
				</Button>,
				<Button
					key='submit'
					className={css.site_button}
					style={{ marginRight: 0 }}
					type='primary'
					onClick={() => form.submit()}
				>
					Зберегти
				</Button>,
			]}
		>
			<Alert
				style={{ margin: "0 0 15px 0" }}
				message={`Ви здійснюєте групове ${
					groupActionType === "confirm" ? "підтвердження" : "закриття"
				} аварійних відключень. 
                             Вибрано ${selectedRowsCount ? selectedRowsCount : 0} аварійних відключень.`}
				type='warning'
				showIcon
			/>

			<Form
				form={form}
				name='groupActionSaveForm'
				preserve={false}
				onFinish={handleSave}
				onFinishFailed={onFinishFailed}
			>
				{groupActionType === "confirm" && (
					<>
						<div className={css.wait_time_label}>Дата відключення та очікуваний час завершення</div>
						<Row gutter={20}>
							<Form.Item label='Період:' style={{ width: "100%", marginBottom: "0px" }} labelCol={{ span: 5 }}>
								<Form.Item
									className='flatpickr'
									style={{ display: "inline-block", marginRight: "20px" }}
									name='waitingStartDate'
								>
									<Flatpickr
										className={css.flat_picker_input}
										ref={waitingStartPickerRef}
										options={{
											dateFormat: "Y-m-d H:i",
											enableTime: true,
											time_24hr: true,
										}}
										placeholder='початкова дата'
									/>
								</Form.Item>
								<Form.Item className='flatpickr' style={{ display: "inline-block" }} name='waitingEndDate'>
									<Flatpickr
										className={css.flat_picker_input}
										ref={waitingEndPickerRef}
										options={{
											dateFormat: "Y-m-d H:i",
											enableTime: true,
											time_24hr: true,
										}}
										placeholder='кінцева дата'
									/>
								</Form.Item>
							</Form.Item>
						</Row>
					</>
				)}

				{groupActionType === "close" && (
					<>
						<div className={css.real_time_label}>Дата відключення та фактичний час завершення</div>
						<Row gutter={20}>
							<Form.Item label='Період:' style={{ width: "100%", marginBottom: "0px" }} labelCol={{ span: 5 }}>
								<Form.Item
									className='flatpickr'
									style={{ display: "inline-block", marginRight: "20px" }}
									name='realStartDate'
									rules={[{ required: true, message: "Будь ласка оберіть період!" }]}
								>
									<Flatpickr
										className={css.flat_picker_input}
										ref={realStartPickerRef}
										options={{
											dateFormat: "Y-m-d H:i",
											enableTime: true,
											time_24hr: true,
										}}
										onChange={onRealStartDateChange}
										placeholder='початкова дата'
									/>
								</Form.Item>
								<Form.Item
									className='flatpickr'
									style={{ display: "inline-block" }}
									name='realEndDate'
									rules={[{ required: true, message: "Будь ласка оберіть період!" }]}
								>
									<Flatpickr
										className={css.flat_picker_input}
										ref={realEndPickerRef}
										options={{
											dateFormat: "Y-m-d H:i",
											enableTime: true,
											time_24hr: true,
										}}
										disabled={isRealEndDateDisabled}
										placeholder='кінцева дата'
									/>
								</Form.Item>
							</Form.Item>
						</Row>
					</>
				)}

				{groupActionType === "confirm" && (
					<>
						<Row>
							<Col lg={19} md={24}>
								<Form.Item
									labelCol={{ span: 6 }}
									label='Вид робіт'
									name='shutdownJobTypeId'
									rules={[{ required: true, message: "Будь ласка, оберіть вид робіт!" }]}
								>
									<Select placeholder='Оберіть вид роботи' popupMatchSelectWidth={false} allowClear>
										{shutdownJobTypes &&
											shutdownJobTypes.map(type => (
												<Option key={type.id} value={type.id}>
													{type.name}
												</Option>
											))}
									</Select>
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col lg={19} md={24}>
								<Form.Item
									labelCol={{ span: 6 }}
									label='Призвідник'
									name='shutdownDriverId'
									rules={[{ required: true, message: "Будь ласка, оберіть призвідника вимкнення!" }]}
								>
									<Select placeholder='Оберіть призвідника вимкнення' popupMatchSelectWidth={false} allowClear>
										{shutdownDrivers &&
											shutdownDrivers.map(d => (
												<Option key={d.id} value={d.id}>
													{d.name}
												</Option>
											))}
									</Select>
								</Form.Item>
							</Col>
						</Row>
					</>
				)}
			</Form>
		</Modal>
	);
};

export default GroupActionsModal;
