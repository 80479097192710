import "moment/locale/uk";

import { Button, Col, DatePicker, Form, Row, Select } from "antd";

import React from "react";
import css from "./Filter.module.scss";
import { dictionaryTypeEnum } from "../../../dictionaries/models/dictionaryTypeEnum";
import { getAllStatsData } from "../../../redux/actions/statsActions";
import useDictionary from "../../../dictionaries/useDictionaries";
import { useDispatch } from "react-redux";

//import locale from 'antd/es/date-picker/locale/uk_UA';

const Filter = () => {
	const dispatch = useDispatch();
	//Res to select control from state
	const departments = useDictionary(dictionaryTypeEnum.DEPARTMENTS);
	//Setlement types from state
	const settlementTypes = useDictionary(dictionaryTypeEnum.SETTLEMENT_TYPES);

	//RangePicker & Select
	const { RangePicker } = DatePicker;
	const { Option } = Select;

	//Form
	const onFinish = values => {
		const departmentId = values.department ? values.department : null;
		const dateBegin = values.period[0];
		const dateEnd = values.period[1];

		dispatch(
			getAllStatsData([
				{ departmentId, settlementTypeId: settlementTypes[0].id, dateBegin, dateEnd },
				{ departmentId, settlementTypeId: settlementTypes[1].id, dateBegin, dateEnd },
			])
		);
	};

	const onFinishFailed = errorInfo => {
		console.log("Failed:", errorInfo);
	};

	return (
		<Row className={css.filter}>
			<Form name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
				<Row className={css.filter_wrapper}>
					<Col>
						<Form.Item
							className={css.form_item}
							name='department'
							label='Підрозділ'
						//rules={[{ required: true, message: 'Будь ласка, оберіть РЕМ!' }]}
						>
							<Select placeholder='Оберіть підрозділ' popupMatchSelectWidth={false} allowClear={true}>
								{departments.map(item => (
									<Option key={item.id} value={item.id}>
										{item.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>

					<Col>
						<Form.Item
							className={css.form_item}
							name='period'
							label='Період'
							rules={[{ required: true, message: "Будь ласка, оберіть період!" }]}
						>
							<RangePicker
								picker='month'
								allowClear={false}
							/>
						</Form.Item>
					</Col>

					<Col>
						<Form.Item className={css.form_item}>
							<Button className={css.site_button} type='primary' htmlType='submit'>
								Порахувати
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Row>
	);
};

export default Filter;
