export const taskStatusEnum = Object.freeze({
	NEW: 1,
	IN_PROCESS: 2,
	DISCARD: 3,
	CLOSED: 4,
});

export const taskInitiatorsEnum = Object.freeze({
	APPEAL: 1,
	APPEAL_PAID_SERVICE: 2,
});
