import "./index.css";

import { applyMiddleware, createStore } from "redux";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import React from "react";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { rootReducer } from "./redux/reducers/rootReducer";
import thunk from "redux-thunk";
import ukUA from "antd/locale/uk_UA"; //For Ant DatePicker

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

const root = createRoot(document.getElementById("root"));
const theme = {
	token: {
		fontFamily: "Helvetica,Arial,sans-serif",
		fontWeightStrong: 500,
	},
};

root.render(
	<BrowserRouter>
		<Provider store={store}>
			<React.StrictMode>
				<ConfigProvider theme={theme} locale={ukUA}>
					<App />
				</ConfigProvider>
			</React.StrictMode>
		</Provider>
	</BrowserRouter>
);

reportWebVitals();
