import React from 'react';
import css from "./DividerGrid.module.scss";
import {Col, Divider, Row} from "antd";


const DividerGrid = ({title}) => {
    return (
        <Row align="middle" className={css.divider_grid}>
            <Col className={css.height_100}>
                <Divider type="vertical" style={{ borderLeft: "3px solid rgb(24, 144, 255)", height: "100%", marginLeft: "0" }} />
            </Col>
            <Col className={css.divider_text} >
                {title}
            </Col>
        </Row>
    )
};

export default DividerGrid
