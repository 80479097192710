//For App
export const APP_INIT = "APP/APP_INIT";
export const SHOW_LOADER = "APP/SHOW_LOADER";
export const HIDE_LOADER = "APP/HIDE_LOADER";
export const SHOW_ERROR = "APP/SHOW_ERROR";
export const SHOW_SUCCESS = "APP/SHOW_SUCCESS";
export const SHOW_WARNING = "APP/SHOW_WARNING";
export const SET_PAGE_BREADCRUMB = "APP/SET_PAGE_BREADCRUMB";
export const SET_API_LAST_ERROR = "APP/SET_API_LAST_ERROR";
export const SET_TABLE_FILTER = "APP/SET_TABLE_FILTER";
export const SET_API_HEALTH = "APP/SET_API_HEALTH";
export const SET_APP_VERSION = "APP/SET_APP_VERSION";
export const SET_APP_ENVIRONMENT = "APP/SET_APP_ENVIRONMENT";

//For Auth
export const SET_AUTH_DATA = "AUTH/SET_AUTH_DATA";

//For Stats
export const SET_DEPARTMENT_DATA = "STATS/SET_DEPARTMENT_DATA";
export const SET_SETTLEMENT_TYPES = "STATS/SET_SETTLEMENT_TYPES";
export const SET_SAIDI_SAIFI_DATA_CITY = "STATS/SET_SAIDI_SAIFI_DATA_CITY";
export const SET_SAIDI_SAIFI_DATA_VILAGE = "STATS/SET_SAIDI_SAIFI_DATA_VILAGE";
export const SET_MAIFI_DATA_CITY = "STATS/SET_MAIFI_DATA_CITY";
export const SET_MAIFI_DATA_VILAGE = "STATS/SET_MAIFI_DATA_VILAGE";
export const SET_ENS_DATA_CITY = "STATS/SET_ENS_DATA_CITY";
export const SET_ENS_DATA_VILAGE = "STATS/SET_ENS_DATA_VILAGE";
export const SET_CHART_DATA_SELECTOR = "STATS/SET_CHART_DATA_SELECTOR";
export const SET_CHART_TYPE_SELECTOR = "STATS/SET_CHART_TYPE_SELECTOR";

//For ADMIN
//Users
export const SET_USERS_DATA = "ADMIN/SET_USERS_DATA";
export const SET_USER_ROLES = "ADMIN/SET_USER_ROLES";
export const SET_CHOSEN_USER = "ADMIN/SET_CHOSEN_USER";
export const SET_CHOSEN_ROLES = "ADMIN/SET_CHOSEN_ROLES";
export const SET_USER_DEPARTMENT_ENTRY_POINTS = "ADMIN/SET_USER_DEPARTMENT_ENTRY_POINTS";

//ShutdownJobTypes
export const SET_SHUTDOWN_JOB_TYPES_DATA = "ADMIN/SET_SHUTDOWN_JOB_TYPES_DATA";
export const SET_CHOSEN_SHUTDOWN_JOB_TYPE = "ADMIN/SET_CHOSEN_SHUTDOWN_JOB_TYPE";

//Recipients
export const SET_RECIPIENTS_DATA = "ADMIN/SET_RECIPIENTS_DATA";
export const SET_CHOSEN_RECIPIENT = "ADMIN/SET_CHOSEN_RECIPIENT";
export const SET_RECIPIENT_TYPES_DATA = "ADMIN/SET_RECIPIENT_TYPES_DATA";
export const SET_RECIPIENT_GROUPS = "ADMIN/SET_RECIPIENT_GROUPS";

//Holidays
export const SET_HOLIDAY_TYPES = "ADMIN/SET_HOLIDAY_TYPES";
export const SET_HOLIDAYS = "HOLIDAYS/SET_HOLIDAYS";

//Rem Hierarchy
export const SET_DEPARTMENTS_FOR_HIERARCHY = "ADMIN/SET_DEPARTMENTS_FOR_HIERARCHY";
export const SET_DEPARTMENT_HIERARCHY_USERS_TREE = "ADMIN/SET_DEPARTMENT_HIERARCHY_USERS_TREE";
export const SET_DEPARTMENT_HIERARCHY_FREE_USERS = "ADMIN/SET_DEPARTMENT_HIERARCHY_FREE_USERS";

//--------------------------------------------------------------------------
//Shutdowns
export const SET_SHUTDOWN_TYPES = "SHUTDOWN/SET_SHUTDOWN_TYPES";
export const SET_SHUTDOWN_DRIVERS = "SHUTDOWN/SET_SHUTDOWN_DRIVERS";
export const SET_SHUTDOWN_STATUSES = "SHUTDOWN/SET_SHUTDOWN_STATUSES";
export const SET_NOTIFICATION_STATUSES = "SHUTDOWN/SET_NOTIFICATION_STATUSES";
export const SET_NOTIFICATION_TYPES = "SHUTDOWN/SET_NOTIFICATION_TYPES";
export const SET_ELECTRIC_NODES = "SHUTDOWN/SET_ELECTRIC_NODES";
export const SET_RESIDENTIAL_COUNTERPARTIES_COUNT = "SHUTDOWN/SET_RESIDENTIAL_COUNTERPARTIES_COUNT";
export const SET_COMMERCIAL_COUNTERPARTIES_COUNT = "SHUTDOWN/SET_COMMERCIAL_COUNTERPARTIES_COUNT";
export const SET_START_SHUTDOWN_STATUS = "SHUTDOWN/SET_START_SHUTDOWN_STATUS";
export const SET_CURRENT_SHUTDOWN_STATUS = "SHUTDOWN/SET_CURRENT_SHUTDOWN_STATUS";
export const SET_START_SHUTDOWN_TYPE = "SHUTDOWN/SET_START_SHUTDOWN_TYPE";
export const SET_SHUTDOWNS_DATA = "SHUTDOWN/SET_SHUTDOWNS_DATA";
export const SET_SHUTDOWN_BY_ID = "SHUTDOWN/SET_SHUTDOWN_BY_ID";
export const SET_SELECTED_SHUTDOWN = "SHUTDOWN/SET_SELECTED_SHUTDOWN";
export const SET_RECIPIENT_NOTIFICATION_HISTORY_DATA = "SHUTDOWN/SET_RECIPIENT_NOTIFICATION_HISTORY_DATA";
export const SET_COUNTERPARTIES_NOTIFICATIONS_HISTORY_DATA = "SHUTDOWN/SET_COUNTERPARTIES_NOTIFICATIONS_HISTORY_DATA";
export const SET_SHUTDOWN_STATUS_CHANGE_FLAG = "SHUTDOWN/SET_SHUTDOWN_STATUS_CHANGE_FLAG";
export const SET_COMMERCIAL_COUNTERPARTIES_NAMES = "SHUTDOWN/SET_COMMERCIAL_COUNTERPARTIES_NAMES";
export const SET_ELECTRIC_ADDRES_BY_COUNTERPARTY_PERSONAL_ACCOUNT =
	"SHUTDOWN/SET_ELECTRIC_ADDRES_BY_COUNTERPARTY_PERSONAL_ACCOUNT";
export const SET_MAP_DATA_BY_SHUTDOWN_LINES = "SHUTDOWN/SET_MAP_DATA_BY_SHUTDOWN_LINES";
export const SET_MAP_DATA_BY_SHUTDOWN_PS = "SHUTDOWN/SET_MAP_DATA_BY_SHUTDOWN_PS";
export const SET_MAP_DATA_BY_SHUTDOWN_FORECAST = "SHUTDOWN/SET_MAP_DATA_BY_SHUTDOWN_FORECAST";
export const SET_SHUTDOWN_JOB_TYPE_REASONS = "SHUTDOWN/SET_SHUTDOWN_JOB_TYPE_REASONS";
export const SET_SCADAS = "SHUTDOWN/SET_SCADAS";
export const SET_SCADA_SUBSTANTIONS = "SHUTDOWN/SET_SCADA_SUBSTANTIONS";
export const SET_SCADA_SUBSTANTION_VOLTAGES = "SHUTDOWN/SET_SCADA_SUBSTANTION_VOLTAGES";
export const SET_SCADA_SYGNAL_STATUSES = "SHUTDOWN/SET_SCADA_SYGNAL_STATUSES";
export const SET_COUNTERPARTIES_BY_SMART_METER = "SHUTDOWN/SET_COUNTERPARTIES_BY_SMART_METER";

//ShutdownsCalendar
export const SET_CALENDAR_SHUTDOWNS = "CALENDAR/SET_CALENDAR_SHUTDOWNS";
export const SET_CALENDAR_SELECTED_DEPARTMENT_ID = "CALENDAR/SET_CALENDAR_SELECTED_DEPARTMENT_ID";
export const SET_CALENDAR_SELECTED_SHUTDOWN_TYPE_ID = "CALENDAR/SET_CALENDAR_SELECTED_SHUTDOWN_TYPE_ID";
export const SET_CALENDAR_SELECTED_PERIOD = "CALENDAR/SET_CALENDAR_SELECTED_PERIOD";

//Appeals
export const SET_CURRENT_APPEAL_STATUS = "APPEAL/SET_CURRENT_APPEAL_STATUS";
export const SET_APPEAL_STATUSES = "APPEAL/SET_APPEAL_STATUSES";
export const SET_START_APPEAL_COMMUNICATION_CHANEL = "APPEAL/SET_START_APPEAL_COMMUNICATION_CHANEL";
export const SET_APPEAL_CLASSIFIERS = "APPEAL/SET_APPEAL_CLASSIFIERS";
export const SET_COUNTERPARTY_TYPES = "APPEAL/SET_COUNTERPARTY_TYPES";
export const SET_APPEAL_SUBJECTS = "APPEAL/SET_APPEAL_SUBJECTS";
export const SET_APPEAL_SUBJECT_TIMES = "APPEAL/SET_APPEAL_SUBJECT_TIMES";
export const SET_APPEAL_COMMUNICATION_TYPES = "APPEAL/SET_APPEAL_COMMUNICATION_TYPES";
export const SET_APPEAL_OBTAINING_METHODS = "APPEAL/SET_APPEAL_OBTAINING_METHODS";
export const SET_APPEAL_SETTLEMENTS = "APPEAL/SET_APPEAL_SETTLEMENTS";
export const SET_APPEALS_DATA = "APPEAL/SET_APPEALS_DATA";
export const SET_APPEAL_PAID_SERVICES = "APPEAL/SET_APPEAL_PAID_SERVICES";
export const SET_APPEAL_BY_ID = "APPEAL/SET_APPEAL_BY_ID";
export const SET_SELECTED_APPEAL = "APPEAL/SET_SELECTED_APPEAL";
export const SET_APPEAL_FILES_LIST = "APPEAL/SET_APPEAL_FILES_LIST";
export const SET_APPEAL_FILES_LIST_TO_UPLOAD = "APPEAL/SET_APPEAL_FILES_LIST_TO_UPLOAD";
export const SET_APPEAL_BILL_FILES_LIST = "APPEAL/SET_APPEAL_BILL_FILES_LIST";
export const SET_APPEAL_BILL_FILES_LIST_TO_UPLOAD = "APPEAL/SET_APPEAL_BILL_FILES_LIST_TO_UPLOAD";
export const SET_MAP_DATA_BY_APPEALS = "APPEAL/SET_MAP_DATA_BY_APPEALS";
export const SET_APPEAL_IS_EDIT_FLAG = "APPEAL/SET_APPEAL_IS_EDIT_FLAG";
export const SET_NOT_READ_APPEALS_COUNT = "APPEAL/SET_NOT_READ_APPEALS_COUNT";
export const SET_APPEAL_PAID_SERVICE_TYPES = "APPEAL/APPEAL_PAID_SERVICE_TYPES";
export const SET_APPEAL_CLOSE_COMMENTS = "APPEAL/SET_APPEAL_CLOSE_COMMENTS";

//Statuses martrix
export const SET_STATUSES_MATRIX_TYPES = "MATRIX/SET_STATUSES_MATRIX_TYPES";
export const SET_ALLOWED_STATUSES_MATRIX_VALUES = "MATRIX/SET_ALLOWED_STATUSES_MATRIX_VALUES";

//Dashboard
export const SET_APPEALS_COUNT = "DASHBOARD/SET_APPEALS_COUNT";
export const SET_EMERGENCY_SHUTDOWNS_COUNT = "DASHBOARD/SET_EMERGENCY_SHUTDOWNS_COUNT";
export const SET_PLANNED_SHUTDOWNS_COUNT = "DASHBOARD/SET_PLANNED_SHUTDOWNS_COUNT";
export const SET_EMERGENCY_SHUTDOWNS_COMMERCIAL_COUNTERPARTIERS_COUNT =
	"DASHBOARD/SET_EMERGENCY_SHUTDOWNS_COMMERCIAL_COUNTERPARTIERS_COUNT";
export const SET_PLANNED_SHUTDOWNS_COMMERCIAL_COUNTERPARTIERS_COUNT =
	"DASHBOARD/SET_PLANNED_SHUTDOWNS_COMMERCIAL_COUNTERPARTIERS_COUNT";
export const SET_EMERGENCY_SHUTDOWNS_RESIDENTIAL_COUNTERPARTIERS_COUNT =
	"DASHBOARD/SET_EMERGENCY_SHUTDOWNS_RESIDENTIAL_COUNTERPARTIERS_COUNT";
export const SET_PLANNED_SHUTDOWNS_RESIDENTIAL_COUNTERPARTIERS_COUNT =
	"DASHBOARD/SET_PLANNED_SHUTDOWNS_RESIDENTIAL_COUNTERPARTIERS_COUNT";
export const SET_EMERGENCY_SHUTDOWNS_COUNT_BY_ELECTRIC_ADDRESSES =
	"DASHBOARD/SET_EMERGENCY_SHUTDOWNS_COUNT_BY_ELECTRIC_ADDRESSES";
export const SET_PLANNED_SHUTDOWNS_COUNT_BY_ELECTRIC_ADDRESSES =
	"DASHBOARD/SET_PLANNED_SHUTDOWNS_COUNT_BY_ELECTRIC_ADDRESSES";

//Settings
export const SET_CREATE_EDIT_SHUTDOWNS_TIME_SETTINGS = "SETTINGS/SET_CREATE_EDIT_SHUTDOWNS_TIME_SETTINGS";

//Reports
export const SET_NKRE_373_APPENDIX2_DATA = "REPORTS/SET_NKRE_373_APPENDIX2_DATA";
export const SET_NKRE_373_APPENDIX4_DATA = "REPORTS/SET_NKRE_373_APPENDIX4_DATA";
export const SET_NKRE_373_APPENDIX5_DATA = "REPORTS/SET_NKRE_373_APPENDIX5_DATA";
export const SET_SHUTDOWNS_PER_YEAR = "REPORTS/SET_SHUTDOWNS_PER_YEAR";
export const SET_OVERDUED_APPEAL_COMPLAINS_DATA = "REPORTS/SET_OVERDUED_APPEAL_COMPLAINS_DATA";
export const SET_OVERDUED_APPEAL_PAID_SERVICES = "REPORTS/SET_OVERDUED_APPEAL_PAID_SERVICES";
export const SET_OVERDUED_APPEALS_V2 = "REPORTS/SET_OVERDUED_APPEALS_V2";
export const SET_ACTIVE_SHUTDOWNS_ASYNC_DATA = "REPORTS/SET_ACTIVE_SHUTDOWNS_ASYNC_DATA";
export const SET_HISTORICAL_SHUTDOWNS_DATA = "REPORTS/SET_HISTORICAL_SHUTDOWNS_DATA";
export const SET_FORM_RM3_DATA = "REPORTS/SET_FORM_RM3_DATA";
export const SET_COUNTERPARTY_SEARCH_DATA = "REPORTS/SET_COUNTERPARTY_SEARCH_DATA";
export const SET_REM_SHUTDOWNS_EFFICIENCY = "REPORTS/SET_REM_SHUTDOWNS_EFFICIENCY";

//User profile
export const SET_USER_APPEAL_OMISSION_MASK = "DEPARTMENT/SET_USER_APPEAL_OMISSION_MASK";
export const SET_APPEAL_SUBJECT_GROUPS = "DEPARTMENT/SET_APPEAL_SUBJECT_GROUPS";
export const SET_USER_DEPARTMENT_HIERARCHY_NAME = "DEPARTMENT/SET_USER_DEPARTMENT_HIERARCHY_NAME";

//Department
export const SET_DEPARTMENT_HIERARCHY_USERS_SUBTREE = "DEPARTMENT/SET_DEPARTMENT_HIERARCHY_USERS_SUBTREE";

//Tasks
export const SET_TASKS_DATA = "TASK/SET_TASKS_DATA";
export const SET_DRAWER_TASKS_DATA = "TASK/SET_DRAWER_TASKS_DATA";
export const SET_TASK_BY_ID = "TASK/SET_TASK_BY_ID";
export const SET_TASK_EXECUTION_LOG = "TASK/SET_TASK_EXECUTION_LOG";
export const SET_TASK_APPEAL = "TASK/SET_TASK_APPEAL";
export const SET_TASK_STATUSES = "TASK/SET_TASK_STATUSES";
export const SET_TASK_INITIATORS = "TASK/SET_TASK_INITIATORS";

//Ovb
export const SET_MAP_DATA_BY_OVB = "OVB/SET_MAP_DATA_BY_OVB";

//Claims
export const SET_CLAIM_EMERGENCY_READINESS = "CLAIM/SET_CLAIM_EMERGENCY_READINESS";
export const SET_CLAIM_TYPES = "CLAIM/SET_CLAIM_TYPES";
export const SET_CLAIM_REPAIR_TYPES = "CLAIM/SET_CLAIM_REPAIR_TYPES";
export const SET_CLAIM_APPROVAL_DEFAULTS = "CLAIM/SET_CLAIM_APPROVAL_DEFAULTS";
export const SET_CLAIM_STATUSES = "CLAIM/SET_CLAIM_STATUSES";
export const SET_APPROVALS = "CLAIM/SET_APPROVALS";
export const SET_CLAIMS = "CLAIM/SET_CLAIMS";
export const SET_CLAIM_BY_ID = "CLAIM/SET_CLAIM_BY_ID";
export const SET_CLAIM_EXECUTION_LOG = "CLAIM/SET_CLAIM_EXECUTION_LOG";
export const SET_NOT_APPROVED_CLAIMS_COUNT = "CLAIM/SET_NOT_APPROVED_CLAIMS_COUNT";
export const SET_CLAIM_APPROVAL_LEVELS = "CLAIM/SET_CLAIM_APPROVAL_LEVELS";

//Scada
export const SET_SCADA_EVENTS = "SCADA/SET_SCADA_EVENTS";

//Smart
export const SET_SMART_DATA = "SMART/SET_SMART_DATA";
export const SET_METER_TYPES = "SMART/SET_METER_TYPES";

//DICTIONARIES
export const SET_BRANCHES = "DICTIONARIES/SET_BRANCHES";
