import { SET_AUTH_DATA } from "../types";

const initialState = {
	token: null,
	userId: null,
	email: null,
	departmentId: null,
	userName: null,
	userRole: null,
	departmentEntryPointId: null,
	departmentHierarchyId: null,
};

export const loginReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_AUTH_DATA:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};
