import {
	SET_APPEALS_COUNT,
	SET_EMERGENCY_SHUTDOWNS_COMMERCIAL_COUNTERPARTIERS_COUNT,
	SET_EMERGENCY_SHUTDOWNS_COUNT,
	SET_EMERGENCY_SHUTDOWNS_COUNT_BY_ELECTRIC_ADDRESSES,
	SET_EMERGENCY_SHUTDOWNS_RESIDENTIAL_COUNTERPARTIERS_COUNT,
	SET_PLANNED_SHUTDOWNS_COMMERCIAL_COUNTERPARTIERS_COUNT,
	SET_PLANNED_SHUTDOWNS_COUNT,
	SET_PLANNED_SHUTDOWNS_COUNT_BY_ELECTRIC_ADDRESSES,
	SET_PLANNED_SHUTDOWNS_RESIDENTIAL_COUNTERPARTIERS_COUNT,
} from "../types";

const initialState = {
	appealsCount: {},
	emergencyShutdownsCount: undefined,
	plannedShutdownsCount: undefined,
	emergencyShutdownCommercialCounterpartiesCount: undefined,
	plannedShutdownCommercialCounterpartiesCount: undefined,
	emergencyShutdownResidentialCounterpartiesCount: undefined,
	plannedShutdownResidentialCounterpartiesCount: undefined,
	emergencyShutdownsCountByElectricAddresses: {},
	plannedShutdownsCountByElectricAddresses: {},
};

export const dashboardReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_APPEALS_COUNT:
			return { ...state, appealsCount: action.payload };
		case SET_EMERGENCY_SHUTDOWNS_COUNT:
			return { ...state, emergencyShutdownsCount: action.payload };
		case SET_PLANNED_SHUTDOWNS_COUNT:
			return { ...state, plannedShutdownsCount: action.payload };
		case SET_EMERGENCY_SHUTDOWNS_COMMERCIAL_COUNTERPARTIERS_COUNT:
			return { ...state, emergencyShutdownCommercialCounterpartiesCount: action.payload };
		case SET_PLANNED_SHUTDOWNS_COMMERCIAL_COUNTERPARTIERS_COUNT:
			return { ...state, plannedShutdownCommercialCounterpartiesCount: action.payload };
		case SET_EMERGENCY_SHUTDOWNS_RESIDENTIAL_COUNTERPARTIERS_COUNT:
			return { ...state, emergencyShutdownResidentialCounterpartiesCount: action.payload };
		case SET_PLANNED_SHUTDOWNS_RESIDENTIAL_COUNTERPARTIERS_COUNT:
			return { ...state, plannedShutdownResidentialCounterpartiesCount: action.payload };
		case SET_EMERGENCY_SHUTDOWNS_COUNT_BY_ELECTRIC_ADDRESSES:
			return { ...state, emergencyShutdownsCountByElectricAddresses: action.payload };
		case SET_PLANNED_SHUTDOWNS_COUNT_BY_ELECTRIC_ADDRESSES:
			return { ...state, plannedShutdownsCountByElectricAddresses: action.payload };
		default:
			return state;
	}
};
