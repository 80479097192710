import {
	SET_ACTIVE_SHUTDOWNS_ASYNC_DATA,
	SET_COUNTERPARTY_SEARCH_DATA,
	SET_FORM_RM3_DATA,
	SET_HISTORICAL_SHUTDOWNS_DATA,
	SET_NKRE_373_APPENDIX2_DATA,
	SET_NKRE_373_APPENDIX4_DATA,
	SET_NKRE_373_APPENDIX5_DATA,
	SET_OVERDUED_APPEALS_V2,
	SET_OVERDUED_APPEAL_COMPLAINS_DATA,
	SET_OVERDUED_APPEAL_PAID_SERVICES,
	SET_REM_SHUTDOWNS_EFFICIENCY,
	SET_SHUTDOWNS_PER_YEAR,
} from "../types";

const initialState = {
	nkre373Appendix2Data: undefined,
	nkre373Appendix4Data: undefined,
	nkre373Appendix5Data: undefined,
	shutdownsPerYear: [],
	overduedAppealsAsyncData: undefined,
	overduedAppealPaidServicesData: undefined,
	overduedAppealsV2: undefined,
	activeShutdownsAsyncData: undefined,
	historicalShutdownsData: undefined,
	formRM3Data: undefined,
	counterpartySearchData: undefined,
	remShutdownsEfficiency: undefined,
};

export const reportsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_NKRE_373_APPENDIX2_DATA:
			return { ...state, nkre373Appendix2Data: action.payload };
		case SET_NKRE_373_APPENDIX4_DATA:
			return { ...state, nkre373Appendix4Data: action.payload };
		case SET_NKRE_373_APPENDIX5_DATA:
			return { ...state, nkre373Appendix5Data: action.payload };
		case SET_SHUTDOWNS_PER_YEAR:
			return { ...state, shutdownsPerYear: action.payload };
		case SET_OVERDUED_APPEAL_COMPLAINS_DATA:
			return { ...state, overduedAppealsAsyncData: action.payload };
		case SET_OVERDUED_APPEAL_PAID_SERVICES:
			return { ...state, overduedAppealPaidServicesData: action.payload };
		case SET_OVERDUED_APPEALS_V2:
			return { ...state, overduedAppealsV2: action.payload };
		case SET_ACTIVE_SHUTDOWNS_ASYNC_DATA:
			return { ...state, activeShutdownsAsyncData: action.payload };
		case SET_HISTORICAL_SHUTDOWNS_DATA:
			return { ...state, historicalShutdownsData: action.payload };
		case SET_FORM_RM3_DATA:
			return { ...state, formRM3Data: action.payload };
		case SET_COUNTERPARTY_SEARCH_DATA:
			return { ...state, counterpartySearchData: action.payload };
		case SET_REM_SHUTDOWNS_EFFICIENCY:
			return { ...state, remShutdownsEfficiency: action.payload };
		default:
			return state;
	}
};
