import { defaultPageSize } from "../options";

export const tableSortBuilder = (getParams, pagination, sorter) => {
    if (pagination) {
        getParams.page = pagination.current;
        getParams.pageSize = pagination.pageSize;
    } else {
        getParams.page = 1;
        getParams.pageSize = defaultPageSize;
    }
    if(sorter) {
        let sorting = sorter.order === 'ascend' ? ' asc' : ' desc';
        sorter.order ? getParams.orderBy = sorter.field + sorting : getParams.orderBy = null;
    }
};