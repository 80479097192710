export const appealStatusEnum = Object.freeze({
	NEW: 1,
	IN_PROCESS: 2,
	CLOSED: 3,
});

export const appealCommunicationTypeEnum = Object.freeze({
	EMAIL: 1,
	SMS: 2,
	PHONE: 3,
	LETTER: 4,
	DO_NOT_INFORM: undefined,
});
