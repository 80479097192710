import { SET_USER_APPEAL_OMISSION_MASK, SET_USER_DEPARTMENT_HIERARCHY_NAME } from "../types";

const initialState = {
	userAppealOmissionMask: undefined,
	userDepartmentHierarchyName: "",
};

export const userProfileReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_USER_APPEAL_OMISSION_MASK:
			return { ...state, userAppealOmissionMask: action.payload };
		case SET_USER_DEPARTMENT_HIERARCHY_NAME:
			return { ...state, userDepartmentHierarchyName: action.payload };
		default:
			return state;
	}
};
