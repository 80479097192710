import axios from "axios";
import { apiClient } from "./api";

//Auth endpoints
export const appAPI = {
	getApiHealth() {
		return apiClient(axios)
			.get("/ApiHealth/Ping")
			.then(response => response);
	},

	getAppVersion() {
		return apiClient(axios)
			.get("/ApiHealth/Version")
			.then(response => response);
	},

	getAppEnvironment() {
		return apiClient(axios)
			.get("/ApiHealth/Environment")
			.then(response => response);
	},
};
