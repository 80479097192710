export const dictionaryTypeEnum = Object.freeze({
	SHUTDOWN_STATUSES: "shutdownStatuses",
	DEPARTMENTS: "departments",
	SHUTDOWN_TYPES: "shutdownTypes",
	SHUTDOWN_DRIVERS: "shutdownDrivers",
	NOTIFICATION_STATUSES: "notificationStatuses",
	NOTIFICATION_TYPES: "notificationTypes",
	SETTLEMENT_TYPES: "settlementTypes",
	DEPARTMENT_ENTRY_POINTS: "departmentEntryPoints",
	RECIPIENT_TYPES: "recipientTypes",
	APPEAL_CLASSIFIERS: "appealClassifiers",
	COUNTERPARTY_TYPES: "counterpartyTypes",
	APPEAL_STATUSES: "appealStatuses",
	TASK_STATUSES: "taskStatuses",
	TASK_INITIATORS: "taskInitiators",
	APPEAL_SUBJECTS: "appealSubjects",
	APPEAL_COMMUNICATION_TYPES: "appealCommunicationTypes",
	APPEAL_OBTAINING_METHODS: "appealObtainingMethods",
	STATUSES_MATRIX_TYPES: "statusesMatrixTypes",
	HOLIDAY_TYPES: "holidayTypes",
	DEPARTMENTS_FOR_HIERARCHY: "departmentsForHierarchy",
	APPEAL_SUBJECT_GROUPS: "appealSubjectGroups",
	CLAIM_EMERGENCY_READINESS: "claimEmergencyReadiness",
	CLAIM_TYPES: "claimTypes",
	CLAIM_REPAIR_TYPES: "claimRepairTypes",
	CLAIM_APPROVAL_DEFAULTS: "claimApprovalDefaults",
	CLAIM_STATUSES: "claimStatuses",
	USER_ROLES: "userRoles",
	RECIPIENT_GROUPS: "recipientGroups",
	CLAIM_APPROVAL_LEVELS: "claimApprovalLevels",
	SHUTDOWN_JOB_TYPE_REASONS: "shutdownJobTypeReasons",
	SCADAS: "scadas",
	SCADA_SUBSTANTIONS: "scadaSubstantions",
	SCADA_SUBSTANTION_VOLTAGES: "scadaSubstantionVoltages",
	SCADA_SYGNAL_STATUSES: "scadaSygnalStatuses",
	APPEAL_PAID_SERVICE_TYPES: "appealPaidServiceTypes",
	BRANCHES: "branches",
	METER_TYPES: "meterTypes",
});

export const dictionaryApiEnum = Object.freeze({
	GET_SHUTDOWN_STATUSES: "getShutdownStatuses",
	GET_DEPARTMENTS: "getDepartments",
	GET_SHUTDOWN_TYPES: "getShutdownTypes",
	GET_SHUTDOWN_DRIVERS: "getShutdownDrivers",
	GET_NOTIFICATION_STATUSES: "getNotificationStatuses",
	GET_NOTIFICATION_TYPES: "getNotificationTypes",
	GET_SETTLEMENT_TYPES: "getSettlementTypes",
	GET_DEPARTMENT_ENTRY_POINTS: "getDepartmentEntryPoints",
	GET_RECIPIENT_TYPES: "getRecipientTypes",
	GET_APPEAL_CLASSIFIERS: "getAppealClassifiers",
	GET_COUNTERPARTY_TYPES: "getCounterpartyTypes",
	GET_APPEAL_STATUSES: "getAppealStatuses",
	GET_TASK_STATUSES: "getTaskStatuses",
	GET_TASK_INITIATORS: "getTaskInitiators",
	GET_APPEAL_SUBJECTS: "getAppealSubjects",
	GET_APPEAL_COMMUNICATION_TYPES: "getAppealCommunicationTypes",
	GET_APPEAL_OBTAINING_METHODS: "getAppealObtainingMethods",
	GET_STATUSES_MATRIX_TYPES: "getStatusesMatrixTypes",
	GET_HOLIDAY_TYPES: "getHolidayTypes",
	GET_DEPARTMENTS_FOR_HIERARCHY: "getDepartmentsForHierarchy",
	GET_APPEAL_SUBJECT_GROUPS: "getAppealSubjectGroups",
	GET_CLAIM_EMERGENCY_READINESS: "getClaimEmergencyReadiness",
	GET_CLAIM_TYPES: "getClaimTypes",
	GET_CLAIM_REPAIR_TYPES: "getClaimRepairTypes",
	GET_CLAIM_APPROVAL_DEFAULTS: "getClaimApprovalDefaults",
	GET_CLAIM_STATUSES: "getClaimStatuses",
	GET_USER_ROLES: "getUserRoles",
	GET_RECIPIENT_GROUPS: "getRecipientGroups",
	GET_CLAIM_APPROVAL_LEVELS: "getClaimApprovalLevels",
	GET_SHUTDOWN_JOB_TYPE_REASONS: "getShutdownJobTypeReasons",
	GET_SCADAS: "getScadas",
	GET_SCADA_SUBSTANTIONS: "getScadaSubstantions",
	GET_SCADA_SUBSTANTION_VOLTAGES: "getScadaSubstantionVoltages",
	GET_SCADA_SYGNAL_STATUSES: "getScadaSygnalStatuses",
	GET_APPEAL_PAID_SERVICE_TYPES: "getAppealPaidServiceTypes",
	GET_BRANCHES: "getBranches",
	GET_METER_TYPES: "getMeterTypes",
});
