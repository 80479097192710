import {
    SET_CALENDAR_SELECTED_PERIOD,
    SET_CALENDAR_SELECTED_DEPARTMENT_ID,
    SET_CALENDAR_SELECTED_SHUTDOWN_TYPE_ID,
    SET_CALENDAR_SHUTDOWNS
} from "../types";
import moment from "moment";

const rangePickerDateFormat = 'YYYY-MM-DD';

const initialState = {
    shutdowns: [],
    selectedDepartmentId: null,
    selectedShutdownTypeId: null,
    selectedPeriod: [moment(moment().startOf('month'), rangePickerDateFormat),
    moment(moment().endOf('month'), rangePickerDateFormat)],
    randomColorSeed: Math.floor(Math.random() * 1000)
};

export const shutdownsCalendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CALENDAR_SHUTDOWNS:
            return {...state, shutdowns: action.payload};
        case SET_CALENDAR_SELECTED_DEPARTMENT_ID:
            return {...state, selectedDepartmentId: action.payload};
        case SET_CALENDAR_SELECTED_SHUTDOWN_TYPE_ID:
            return {...state, selectedShutdownTypeId: action.payload};
        case SET_CALENDAR_SELECTED_PERIOD:
            return {...state, selectedPeriod: action.payload};          
        default:
            return state
    }
};