import { Col, Row, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import StatisticIndicatorType0 from "./StatisticIndicatorType0/StatisticIndicatorType0";
import StatisticIndicatorType1 from "./StatisticIndicatorType1/StatisticIndicatorType1";
import StatisticIndicatorType2 from "./StatisticIndicatorType2/StatisticIndicatorType2";
import StatisticIndicatorType3 from "./StatisticIndicatorType3/StatisticIndicatorType3";
import css from "./Main.module.scss";
import { dictionaryTypeEnum } from "../../dictionaries/models/dictionaryTypeEnum";
import { getAllDashboardData } from "../../redux/actions/dashboardActions";
import moment from "moment";
import { setPageBreadcrumb } from "../../redux/actions/appActions";
import { shutdownTypeEnum } from "../Shutdowns/enums/shutdownEnums";
import useDictionary from "../../dictionaries/useDictionaries";

const Main = ({ text }) => {
	const dispatch = useDispatch();
	const { Option } = Select;
	const dateFormat = "YYYY-MM-DD (HH:mm)";

	//const isLoading = useSelector(state => state.app.isLoading);
	const userDepartmentId = useSelector(state => state.auth.departmentId);
	const departments = useDictionary(dictionaryTypeEnum.DEPARTMENTS);
	const appealsCount = useSelector(state => state.dashboard.appealsCount);
	const emergencyShutdownsCount = useSelector(state => state.dashboard.emergencyShutdownsCount);
	const plannedShutdownsCount = useSelector(state => state.dashboard.plannedShutdownsCount);
	const emergencyShutdownCommercialCounterpartiesCount = useSelector(
		state => state.dashboard.emergencyShutdownCommercialCounterpartiesCount
	);
	const plannedShutdownCommercialCounterpartiesCount = useSelector(
		state => state.dashboard.plannedShutdownCommercialCounterpartiesCount
	);
	const emergencyShutdownResidentialCounterpartiesCount = useSelector(
		state => state.dashboard.emergencyShutdownResidentialCounterpartiesCount
	);
	const plannedShutdownResidentialCounterpartiesCount = useSelector(
		state => state.dashboard.plannedShutdownResidentialCounterpartiesCount
	);
	const emergencyShutdownsCountByElectricAddresses = useSelector(
		state => state.dashboard.emergencyShutdownsCountByElectricAddresses
	);
	const plannedShutdownsCountByElectricAddresses = useSelector(
		state => state.dashboard.plannedShutdownsCountByElectricAddresses
	);

	const [departmentId, setDepartmentId] = useState(undefined);
	const [dateNow, setDateNow] = useState(moment().format(dateFormat));
	const [resSelectorDisabled, setResSelectorDisabled] = useState(false);
	const [params, setParams] = useState(undefined);

	//Bradcrums etc..
	useEffect(() => {
		const breadCrumbData = [{ name: text, link: false }];
		dispatch(setPageBreadcrumb(breadCrumbData));
	}, [dispatch, text]);

	//Roles and access control
	useEffect(() => {
		const parsedUserDepartmentId = JSON.parse(userDepartmentId);

		if (!parsedUserDepartmentId || parsedUserDepartmentId === 25) {
			setDepartmentId(null);
			setResSelectorDisabled(false);
		} else {
			setDepartmentId(parsedUserDepartmentId);
			setResSelectorDisabled(true);
		}
	}, [dispatch, userDepartmentId]);

	//Running of the dashboard endpoints
	useEffect(() => {
		if (departmentId === undefined) return;

		const getParams = {
			emergencyShutdownsGetParams: {
				departmentId: departmentId,
				shutdownTypeId: shutdownTypeEnum.EMERGENCY,
			},
			plannedShutdownsGetParams: {
				departmentId: departmentId,
				shutdownTypeId: shutdownTypeEnum.PLANNED,
			},
			appealsGetParams: {
				departmentId: departmentId,
			},
		};

		setParams(getParams);
	}, [departmentId]);

	const getAllDashboardDataCallback = useCallback(
		prms => {
			dispatch(getAllDashboardData(prms));
		},
		[dispatch]
	);

	useEffect(() => {
		if (!params) return;

		getAllDashboardDataCallback(params);
	}, [getAllDashboardDataCallback, params]);

	const onDepartmentChange = value => {
		setDepartmentId(value || null);
		setDateNow(moment().format(dateFormat));
	};

	return (
		<div className={css.main_wrapper}>
			<Row className={css.filter_wrapper}>
				<Col className={css.controls_wrap}>
					<span>Підрозділ: </span>
					<Select
						className={css.res_select}
						placeholder='(Всі)'
						popupMatchSelectWidth={false}
						allowClear={true}
						onChange={onDepartmentChange}
						disabled={resSelectorDisabled}
						value={departmentId}
					>
						{departments.length &&
							departments.map(item => (
								<Option key={item.id} value={item.id}>
									{item.name}
								</Option>
							))}
					</Select>
				</Col>
				<Col className={css.info_data_now}>Інформація станом на: {dateNow}</Col>
			</Row>

			<Row className={css.shutdowns_statistic_wrapper} gutter={16}>
				<Col md={24} lg={12}>
					<div className={css.content_box}>
						<Row className={css.title}>
							<span>Скарги, пропозиції</span>
						</Row>

						<Row className={css.content} gutter={8}>
							<Col sm={24} md={7}>
								<StatisticIndicatorType0
									value={appealsCount.appealsCountByComplains?.new}
									valueBgColor={"#DD4B39"}
									valueColor={"#fff"}
									title={"К-сть зі статусом"}
									titleColor={"#000"}
									status={"нове"}
									statusColor={"#DD4B39"}
									background={"#fff"}
									border={"1px solid #fff"}
								/>
							</Col>

							<Col sm={24} md={7}>
								<StatisticIndicatorType0
									value={appealsCount.appealsCountByComplains?.inProgress}
									valueBgColor={"#00C0EF"}
									valueColor={"#fff"}
									title={"К-cть зі статусом"}
									titleColor={"#000"}
									status={"в роботі"}
									statusColor={"#00C0EF"}
									background={"#fff"}
									border={"1px solid #fff"}
								/>
							</Col>

							<Col sm={24} md={10}>
								<StatisticIndicatorType0
									value={appealsCount.appealsCountByComplains?.overdued}
									valueBgColor={"#76608A"}
									valueColor={"#fff"}
									title={"Кількість"}
									titleColor={"#000"}
									status={"протермінованих"}
									statusColor={"#76608A"}
									background={"#fff"}
									border={"1px solid #fff"}
								/>
							</Col>
						</Row>
					</div>
				</Col>

				<Col md={24} lg={12}>
					<div className={css.content_box}>
						<Row className={css.title}>
							<span>Заяви на послуги</span>
						</Row>

						<Row className={css.content} gutter={8}>
							<Col sm={24} md={7}>
								<StatisticIndicatorType0
									value={appealsCount.appealsCountByPaidServices?.new}
									valueBgColor={"#DD4B39"}
									valueColor={"#fff"}
									title={"К-cть зі статусом"}
									titleColor={"#000"}
									status={"нове"}
									statusColor={"#DD4B39"}
									background={"#fff"}
									border={"1px solid #fff"}
								/>
							</Col>

							<Col sm={24} md={7}>
								<StatisticIndicatorType0
									value={appealsCount.appealsCountByPaidServices?.inProgress}
									valueBgColor={"#00C0EF"}
									valueColor={"#fff"}
									title={"К-cть зі статусом"}
									titleColor={"#000"}
									status={"в роботі"}
									statusColor={"#00C0EF"}
									background={"#fff"}
									border={"1px solid #fff"}
								/>
							</Col>

							<Col sm={24} md={10}>
								<StatisticIndicatorType0
									value={appealsCount.appealsCountByPaidServices?.overdued}
									valueBgColor={"#76608A"}
									valueColor={"#fff"}
									title={"Кількість"}
									titleColor={"#000"}
									status={"протермінованих"}
									statusColor={"#76608A"}
									background={"#fff"}
									border={"1px solid #fff"}
								/>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>

			<Row className={css.shutdowns_statistic_wrapper} gutter={16}>
				<Col md={24} lg={12}>
					<div className={css.content_box}>
						<Row className={css.title}>
							<span className={css.emergency_shurdown_title_border}>Аварійні відключення</span>
						</Row>

						<Row className={css.content} gutter={16}>
							<Col sm={24} md={12}>
								<StatisticIndicatorType1
									value={emergencyShutdownsCount?.emergencyConfirmed}
									valueBgColor={"#fff"}
									valueColor={"#000"}
									title={"Кількість відключень зі статусом"}
									titleColor={"#fff"}
									status={"підтверджене"}
									statusColor={"#fff"}
									background={"#00A65A"}
									border={"1px solid #00A65A"}
									marginRight={"0"}
								/>
							</Col>

							<Col sm={24} md={12}>
								<StatisticIndicatorType1
									value={emergencyShutdownsCount?.emergencyUnconfirmed}
									valueBgColor={"#fff"}
									valueColor={"#000"}
									title={"Кількість відключень зі статусом"}
									titleColor={"#fff"}
									status={"непідтверджене"}
									statusColor={"#fff"}
									background={"#DD4B39"}
									border={"1px solid #DD4B39"}
									marginRight={0}
									description={"*за поточну добу"}
								/>
							</Col>
						</Row>
						{/*<Text className={css.discription} italic>Кількість відключень зі статусом НЕПІДТВЕРЖЕНЕ за поточну добу</Text>*/}

						<Row className={css.recipient_statistic_wrapper} gutter={16}>
							<Col sm={24} lg={12}>
								<StatisticIndicatorType2
									title={"Відключення зі статусом ПІДТВЕРДЖЕНЕ"}
									subTitle={"непобутових споживачів"}
									value={emergencyShutdownCommercialCounterpartiesCount}
									marginRight={0}
									color={"#607D8B"}
								/>
							</Col>

							<Col sm={24} lg={12}>
								<StatisticIndicatorType2
									title={"Відключення зі статусом ПІДТВЕРДЖЕНЕ"}
									subTitle={"побутових споживачів"}
									value={emergencyShutdownResidentialCounterpartiesCount}
									marginRight={"0px"}
									color={"#7499BD"}
								/>
							</Col>
						</Row>

						<Row className={css.shutdown_nodes_statistic_wrapper}>
							<StatisticIndicatorType3 data={emergencyShutdownsCountByElectricAddresses} />
						</Row>
					</div>
				</Col>

				<Col md={24} lg={12}>
					<div className={css.content_box}>
						<Row className={css.title}>
							<span className={css.planned_shurdown_title_border}>Планові відключення</span>
						</Row>

						<Row className={css.content} gutter={16}>
							<Col sm={24} md={12}>
								<StatisticIndicatorType1
									value={plannedShutdownsCount?.plannedActive}
									valueBgColor={"#fff"}
									valueColor={"#000"}
									title={"Кількість відключень зі статусом"}
									titleColor={"#fff"}
									status={"активне"}
									statusColor={"#fff"}
									background={"#F1B24E"}
									border={"1px solid #F1B24E"}
									marginRight={0}
								/>
							</Col>

							<Col sm={24} md={12}>
								<StatisticIndicatorType1
									value={plannedShutdownsCount?.plannedAllowed}
									valueBgColor={"#fff"}
									valueColor={"#000"}
									title={"Кількість відключень зі статусом"}
									titleColor={"#fff"}
									status={"дозволене"}
									statusColor={"#fff"}
									background={"#00A65A"}
									border={"1px solid #00A65A"}
									marginRight={0}
								/>
							</Col>
						</Row>

						<Row className={css.recipient_statistic_wrapper} gutter={16}>
							<Col sm={24} lg={12}>
								<StatisticIndicatorType2
									title={"Відключення зі статусом АКТИВНЕ"}
									subTitle={"непобутових споживачів"}
									value={plannedShutdownCommercialCounterpartiesCount}
									marginRight={0}
									color={"#607D8B"}
								/>
							</Col>

							<Col sm={24} lg={12}>
								<StatisticIndicatorType2
									title={"Відключення зі статусом АКТИВНЕ"}
									subTitle={"побутових споживачів"}
									value={plannedShutdownResidentialCounterpartiesCount}
									marginRight={"0"}
									color={"#7499BD"}
								/>
							</Col>
						</Row>

						<Row className={css.shutdown_nodes_statistic_wrapper}>
							<StatisticIndicatorType3 data={plannedShutdownsCountByElectricAddresses} />
						</Row>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default Main;
