//Processing responses and displaying errors
import { message } from "antd";
import { logOut } from "./loginActions";

export const thunkErrorHandler = async (errorResponse, dispatch) => {
    const duration = 4;
    if (!errorResponse)
        return;
        
    let data = errorResponse.data;

    const isJsonBlob = errorResponse.data instanceof Blob;
    const responseData = isJsonBlob ? await (errorResponse?.data)?.text() : errorResponse.data || {};
    data = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;

    switch (errorResponse.status) {
        case 400:
            if (data.errors) {
                const keys = Object.keys(data.errors);

                const errors = keys.map(x => {
                    return data.errors[x].map(y => `${y}`)
                });

                message.warning(errors.join('\n'), duration);
                break;
            }

            if (data.detail) {
                message.warning(data.detail, duration);
            }

            break;

        case 401:
            //message.error(`Помилка авторизації, невірний логін, пароль або закінчився строк дії сесії: ${data.title}!`, duration);
            console.error(`Помилка авторизації, невірний логін, пароль або закінчився строк дії сесії: ${data.title}!`);
            dispatch(logOut());
            break;

        case 403:
            message.warning(`Доступ заборонено: ${data.title}!`, duration);
            break;

        case 404:
            message.warning('Ресурс не знайдено', duration);
            break;

        case 412:
            message.warning(data.detail, duration);
            break;

        case 500:
            if (!process.env.REACT_APP_BASE_URL) {
                message.warning(`Виникла непередбачувана помилка. Зверніться до адміністратора`, duration);
            } else {
                const keys = Object.keys(data.exceptionDetails);
                const errors = keys.map(x => {
                    return data.exceptionDetails[x].message
                });
                message.warning(errors.join('\n'), duration);
            }
            break;

        default:
            message.warning(`Виникла непередбачувана помилка. Зверніться до адміністратора`, duration);
    }
};

