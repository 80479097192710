import axios from "axios";
import { apiClient } from "./api";

//Remhierarchy endpoints
export const departmentHierarchyAPI = {
	getDepartmentHierarchyUsersTree(departmentId) {
		return apiClient(axios)
			.get(`/Department/GetDepartmentHierarchyUsersTree/${departmentId}`)
			.then(response => response);
	},

	getDepartmentHierarchyFreeUsers(departmentId) {
		return apiClient(axios)
			.get(`/Department/GetDepartmentHierarchyFreeUsers/${departmentId}`)
			.then(response => response);
	},

	addDepartmentHierarchyUser(departmentHierarchyId, userId) {
		return apiClient(axios)
			.post(`/Department/AddDepartmentHierarchyUser`, { departmentHierarchyId, userId })
			.then(response => response);
	},

	deleteDepartmentHierarchyUser(departmentHierarchyUserId) {
		return apiClient(axios)
			.delete(`/Department/DeleteDepartmentHierarchyUser/${departmentHierarchyUserId}`)
			.then(response => response);
	},

	getUserAppealOmissionMask() {
		return apiClient(axios)
			.get(`/Department/GetUserAppealOmissionMask`)
			.then(response => response);
	},

	getUserDepartmentHierarchyName() {
		return apiClient(axios)
			.get(`/Department/GetUserDepartmentHierarchyName`)
			.then(response => response);
	},
	setUserAppealOmissionMaskDefault() {
		return apiClient(axios)
			.put(`/Department/setUserAppealOmissionMaskDefault`)
			.then(response => response);
	},
	setUserAppealOmissionMaskCurrent(mask) {
		return apiClient(axios)
			.put(`/Department/setUserAppealOmissionMaskCurrent/${mask}`)
			.then(response => response);
	},

	getDepartmentHierarchyUsersSubtree(departmentId, taskId) {
		return apiClient(axios)
			.get(
				taskId
					? `/Department/GetDepartmentHierarchyUsersSubtree/${departmentId}/${taskId}`
					: `/Department/GetDepartmentHierarchyUsersSubtree/${departmentId}`
			)
			.then(response => response);
	},
};
