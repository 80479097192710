import { Button, Col, Divider, Drawer, Row, Timeline } from "antd";
import React, { useEffect, useState } from "react";
import { getTaskById, getTaskExecutionLog } from "../../../redux/actions/taskActions";
import { useDispatch, useSelector } from "react-redux";

import CheckCircleFilled from "@ant-design/icons/es/icons/CheckCircleFilled";
import LinkOutlined from "@ant-design/icons/es/icons/LinkOutlined";
import ViewAppealDrawer from "../../Appeals/ViewAppealDrawer/ViewAppealDrawer";
import ViewAppealPaidServiceDrawer from "../../AppealPaidService/ViewAppealPaidServiceDrawer/ViewAppealPaidServiceDrawer";
import css from "./ViewTaskDrawer.module.scss";
import moment from "moment";
import { tableButtonsSize } from "../../../assets/js/options";
import { taskExecutionLogCreator } from "../helpers/taskExecutionLogCreator";
import { taskInitiatorsEnum } from "../enums/taskEnums";

const ViewTaskDrawer = ({ visible, onClose, taskId }) => {
	const dispatch = useDispatch();
	const dateFormat = "YYYY-MM-DD (HH:mm)";
	const timelineIndexPrefix = "timelineIndex_";
	const pIndexPrefix = "pIndex_";

	const task = useSelector(state => state.task.taskById);
	const taskExecutionLog = useSelector(state => state.task.taskExecutionLog);

	const [currentTask, setCurrentTask] = useState(null);
	const [viewModalVisible, setViewModalVisible] = useState(false);
	const [convertedExecutionLog, setConvertedExecutionLog] = useState([]);

	const setStatusColor = () => {
		let color = { titleColor: null, lineColor: null, labelColor: null };
		switch (currentTask.taskStatusId) {
			case 1:
				color.titleColor = "#D9F6FD";
				color.lineColor = "#00ACD7";
				color.labelColor = "#00ACD7";
				return color;
			case 2:
				color.titleColor = "#a0fdb6";
				color.lineColor = "#00a65a";
				color.labelColor = "#00a65a";
				return color;
			case 3:
				color.titleColor = "#FAE4E1";
				color.lineColor = "#C64333";
				color.labelColor = "#C64333";
				return color;
			case 4:
				color.titleColor = "#BDBDBD";
				color.lineColor = "#828282";
				color.labelColor = "#828282";
				return color;
			default:
				return color;
		}
	};

	//Get tasks and etc
	useEffect(() => {
		if (!taskId || !visible) return;
		dispatch(getTaskById(taskId));
		dispatch(getTaskExecutionLog(taskId));
	}, [dispatch, taskId, visible]);

	useEffect(() => {
		if (!task) return;
		setCurrentTask(task);
	}, [task, taskId]);

	useEffect(() => {
		if (taskExecutionLog) setConvertedExecutionLog(taskExecutionLogCreator(taskExecutionLog));
		else setConvertedExecutionLog([]);
	}, [taskExecutionLog]);

	return (
		<>
			{currentTask && (
				<Drawer
					title={<b>{`Перегляд завдання №${currentTask.id}:`}</b>}
					placement='right'
					width={"25%"}
					headerStyle={{ backgroundColor: setStatusColor().titleColor, borderRadius: "0px" }}
					drawerStyle={{ borderLeft: `4px solid ${setStatusColor().lineColor}` }}
					onClose={onClose}
					open={visible}
				>
					<div className={css.view_task_wrapp}>
						<Row className={css.item}>
							<Col className={css.sub_item_title}>Статус:</Col>
							<Col style={{ color: setStatusColor().labelColor }}>{currentTask.taskStatusName}</Col>
						</Row>

						<Row className={css.item}>
							<Col className={css.sub_item_title}>Дата створення:</Col>
							<Col>{moment(currentTask.created).format(dateFormat)}</Col>
						</Row>

						<Divider />

						<Row className={css.item}>
							<Col className={css.sub_item_title}>Підрозділ:</Col>
							<Col>{currentTask.departmentName}</Col>
						</Row>

						<Row className={css.item}>
							<Col className={css.sub_item_title}>Автор завдання:</Col>
							<Col>{currentTask.createdByUserName}</Col>
						</Row>

						<Row className={css.item}>
							<Col className={css.sub_item_title}>Виконавець:</Col>
							<Col>{currentTask.departmentHierarchyUserName}</Col>
						</Row>

						<Divider />

						<Row className={css.item}>
							<Col>
								<span>
									<span className={css.sub_item_title}>Зміст завдання:</span>
									{currentTask.description}
								</span>
							</Col>
						</Row>

						<Row className={css.item}>
							{currentTask.appealId ? (
								<Col>
									<Row className={css.sub_item_title}>
										Звернення:
										<span className={css.table_action_btn}>
											<Button
												size={tableButtonsSize}
												style={{ marginLeft: 10 }}
												onClick={() => setViewModalVisible(true)}
											>
												<LinkOutlined />
											</Button>
										</span>
									</Row>
								</Col>
							) : (
								<Col>
									<Row>
										<span className={css.sub_item_title}>Звернення: </span>Відсутнє
									</Row>
								</Col>
							)}
						</Row>

						<Divider />

						<Row className={css.item}>
							<Col>
								<Row className={css.sub_item_title}>Етапи виконання:</Row>
								<Row className={css.execution_log}>
									<Timeline
										items={
											convertedExecutionLog.length
												? convertedExecutionLog.map((item, timelineIndex) => ({
														key: timelineIndexPrefix + timelineIndex,
														dot: <CheckCircleFilled />,
														color: timelineIndex === 0 ? "red" : "blue",
														children: (
															<>
																<p
																	className={`${css.p_header_font} ${
																		timelineIndex === 0 ? css.p_header_bkg_red : css.p_header_bkg_blue
																	}`}
																>
																	{item.created}, <strong>{item.createdByUserName}</strong>{" "}
																	{timelineIndex === 0 ? "створив(ла):" : "змінив(ла):"}
																</p>
																{item.operations.map((operation, pIndex) =>
																	timelineIndex === 0 ? (
																		<p key={pIndexPrefix + pIndex} className={css.operation_log}>
																			<i>{operation.column}</i>: {operation.value}
																		</p>
																	) : operation.oldValue ? (
																		<p key={pIndexPrefix + pIndex} className={css.operation_log}>
																			<i>{operation.column}</i> з{" "}
																			<strong>{operation.oldValue}</strong> на{" "}
																			<strong>{operation.value}</strong>
																		</p>
																	) : (
																		<p key={pIndexPrefix + pIndex} className={css.operation_log}>
																			<i>{operation.column}</i>: {operation.value}
																		</p>
																	)
																)}
															</>
														),
												  }))
												: { children: "Намає даних" }
										}
									/>
								</Row>
							</Col>
						</Row>
					</div>
				</Drawer>
			)}

			{currentTask && currentTask.appealId && (
				<>
					{currentTask.taskInitiatorId === taskInitiatorsEnum.APPEAL ? (
						<ViewAppealDrawer
							visible={viewModalVisible}
							onClose={() => setViewModalVisible(false)}
							appealId={currentTask.appealId}
						/>
					) : currentTask.taskInitiatorId === taskInitiatorsEnum.APPEAL_PAID_SERVICE ? (
						<ViewAppealPaidServiceDrawer
							visible={viewModalVisible}
							onClose={() => setViewModalVisible(false)}
							appealId={currentTask.appealId}
						/>
					) : null}
				</>
			)}
		</>
	);
};

export default ViewTaskDrawer;
