import axios from "axios";
import { apiClient, domainApiClient } from "./api";

//Auth endpoints
export const authAPI = {
	login(email, password) {
		return apiClient(axios)
			.post("/Token", { email, password })
			.then(response => response);
	},
	domainLogin() {
		return domainApiClient(axios)
			.get("/Token/GetFromWindowsAuthentication")
			.then(response => response);
	},
	sendNewPassword(email) {
		return apiClient(axios)
			.put(`/User/SendNewPassword/${email}`)
			.then(response => response);
	},
};
