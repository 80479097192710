import { Button, Col, DatePicker, Input, Row, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { defaultPageSize, paginationPosition, tableSize } from "../../../assets/js/options";
import {
	getOverduedAppealPaidServices,
	getOverduedAppealPaidServicesXlsx,
} from "../../../redux/actions/reportsActions";
import { useDispatch, useSelector } from "react-redux";

import DatabaseOutlined from "@ant-design/icons/es/icons/DatabaseOutlined";
import { DollarOutlined } from "@ant-design/icons";
import FileExcelOutlined from "@ant-design/icons/es/icons/FileExcelOutlined";
import PartitionOutlined from "@ant-design/icons/es/icons/PartitionOutlined";
import TreeSelect from "antd/es/tree-select";
import css from "./OverduedAppealPaidServices.module.scss";
import { dictionaryTypeEnum } from "../../../dictionaries/models/dictionaryTypeEnum";
import moment from "moment";
import { setPageBreadcrumb } from "../../../redux/actions/appActions";
import useDictionary from "../../../dictionaries/useDictionaries";

const OverduedAppealPaidServices = ({ text }) => {
	const rangePickerDateFormat = "YYYY-MM-DD";
	const tableDateFormat = "YYYY-MM-DD (HH:mm)";
	const { Option } = Select;
	const { SHOW_PARENT } = TreeSelect;
	const dispatch = useDispatch();

	const currentUser = useSelector(state => state.auth);
	const departments = useDictionary(dictionaryTypeEnum.DEPARTMENTS);
	const treeData = useDictionary(dictionaryTypeEnum.APPEAL_PAID_SERVICE_TYPES);
	const overduedAppealPaidServicesData = useSelector(state => state.reports.overduedAppealPaidServicesData);

	const [selectedDepartmentId, setSelectedDepartmentId] = useState(
		JSON.parse(currentUser.departmentId) === 25 ? null : JSON.parse(currentUser.departmentId)
	);
	const [appealPaidServicesTypesTree, setAppealPaidServicesTypesTree] = useState(undefined);
	const [startDate, setStartDate] = useState(moment());
	const [appealPaidServicesId, setAppealPaidServicesId] = useState(null);
	const [counterpartyPersonalAccount, setCounterpartyPersonalAccount] = useState(null);
	const [btnDisabled, setBtnDissabled] = useState(true);

	const overduedAppealPaidServiceColumns = [
		{
			title: "Підрозділ",
			dataIndex: "departmentName",
			key: "departmentName",
			sorter: (a, b) => a.departmentName.localeCompare(b.departmentName),
			width: 150,
			fixed: "left",
		},
		{
			title: "Точка входу",
			dataIndex: "departmentEntryPointName",
			key: "departmentEntryPointName",
			sorter: (a, b) => a.departmentEntryPointName.localeCompare(b.departmentEntryPointName),
			width: 150,
			fixed: "left",
		},
		{
			title: "Реєстраційний номер",
			dataIndex: "innerRegistrationNumber",
			key: "innerRegistrationNumber",
			sorter: (a, b) => a.innerRegistrationNumber.localeCompare(b.innerRegistrationNumber),
			width: 150,
			align: "center",
		},
		{
			title: "Дата реєстрації",
			dataIndex: "innerRegistrationDate",
			key: "innerRegistrationDate",
			sorter: (a, b) => moment(a.innerRegistrationDate) - moment(b.innerRegistrationDate),
			width: 150,
			align: "center",
		},
		{
			title: "Термін розляду, днів",
			dataIndex: "appealPaidServiceTimeValue",
			key: "appealPaidServiceTimeValue",
			sorter: (a, b) => a.appealPaidServiceTimeValue - b.appealPaidServiceTimeValue,
			width: 120,
			align: "center",
		},
		{
			title: "Кінцева дата розгляду/вирішення",
			dataIndex: "dueDate",
			key: "dueDate",
			sorter: (a, b) => moment(a.dueDate) - moment(b.dueDate),
			width: 180,
			align: "center",
		},
		{
			title: "Тематика",
			dataIndex: "appealPaidServiceName",
			key: "appealPaidServiceName",
			sorter: (a, b) => a.appealPaidServiceName.localeCompare(b.appealPaidServiceName),
			width: 200,
		},
		{
			title: "Зміст звернення",
			dataIndex: "description",
			key: "description",
			sorter: (a, b) => a.description.localeCompare(b.description),
			width: 300,
		},
		{
			title: "ОР, № договору",
			dataIndex: "counterpartyPersonalAccount",
			key: "counterpartyPersonalAccount",
			sorter: (a, b) => a.counterpartyPersonalAccount - b.counterpartyPersonalAccount,
			width: 150,
			align: "center",
		},
		{
			title: "Назва заявника",
			dataIndex: "counterpartyName",
			key: "counterpartyName",
			sorter: (a, b) => a.counterpartyName.localeCompare(b.counterpartyName),
			width: 200,
		},
		{
			title: "Населений пункт",
			dataIndex: "settlementName",
			key: "settlementName",
			sorter: (a, b) => a.settlementName.localeCompare(b.settlementName),
			width: 250,
		},
		{
			title: "Адреса",
			dataIndex: "address",
			key: "address",
			sorter: (a, b) => a.address.localeCompare(b.address),
			width: 250,
		},
	];

	const overduedAppealsTableData = () => {
		if (overduedAppealPaidServicesData) {
			return overduedAppealPaidServicesData.map(item => {
				return {
					key: item.id,
					innerRegistrationNumber: item.innerRegistrationNumber,
					departmentEntryPointName: item.departmentEntryPointName,
					departmentName: item.departmentName ? item.departmentName : "", //Check need when departmentName = null/undefined
					innerRegistrationDate: moment(item.innerRegistrationDate).format(tableDateFormat),
					appealPaidServiceTimeValue: item.appealPaidServiceTimeValue,
					dueDate: moment(item.dueDate).format(tableDateFormat),
					description: item.description,
					appealPaidServiceName: item.appealPaidServiceName,
					counterpartyPersonalAccount: item.counterpartyPersonalAccount,
					counterpartyName: item.counterpartyName,
					settlementName: item.settlementName,
					address: item.address,
				};
			});
		}
	};

	//Bradcrums etc..
	useEffect(() => {
		const breadCrumbData = [{ name: text, link: false }];

		dispatch(setPageBreadcrumb(breadCrumbData));
	}, [dispatch, text]);

	//Paid service's types tree converting
	useEffect(() => {
		if (!treeData) {
			setAppealPaidServicesTypesTree([]);
			return;
		}

		const data = [...treeData];

		data.forEach(el => {
			el.value = el.id;
			el.icon = el.selectable ? (
				<DollarOutlined style={{ color: "orange" }} />
			) : (
				<PartitionOutlined style={{ color: "red" }} />
			);
			el.checkable = el.selectable;
		});

		setAppealPaidServicesTypesTree(data);
	}, [treeData]);

	//Enable / Disable buttons
	useEffect(() => {
		if (startDate) {
			setBtnDissabled(false);
		} else {
			setBtnDissabled(true);
		}
	}, [selectedDepartmentId, appealPaidServicesId, startDate, counterpartyPersonalAccount]);

	const onDepartmentChange = value => {
		setSelectedDepartmentId(value);
	};

	const onPeriodChange = value => {
		setStartDate(value);
	};

	const onPaidServiceChange = value => {
		setAppealPaidServicesId(value);
	};

	const onCounterpartyPersonalAccountChange = event => {
		setCounterpartyPersonalAccount(event.target.value);
	};

	const onReportCreate = () => {
		dispatch(
			getOverduedAppealPaidServices(
				selectedDepartmentId ? Number(selectedDepartmentId) : null,
				appealPaidServicesId,
				moment(startDate).format(), //without "format()" we have "-2 hours in the date"
				counterpartyPersonalAccount ? String(counterpartyPersonalAccount) : null
			)
		);
	};

	const onXlsxFileCreate = () => {
		dispatch(
			getOverduedAppealPaidServicesXlsx(
				selectedDepartmentId ? Number(selectedDepartmentId) : null,
				appealPaidServicesId,
				moment(startDate).format(),
				counterpartyPersonalAccount ? String(counterpartyPersonalAccount) : null
			)
		);
	};

	return (
		<div className={css.report_wrapp}>
			<Row>
				<Col className={css.controls_wrap}>
					<span>Підрозділ: </span>
					<Select
						className={css.department_select}
						placeholder='(Всі)'
						popupMatchSelectWidth={false}
						onChange={onDepartmentChange}
						allowClear={true}
						value={selectedDepartmentId}
						disabled={
							!(currentUser.userRole.includes("Administrator") || Number(currentUser.departmentId) === 25)
						}
					>
						{departments.map(item => (
							<Option key={item.id} value={item.id}>
								{item.name}
							</Option>
						))}
					</Select>
				</Col>

				<Col className={css.controls_wrap}>
					<span>На дату: </span>
					<DatePicker
						allowClear={true}
						showToday={true}
						defaultValue={startDate}
						format={rangePickerDateFormat}
						onChange={onPeriodChange}
					/>
				</Col>

				<Col className={css.controls_wrap}>
					<Input
						style={{ minWidth: "200px" }}
						placeholder='OР, № договору'
						onChange={onCounterpartyPersonalAccountChange}
					/>
				</Col>

				<Col className={css.controls_wrap}>
					<TreeSelect
						style={{ minWidth: "250px" }}
						required={true}
						treeData={appealPaidServicesTypesTree}
						placeholder='Оберіть послугу'
						treeDataSimpleMode={{
							id: "id",
							pId: "parentId",
							rootPId: "/",
							key: "id",
						}}
						treeNodeFilterProp='title'
						treeNodeLabelProp='title'
						showCheckedStrategy={SHOW_PARENT}
						showSearch={true}
						listHeight={500}
						popupMatchSelectWidth={700}
						treeDefaultExpandAll={false}
						onChange={value => onPaidServiceChange(value)}
						multiple={true}
						allowClear
						treeCheckable
						treeIcon
					/>
				</Col>

				<Col>
					<Button
						className={btnDisabled ? "" : css.site_button}
						style={{ marginRight: "10px" }}
						type='primary'
						icon={<DatabaseOutlined />}
						onClick={onReportCreate}
						disabled={btnDisabled}
						//loading={!!isLoading}
					>
						Сформувати
					</Button>
				</Col>
				<Col>
					<Button
						className={btnDisabled ? "" : css.site_button}
						type='primary'
						icon={<FileExcelOutlined />}
						disabled={btnDisabled}
						onClick={onXlsxFileCreate}
						//loading={!!isLoading}
					>
						XLSX
					</Button>
				</Col>
			</Row>

			<Table //className={css.ant_table_wrapper}
				size={tableSize}
				pagination={{
					position: paginationPosition,
					defaultPageSize: defaultPageSize,
					showSizeChanger: true,
				}}
				scroll={{ x: "100%", y: "calc(100vh - 320px)" }}
				columns={overduedAppealPaidServiceColumns}
				showSorterTooltip={false}
				bordered
				dataSource={overduedAppealsTableData()}
			/>
		</div>
	);
};

export default OverduedAppealPaidServices;
