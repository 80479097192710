import "./../../../assets/scss/common_components_styles/flat_pickr.css";

import { Button, DatePicker, Space, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { getOverduedAppealsV2, getOverduedAppealsV2Xlsx } from "../../../redux/actions/reportsActions";
import { useDispatch, useSelector } from "react-redux";

import DatabaseOutlined from "@ant-design/icons/es/icons/DatabaseOutlined";
import FileExcelOutlined from "@ant-design/icons/es/icons/FileExcelOutlined";
import css from "./AppealsControl.module.scss";
import locale from "antd/es/date-picker/locale/uk_UA";
import moment from "moment";
import { setPageBreadcrumb } from "../../../redux/actions/appActions";
import { tableSize } from "../../../assets/js/options";

const AppealsControl = ({ text }) => {
	const dispatch = useDispatch();
	const { RangePicker } = DatePicker;
	const rangePickerDateFormat = "YYYY-MM-DD";

	const appealsControlData = useSelector(state => state.reports.overduedAppealsV2);
	const isLoading = useSelector(state => state.app.isLoading);

	const [btnDisabled, setBtnDisabled] = useState(true);

	const [appealsControlRequestParams, setAppealsControlRequestParams] = useState({
		startDate: moment().add(-30, "days"),
		endDate: moment(),
	});

	const appealsControlTableColumns = [
		{
			title: "Підрозділ Товариства",
			dataIndex: "departmentName",
			key: "departmentName",
			align: "left",
			ellipsis: true,
			width: 230,
		},
		{
			title: "Звернення побутових споживачів",
			children: [
				{
					title: "Надійшло звернень",
					dataIndex: "residentialTotalCount",
					key: "residentialTotalCount",
					width: 115,
					align: "center",
				},
				{
					title: "Розглянуто звернень",
					dataIndex: "residentialReviewedCount",
					key: "residentialReviewedCount",
					width: 115,
					align: "center",
				},
				{
					title: "Скарги",
					children: [
						{
							title: "Протерміновані",
							dataIndex: "residentialSubjectOverduedCount",
							key: "residentialSubjectOverduedCount",
							width: 115,
							align: "center",
						},
						{
							title: "Будуть протерміновані за 5 днів",
							dataIndex: "residentialSubjectOverduedIn5DaysCount",
							key: "residentialSubjectOverduedIn5DaysCount",
							width: 115,
							align: "center",
						},
					],
				},
				{
					title: "Платні послуги",
					children: [
						{
							title: "Протерміновані",
							dataIndex: "residentialPaidServiceOverduedCount",
							key: "residentialPaidServiceOverduedCount",
							width: 115,
							align: "center",
						},
						{
							title: "Будуть протерміновані за 5 днів",
							dataIndex: "residentialPaidServiceOverduedIn5DaysCount",
							key: "residentialPaidServiceOverduedIn5DaysCount",
							width: 115,
							align: "center",
						},
					],
				},
			],
		},

		{
			title: "Звернення непобутових споживачів",
			children: [
				{
					title: "Надійшло звернень",
					dataIndex: "commercialTotalCount",
					key: "commercialTotalCount",
					width: 115,
					align: "center",
				},
				{
					title: "Розглянуто звернень",
					dataIndex: "commercialReviewedCount",
					key: "commercialReviewedCount",
					width: 115,
					align: "center",
				},
				{
					title: "Скарги",
					children: [
						{
							title: "Протерміновані",
							dataIndex: "commercialSubjectOverduedCount",
							key: "commercialSubjectOverduedCount",
							width: 115,
							align: "center",
						},
						{
							title: "Будуть протерміновані за 5 днів",
							dataIndex: "commercialSubjectOverduedIn5DaysCount",
							key: "commercialSubjectOverduedIn5DaysCount",
							width: 115,
							align: "center",
						},
					],
				},
				{
					title: "Платні послуги",
					children: [
						{
							title: "Протерміновані",
							dataIndex: "commercialPaidServiceOverduedCount",
							key: "commercialPaidServiceOverduedCount",
							width: 115,
							align: "center",
						},
						{
							title: "Будуть протерміновані за 5 днів",
							dataIndex: "commercialPaidServiceOverduedIn5DaysCount",
							key: "commercialPaidServiceOverduedIn5DaysCount",
							width: 115,
							align: "center",
						},
					],
				},
			],
		},
	];

	const appealsControlTableData = () => {
		if (appealsControlData) {
			return appealsControlData.map((item, i) => {
				return {
					key: i,
					departmentName: item.departmentName,
					residentialTotalCount: item.residentialTotalCount,
					residentialReviewedCount: item.residentialReviewedCount,
					residentialSubjectOverduedCount: item.residentialSubjectOverduedCount,
					residentialSubjectOverduedIn5DaysCount: item.residentialSubjectOverduedIn5DaysCount,
					residentialPaidServiceOverduedCount: item.residentialPaidServiceOverduedCount,
					residentialPaidServiceOverduedIn5DaysCount: item.residentialPaidServiceOverduedIn5DaysCount,
					commercialTotalCount: item.commercialTotalCount,
					commercialReviewedCount: item.commercialReviewedCount,
					commercialSubjectOverduedCount: item.commercialSubjectOverduedCount,
					commercialSubjectOverduedIn5DaysCount: item.commercialSubjectOverduedIn5DaysCount,
					commercialPaidServiceOverduedCount: item.commercialPaidServiceOverduedCount,
					commercialPaidServiceOverduedIn5DaysCount: item.commercialPaidServiceOverduedIn5DaysCount,
				};
			});
		}
	};

	const onReportCreate = () => {
		dispatch(
			getOverduedAppealsV2(
				appealsControlRequestParams.startDate.format(rangePickerDateFormat),
				appealsControlRequestParams.endDate.format(rangePickerDateFormat)
			)
		);
	};

	const onXlsxFileCreate = () => {
		dispatch(
			getOverduedAppealsV2Xlsx(
				appealsControlRequestParams.startDate.format(rangePickerDateFormat),
				appealsControlRequestParams.endDate.format(rangePickerDateFormat)
			)
		);
	};

	//Bradcrums etc..
	useEffect(() => {
		const breadCrumbData = [{ name: text, link: false }];

		dispatch(setPageBreadcrumb(breadCrumbData));
	}, [dispatch, text]);

	//Unlocking report button
	useEffect(() => {
		if (appealsControlRequestParams.startDate && appealsControlRequestParams.endDate) {
			setBtnDisabled(false);
		} else {
			setBtnDisabled(true);
		}
	}, [appealsControlRequestParams.endDate, appealsControlRequestParams.startDate]);

	const onPeriodChange = value => {
		if (!value) {
			setAppealsControlRequestParams(prev => ({ ...prev, startDate: undefined, endDate: undefined }));
		} else {
			setAppealsControlRequestParams(prev => ({
				...prev,
				startDate: value[0].format(rangePickerDateFormat),
				endDate: value[1].format(rangePickerDateFormat),
			}));
		}
	};

	return (
		<div className={css.appeals_control_wrapp}>
			<Space className={css.filter}>
				<RangePicker
					locale={locale}
					allowClear={true}
					showToday={true}
					format={rangePickerDateFormat}
					defaultValue={[appealsControlRequestParams.startDate, appealsControlRequestParams.endDate]}
					onChange={value => onPeriodChange(value)}
				/>

				<Button
					className={btnDisabled ? "" : css.site_button}
					style={{ marginRight: "10px" }}
					type='primary'
					icon={<DatabaseOutlined />}
					onClick={onReportCreate}
					disabled={btnDisabled}
					loading={!!isLoading}
				>
					Сформувати
				</Button>
				<Button
					className={btnDisabled ? "" : css.site_button}
					type='primary'
					icon={<FileExcelOutlined />}
					disabled={btnDisabled}
					onClick={onXlsxFileCreate}
					loading={!!isLoading}
				>
					XLSX
				</Button>
			</Space>

			<Spin spinning={isLoading} tip={"Завантаження"}>
				<Table
					className={css.appeals_control_table_wrapper}
					pagination={false}
					size={tableSize}
					scroll={{ x: "100%", y: "calc(100vh - 340px)" }}
					columns={appealsControlTableColumns}
					bordered
					dataSource={appealsControlTableData()}
				/>
			</Spin>
		</div>
	);
};

export default AppealsControl;
