//Instance for request start settings
export const apiClient = axios => {
	let token = localStorage.getItem("token");
	let baseURL = "/api";

	if (process.env.REACT_APP_BASE_URL) {
		baseURL = process.env.REACT_APP_BASE_URL + "/api";
	}

	return axios.create({
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + token,
		},
		baseURL: baseURL,
	});
};

export const domainApiClient = axios => {
	let baseURL = "/api";

	if (process.env.REACT_APP_BASE_URL) {
		baseURL = process.env.REACT_APP_BASE_URL + "/api";
	}

	return axios.create({
		headers: {
			"WWW-Authenticate": "Negotiate",
		},
		baseURL: baseURL,
	});
};
