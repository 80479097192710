import {
	SET_CHART_DATA_SELECTOR,
	SET_CHART_TYPE_SELECTOR,
	SET_ENS_DATA_CITY,
	SET_ENS_DATA_VILAGE,
	SET_MAIFI_DATA_CITY,
	SET_MAIFI_DATA_VILAGE,
	SET_SAIDI_SAIFI_DATA_CITY,
	SET_SAIDI_SAIFI_DATA_VILAGE,
} from "../types";

const initialState = {
	saidiSaifiDataCity: [],
	saidiSaifiDataVilage: [],
	maifiDataCity: [],
	maifiDataVilage: [],
	ensDataCity: [],
	ensDataVilage: [],
	chartDataSelector: 1,
	chartTypeSelector: 1,
};

export const statsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SAIDI_SAIFI_DATA_CITY:
			return {
				...state,
				saidiSaifiDataCity: action.payload,
			};
		case SET_SAIDI_SAIFI_DATA_VILAGE:
			return {
				...state,
				saidiSaifiDataVilage: action.payload,
			};
		case SET_MAIFI_DATA_CITY:
			return {
				...state,
				maifiDataCity: action.payload,
			};
		case SET_MAIFI_DATA_VILAGE:
			return {
				...state,
				maifiDataVilage: action.payload,
			};
		case SET_ENS_DATA_CITY:
			return {
				...state,
				ensDataCity: action.payload,
			};
		case SET_ENS_DATA_VILAGE:
			return {
				...state,
				ensDataVilage: action.payload,
			};
		case SET_CHART_DATA_SELECTOR:
			return {
				...state,
				chartDataSelector: action.payload,
			};
		case SET_CHART_TYPE_SELECTOR:
			return {
				...state,
				chartTypeSelector: action.payload,
			};
		default:
			return state;
	}
};
