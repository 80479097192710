import {
	SET_APPEALS_DATA,
	SET_APPEAL_BILL_FILES_LIST,
	SET_APPEAL_BILL_FILES_LIST_TO_UPLOAD,
	SET_APPEAL_BY_ID,
	SET_APPEAL_CLOSE_COMMENTS,
	SET_APPEAL_FILES_LIST,
	SET_APPEAL_FILES_LIST_TO_UPLOAD,
	SET_APPEAL_IS_EDIT_FLAG,
	SET_APPEAL_PAID_SERVICES,
	SET_APPEAL_SETTLEMENTS,
	SET_APPEAL_SUBJECT_TIMES,
	SET_CURRENT_APPEAL_STATUS,
	SET_MAP_DATA_BY_APPEALS,
	SET_NOT_READ_APPEALS_COUNT,
	SET_SELECTED_APPEAL,
	SET_START_APPEAL_COMMUNICATION_CHANEL,
} from "../types";
import { hideLoader, setApiLastError, setSuccess, setWarning, showLoader } from "./appActions";

import { appealAPI } from "../../api/appealEndpoints";
import { appealGetParameters } from "../../api/parameters";
import { createDownloadFile } from "../../assets/js/helpers/createDownloadFile";
import { dictionariAPI } from "../../api/dictionaryEndpoints";
import { thunkErrorHandler } from "./actionHandlers";

//For Appeals

export const setCurrentAppealStatus = currentAppealStatus => {
	return {
		type: SET_CURRENT_APPEAL_STATUS,
		payload: currentAppealStatus,
	};
};

export const setStartAppealCommunicationChanel = startAppealCommunicationChanel => {
	return {
		type: SET_START_APPEAL_COMMUNICATION_CHANEL,
		payload: startAppealCommunicationChanel,
	};
};

export const setAppealSubjectTimes = appealSubjectTimes => {
	return {
		type: SET_APPEAL_SUBJECT_TIMES,
		payload: appealSubjectTimes,
	};
};

export const setAppealSettlements = appealSettlements => {
	return {
		type: SET_APPEAL_SETTLEMENTS,
		payload: appealSettlements,
	};
};

export const setSelectedAppeal = selectedAppeal => {
	return {
		type: SET_SELECTED_APPEAL,
		payload: selectedAppeal,
	};
}; //For delete appeal

export const setAppealIsEditFlag = isAppealEdited => {
	return {
		type: SET_APPEAL_IS_EDIT_FLAG,
		payload: isAppealEdited,
	};
};

export const getAppealSubjectTimes = appealSubjectId => async dispatch => {
	try {
		dispatch(showLoader());
		const response = await dictionariAPI.getAppealSubjectTimes(appealSubjectId);
		if (response.status >= 200 && response.status < 300) {
			const appealSubjectTimes = response.data;
			dispatch(setAppealSubjectTimes(appealSubjectTimes));
		}
		dispatch(hideLoader());
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getAppealSubjectCloseComments = appealSubjectId => async dispatch => {
	try {
		dispatch(showLoader());
		const response = await dictionariAPI.getAppealSubjectCloseComments(appealSubjectId);
		if (response.status >= 200 && response.status < 300) {
			const appealSubjectCloseComments = response.data;
			dispatch(setAppealSubjectCloseComments(appealSubjectCloseComments));
		}
		dispatch(hideLoader());
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const setAppealSubjectCloseComments = appealSubjectCloseComments => {
	return {
		type: SET_APPEAL_CLOSE_COMMENTS,
		payload: appealSubjectCloseComments,
	};
};

//Depricated and not use!!
export const getSettlements = departmentId => async dispatch => {
	try {
		const response = await dictionariAPI.getSettlements(departmentId);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setAppealSettlements(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getSettlementsByName = async query => {
	try {
		const response = await dictionariAPI.getSettlementsByName(query);
		if (response.status >= 200 && response.status < 300) {
			return response.data;
		}
	} catch (error) {
		//dispatch(setApiLastError({isErrorExists: true}));
		await thunkErrorHandler(error.response, undefined);
	}
};

export const getNotReadAppeals = appealGetParameters => async dispatch => {
	try {
		const response = await appealAPI.getAppeals(appealGetParameters);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setAppealsData(response.data));
			dispatch(getNotReadAppealsCount());
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getAppealPaidServices = appealGetParameters => async dispatch => {
	try {
		const response = await appealAPI.getAppealPaidServices(appealGetParameters);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setAppealPaidServices(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
export const setAppealPaidServices = appealPaidServices => {
	return {
		type: SET_APPEAL_PAID_SERVICES,
		payload: appealPaidServices,
	};
};

export const getAppeals = appealGetParameters => async dispatch => {
	try {
		const response = await appealAPI.getAppeals(appealGetParameters);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setAppealsData(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
export const setAppealsData = appeals => {
	return {
		type: SET_APPEALS_DATA,
		payload: appeals,
	};
};

export const getAppealById = appealId => async dispatch => {
	try {
		const response = await appealAPI.getAppealById(appealId);
		if (response.status >= 200 && response.status < 300) {
			const result = response.data.data?.shift(0);
			dispatch(setAppealById(result));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getAppealPaidServiceById = appealId => async dispatch => {
	try {
		const response = await appealAPI.getAppealPaidServiceById(appealId);
		if (response.status >= 200 && response.status < 300) {
			const result = response.data.data?.shift(0);
			dispatch(setAppealById(result));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const setAppealById = appeal => {
	return {
		type: SET_APPEAL_BY_ID,
		payload: appeal,
	};
};

export const getAppeal = id => async dispatch => {
	try {
		const response = await appealAPI.getAppeal(id);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setSelectedAppeal(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const addAppeal =
	(appealPostParameters, appealFilesListToUpload, appealBillFilesListToUpload) => async dispatch => {
		try {
			const response = await appealAPI.addAppeal(appealPostParameters);
			dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));
			if (response.status >= 200 && response.status < 300) {
				//Upload files if exists
				if (appealFilesListToUpload.length) {
					const formData = new FormData();
					const appealId = response.data;

					appealFilesListToUpload.forEach(file => {
						formData.append("files", file.originFileObj);
					});
					await dispatch(addFilesAsync(appealId, formData));
					await dispatch(setAppealFilesToUpload([]));
				}

				if (appealBillFilesListToUpload.length) {
					const formData = new FormData();
					const appealId = response.data;

					appealBillFilesListToUpload.forEach(file => {
						formData.append("files", file.originFileObj);
					});
					await dispatch(addBillFilesAsync(appealId, formData));
					await dispatch(setAppealBillFilesToUpload([]));
				}

				dispatch(setSuccess("Звернення успішно додано!"));
				dispatch(setSuccess(null));
				dispatch(setApiLastError({ isErrorExists: false, errorObject: null }));
			}
		} catch (error) {
			dispatch(setApiLastError({ isErrorExists: true }));
			await thunkErrorHandler(error.response, dispatch);
		}
	};

export const editAppeal =
	(appealPostParameters, appealFilesListToUpload, appealBillFilesListToUpload) => async dispatch => {
		try {
			const response = await appealAPI.editAppeal(appealPostParameters);
			dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));
			if (response.status >= 200 && response.status < 300) {
				if (appealFilesListToUpload.length) {
					const formData = new FormData();
					const appealId = response.data.id;

					appealFilesListToUpload.forEach(file => {
						formData.append("files", file.originFileObj);
					});
					await dispatch(addFilesAsync(appealId, formData));
					await dispatch(setAppealFilesToUpload([]));
				}

				if (appealBillFilesListToUpload.length) {
					const formData = new FormData();
					const appealId = response.data.id;

					appealBillFilesListToUpload.forEach(file => {
						formData.append("files", file.originFileObj);
					});
					await dispatch(addBillFilesAsync(appealId, formData));
					await dispatch(setAppealBillFilesToUpload([]));
				}

				dispatch(setSuccess("Звернення успішно відредаговано!"));
				dispatch(setSuccess(null));
				dispatch(setApiLastError({ isErrorExists: false, errorObject: null }));
			}
		} catch (error) {
			dispatch(setApiLastError({ isErrorExists: true }));
			await thunkErrorHandler(error.response, dispatch);
		}
	};

export const deleteAppeal = (id, tableFilter) => async dispatch => {
	try {
		const response = await appealAPI.deleteAppeal(id);
		if (response.status === 204) {
			appealGetParameters.filter = tableFilter;
			dispatch(getAppeals(appealGetParameters));
			dispatch(setSuccess("Звернення успішно видалено!"));
			dispatch(setSuccess(null));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

//FILES----------------------------------------------------------
export const getFilesList = appealId => async dispatch => {
	if (!appealId) {
		dispatch(setFilesList([]));
		return;
	}
	try {
		const response = await appealAPI.getFilesList(appealId);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setFilesList(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const setFilesList = appealFileslist => {
	return {
		type: SET_APPEAL_FILES_LIST,
		payload: appealFileslist,
	};
};

export const setAppealFilesToUpload = appealFilesListToUpload => {
	return {
		type: SET_APPEAL_FILES_LIST_TO_UPLOAD,
		payload: appealFilesListToUpload,
	};
};

export const addFilesAsync = (appealId, formData) => async dispatch => {
	try {
		const response = await appealAPI.addFilesAsync(appealId, formData);
		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));
		if (response.status >= 200 && response.status < 300) {
			dispatch(setApiLastError({ isErrorExists: false, errorObject: null }));
			dispatch(getFilesList(appealId));
			dispatch(setSuccess("Файл успішно завантажено!"));
			dispatch(setSuccess(null));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const deleteFileAsync = (appealFileId, appealId) => async dispatch => {
	try {
		const response = await appealAPI.deleteFileAsync(appealFileId);
		if (response.status >= 200 && response.status < 300) {
			dispatch(getFilesList(appealId));
			dispatch(setSuccess("Файл успішно видалено!"));
			dispatch(setSuccess(null));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getFileAsync = (appealFileId, fileName) => async dispatch => {
	try {
		const response = await appealAPI.getFileAsync(appealFileId);
		if (response.status >= 200 && response.status < 300) {
			createDownloadFile(response, fileName, "show");
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

//BILLS-------------------------------------------------------------------------
export const getBillFilesList = appealId => async dispatch => {
	if (!appealId) {
		dispatch(setBillFilesList([]));
		return;
	}
	try {
		const response = await appealAPI.getBillFilesList(appealId);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setBillFilesList(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const setBillFilesList = appealBillFileslist => {
	return {
		type: SET_APPEAL_BILL_FILES_LIST,
		payload: appealBillFileslist,
	};
};

export const setAppealBillFilesToUpload = appealBillFilesListToUpload => {
	return {
		type: SET_APPEAL_BILL_FILES_LIST_TO_UPLOAD,
		payload: appealBillFilesListToUpload,
	};
};

export const addBillFilesAsync = (appealId, formData) => async dispatch => {
	try {
		const response = await appealAPI.addBillFilesAsync(appealId, formData);
		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));
		if (response.status >= 200 && response.status < 300) {
			dispatch(setApiLastError({ isErrorExists: false, errorObject: null }));
			dispatch(getBillFilesList(appealId));
			dispatch(setSuccess("Файл рахунку успішно завантажено!"));
			dispatch(setSuccess(null));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const deleteBillFileAsync = (appealBillFileId, appealId) => async dispatch => {
	try {
		const response = await appealAPI.deleteBillFileAsync(appealBillFileId);
		if (response.status >= 200 && response.status < 300) {
			dispatch(getBillFilesList(appealId));
			dispatch(setSuccess("Файл рахунку успішно видалено!"));
			dispatch(setSuccess(null));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const getBillFileAsync = (appealBillFileId, fileName) => async dispatch => {
	try {
		const response = await appealAPI.getBillFileAsync(appealBillFileId);
		if (response.status >= 200 && response.status < 300) {
			createDownloadFile(response, fileName, "show");
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
//-------------------------------------------------------------------------

export const getMapDataByAppeals = departmentId => async dispatch => {
	try {
		const response = await appealAPI.getMapDataByAppeals(departmentId);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setMapDataByAppeals(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const setMapDataByAppeals = mapDataByAppeals => {
	return {
		type: SET_MAP_DATA_BY_APPEALS,
		payload: mapDataByAppeals,
	};
};

export const getCounterpartiesByFilter = async (counterpartyName, counterpartyPersonalAccount, departmentId) => {
	try {
		const response = await dictionariAPI.getCounterpartiesByFilter(
			counterpartyName,
			counterpartyPersonalAccount,
			departmentId
		);
		if (response.status >= 200 && response.status < 300) {
			return response.data;
		}
	} catch (error) {
		//dispatch(setApiLastError({isErrorExists: true}));
		await thunkErrorHandler(error.response, undefined);
	}
};

export const setNotReadAppealsCount = notReadAppealCount => {
	return {
		type: SET_NOT_READ_APPEALS_COUNT,
		payload: notReadAppealCount,
	};
};

export const getNotReadAppealsCount = () => async dispatch => {
	try {
		const response = await appealAPI.getNotReadAppealsCount();
		if (response.status >= 200 && response.status < 300) {
			dispatch(setNotReadAppealsCount(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};

export const updateBulk = appeals => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await appealAPI.updateBulk(appeals);
		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));
		if (response.status >= 200 && response.status < 300) {
			if (response.data.length) {
				dispatch(setApiLastError(response.data)); //For group action errors Drawer
				dispatch(setWarning("Скарги/пропозиції відредаговані з помилками!"));
				dispatch(setWarning(null));
			} else {
				dispatch(setSuccess("Скарги/пропозиції успішно відредаговані!"));
				dispatch(setSuccess(null));
				dispatch(setApiLastError({ isErrorExists: false, errorObject: null }));
				appealGetParameters.filter = "appealStatusId=1|appealStatusId=2";
				dispatch(getAppeals(appealGetParameters));
			}
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};
