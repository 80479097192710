import { NavLink } from "react-router-dom/dist";
import React from "react";

const PrivacyPolicy = () => {
	return (
		<div>
			<p>
				<b>Політика конфіденційності</b>
			</p>

			<p style={{ width: "50%", lineHeight: "1.5" }}>
				«Система управління аварійними та плановими відключеннями» (OMS) належить АТ «Хмельницькобленерго».
				Інформація, дані, які використовуються системою належать виключно АТ «Хмельницькобленерго». «Система
				управління аварійними та плановими відключеннями» (OMS) не збирає та не передає третім особам жодних даних,
				в тому числі і про користувачів системи.
			</p>

			<p>
				<NavLink to={"/"}>Повернутися на головну</NavLink>
			</p>
		</div>
	);
};

export default PrivacyPolicy;
