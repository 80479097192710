import { apiClient } from "./api";
import axios from "axios";

//Smart endpoints
export const smartAPI = {
	getSmartData(params) {
		return apiClient(axios)
			.post("/Smart/GetAll", { ...params })
			.then(response => response);
	},
};
