import {
	SET_ACTIVE_SHUTDOWNS_ASYNC_DATA,
	SET_COUNTERPARTY_SEARCH_DATA,
	SET_FORM_RM3_DATA,
	SET_HISTORICAL_SHUTDOWNS_DATA,
	SET_NKRE_373_APPENDIX2_DATA,
	SET_NKRE_373_APPENDIX4_DATA,
	SET_NKRE_373_APPENDIX5_DATA,
	SET_OVERDUED_APPEALS_V2,
	SET_OVERDUED_APPEAL_COMPLAINS_DATA,
	SET_OVERDUED_APPEAL_PAID_SERVICES,
	SET_REM_SHUTDOWNS_EFFICIENCY,
	SET_SHUTDOWNS_PER_YEAR,
} from "../types";
import { createDownloadFile, getFileName } from "../../assets/js/helpers/createDownloadFile";
import { hideLoader, setApiLastError, showLoader } from "./appActions";

import moment from "moment";
import { reportsAPI } from "../../api/reportsEndpoints";
import { thunkErrorHandler } from "./actionHandlers";

const format = "DD.MM.YYYY";

export const getNkre373Appendix2 = (startDate, endDate) => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getNkre373Appendix2(startDate, endDate);
		if (response.status === 200) {
			dispatch(setNkre373Appendix2(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const setNkre373Appendix2 = nkre373Appendix2Data => {
	return {
		type: SET_NKRE_373_APPENDIX2_DATA,
		payload: nkre373Appendix2Data,
	};
};

export const getNkre373Appendix4 = (startDate, endDate, totalOperatorsTimeInHours) => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getNkre373Appendix4(startDate, endDate, totalOperatorsTimeInHours);
		if (response.status === 200) {
			dispatch(setNkre373Appendix4(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const setNkre373Appendix4 = nkre373Appendix4Data => {
	return {
		type: SET_NKRE_373_APPENDIX4_DATA,
		payload: nkre373Appendix4Data,
	};
};

export const getNkre373Appendix5 = (startDate, endDate, departmentId, appealSubjectsId) => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getNkre373Appendix5(startDate, endDate, departmentId, appealSubjectsId);
		if (response.status === 200) {
			dispatch(setNkre373Appendix5(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const setNkre373Appendix5 = nkre373Appendix5Data => {
	return {
		type: SET_NKRE_373_APPENDIX5_DATA,
		payload: nkre373Appendix5Data,
	};
};

export const getNkre373Appendix2Docx = (startDate, endDate) => async dispatch => {
	dispatch(showLoader());
	try {
		let response = await reportsAPI.getNkre373Appendix2Docx(startDate, endDate);
		if (response.status === 200) {
			createDownloadFile(response, `Додаток 2 - ${startDate}-${endDate}.docx`);
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const getNkre373Appendix2Pdf = (startDate, endDate) => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getNkre373Appendix2Pdf(startDate, endDate);
		if (response.status === 200) {
			createDownloadFile(response, `Додаток 2 - ${startDate}-${endDate}.pdf`);
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const getNkre373Appendix2Xlsx = (startDate, endDate) => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getNkre373Appendix2Xlsx(startDate, endDate);
		if (response.status === 200) {
			createDownloadFile(response, `Додаток 2 - ${startDate}-${endDate}.xlsx`);
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const getNkre373Appendix4Docx = (startDate, endDate, totalOperatorsTimeInHours) => async dispatch => {
	dispatch(showLoader());
	try {
		let response = await reportsAPI.getNkre373Appendix4Docx(startDate, endDate, totalOperatorsTimeInHours);
		if (response.status === 200) {
			createDownloadFile(response, `Додаток 4 - ${startDate}-${endDate}.docx`);
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const getNkre373Appendix4Pdf = (startDate, endDate, totalOperatorsTimeInHours) => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getNkre373Appendix4Pdf(startDate, endDate, totalOperatorsTimeInHours);
		if (response.status === 200) {
			createDownloadFile(response, `Додаток 4 - ${startDate}-${endDate}.pdf`);
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const getNkre373Appendix4Xlsx = (startDate, endDate, totalOperatorsTimeInHours) => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getNkre373Appendix4Xlsx(startDate, endDate, totalOperatorsTimeInHours);
		if (response.status === 200) {
			createDownloadFile(response, `Додаток 4 - ${startDate}-${endDate}.xlsx`);
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const getNkre373Appendix5Xlsx = (startDate, endDate, departmentId, appealSubjectsId) => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getNkre373Appendix5Xlsx(startDate, endDate, departmentId, appealSubjectsId);
		if (response.status === 200) {
			createDownloadFile(response, `Додаток 5 - ${startDate}-${endDate}.xlsx`);
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const getShutdownsPerYear =
	(year, shutdownTypeId, shutdownJobTypeId, departmentId, shutdownDriverId, maxRecords) => async dispatch => {
		dispatch(showLoader());
		try {
			const response = await reportsAPI.getShutdownsPerYear(
				year,
				shutdownTypeId,
				shutdownJobTypeId,
				departmentId,
				shutdownDriverId,
				maxRecords
			);
			if (response.status === 200) {
				dispatch(setShutdownsPerYear(response.data));
			}
		} catch (error) {
			dispatch(setApiLastError({ isErrorExists: true }));
			await thunkErrorHandler(error.response, dispatch);
		}
		dispatch(hideLoader());
	};
export const setShutdownsPerYear = shutdownsPerYear => {
	return {
		type: SET_SHUTDOWNS_PER_YEAR,
		payload: shutdownsPerYear,
	};
};

export const getOverduedAppealComplainsAsync =
	(departmentId, appealSubjectsId, startDate, counterpartyPersonalAccount) => async dispatch => {
		dispatch(showLoader());
		try {
			const response = await reportsAPI.getOverduedAppealComplainsAsync(
				departmentId,
				appealSubjectsId,
				startDate,
				counterpartyPersonalAccount
			);
			if (response.status >= 200 && response.status < 300) {
				dispatch(setOverduedAppealComplainsAsync(response.data));
			}
		} catch (error) {
			dispatch(setApiLastError({ isErrorExists: true }));
			await thunkErrorHandler(error.response, dispatch);
		}
		dispatch(hideLoader());
	};

export const setOverduedAppealComplainsAsync = overduedAppealComplainsData => {
	return {
		type: SET_OVERDUED_APPEAL_COMPLAINS_DATA,
		payload: overduedAppealComplainsData,
	};
};

export const getOverduedAppealComplainsXlsxAsync =
	(departmentId, appealSubjectsId, startDate, counterpartyPersonalAccount) => async dispatch => {
		dispatch(showLoader());
		try {
			const response = await reportsAPI.getOverduedAppealComplainsXlsxAsync(
				departmentId,
				appealSubjectsId,
				startDate,
				counterpartyPersonalAccount
			);
			if (response.status >= 200 && response.status < 300) {
				createDownloadFile(response, `Протерміновані скарги - ${moment(startDate).format(format)}.xlsx`);
			}
		} catch (error) {
			dispatch(setApiLastError({ isErrorExists: true }));
			await thunkErrorHandler(error.response, dispatch);
		}
		dispatch(hideLoader());
	};

export const getOverduedAppealPaidServices =
	(departmentId, appealPaidServicesId, startDate, counterpartyPersonalAccount) => async dispatch => {
		dispatch(showLoader());
		try {
			const response = await reportsAPI.getOverduedAppealPaidServices(
				departmentId,
				appealPaidServicesId,
				startDate,
				counterpartyPersonalAccount
			);
			if (response.status >= 200 && response.status < 300) {
				dispatch(setOverduedAppealPaidServices(response.data));
			}
		} catch (error) {
			dispatch(setApiLastError({ isErrorExists: true }));
			await thunkErrorHandler(error.response, dispatch);
		}
		dispatch(hideLoader());
	};

export const setOverduedAppealPaidServices = overduedAppealPaidServicesData => {
	return {
		type: SET_OVERDUED_APPEAL_PAID_SERVICES,
		payload: overduedAppealPaidServicesData,
	};
};

export const getOverduedAppealPaidServicesXlsx =
	(departmentId, appealPaidServisesId, startDate, counterpartyPersonalAccount) => async dispatch => {
		dispatch(showLoader());
		try {
			const response = await reportsAPI.getOverduedAppealPaidServicesXlsx(
				departmentId,
				appealPaidServisesId,
				startDate,
				counterpartyPersonalAccount
			);
			if (response.status >= 200 && response.status < 300) {
				createDownloadFile(response, `Платні послуги- ${moment(startDate).format(format)}.xlsx`);
			}
		} catch (error) {
			dispatch(setApiLastError({ isErrorExists: true }));
			await thunkErrorHandler(error.response, dispatch);
		}
		dispatch(hideLoader());
	};

//V2
export const getOverduedAppealsV2 = (startDate, endDate) => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getOverduedAppealsV2(startDate, endDate);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setOverduedAppealsV2(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const setOverduedAppealsV2 = overduedAppealsV2 => {
	return {
		type: SET_OVERDUED_APPEALS_V2,
		payload: overduedAppealsV2,
	};
};

export const getOverduedAppealsV2Xlsx = (startDate, endDate) => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getOverduedAppealsV2Xlsx(startDate, endDate);
		if (response.status >= 200 && response.status < 300) {
			createDownloadFile(response, `Контроль звернень - ${startDate}_${endDate}.xlsx`);
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const getActiveShutdownsAsync = (departmentId, shutdownTypeId) => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getActiveShutdownsAsync(departmentId, shutdownTypeId);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setActiveShutdownsAsync(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const setActiveShutdownsAsync = activeShutdownsAsyncData => {
	return {
		type: SET_ACTIVE_SHUTDOWNS_ASYNC_DATA,
		payload: activeShutdownsAsyncData,
	};
};

export const getActiveShutdownsXlsxAsync = (departmentId, shutdownTypeId) => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getActiveShutdownsXlsxAsync(departmentId, shutdownTypeId);
		if (response.status >= 200 && response.status < 300) {
			createDownloadFile(response, `Активні відключення сodeRes#${departmentId} - ${moment().format(format)}.xlsx`);
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const getHistoricalShutdowns =
	(departmentId, startDate, endDate, counterpartyPersonalAccount) => async dispatch => {
		dispatch(showLoader());
		try {
			const response = await reportsAPI.getHistoricalShutdowns(
				departmentId,
				startDate,
				endDate,
				counterpartyPersonalAccount
			);
			if (response.status >= 200 && response.status < 300) {
				dispatch(setHistoricalShutdowns(response.data));
			}
		} catch (error) {
			dispatch(setApiLastError({ isErrorExists: true }));
			await thunkErrorHandler(error.response, dispatch);
		}
		dispatch(hideLoader());
	};

export const setHistoricalShutdowns = historicalShutdownsData => {
	return {
		type: SET_HISTORICAL_SHUTDOWNS_DATA,
		payload: historicalShutdownsData,
	};
};

export const getHistoricalShutdownsDocx =
	(departmentId, startDate, endDate, counterpartyPersonalAccount) => async dispatch => {
		dispatch(showLoader());

		try {
			let response = await reportsAPI.getHistoricalShutdownsDocx(
				departmentId,
				startDate,
				endDate,
				counterpartyPersonalAccount
			);
			if (response.status === 200) {
				createDownloadFile(
					response,
					`Відключення - ${moment(startDate).format(format)}-${moment(endDate).format(format)}.docx`
				);
			}
		} catch (error) {
			dispatch(setApiLastError({ isErrorExists: true }));
			await thunkErrorHandler(error.response, dispatch);
		}
		dispatch(hideLoader());
	};

export const getHistoricalShutdownsXlsx =
	(departmentId, startDate, endDate, counterpartyPersonalAccount) => async dispatch => {
		dispatch(showLoader());
		try {
			const response = await reportsAPI.getHistoricalShutdownsXlsx(
				departmentId,
				startDate,
				endDate,
				counterpartyPersonalAccount
			);
			if (response.status === 200) {
				createDownloadFile(
					response,
					`Відключення - ${moment(startDate).format(format)}-${moment(endDate).format(format)}.xlsx`
				);
			}
		} catch (error) {
			dispatch(setApiLastError({ isErrorExists: true }));
			await thunkErrorHandler(error.response, dispatch);
		}
		dispatch(hideLoader());
	};

export const getFormRM3 = (selectedDate, reasonId) => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getFormRM3(selectedDate, reasonId);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setFormRM3(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const setFormRM3 = formRM3Data => {
	return {
		type: SET_FORM_RM3_DATA,
		payload: formRM3Data,
	};
};

export const getFormRM3Xls = (selectedDate, reasonId) => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getFormRM3Xls(selectedDate, reasonId);
		if (response.status === 200) {
			createDownloadFile(response, `Звіт по знеструмленню на - ${moment(selectedDate).format(format)}.xlsx`);
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const getGetCounterpartiesByFilter = params => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getGetCounterpartiesByFilter(params);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setGetCounterpartiesByFilter(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const setGetCounterpartiesByFilter = counterpartySearchData => {
	return {
		type: SET_COUNTERPARTY_SEARCH_DATA,
		payload: counterpartySearchData,
	};
};

export const getRemShutdownsEfficiency = params => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getRemShutdownsEfficiency(params);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setRemShutdownsEfficiency(response.data));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const setRemShutdownsEfficiency = remShutdownsEfficiency => {
	return {
		type: SET_REM_SHUTDOWNS_EFFICIENCY,
		payload: remShutdownsEfficiency,
	};
};

export const getRemShutdownsEfficiencyXlsx = params => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getRemShutdownsEfficiencyXlsx(params);
		if (response.status === 200) {
			createDownloadFile(
				response,
				`Звіт по знеструмленню на - ${moment(params.startDate).format(format)} - ${moment(params.endDate).format(
					format
				)}.xlsx`
			);
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const getSaidiSaifiCalculationsXlsx = (departmentId, settlementTypeId, dateBegin, dateEnd) => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getSaidiSaifiCalculationsXlsx(
			departmentId,
			settlementTypeId,
			dateBegin,
			dateEnd
		);
		const contentDisposition = response.headers.get("Content-Disposition");
		let fileName = contentDisposition ? getFileName(contentDisposition) : null;
		fileName = fileName || `Звіт.xlsx`;

		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));

		if (response.status >= 200 && response.status < 300) {
			createDownloadFile(response, fileName);
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const getMaifiCalculationsXlsx = (departmentId, settlementTypeId, dateBegin, dateEnd) => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.getMaifiCalculationsXlsx(departmentId, settlementTypeId, dateBegin, dateEnd);
		const contentDisposition = response.headers.get("Content-Disposition");
		let fileName = contentDisposition ? getFileName(contentDisposition) : null;
		fileName = fileName || `Звіт.xlsx`;

		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));

		if (response.status >= 200 && response.status < 300) {
			createDownloadFile(response, fileName);
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};

export const generateStatisticsDocument = (departmentId, settlementTypeId, dateBegin, dateEnd) => async dispatch => {
	dispatch(showLoader());
	try {
		const response = await reportsAPI.generateStatisticsDocument(departmentId, settlementTypeId, dateBegin, dateEnd);
		const contentDisposition = response.headers.get("Content-Disposition");
		let fileName = contentDisposition ? getFileName(contentDisposition) : null;
		fileName = fileName || `Звіт.docx`;

		dispatch(setApiLastError({ isErrorExists: undefined, errorObject: null }));

		if (response.status >= 200 && response.status < 300) {
			createDownloadFile(response, fileName);
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
	dispatch(hideLoader());
};
