import {
	hideLoader,
	setApiLastError,
	setAppInit,
	setError,
	setIsApiHealthy,
	setSuccess,
	showLoader,
} from "./appActions";

import { SET_AUTH_DATA } from "../types";
import { authAPI } from "../../api/authEndpoints";
import { thunkErrorHandler } from "./actionHandlers";

export const setAuthData = (
	token = null,
	userId = null,
	email = null,
	departmentId = null,
	userName = null,
	userRole = null,
	departmentEntryPointId = null,
	departmentHierarchyId = null
) => {
	return {
		type: SET_AUTH_DATA,
		payload: {
			token,
			userId,
			email,
			departmentId,
			userName,
			userRole,
			departmentEntryPointId,
			departmentHierarchyId,
		},
	};
};

//For LOGIN
export const login = (email, password) => async dispatch => {
	try {
		const response = await authAPI.login(email, password);
		dispatch(showLoader());
		if (response.status >= 200 && response.status < 300) {
			localStorage.setItem("token", response.data.access_token);
			localStorage.setItem("userId", response.data.user.id);
			localStorage.setItem("email", response.data.user.email);
			localStorage.setItem("departmentId", response.data.user.departmentId);
			localStorage.setItem("userName", response.data.user.pib);
			localStorage.setItem("userRole", JSON.stringify(response.data.user.roles));
			localStorage.setItem("departmentEntryPointId", response.data.user.departmentEntryPointId);
			localStorage.setItem(
				"departmentHierarchyId",
				response.data.departmentHierarchyUser ? response.data.departmentHierarchyUser.departmentHierarchyId : null
			);
			dispatch(
				setAuthData(
					localStorage.getItem("token"),
					localStorage.getItem("userId"),
					localStorage.getItem("email"),
					localStorage.getItem("departmentId"),
					localStorage.getItem("userName"),
					JSON.parse(localStorage.getItem("userRole")),
					localStorage.getItem("departmentEntryPointId"),
					localStorage.getItem("departmentHierarchyId")
				)
			);
			dispatch(setIsApiHealthy(true));
			dispatch(setSuccess(`${localStorage.getItem("userName")}, вітаємо у системі!`));
			dispatch(hideLoader());
		}
	} catch (error) {
		if (error.response.status === 401) {
			dispatch(setError(`Помилка авторизаціі! Невірний логін або пароль.`));
		}
		if (error.response.status === 403) {
			console.log(`Доступ заборонено: ${error.message}`);
		}
	}
};

export const domainLogin = () => async dispatch => {
	try {
		const response = await authAPI.domainLogin();
		dispatch(showLoader());
		if (response.status >= 200 && response.status < 300) {
			localStorage.setItem("token", response.data.access_token);
			localStorage.setItem("userId", response.data.user.id);
			localStorage.setItem("email", response.data.user.email);
			localStorage.setItem("departmentId", response.data.user.departmentId);
			localStorage.setItem("userName", response.data.user.pib);
			localStorage.setItem("userRole", JSON.stringify(response.data.user.roles));
			localStorage.setItem("departmentEntryPointId", response.data.user.departmentEntryPointId);
			localStorage.setItem(
				"departmentHierarchyId",
				response.data.departmentHierarchyUser ? response.data.departmentHierarchyUser.departmentHierarchyId : null
			);
			dispatch(
				setAuthData(
					localStorage.getItem("token"),
					localStorage.getItem("userId"),
					localStorage.getItem("email"),
					localStorage.getItem("departmentId"),
					localStorage.getItem("userName"),
					JSON.parse(localStorage.getItem("userRole")),
					localStorage.getItem("departmentEntryPointId"),
					localStorage.getItem("departmentHierarchyId")
				)
			);
			dispatch(setIsApiHealthy(true));
			dispatch(setSuccess(`${localStorage.getItem("userName")}, вітаємо у системі!`));
			dispatch(hideLoader());
		}
	} catch (error) {
		if (error.response.status === 401) {
			dispatch(setError(`Помилка авторизаціі! Невірний логін або пароль.`));
		}
		if (error.response.status === 403) {
			console.log(`Доступ заборонено: ${error.message}`);
		}
	}
};

export const getAuthData = () => dispatch => {
	dispatch(
		setAuthData(
			localStorage.getItem("token"),
			localStorage.getItem("userId"),
			localStorage.getItem("email"),
			localStorage.getItem("departmentId"),
			localStorage.getItem("userName"),
			JSON.parse(localStorage.getItem("userRole")),
			localStorage.getItem("departmentEntryPointId"),
			localStorage.getItem("departmentHierarchyId")
		)
	);
};

export const logOut = () => dispatch => {
	localStorage.removeItem("token");
	localStorage.removeItem("userId");
	localStorage.removeItem("email");
	localStorage.removeItem("departmentId");
	localStorage.removeItem("userName");
	localStorage.removeItem("userRoleId");
	localStorage.removeItem("departmentEntryPointId");
	localStorage.removeItem("departmentHierarchyId");
	dispatch(setSuccess(null));
	dispatch(setAppInit(false));
	dispatch(setAuthData(null, null, null, null, null, null, null, null));
};

export const sendNewPassword = email => async dispatch => {
	try {
		const response = await authAPI.sendNewPassword(email);
		if (response.status >= 200 && response.status < 300) {
			dispatch(setSuccess("Новий пароль успішно відправлено!"));
		}
	} catch (error) {
		dispatch(setApiLastError({ isErrorExists: true }));
		await thunkErrorHandler(error.response, dispatch);
	}
};
