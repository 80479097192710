import { Alert, Button, Card, Checkbox, Col, Divider, Form, Input, Modal, Row, Tabs, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import {
	changeUser,
	getUserAppealOmissionMask,
	getUserDepartmentHierarchyName,
	setUserAppealOmissionMaskCurrent,
	setUserAppealOmissionMaskDefault,
} from "../../redux/actions/userProfileActions";
import { useDispatch, useSelector } from "react-redux";

import css from "./UserProfile.module.scss";
import { dictionaryTypeEnum } from "../../dictionaries/models/dictionaryTypeEnum";
import { getAuthData } from "../../redux/actions/loginActions";
import useDictionary from "../../dictionaries/useDictionaries";

const { Title } = Typography;

const UserProfile = ({ onCancel }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const currentUser = useSelector(state => state.auth);
	const userDepartmentHierarchyName = useSelector(state => state.userProfile.userDepartmentHierarchyName);
	const appealSubjectGroups = useDictionary(dictionaryTypeEnum.APPEAL_SUBJECT_GROUPS);
	const userAppealOmissionMask = useSelector(state => state.userProfile.userAppealOmissionMask);

	const [pib, setPib] = useState(null);
	const [password, setPassword] = useState(null);
	const [newPassword, setNewPassword] = useState(null);
	const [confirmNewPassword, setConfirmNewPassword] = useState(null);

	//CheckBox states
	const [checkBoxesState, setCheckBoxesState] = useState([false, false, false, false, false, false, false, false]);

	useEffect(() => {
		if (currentUser.departmentId === "null") return;
		dispatch(getUserAppealOmissionMask());
		dispatch(getUserDepartmentHierarchyName());
	}, [dispatch, currentUser.departmentId]);

	//Set check boxes states first time (and after save or reset to default)
	useEffect(() => {
		if (!userAppealOmissionMask) return;

		if (!(appealSubjectGroups && appealSubjectGroups.length)) return;
		const state = [...checkBoxesState];
		checkBoxesState.forEach((item, i) => {
			state[i] = getCheckBoxesStates("checked", appealSubjectGroups[i].id);
		});
		setCheckBoxesState(state);
	}, [userAppealOmissionMask, appealSubjectGroups]);

	const onChangeUserData = () => {
		if (!pib && !password && !newPassword) return;
		if (confirmNewPassword !== newPassword) {
			message.warning("Підтвердження нового паролю відрізняється від нового паролю!");
			return;
		}
		const userProfileData = { pib, password, newPassword };
		dispatch(changeUser(userProfileData));

		form.setFieldsValue({
			oldPassword: "",
			newPassword: "",
			confirmNewPassword: "",
		});

		//Change data in redux (auth) and local storage
		pib && localStorage.setItem("userName", pib.toString());
		dispatch(getAuthData());
	};

	const onFinishFailed = errorInfo => {
		console.log("Failed:", errorInfo);
	};

	const onCheckBoxChange = (e, index) => {
		const state = [...checkBoxesState];
		state[index] = e.target.checked;
		setCheckBoxesState(state);
	};

	//Check boxes state by response (defines by response masks)
	const getCheckBoxesStates = (checkBoxPropName, groupId) => {
		if (!userAppealOmissionMask) return;
		const prop = {
			disabled: [],
			checked: [],
		};
		//current mask === null
		if (userAppealOmissionMask.appealSubjectOmissionMaskCurrent === null) {
			appealSubjectGroups.forEach(gr => {
				prop.disabled.push(!!(userAppealOmissionMask.appealSubjectOmissionMaskAllowed & gr.omissionMaskCode));
				prop.checked.push(!!(userAppealOmissionMask.appealSubjectOmissionMaskDefault & gr.omissionMaskCode));
			});
			//current mask !== null
		} else {
			appealSubjectGroups.forEach(gr => {
				prop.disabled.push(!!(userAppealOmissionMask.appealSubjectOmissionMaskAllowed & gr.omissionMaskCode));
				prop.checked.push(!!(userAppealOmissionMask.appealSubjectOmissionMaskCurrent & gr.omissionMaskCode));
			});
		}

		groupId = groupId - 1; //cause array starts from 0
		if (checkBoxPropName === "disabled") return prop.disabled[groupId];
		if (checkBoxPropName === "checked") return prop.checked[groupId];
	};

	const isAnySubjectCheckBoxEnabled = () => {
		const resultArr = [];
		appealSubjectGroups.forEach((_, i) => {
			resultArr.push(getCheckBoxesStates("disabled", i + 1));
		});

		return resultArr.includes(true);
	};

	const onSaveUserAppealOmissionMask = () => {
		let mask = 0;
		const appealSubjectOmissionMaskAllowed = userAppealOmissionMask.appealSubjectOmissionMaskAllowed;
		const maskCodesCheckedPropArray = [];
		checkBoxesState.forEach((item, i) => {
			if (item) {
				maskCodesCheckedPropArray.push(appealSubjectGroups[i].omissionMaskCode);
			}
		});

		maskCodesCheckedPropArray.forEach(code => {
			mask = mask | code;
		});

		mask = mask & appealSubjectOmissionMaskAllowed;
		dispatch(setUserAppealOmissionMaskCurrent(mask));
		dispatch(getUserAppealOmissionMask());
	};

	const onSaveUserAppealOmissionMaskDefault = () => {
		dispatch(setUserAppealOmissionMaskDefault());
		dispatch(getUserAppealOmissionMask());
	};

	//if (!isAuth) navigate("/login");

	return (
		<Modal
			className={css.user_profile_wrapper}
			destroyOnClose
			open={true}
			onCancel={onCancel}
			footer={[
				<Button key='back' onClick={onCancel}>
					Закрити
				</Button>,
			]}
		>
			<div className={css.modal_header}>Профіль</div>
			<Tabs
				type='line'
				items={[
					{
						key: 1,
						label: "Загальна інформація",
						children: (
							<>
								<Row className={css.item}>
									<Col className={css.item_title}>ПІБ:</Col>
									<Col className={css.item_description}>{currentUser.userName}</Col>
								</Row>
								<Row className={css.item}>
									<Col className={css.item_title}>Email:</Col>
									<Col className={css.item_description}>{currentUser.email}</Col>
								</Row>
								<Row className={css.item}>
									<Col className={css.item_title}>Роль:</Col>
									<Col className={css.item_description}>
										{currentUser.userRole ? currentUser.userRole[0] : null}
									</Col>
								</Row>

								<Divider>Редагування інформації</Divider>

								<Form
									layout='vertical'
									form={form}
									preserve={false}
									onFinish={onChangeUserData}
									onFinishFailed={onFinishFailed}
									initialValues={{
										pib: currentUser.userName,
										oldPassword: null,
									}}
								>
									<Form.Item label="Прізвище Ім'я По-батькові" name='pib'>
										<Input
											placeholder={"Не зміниться при збереженні, якщо пусто"}
											onChange={e => setPib(e.target.value)}
										/>
									</Form.Item>

									<Card title={<Title level={5}>Зміна паролю</Title>} className={css.card}>
										<Form.Item
											style={{ width: "100%" }}
											label='Старий пароль (лишіть пустим, щоб не змінювати)'
											name='oldPassword'
											//rules={[{required: true, message: 'Будь ласка введіть старий пароль!'}]}
										>
											<Input.Password onChange={e => setPassword(e.target.value ? e.target.value : null)} />
										</Form.Item>
										<Form.Item
											style={{ width: "100%" }}
											label='Новий пароль (лишіть пустим, щоб не змінювати)'
											name='newPassword'
											rules={[{ required: !!password, message: "Будь ласка введіть новий пароль!" }]}
										>
											<Input.Password
												onChange={e => setNewPassword(e.target.value ? e.target.value : null)}
											/>
										</Form.Item>
										<Form.Item
											style={{ width: "100%" }}
											label='Підтвердити новий пароль'
											name='confirmNewPassword'
											rules={[
												{ required: !!newPassword, message: "Будь ласка введіть підтвердження паролю!" },
											]}
										>
											<Input.Password
												onChange={e => setConfirmNewPassword(e.target.value ? e.target.value : null)}
											/>
										</Form.Item>
									</Card>

									<Form.Item>
										<Button type='primary' htmlType='submit' className={css.site_button}>
											Змінити
										</Button>
									</Form.Item>
								</Form>
							</>
						),
					},
					{
						key: 2,
						label: "Звернення",
						children: (
							<>
								{currentUser.departmentId === "null" ? (
									<Alert
										message='Зверніть увагу'
										description='Ви не можете реагувати на звернення. За необхідності зверніться до Адміністратора системи.'
										type='info'
										showIcon
									/>
								) : (
									<>
										<Row className={css.item}>
											<Col className={css.item_title}>Відділ підрозділа:</Col>
											<Col className={css.item_description}>{`"${userDepartmentHierarchyName}"`}</Col>
										</Row>
										<Divider>Групи тем</Divider>
										{appealSubjectGroups.length && (
											<>
												<Row>
													<Col>
														<Checkbox
															checked={checkBoxesState[0]}
															disabled={!getCheckBoxesStates("disabled", appealSubjectGroups[0].id)}
															onChange={e => onCheckBoxChange(e, 0)}
														>
															{appealSubjectGroups[0].name}
														</Checkbox>
													</Col>
												</Row>
												<Row>
													<Col>
														<Checkbox
															checked={checkBoxesState[1]}
															disabled={!getCheckBoxesStates("disabled", appealSubjectGroups[1].id)}
															onChange={e => onCheckBoxChange(e, 1)}
														>
															{appealSubjectGroups[1].name}
														</Checkbox>
													</Col>
												</Row>
												<Row>
													<Col>
														<Checkbox
															checked={checkBoxesState[2]}
															disabled={!getCheckBoxesStates("disabled", appealSubjectGroups[2].id)}
															onChange={e => onCheckBoxChange(e, 2)}
														>
															{appealSubjectGroups[2].name}
														</Checkbox>
													</Col>
												</Row>
												<Row>
													<Col>
														<Checkbox
															checked={checkBoxesState[3]}
															disabled={!getCheckBoxesStates("disabled", appealSubjectGroups[3].id)}
															onChange={e => onCheckBoxChange(e, 3)}
														>
															{appealSubjectGroups[3].name}
														</Checkbox>
													</Col>
												</Row>
												<Row>
													<Col>
														<Checkbox
															checked={checkBoxesState[4]}
															disabled={!getCheckBoxesStates("disabled", appealSubjectGroups[4].id)}
															onChange={e => onCheckBoxChange(e, 4)}
														>
															{appealSubjectGroups[4].name}
														</Checkbox>
													</Col>
												</Row>
												<Row>
													<Col>
														<Checkbox
															checked={checkBoxesState[5]}
															disabled={!getCheckBoxesStates("disabled", appealSubjectGroups[5].id)}
															onChange={e => onCheckBoxChange(e, 5)}
														>
															{appealSubjectGroups[5].name}
														</Checkbox>
													</Col>
												</Row>
												<Row>
													<Col>
														<Checkbox
															checked={checkBoxesState[6]}
															disabled={!getCheckBoxesStates("disabled", appealSubjectGroups[6].id)}
															onChange={e => onCheckBoxChange(e, 6)}
														>
															{appealSubjectGroups[6].name}
														</Checkbox>
													</Col>
												</Row>
												<Row>
													<Col>
														<Checkbox
															checked={checkBoxesState[7]}
															disabled={!getCheckBoxesStates("disabled", appealSubjectGroups[7].id)}
															onChange={e => onCheckBoxChange(e, 7)}
														>
															{appealSubjectGroups[7].name}
														</Checkbox>
													</Col>
												</Row>
												<Row>
													<Col className={css.button_wrapper}>
														<Button
															type='primary'
															onClick={onSaveUserAppealOmissionMask}
															disabled={!isAnySubjectCheckBoxEnabled()}
														>
															Зберегти
														</Button>
													</Col>
													<Col className={css.button_wrapper}>
														<Button
															type='default'
															onClick={onSaveUserAppealOmissionMaskDefault}
															disabled={!isAnySubjectCheckBoxEnabled()}
														>
															Повернути по замовчуванню
														</Button>
													</Col>
												</Row>
											</>
										)}
									</>
								)}
							</>
						),
					},
				]}
			/>
		</Modal>
	);
};

export default UserProfile;
