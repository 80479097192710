export const shutdownTypeEnum = Object.freeze({
	EMERGENCY: 1,
	PLANNED: 2,
});

export const emergencyShutdownStatusEnum = Object.freeze({
	UNCONFIRMED: 6,
	CONFIRMED: 7,
	CLOSED: 8,
});

export const plannedShutdownStatusEnum = Object.freeze({
	PROJECT: 1,
	ALLOWED: 2,
	ACTIVE: 3,
	DISCARD: 4,
	CLOSED: 5,
});

export const recipientTypeEnum = Object.freeze({
	DEPARTMENT_DIRECTOR: 1,
	DEPARTMENT_MAIN_ENGINEER: 2,
	ODG_LEADER: 3,
	DEPUTY_OF_BALANCE: 4,
	ODS: 6,
	RM_DEPARTMENT_DIRECTOR: 7,
});

export const recipientDepartmentHierarchyEnum = Object.freeze({
	DEPARTMENT_DIRECTOR: "/1/1/",
	DEPARTMENT_MAIN_ENGINEER: "/1/1/1/",
	ODG_LEADER: "/1/1/1/1/",
	DEPUTY_OF_BALANCE: "/1/1/3/",
	ODS: "/2/9/2/",
	RM_DEPARTMENT_DIRECTOR: "/2/9/3/",
});
