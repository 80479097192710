export const claimStatusEnum = Object.freeze({
	PROJECT: 1,
	ALLOWED: 2,
	ACTIVE: 3,
	DISCARD: 4,
	CLOSED: 5,
});

export const claimApprovalLevelEnum = Object.freeze({
	INITIATOR: 1,
	MAIN_ENGINEER: 2,
	ODS: 3,
	ODG_REM: 4,
});

export const claimTypeEnum = Object.freeze({
	EMERGENCY: 1,
	PLANNED: 2,
	URGENT: 3,
});

export const claimRepairTypeEnum = Object.freeze({
	EMERGENCY: 1,
	PLANNED: 2,
	UNSCHEDULED: 3,
});
