import { Button, Form, Input, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { domainLogin, login, sendNewPassword } from "../../redux/actions/loginActions";
import { useDispatch, useSelector } from "react-redux";

import Divider from "antd/es/divider";
import css from "./Login.module.scss";
import openNotify from "../../assets/js/notification";
import { setError } from "../../redux/actions/appActions";

const layout = {
	labelCol: { span: 5 },
	wrapperCol: { span: 16 },
};

const Login = () => {
	const dispatch = useDispatch();
	const errorText = useSelector(state => state.app.error);

	const [isStandartLogin, setStandartLogin] = useState(false);
	const [passRecoveryMode, setPassRecoveryMode] = useState(false);
	const [isRecoveryDone, setRecoveryDone] = useState(false);
	const [recoveryEmailValue, setRecoveryEmailValue] = useState("");

	const onChangeLoginType = checked => {
		setStandartLogin(checked);
	};

	const onDomainLogin = values => {
		dispatch(domainLogin());
	};

	const onLogin = values => {
		dispatch(login(values.email, values.password));
	};

	const onLoginFailed = errorInfo => {
		console.log("Failed:", errorInfo);
	};

	const onRecoveryPasword = () => {
		dispatch(sendNewPassword(recoveryEmailValue));
		setRecoveryDone(true);
	};

	const onReturnToLoginForm = () => {
		setPassRecoveryMode(false);
		setRecoveryDone(false);
		setRecoveryEmailValue("");
	};

	//For error notification
	useEffect(() => {
		if (!errorText) return;
		console.log(errorText);
		openNotify("error", "Помилка!", errorText);
		dispatch(setError(null));
	}, [errorText, dispatch]);

	return (
		<>
			{!passRecoveryMode && (
				<div className={css.login_wrapper}>
					<div className={css.form_title}>
						Вхід до системи управління
						<br />
						плановими та аварійними відключеннями
					</div>

					<div className={css.domain_login_form}>
						<div className={css.title}>За допомогою доменного облікового запису</div>
						<div className={css.continue_btn_wrapp}>
							<Button
								className={!isStandartLogin ? css.site_button : ""}
								style={{ textTransform: "uppercase", margin: 0, marginBottom: "15px" }}
								type='primary'
								onClick={onDomainLogin}
								disabled={isStandartLogin}
							>
								Продовжити
							</Button>
						</div>

						<Divider style={{ margin: "15px 0 30px 0" }} />

						<div className={css.login_type_switch}>
							<Switch onChange={onChangeLoginType} style={{ marginRight: "10px" }} />
							<span>за допомогою локального облікового запису</span>
						</div>
					</div>

					{isStandartLogin && (
						<Form
							className={css.form_content}
							{...layout}
							name='basic'
							onFinish={onLogin}
							onFinishFailed={onLoginFailed}
						>
							<Form.Item
								label='Логін'
								name='email'
								rules={[{ required: true, message: "Будь ласка введіть логін!" }]}
							>
								<Input />
							</Form.Item>

							<Form.Item
								label='Пароль'
								name='password'
								rules={[{ required: true, message: "Будь ласка введіть пароль!" }]}
							>
								<Input.Password />
							</Form.Item>

							<div style={{ textAlign: "center", marginBottom: "20px" }}>
								<Button
									className={css.site_button}
									style={{ textTransform: "uppercase", marginBottom: "0px" }}
									type='primary'
									htmlType='submit'
								>
									Увійти
								</Button>
							</div>

							<div className={css.password_recovery}>
								<Button onClick={() => setPassRecoveryMode(true)} type='link'>
									Забули пароль?
								</Button>
							</div>
						</Form>
					)}
				</div>
			)}

			{passRecoveryMode && (
				<div className={css.login_wrapper}>
					<div className={css.form_title}>Відновлення пароля</div>
					<div className={css.form_content}>
						<div className={css.content_title}>
							{isRecoveryDone
								? "Пароль надіслано на вказану електрону поштову скриньку"
								: "Введіть адресу електронної поштової скриньки, на яку здійснювалась реєстрація"}
						</div>

						{!isRecoveryDone && (
							<Form.Item
								className={css.email}
								label='E-mail'
								rules={[{ required: true, message: "Будь ласка введіть email!" }]}
							>
								<Input
									placeholder='user@hoe.com.ua'
									onChange={e => setRecoveryEmailValue(e.target.value)}
									value={recoveryEmailValue}
								/>
							</Form.Item>
						)}

						<div className={css.password_recovery}>
							{recoveryEmailValue && !isRecoveryDone && (
								<Button className={css.site_button} type='primary' onClick={onRecoveryPasword}>
									Продовжити
								</Button>
							)}

							{isRecoveryDone && (
								<Button className={css.site_button} type='primary' onClick={onReturnToLoginForm}>
									Готово
								</Button>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Login;
