import {
	SET_APPEALS_DATA,
	SET_APPEAL_BILL_FILES_LIST,
	SET_APPEAL_BILL_FILES_LIST_TO_UPLOAD,
	SET_APPEAL_BY_ID,
	SET_APPEAL_CLOSE_COMMENTS,
	SET_APPEAL_FILES_LIST,
	SET_APPEAL_FILES_LIST_TO_UPLOAD,
	SET_APPEAL_IS_EDIT_FLAG,
	SET_APPEAL_PAID_SERVICES,
	SET_APPEAL_SETTLEMENTS,
	SET_APPEAL_SUBJECT_TIMES,
	SET_CURRENT_APPEAL_STATUS,
	SET_MAP_DATA_BY_APPEALS,
	SET_NOT_READ_APPEALS_COUNT,
	SET_SELECTED_APPEAL,
	SET_START_APPEAL_COMMUNICATION_CHANEL,
} from "../types";

const initialState = {
	startAppealStatus: 1,
	currentAppealStatus: null,
	startAppealCommunicationChanel: undefined,
	appeals: [],
	appealPaidServices: [],
	appealSettlements: [],
	selectedAppeal: {},
	appealSubjectTimes: [],
	appealFilesList: [],
	appealFilesListToUpload: [],
	appealBillFilesList: [],
	appealBillFilesListToUpload: [],
	appealById: undefined,
	mapDataByAppeals: [],
	isAppealEdited: false, //Flag for watching if appeal was changed (for reset editing),
	notReadAppealCount: 0,
	appealSubjectCloseComments: [],
};

export const appealReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CURRENT_APPEAL_STATUS:
			return { ...state, currentAppealStatus: action.payload };
		case SET_START_APPEAL_COMMUNICATION_CHANEL:
			return { ...state, startAppealCommunicationChanel: action.payload };
		case SET_APPEAL_SUBJECT_TIMES:
			return { ...state, appealSubjectTimes: action.payload };
		case SET_APPEAL_SETTLEMENTS:
			return { ...state, appealSettlements: action.payload };
		case SET_APPEALS_DATA:
			return { ...state, appeals: action.payload };
		case SET_APPEAL_PAID_SERVICES:
			return { ...state, appealPaidServices: action.payload };
		case SET_SELECTED_APPEAL:
			return { ...state, selectedAppeal: action.payload };
		case SET_APPEAL_FILES_LIST:
			return { ...state, appealFilesList: action.payload };
		case SET_APPEAL_FILES_LIST_TO_UPLOAD:
			return { ...state, appealFilesListToUpload: action.payload };
		case SET_APPEAL_BILL_FILES_LIST:
			return { ...state, appealBillFilesList: action.payload };
		case SET_APPEAL_BILL_FILES_LIST_TO_UPLOAD:
			return { ...state, appealBillFilesListToUpload: action.payload };
		case SET_APPEAL_BY_ID:
			return { ...state, appealById: action.payload };
		case SET_MAP_DATA_BY_APPEALS:
			return { ...state, mapDataByAppeals: action.payload };
		case SET_APPEAL_IS_EDIT_FLAG:
			return { ...state, isAppealEdited: action.payload };
		case SET_NOT_READ_APPEALS_COUNT:
			return { ...state, notReadAppealCount: action.payload };
		case SET_APPEAL_CLOSE_COMMENTS:
			return { ...state, appealSubjectCloseComments: action.payload };
		default:
			return state;
	}
};
