import { Button, Card, Popover, Row, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { approveClaims, getApprovals } from "../../redux/actions/claimActions";
import { defaultPageSize, paginationPosition, tableButtonsSize, tableSize } from "../../assets/js/options";
import { useDispatch, useSelector } from "react-redux";

import ClaimApprovalGroupActionsModal from "./ClaimApprovalGroupActionsModal/ClaimApprovalGroupActionsModal";
import { EyeOutlined } from "@ant-design/icons";
import ViewClaimDrawer from "../Claims/ViewClaimDrawer/ViewClaimDrawer";
import { approvalGetParameters } from "../../api/parameters";
import css from "./ClaimApprovalsPage.module.scss";
import { setPageBreadcrumb } from "../../redux/actions/appActions";
import { showTotal } from "../../assets/js/helpers/showTotal";

const ClaimApprovalsPage = ({ text }) => {
	const dateFormat = "YYYY-MM-DD HH:mm";
	const dispatch = useDispatch();

	//Selectors
	const approvals = useSelector(state => state.claim.approvals.data);
	const approvalTotalItems = useSelector(state => state.claim.approvals.count);

	//Local states
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [groupActionModalVisible, setGroupActionModalVisible] = useState(false);
	const [groupActionType, setGroupActionType] = useState("");
	const [viewModalVisible, setViewModalVisible] = useState(false);
	const [selectedClaimId, setSelectedClaimId] = useState(null);

	const claimTableColumns = [
		{
			title: "№",
			dataIndex: "id",
			key: "id",
			width: 60,
		},
		{
			title: "Служба",
			dataIndex: "createdByDepartmentHierarchyName",
			key: "createdByDepartmentHierarchyName",
			width: 180,
		},
		{
			title: "Вид заявки",
			dataIndex: "claimTypeName",
			key: "claimTypeName",
			width: 100,
		},
		{
			title: "Авар. готов, год",
			dataIndex: "claimEmergencyReadinessValue",
			key: "claimEmergencyReadinessValue",
			width: 100,
		},
		{
			title: "Дата початку",
			dataIndex: "startDate",
			key: "startDate",
			sorter: true,
			width: 95,
		},
		{
			title: "Дата заверш.",
			dataIndex: "endDate",
			key: "endDate",
			width: 95,
		},
		{
			title: "Назва електроустановки",
			dataIndex: "electricAddress",
			key: "electricAddress",
		},
		{
			title: "Мета відключення",
			dataIndex: "shutdownPurpose",
			key: "shutdownPurpose",
			width: 320,
		},
		{
			title: "Дії",
			dataIndex: "actions",
			key: "actions",
			align: "left",
			width: 90,
		},
	];

	const getClaimDataForTable = () => {
		let moment = require("moment");
		if (approvals) {
			return approvals?.map(claim => {
				return {
					key: claim.id,
					id: claim.id,
					createdByDepartmentHierarchyName: claim.createdByDepartmentHierarchyName,
					claimTypeName: claim.claimTypeName,
					claimEmergencyReadinessValue: claim.claimEmergencyReadinessValue + " год",
					startDate: moment(claim.startDate).format(dateFormat),
					endDate: moment(claim.endDate).format(dateFormat),
					electricAddress: claim.electricAddress,
					shutdownPurpose: claim.shutdownPurpose,
					actions: (
						<span className={css.table_action_btn}>
							<Tooltip placement='topLeft' title='Перегляд заявки' mouseEnterDelay={0.7}>
								<Button size={tableButtonsSize} onClick={() => onViewClaim(claim.id)}>
									<EyeOutlined />
								</Button>
							</Tooltip>
						</span>
					),
				};
			});
		}
	};

	// rowSelection object indicates the need for row selection
	const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
		setSelectedRowKeys(newSelectedRowKeys);
		setSelectedRows(newSelectedRows);
	};

	const tableRowSelection = {
		selectedRowKeys,
		selectedRows,
		onChange: onSelectChange,
	};

	const groupActionsContent = (
		<div className={css.group_operations_content}>
			{selectedRows.length > 0 ? (
				<div>
					<Button
						className={css.group_actions_button}
						type='default'
						onClick={() => {
							setGroupActionModalVisible(true);
							setGroupActionType("approve");
						}}
					>
						Погодження
					</Button>
					<Button
						className={css.group_actions_button}
						type='default'
						onClick={() => {
							setGroupActionModalVisible(true);
							setGroupActionType("decline");
						}}
					>
						Відхилення
					</Button>
				</div>
			) : (
				<div style={{ color: "red" }}>Не обрано жодної заявки, або кількість обраних недостатня!</div>
			)}
		</div>
	);

	const onGroupModalSave = modalValues => {
		if (!modalValues) return;

		let data = undefined;
		let claimIds = [];
		selectedRowKeys.forEach(x => {
			let claimId = approvals.find(item => item.id === x)?.id;
			claimIds.push(claimId);
		});

		//To confirm
		if (groupActionType === "approve") {
			data = {
				isApproved: true,
				description: modalValues.description || null,
				claimIds: claimIds,
			};
		}

		//To close
		if (groupActionType === "decline") {
			data = {
				isApproved: false,
				description: modalValues.description,
				claimIds: claimIds,
			};
		}

		dispatch(approveClaims(data));
		onGroupModalCancel();
	};

	const onGroupModalCancel = () => {
		setGroupActionModalVisible(false);
		setSelectedRowKeys([]);
		setSelectedRows([]);
		setGroupActionType("");
	};

	const onViewClaim = claimId => {
		setSelectedClaimId(claimId);
		setViewModalVisible(true);
	};

	useEffect(() => {
		const breadCrumbData = [{ name: text, link: false }];
		dispatch(setPageBreadcrumb(breadCrumbData));
		dispatch(getApprovals(approvalGetParameters));
	}, [dispatch, text]);

	return (
		<>
			<Row className={css.claim_aprovals_page_container}>
				<Popover content={groupActionsContent} title='Обробка:'>
					{selectedRows.length > 0 && (
						<Button className={css.site_button} type='primary'>
							Обробка ({selectedRows.length})
						</Button>
					)}
				</Popover>

				<Card bodyStyle={{ padding: "0px" }} bordered={true} style={{ width: "100%" }}>
					<Table
						className={css.ant_table_wrapper}
						size={tableSize}
						pagination={{
							position: paginationPosition,
							defaultPageSize: defaultPageSize,
							showSizeChanger: true,
							total: approvalTotalItems,
							current: approvalGetParameters.page,
							showTotal: showTotal,
						}}
						//scroll={claimTableScroll}
						columns={claimTableColumns}
						dataSource={getClaimDataForTable()}
						rowSelection={{ ...tableRowSelection }}
						rowClassName={(record, index) => (index % 2 === 0 ? css.table_row_light : css.table_row_dark)}
					/>
				</Card>

				<ClaimApprovalGroupActionsModal
					showModal={groupActionModalVisible}
					onCancel={onGroupModalCancel}
					groupActionType={groupActionType}
					selectedRowsCount={selectedRows.length}
					onSave={onGroupModalSave}
				/>

				<ViewClaimDrawer
					open={viewModalVisible}
					onClose={() => {
						setViewModalVisible(false);
					}}
					claimId={selectedClaimId}
				/>
			</Row>
		</>
	);
};

export default ClaimApprovalsPage;
