import { defaultPageSize } from "../assets/js/options";

//For get all users
export const usersGetParameters = {
	filter: "",
	page: 1, //Start page view
	pageSize: defaultPageSize, //How mutch rows need to view on one page (combobox 10-20-100)
	orderBy: null, //Column name
};

//For get all job types
export const shutdownJobTypesGetParameters = {
	filter: "isActive=true",
	page: 1,
	pageSize: defaultPageSize,
	orderBy: null,
};

export const shutdownJobTypesGetParametersAdmin = {
	filter: null,
	page: 1,
	pageSize: defaultPageSize,
	orderBy: null,
};

//For get all recipients
export const recipientsGetParameters = {
	filter: null,
	page: 1,
	pageSize: defaultPageSize,
	orderBy: null,
};

//For get all shutdowns
export const shutdownsGetParameters = {
	filter: "",
	page: 1,
	pageSize: defaultPageSize,
	orderBy: null,
};

//For get all appeals
export const appealGetParameters = {
	filter: "",
	page: 1,
	pageSize: defaultPageSize,
	orderBy: null,
};

//For get all tasks
export const tasksGetParameters = {
	filter: "",
	page: 1,
	pageSize: defaultPageSize,
	orderBy: null,
};

//For get all claims
export const claimGetParameters = {
	filter: "",
	page: 1,
	pageSize: defaultPageSize,
	orderBy: null,
};

//For get all approvals
export const approvalGetParameters = {
	filter: "",
	page: 1,
	pageSize: defaultPageSize,
	orderBy: null,
};

//For get all scada events
export const scadaEventsGetParameters = {
	filter: "",
	page: 1,
	pageSize: defaultPageSize,
	orderBy: null,
};

//For get all smart
export const smartGetParameters = {
	filter: "",
	page: 1,
	pageSize: defaultPageSize,
	orderBy: null,
};
