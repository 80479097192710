import { adminReducer } from "./adminReducer";
import { appReducer } from "./appReducer";
import { appealReducer } from "./appealReducer";
import { claimReducer } from "./claimReducer";
import { combineReducers } from "redux";
import { dashboardReducer } from "./dashboardReducer";
import { dictionaryReducer } from "./dictionaryReducer";
import { holidaysReducer } from "./holidaysReducer";
import { loginReducer } from "./loginReducer";
import { ovbReducer } from "./ovbReducer";
import { reportsReducer } from "./reportsReducer";
import { scadaEventsReducer } from "./scadaEventsReducer";
import { settingsReducer } from "./settingsReducer";
import { shutdownReducer } from "./shutdownReducer";
import { shutdownsCalendarReducer } from "./shutdownsCalendarReducer";
import { smartReducer } from "./smartReducer";
import { statsReducer } from "./statsReducer";
import { statusesMatrixReducer } from "./statusesMatrixReducer";
import { taskReducer } from "./taskReducer";
import { userProfileReducer } from "./userProfileReducer";

export const rootReducer = combineReducers({
	admin: adminReducer,
	app: appReducer,
	auth: loginReducer,
	appeal: appealReducer,
	claim: claimReducer,
	dashboard: dashboardReducer,
	dictionary: dictionaryReducer,
	holidays: holidaysReducer,
	ovb: ovbReducer,
	reports: reportsReducer,
	scada: scadaEventsReducer,
	settings: settingsReducer,
	shutdown: shutdownReducer,
	shutdownsCalendar: shutdownsCalendarReducer,
	smart: smartReducer,
	stats: statsReducer,
	statusesMatrix: statusesMatrixReducer,
	task: taskReducer,
	userProfile: userProfileReducer,
});
