//"filters" - parameter from tableChange event (one level up), object
//"tableFilterNamesArr" - names array of the table filter fields (string). *Sinchronic with fieldNamesInDBArr!
//"fieldNamesInDBArr" - name fields in database table which we want to filter (string). *sinchronic with tableFilterNamesArr!
//"operationsArr" - operations that fields have to do with values:

// returns built filter string
/*
Equal 	= 	"FieldName = Value"
NotEqual 	!= 	"FieldName !=Value"
LessThan 	< 	"FieldName < Value"
GreaterThan 	> 	"FieldName > Value"
GreaterThanOrEqual 	>= 	"FieldName >=Value"
LessThanOrEqual 	<= 	"FieldName <=Value"
Contains - Like 	=* 	"FieldName =*Value"
NotContains - NotLike 	!* 	"FieldName !*Value"
StartsWith 	^ 	"FieldName ^ Value"
NotStartsWith 	!^ 	"FieldName !^ Value"
EndsWith 	$ 	"FieldName $ Value"
NotEndsWith 	!$ 	"FieldName !$ Value"
*/

export const tableFilterStringBuilder = (filters, tableFilterNamesArr, fieldNamesInDBArr, operationsArr = null) => {
	const filterStringArray = [];
	if (filters) {
		tableFilterNamesArr.forEach((item, i) => {
			if (filters[item]) {
				let filterString = "";
				let operation = operationsArr ? operationsArr[i] : "=";
				filters[item].forEach(fltName => {
					filterString += "|" + fieldNamesInDBArr[i] + operation + fltName;
				});

				filterString = filterString.substring(1);
				filterString = `(${filterString})`;
				filterStringArray.push(filterString);
			}
		});

		let result = "";
		filterStringArray.forEach(item => {
			if (item) result += "," + item;
		});

		if (result) {
			return result.substring(1);
		} else return "";
	}
};

//Old Filters
//------------------
// let shutdownStatusFilter = '', DepartmentFilter = '', shutdownJobTypeFilter = '';
// if (filters) {
//     if (filters.shutdownStatusName) {
//         filters.shutdownStatusName.forEach(item => {
//             shutdownStatusFilter += '|shutdownStatusId=' + item;
//         });
//         shutdownStatusFilter = shutdownStatusFilter.substring(1);
//         shutdownStatusFilter = `(${shutdownStatusFilter})`
//     }
//
//     if (filters.DepartmentName) {
//         filters.DepartmentName.forEach(item => {
//             DepartmentFilter += '|DepartmentId=' + item;
//         });
//         DepartmentFilter = DepartmentFilter.substring(1);
//         DepartmentFilter = `(${DepartmentFilter})`
//     }
//
//     if (filters.shutdownJobTypeName) {
//         filters.shutdownJobTypeName.forEach(item => {
//             shutdownJobTypeFilter += '|shutdownJobTypeId=' + item;
//         });
//         shutdownJobTypeFilter = shutdownJobTypeFilter.substring(1);
//         shutdownJobTypeFilter = `(${shutdownJobTypeFilter})`
//     }
//
//     const filterArray = [shutdownStatusFilter, DepartmentFilter, shutdownJobTypeFilter];
//     let result = '';
//     filterArray.forEach((item) => {
//         if (item) result += ',' + item;
//     });
//     if (result) shutdownsGetParameters.filter += ',' + result.substring(1);
// }

//-------------------------
