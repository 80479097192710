import { Button, Card, Col, DatePicker, Input, Row, Table, Tag, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { defaultPageSize, paginationPosition, tableButtonsSize, tableSize } from "../../assets/js/options";
import { getTasks, setTaskAppeal } from "../../redux/actions/taskActions";
import { useDispatch, useSelector } from "react-redux";

import ClearOutlined from "@ant-design/icons/es/icons/ClearOutlined";
import EyeOutlined from "@ant-design/icons/es/icons/EyeOutlined";
import FilterFilled from "@ant-design/icons/es/icons/FilterFilled";
import ModalForDeleteTask from "./ModalForDeleteTask/ModalForDeleteTask";
import { NavLink } from "react-router-dom";
import ViewTaskDrawer from "./ViewTaskDrawer/ViewTaskDrawer";
import css from "./Tasks.module.scss";
import { dictionaryTypeEnum } from "../../dictionaries/models/dictionaryTypeEnum";
import { scrollAntTableToTop } from "../../assets/js/helpers/scrollAntTableToTop";
import { setPageBreadcrumb } from "../../redux/actions/appActions";
import { setTaskStatusTagColor } from "./helpers/otherTaskFns";
import { showTotal } from "../../assets/js/helpers/showTotal";
import { tableFilterStringBuilder } from "../../assets/js/helpers/tableFilterStringBuilder";
import { tableSortBuilder } from "../../assets/js/helpers/tableSortBuilder";
import { taskStatusEnum } from "./enums/taskEnums";
import { tasksGetParameters } from "../../api/parameters";
import useDebounce from "../../assets/js/hooks/useDebounce";
import useDebouncedEffect from "../../assets/js/hooks/useDebouncedEffect";
import useDictionary from "../../dictionaries/useDictionaries";
import useIsMount from "../../assets/js/hooks/useIsMount";

const Tasks = ({ text }) => {
	const dateFormat = "YYYY-MM-DD HH:mm";
	const dispatch = useDispatch();
	const isMount = useIsMount();

	//Selectors
	const currentUserRole = useSelector(state => state.auth.userRole);
	const tasks = useSelector(state => state.task.tasks.data);
	const taskTotalItems = useSelector(state => state.task.tasks.count);

	//For table
	//For table filters, sorters and searchers (selectors, refs, states)
	const taskStatuses = useDictionary(dictionaryTypeEnum.TASK_STATUSES);
	const departments = useDictionary(dictionaryTypeEnum.DEPARTMENTS);

	//Local states
	//Modals
	const [viewModalVisible, setViewModalVisible] = useState(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const [selectedTaskId, setSelectedTaskId] = useState(null);

	const [sorteredInfo, setSorteredInfo] = useState(null);
	const [filteredInfo, setFilteredInfo] = useState(null);
	const [filterString, setFilterString] = useState("");
	const [searchString, setSearchString] = useState("");
	const [tasksGetParametersLocal, setTasksGetParametersLocal] = useState({ ...tasksGetParameters });
	const [departmentHierarchyUserNameSearch, setDepartmentHierarchyUserNameSearch] = useState("");
	const debouncedDepartmentHierarchyUserNameSearch = useDebounce(departmentHierarchyUserNameSearch, 500);

	//For table filter rangepicker
	const { RangePicker } = DatePicker;
	const [createdSearch, setCreatedSearch] = useState("");
	const [dueDateSearch, setDueDateSearch] = useState("");

	const getCreatedFieldColumnFilterProps = () => {
		return {
			filterDropdown: ({ confirm }) => (
				<RangePicker
					value={createdSearch ? createdSearch : ""}
					onChange={value => {
						setCreatedSearch(value);
						confirm();
					}}
				/>
			),
			filterIcon: () => <FilterFilled style={{ color: createdSearch ? "#1890ff" : undefined }} />,
		};
	};

	const getDueDateFieldColumnFilterProps = () => {
		return {
			filterDropdown: ({ confirm }) => (
				<RangePicker
					value={dueDateSearch ? dueDateSearch : ""}
					onChange={value => {
						setDueDateSearch(value);
						confirm();
					}}
				/>
			),
			filterIcon: () => <FilterFilled style={{ color: dueDateSearch ? "#1890ff" : undefined }} />,
		};
	};

	const tasksTableColumns = [
		{
			title: "№",
			dataIndex: "id",
			key: "id",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "id" && sorteredInfo.order : null,
			filteredValue: null,
			width: 80,
		},
		{
			title: "Статус",
			dataIndex: "taskStatusName",
			key: "taskStatusName",
			align: "center",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "taskStatusName" && sorteredInfo.order : null,
			filters:
				taskStatuses &&
				taskStatuses.map(item => {
					return {
						text: item.name,
						value: item.id,
					};
				}),
			filteredValue: filteredInfo ? filteredInfo.taskStatusName : null, //|| ["1", "2", "3"] : ["1", "2", "3"],
			width: 110,
		},
		{
			title: "Підрозділ",
			dataIndex: "departmentName",
			key: "departmentName",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "departmentName" && sorteredInfo.order : null,
			width: 150,
			ellipsis: true,
			filters: departments.map(item => {
				return {
					text: item.name,
					value: item.id,
				};
			}),
			filteredValue: filteredInfo ? filteredInfo.departmentName || null : null,
		},
		{
			title: "Дата створення",
			dataIndex: "created",
			key: "created",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "created" && sorteredInfo.order : null,
			filteredValue: null,
			width: 130,
			...getCreatedFieldColumnFilterProps("created"),
		},
		{
			title: "Виконати до",
			dataIndex: "dueDate",
			key: "dueDate",
			sorter: true,
			sortOrder: sorteredInfo ? sorteredInfo.columnKey === "dueDate" && sorteredInfo.order : null,
			filteredValue: null,
			width: 130,
			...getDueDateFieldColumnFilterProps("dueDate"),
		},
		{
			title: (
				<Input
					placeholder='Виконавець'
					value={departmentHierarchyUserNameSearch}
					onChange={e => {
						onTableColumnSearch(e.target.value, "departmentHierarchyUserName");
					}}
				/>
			),
			dataIndex: "departmentHierarchyUserName",
			key: "departmentHierarchyUserName",
			filteredValue: null,
			ellipsis: true,
			width: 260,
		},
		{
			title: "Зміст завдання",
			dataIndex: "description",
			key: "description",
			sorter: true,
			ellipsis: true,
			filteredValue: null,
			//width: 300
		},
		{
			title: "Дії",
			dataIndex: "actions",
			key: "actions",
			align: "left",
			filteredValue: null,
			width: 140,
		},
	];

	const getTasksDataForTable = () => {
		let moment = require("moment");
		if (tasks) {
			return tasks.map(task => {
				return {
					key: task.id,
					id: task.id,
					taskStatusId: task.taskStatusId,
					taskStatusName: (
						<NavLink
							disabled={task.taskStatusId === taskStatusEnum.CLOSED}
							to={{
								pathname: task.taskStatusId === taskStatusEnum.CLOSED ? "" : `/tasks/edit-task/${task.id}`,
							}}
						>
							<Tag
								style={{ minWidth: 80, textAlign: "center" }}
								color={setTaskStatusTagColor(task.taskStatusId)}
							>
								{task.taskStatusName}
							</Tag>
						</NavLink>
					),
					created: moment(task.created).format(dateFormat),
					departmentName: task.departmentName,
					departmentHierarchyUserName: task.departmentHierarchyUserName,
					dueDate: moment(task.dueDate).format(dateFormat),
					description: task.description,
					actions: (
						<>
							<span className={css.table_action_btn}>
								<Tooltip placement='topLeft' title='Перегляд завдання' mouseEnterDelay={0.7}>
									<Button size={tableButtonsSize} onClick={() => onViewTask(task.id)}>
										<EyeOutlined />
									</Button>
								</Tooltip>
							</span>
							{task.taskStatusId !== 4 &&
							(currentUserRole.includes("Administrator") ||
								currentUserRole.includes("DepartmentUser") ||
								currentUserRole.includes("DepartmentDispatcher")) ? (
								<span className={css.table_action_btn}>
									<NavLink
										disabled={task.taskStatusId === taskStatusEnum.CLOSED}
										to={{
											pathname:
												task.taskStatusId === taskStatusEnum.CLOSED ? "" : `/tasks/edit-task/${task.id}`,
										}}
									>
										<Tooltip placement='topLeft' title='Редагування завдання' mouseEnterDelay={0.7}>
											<Button size={tableButtonsSize}>
												<EditOutlined />
											</Button>
										</Tooltip>
									</NavLink>
								</span>
							) : (
								<></>
							)}
							{currentUserRole.includes("Administrator") ? (
								<span className={css.table_action_btn}>
									{
										<Tooltip placement='topLeft' title='Видалення завдання' mouseEnterDelay={0.7}>
											<Button size={tableButtonsSize} danger onClick={() => onDeleteTask(task.id)}>
												<DeleteOutlined />
											</Button>
										</Tooltip>
									}
								</span>
							) : (
								<></>
							)}
						</>
					),
				};
			});
		}
	};

	//Search in inputs table header with delay
	const onTableColumnSearch = (value, column) => {
		if (column === "departmentHierarchyUserName") {
			setDepartmentHierarchyUserNameSearch(value);
		}
	};

	const onTaskTableChange = (pagination, filters, sorter) => {
		let tasksGetParametersChanged = { ...tasksGetParametersLocal };
		setFilteredInfo(filters);

		//Sorting
		tableSortBuilder(tasksGetParametersChanged, pagination, sorter);
		setSorteredInfo(sorter);

		//Filtering
		const tableFilterNamesArr = ["taskStatusName", "created", "departmentName", "dueDate"];
		const fieldNamesInDBArr = ["taskStatusId", "created", "departmentId", "dueDate"];
		const filterStringLocal = tableFilterStringBuilder(filters, tableFilterNamesArr, fieldNamesInDBArr);
		setFilterString(filterStringLocal);

		tasksGetParametersChanged.filter = combineFilter(filterStringLocal, searchString);
		setTasksGetParametersLocal(tasksGetParametersChanged);
		scrollAntTableToTop();
	};

	const combineFilter = (filterString, searchString) => {
		if (filterString && searchString) return `(${searchString}),(${filterString})`;
		else if (filterString) return filterString;
		else if (searchString) return searchString;
		else return "";
	};

	const onClearFilters = () => {
		setSorteredInfo(null);
		setFilteredInfo(null);
		setDepartmentHierarchyUserNameSearch("");
		setCreatedSearch("");
		setDueDateSearch("");
		setFilterString("");
		setSearchString("");
		scrollAntTableToTop();

		setTasksGetParametersLocal({ ...tasksGetParameters });
	};

	//Get className for table row + overdue task check (+ className if need)
	const onOverdueTaskCheck = (record, index) => {
		const moment = require("moment");
		const dateTimeNow = moment();
		//Red row if over dueDate (only for "In Work" status)
		let overdueTaskClassName = "";
		if (dateTimeNow.isAfter(moment(record.dueDate)) && record.taskStatusId === taskStatusEnum.IN_PROCESS) {
			overdueTaskClassName = `${css.attention_row}`;
		}

		//For dark and light rows
		if (index % 2 === 0) {
			return `${css.table_row_light} ${overdueTaskClassName}`;
		} else {
			return `${css.table_row_dark} ${overdueTaskClassName}`;
		}
	};

	//On View Task
	const onViewTask = taskId => {
		setSelectedTaskId(taskId);
		setViewModalVisible(true);
	};

	//On Delete task
	const onDeleteTask = taskId => {
		setSelectedTaskId(taskId);
		setDeleteModalVisible(true);
	};

	//Breadcrumbs
	useEffect(() => {
		const breadCrumbData = [{ name: text, link: false }];
		dispatch(setTaskAppeal(null));
		dispatch(setPageBreadcrumb(breadCrumbData));
	}, [dispatch, text]);

	useDebouncedEffect(() => {
		dispatch(getTasks(tasksGetParametersLocal));
	}, [tasksGetParametersLocal, 700]);

	//Full search string creating
	useDebouncedEffect(
		() => {
			if (isMount) return;
			let searchStr = "";
			const searchObjArray = [
				{
					stringValue: createdSearch
						? `(created >=${createdSearch[0].format("YYYY-MM-DD")}, 
									created <=${createdSearch[1].format("YYYY-MM-DD")}T23:59:59)`
						: "",
				},
				{
					stringValue: debouncedDepartmentHierarchyUserNameSearch
						? `(departmentHierarchyUserName =*${debouncedDepartmentHierarchyUserNameSearch})`
						: "",
				},
				{
					stringValue: dueDateSearch
						? `(dueDate >=${dueDateSearch[0].format("YYYY-MM-DD")}, 
									dueDate <=${dueDateSearch[1].format("YYYY-MM-DD")}T23:59:59)`
						: "",
				},
			];

			searchObjArray.forEach(item => {
				if (item.stringValue) {
					searchStr += item.stringValue + ",";
				}
			});

			searchStr = searchStr.slice(0, -1); //delete last comma in string
			setSearchString(searchStr);

			setTasksGetParametersLocal({
				...tasksGetParametersLocal,
				filter: combineFilter(filterString, searchStr),
				page: 1,
			});
		},
		[dispatch, createdSearch, debouncedDepartmentHierarchyUserNameSearch, dueDateSearch],
		100
	);

	return (
		<>
			<Row className={css.tasks}>
				<Col span={12}>
					<NavLink
						to={{
							pathname: "/tasks/create-task",
						}}
					>
						<Button hidden={null} className={css.site_button} type='primary'>
							<PlusOutlined />
							Створити завдання
						</Button>
					</NavLink>
				</Col>
				<Col span={12} className={css.clear_filters_btn_wrap}>
					<Button type='default' className={css.filter_button} onClick={onClearFilters}>
						<ClearOutlined />
						Скинути всі фільтри
					</Button>
				</Col>
				<Card bodyStyle={{ padding: "0px" }} bordered={true} style={{ width: "100%" }}>
					<Table
						onChange={onTaskTableChange}
						className={css.ant_table_wrapper}
						size={tableSize}
						pagination={{
							position: paginationPosition,
							defaultPageSize: defaultPageSize,
							showSizeChanger: true,
							total: taskTotalItems,
							current: tasksGetParametersLocal.page,
							showTotal: showTotal,
						}}
						//scroll={tasksTableScroll}
						showSorterTooltip={false}
						columns={tasksTableColumns}
						dataSource={getTasksDataForTable()}
						rowClassName={(record, index) => onOverdueTaskCheck(record, index)}
					/>
				</Card>
				<ViewTaskDrawer
					visible={viewModalVisible}
					onClose={() => {
						setViewModalVisible(false);
					}}
					taskId={selectedTaskId}
				/>
				<ModalForDeleteTask
					open={deleteModalVisible}
					onCancel={() => setDeleteModalVisible(false)}
					taskId={selectedTaskId}
				/>
			</Row>
		</>
	);
};

export default Tasks;
