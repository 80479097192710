import React from 'react'
import { Spin } from 'antd'
import css from './Preloader.module.scss'

//size: large, middle, small
const Preloader = (props) => {
  return(
      <div className={css.preloader_wrap}>
          <Spin size={props.size} />
      </div>
  )
};

export default Preloader