import React, { useEffect, useState } from "react";
import { appInit, getAppEnvironment, getAppVersion } from "./redux/actions/appActions";
import { getAuthData, logOut } from "./redux/actions/loginActions";
import { useDispatch, useSelector } from "react-redux";

import AppLayout from "./components/Layout/AppLayout";
import { Modal } from "antd";

const App = () => {
	const isAuth = useSelector(state => state.auth.token);
	const error = useSelector(state => state.app.error);
	const dispatch = useDispatch();

	const [isModalVisible, setIsModalVisible] = useState(false);
	useEffect(() => {
		dispatch(getAuthData());
		if (isAuth) {
			dispatch(appInit());
			dispatch(getAppVersion());
			dispatch(getAppEnvironment());
		} else if (error) {
			dispatch(logOut());
		}
	}, [dispatch, isAuth, error]);

	const handleOk = () => {
		setIsModalVisible(false);
	};

	return (
		<div className='App'>
			<AppLayout />

			<Modal title='Сеанс закінчено!' open={isModalVisible} onOk={handleOk} cancelButtonProps={{ visible: "false" }}>
				<p>Час сеансу вичерпано. Залогінтесь у додаток повторно.</p>
			</Modal>
		</div>
	);
};

export default App;
