import { Col, Form, Input, Radio, Row } from "antd";

import DividerGrid from "../../../common/DividerGrid/DividerGrid";
import { PatternFormat } from "react-number-format";
import React from "react";
import { appealCommunicationTypeEnum } from "../../enums/appealEnums";
import css from "./AppealPaidServiceSelectedСommunication.module.scss";

const AppealPaidServiceSelectedСommunication = () => {
	const form = Form.useFormInstance();
	const appealCommunicationTypeId = Form.useWatch("appealCommunicationTypeId", form);

	const onPhoneValueChange = values => {
		form.setFieldsValue({
			phoneUnmasked: values.value,
		});
	};

	return (
		<div className={css.appeal_selected_communication_wrapper}>
			<DividerGrid title={"Обраний заявником канал комунікації"} />

			<Row>
				<Col>
					<Form.Item name='appealCommunicationTypeId'>
						<Radio.Group>
							<Radio value={appealCommunicationTypeEnum.DO_NOT_INFORM}>
								<span className={css.radio_label}>Не інформувати</span>
							</Radio>
							<Radio value={appealCommunicationTypeEnum.SMS}>
								<span className={css.radio_label}>SMS</span>
							</Radio>
							<Radio value={appealCommunicationTypeEnum.PHONE}>
								<span className={css.radio_label}>Телефонний дзвінок</span>
							</Radio>
							<Radio value={appealCommunicationTypeEnum.EMAIL}>
								<span className={css.radio_label}>E-mail</span>
							</Radio>
							<Radio value={appealCommunicationTypeEnum.LETTER}>
								<span className={css.radio_label}>Лист</span>
							</Radio>
						</Radio.Group>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={20}>
				<Col lg={12} md={24}>
					<Form.Item
						label='Телефон:'
						name='phone'
						rules={[
							{
								required:
									appealCommunicationTypeId === appealCommunicationTypeEnum.PHONE ||
									appealCommunicationTypeId === appealCommunicationTypeEnum.SMS,
								pattern:
									appealCommunicationTypeId === appealCommunicationTypeEnum.PHONE
										? "\\([0-9]{3}\\)[0-9]{3}-[0-9]{2}-[0-9]{2}"
										: "(\\([0-9]{3}\\)[0-9]{3}-[0-9]{2}-[0-9]{2})|(\\(x{3}\\)x{3}-x{2}-x{2})",
								message: "Будь ласка, вкажіть вірний формат номера",
							},
						]}
					>
						<PatternFormat
							type={"tel"}
							mask='x'
							format='(###)###-##-##'
							onValueChange={onPhoneValueChange}
							allowEmptyFormatting={true}
							customInput={Input}
						/>
					</Form.Item>
					<Form.Item name='phoneUnmasked' noStyle>
						<Input type='hidden' />
					</Form.Item>
				</Col>
				<Col lg={12} md={24}>
					<Form.Item
						name='email'
						label='E-mail'
						rules={[
							{
								type: "email",
								message: "Введено некоректний e-mail!",
							},
							{
								required: appealCommunicationTypeId === appealCommunicationTypeEnum.EMAIL,
								message: "Будь ласка, вкажіть e-mail!",
							},
						]}
					>
						<Input placeholder='name@mail.com' />
					</Form.Item>
				</Col>
			</Row>
		</div>
	);
};

export default AppealPaidServiceSelectedСommunication;
